import * as moment from 'moment';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-teacher-experience',
  templateUrl: './teacher-experience.component.html',
  styleUrls: ['./teacher-experience.component.scss'],
})
export class TeacherExperienceComponent implements OnInit, OnChanges {
  @Input() countries;
  @Input() grades;
  @Input() subjects;
  @Input() teacherProfileData;
  @Output() submitTeaherExperience = new EventEmitter();

  form: FormGroup;
  submitted = false;
  currentDate = new Date();
  designationOptions = [
    'General Manager',
    'Accountant',
    'Executive Director',
    'Teacher',
    'Administrator',
    'Secondary Teacher',
  ];

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.formPost();
    if (this.teacherProfileData && this.teacherProfileData != undefined) {
      this.bindData();
    }
  }

  ngOnChanges() {
    if (this.teacherProfileData && this.teacherProfileData != undefined) {
      this.bindData();
    }
  }

  formPost() {
    this.form = this.fb.group({
      experiences: this.fb.array([this.createTeacherExperienceFormGroup()]),
    });
  }

  get experiences(): FormArray {
    return this.form.get('experiences') as FormArray;
  }

  createTeacherExperienceFormGroup(): FormGroup {
    return this.fb.group({
      id: [undefined],
      schoolName: [undefined, Validators.required],
      designation: [undefined],
      country: [undefined, Validators.required],
      teachGrade: [undefined],
      teachSubject: [undefined],
      start: [undefined, Validators.required],
      end: [undefined, Validators.required],
    });
  }

  addTeacherExperience() {
    this.experiences.push(this.createTeacherExperienceFormGroup());
  }

  removeTeacherExperience(i: number) {
    this.experiences.removeAt(i);
  }

  bindData() {
    const experiences = this.teacherProfileData.experiences;
    if (experiences && experiences.length === 0) return;
    this.experiences.controls.length = 0;
    experiences.forEach((experience) => {
      const experienceGroup = this.fb.group({
        id: [experience._id],
        schoolName: [experience.schoolName, Validators.required],
        designation: [
          experience.designation ? experience.designation : undefined,
        ],
        country: [experience.country._id, Validators.required],
        teachGrade: [experience.grade ? experience.grade : undefined],
        teachSubject: [experience.subject ? experience.subject : undefined],
        start: [experience.start, Validators.required],
        end: [experience.end, Validators.required],
      });
      this.experiences.push(experienceGroup);
    });
  }

  submit(nextTab) {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    let { experiences } = this.form.getRawValue();

    experiences = experiences.map((experience) => {
      return {
        ...experience,
        start: moment(experience.start).format('YYYY-MM-DD'),
        end: moment(experience.end).format('YYYY-MM-DD'),
      };
    });

    this.submitTeaherExperience.emit({ experiences, nextTab });
  }
}
