import { Injectable } from '@angular/core';
import { GenericService } from '../../generic/generic.service';

@Injectable({
  providedIn: 'root',
})
export class GazzettedHolidayService {
  constructor(private generic: GenericService) {}

  controller = 'attendance/holidays';

  get() {
    return this.generic.getData(this.controller);
  }

  post(body) {
    return this.generic.create(this.controller, body);
  }

  getByID(id) {
    return this.generic.getDataById(this.controller, id);
  }
  delete(id) {
    return this.generic.delete(this.controller, id);
  }
  update(id, body) {
    return this.generic.update(`${this.controller}/${id}`, body);
  }
  search(search, filter, order) {
    return this.generic.getDataBySearch(this.controller, search, filter, order);
  }
}
