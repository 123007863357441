import { GenericService } from '../../generic/generic.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ParentUserService {
  controller = 'parent';

  constructor(private generic: GenericService) {}

  get() {
    return this.generic.getData(this.controller);
  }

  post(body) {
    return this.generic.create(this.controller, body);
  }

  getByID(id) {
    return this.generic.getDataById(this.controller, id);
  }

  delete(id) {
    return this.generic.delete(this.controller, id);
  }

  update(body) {
    return this.generic.update(this.controller, body);
  }

  getbyParams(params) {
    const order = 'desc';
    return this.generic.getDataByParams(this.controller, params, order);
  }

  search(search, filter, order) {
    return this.generic.getDataBySearch(this.controller, search, filter, order);
  }

  dueDateExtension(body) {
    return this.generic.update(
      this.controller + '/dueDateExtension/request',
      body,
    );
  }
  getGmailCreditionals(stdID) {
    return this.generic.getData(`${this.controller}/credentials/${stdID}`);
  }

  getClasses(branch, grade, section) {
    return this.generic.getData(
      `${this.controller}/classes?branch=${branch}&grade=${grade}&section=${section}`,
    );
  }

  getClassById(classId) {
    return this.generic.getDataById(`${this.controller}/classes`, classId);
  }

  getStream(classId, params, order = 'desc', sort = 'createdAt') {
    return this.generic.getDataByParams(
      `${this.controller}/classes/${classId}/stream`,
      params,
      order,
      sort,
    );
  }

  addReaction(classId, streamId, body) {
    return this.generic.create(
      `${this.controller}/classes/${classId}/stream/${streamId}/reaction`,
      body,
    );
  }

  getClassWork(classId) {
    return this.generic.getData(
      `${this.controller}/classes/${classId}/classwork`,
    );
  }

  getByIdClassAssignment(classId, assignmentId, studentId) {
    return this.generic.getData(
      `${this.controller}/classes/${classId}/assignment/${assignmentId}?studentId=${studentId}`,
    );
  }

  getByIdMaterial(classId, materialId) {
    return this.generic.getDataById(
      `${this.controller}/classes/${classId}/material`,
      materialId,
    );
  }

  uploadAnswerDocument(classId, assignmentId, questionId, body) {
    return this.generic.imageUpload(
      `${this.controller}/classes/${classId}/assignment/${assignmentId}/question/${questionId}/answer/upload-attachment`,
      body,
    );
  }

  deleteAnswerDocument(classId, assignmentId, questionId, url) {
    return this.generic.deleteWithOutId(
      `${this.controller}/classes/${classId}/assignment/${assignmentId}/question/${questionId}/answer/delete-attachment?fileUrl=${url}`,
    );
  }

  submitAssignment(classId, assignmentId, studentId, body) {
    return this.generic.create(
      `${this.controller}/classes/${classId}/assignment/${assignmentId}/submit?studentId=${studentId}`,
      body,
    );
  }
}
