import { Component, Inject } from '@angular/core';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-image-crop-modal-component',
  templateUrl: './image-crop-modal-component.component.html',
  styleUrls: ['./image-crop-modal-component.component.scss'],
})
export class ImageCropModalComponentComponent {
  imageChangedEvent;
  croppedImage;
  aspectRatio;
  roundCropper;
  canvasRotation = 0;
  scale = 1;
  transform: ImageTransform = {};

  constructor(
    public dialogRef: MatDialogRef<ImageCropModalComponentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.imageChangedEvent = data.event;
    this.aspectRatio = data.aspectRatio || 1 / 1;
    this.roundCropper = data.roundCropper || false;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  rotateLeft() {
    this.canvasRotation--;
  }

  rotateRight() {
    this.canvasRotation++;
  }

  zoomOut() {
    this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  zoomIn() {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  handleCancel() {
    this.dialogRef.close();
  }

  handleDone() {
    this.dialogRef.close(this.base64ToFile(this.croppedImage, 'profile.png'));
  }

  base64ToFile(dataURI, filename) {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new File([ab], filename, { type: mimeString });
  }
}
