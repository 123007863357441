import { GenericService } from '../generic/generic.service';
import { Injectable } from '@angular/core';
import { getFromLocalStorage } from 'src/app/shared';

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  controller = 'billing/individuals';
  academicYear = '';

  constructor(private generic: GenericService) {
    this.academicYear = getFromLocalStorage.currentAcademicYearID();
  }

  get() {
    return this.generic.getData(this.controller);
  }

  post(body) {
    return this.generic.create(this.controller, body);
  }

  getByID(id) {
    return this.generic.getDataById(this.controller, id);
  }
  delete(id) {
    return this.generic.delete(this.controller, id);
  }
  update(body) {
    return this.generic.update(this.controller, body);
  }
  search(search, filter, order) {
    return this.generic.getDataBySearch(this.controller, search, filter, order);
  }

  getBillingForProfile(id, params = '') {
    const order = 'desc';
    params = params
      ? (params += `&academicYear=${this.academicYear}&limit=1000`)
      : `academicYear=${this.academicYear}&limit=1000`;
    return this.generic.getDataByParams(
      `billings/student-billing/${id}`,
      params,
      order,
    );
  }

  getBillingForProfiles(body) {
    if (!body.academicYear) {
      body.academicYear = this.academicYear;
    }
    return this.generic.getDataByPost(`billings/student-billing/bulk`, body);
  }

  getFeeDefaulters(params) {
    const order = 'desc';
    return this.generic.getDataByParams(`billings/feeDefaulter`, params, order);
  }

  getVoucherByRollNoVoucher(params) {
    const order = 'desc';
    return this.generic.getDataByParams(
      `billings/searchByVoucherRollNo/`,
      params,
      order,
    );
  }

  receiveInvoice(body) {
    return this.generic.create(`billings/receiveInvoice`, body);
  }

  getOustandingDuesDashboard(params) {
    const order = 'desc';
    return this.generic.getDataByParams(
      `billings/outstanding-dashboard-graph`,
      params,
      order,
    );
  }

  getCollectionsDashboard(params) {
    const order = 'desc';
    return this.generic.getDataByParams(
      `billings/collection-dashboard-graph`,
      params,
      order,
    );
  }

  getPaidUnpaidGraph(params) {
    const order = 'desc';
    return this.generic.getDataByParams(
      `billings/paid-unpaid-graph`,
      params,
      order,
    );
  }

  getDashboardOverviewReport(params) {
    const order = 'desc';
    return this.generic.getDataByParams(
      `billings/dashboard-graph`,
      params,
      order,
    );
  }

  getStudentConcessionCount(params) {
    const order = 'desc';
    return this.generic.getDataByParams(
      `billings/student-concession-count`,
      params,
      order,
    );
  }

  getStudentBillings(params) {
    const order = 'desc';
    return this.generic.getDataByParams(
      `billings/student-billings`,
      params,
      order,
    );
  }

  getOnlinePaymentReport(params) {
    const order = 'desc';
    return this.generic.getDataByParams(
      `billings/student-billings-by-grade`,
      params,
      order,
    );
  }

  searchOnlinePaymentReport(search, filter, order) {
    return this.generic.getDataBySearch(
      `billings/student-billings-by-grade`,
      search,
      filter,
      order,
    );
  }

  updateInvoiceFormat(body) {
    return this.generic.create(`billings/invoice-format`, body);
  }

  getInvoiceFormat() {
    return this.generic.getData(`billings/get-invoice-format`);
  }
}
