/* eslint-disable @typescript-eslint/no-empty-function */
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

declare let tinymce: any;

@Component({
  selector: 'app-rich-editor',
  template: `<textarea #editor class="rich-editor"></textarea>`,
  styles: [
    `
      .editor-min-height {
        min-height: 210px !important;
      }
      .tox-tinymce {
        border: 1px solid #ddd !important;
      }
      .tox-editor-header {
        padding: 0 !important;
      }
      .tox-toolbar__primary {
        background: #656d7f !important;
      }
      .tox .tox-toolbar__primary svg {
        fill: #ffffff !important;
      }
      .tox-statusbar {
        border: none;
        background: #f7f7f7 !important;
      }
      .tox-tbtn.tox-tbtn--select .tox-tbtn__select-label {
        color: #ffffff;
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RichEditorComponent),
      multi: true,
    },
  ],
})
export class RichEditorComponent
  implements AfterViewInit, OnDestroy, ControlValueAccessor
{
  @ViewChild('editor', { static: false }) editorContent: ElementRef;
  private editorInstance: any;
  private editorId: string;

  @Input() value = '';
  @Output() valueChange = new EventEmitter<string>();
  @Input() enableAI = false;
  @Input() shortToolBar = false;
  @Output() openAskAIModal = new EventEmitter<string>();

  onChange: (value: string) => void = () => {};
  onTouched: () => void = () => {};

  constructor(private ngZone: NgZone) {
    this.editorId = 'editor-' + Math.random().toString(36).substring(2);
  }

  writeValue(value: any): void {
    this.value = value;
    if (this.editorInstance) {
      this.editorInstance.setContent(this.value);
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  ngAfterViewInit() {
    this.initializeEditor();
  }

  ngOnDestroy() {
    this.destroyEditor();
  }

  private initializeEditor() {
    this.editorContent.nativeElement.id = this.editorId;

    let toolbar = '';

    if (this.shortToolBar) {
      toolbar = 'bold italic underline bullist numlist link table';
    } else {
      toolbar =
        'undo redo | fontsize | bold italic underline forecolor backcolor | align bullist numlist | searchreplace link table fullscreen';
    }

    if (this.enableAI) toolbar = 'AI | ' + toolbar;

    this.ngZone.runOutsideAngular(() => {
      tinymce.init({
        selector: `#${this.editorId}`,
        content_style: 'body { background: #f7f7f7 !important; }',
        base_url: '/assets/tinymce',
        suffix: '.min',
        menubar: false,
        branding: false,
        elementpath: false,
        resize: false,
        min_height: 210,
        autoresize_bottom_margin: 10,
        paste_data_images: false,
        plugins: [
          'fullscreen',
          'table',
          'lists',
          'link',
          'searchreplace',
          'autolink',
          'autoresize',
          'wordcount',
        ],
        toolbar,
        setup: (editor) => {
          this.editorInstance = editor;

          editor.ui.registry.addMenuButton('AI', {
            icon: 'AI',
            fetch: (callback) => {
              const items = [
                {
                  type: 'menuitem',
                  text: 'Proof Read',
                  icon: 'checkmark',
                  onAction: () => {
                    this.openAskAIModal.emit('PROOF_READ');
                  },
                },
                {
                  type: 'menuitem',
                  text: 'Ask AI',
                  icon: 'ai-prompt',
                  onAction: () => {
                    this.openAskAIModal.emit('ASK_AI');
                  },
                },
              ];
              callback(items);
            },
          });

          editor.on('init', () => {
            if (this.value) {
              editor.setContent(this.value);
            }
          });

          const updateValue = () => {
            const content = editor.getContent();
            this.ngZone.run(() => {
              this.valueChange.emit(content);
              this.onChange(content);
              this.onTouched();
            });
          };

          editor.on('change', updateValue);
          editor.on('keyup', updateValue);
          editor.on('blur', () => this.ngZone.run(() => this.onTouched()));
          editor.on('focus', () => this.ngZone.run(() => this.onTouched()));
          editor.on('keydown', () => this.ngZone.run(() => this.onTouched()));
        },
      });
    });
  }

  private destroyEditor() {
    if (this.editorInstance) {
      tinymce.remove(this.editorInstance);
    }
  }

  reInitializeEditor() {
    this.value = this.editorInstance.getContent();
    this.destroyEditor();
    this.initializeEditor();
  }
}
