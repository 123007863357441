import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-table-paginator',
  templateUrl: './table-paginator.component.html',
  styleUrls: ['./table-paginator.component.scss'],
})
export class TablePaginatorComponent {
  pageSize!: number;
  pageIndex!: number;
  length!: number;
  goTo!: number;
  pageNumbers!: number[];
  @ViewChild(MatPaginator, { static: true })
  paginator!: MatPaginator;

  @Input() disabled = false;
  @Input() hidePageSize = false;
  @Input()
  pageSizeOptions!: number[];
  @Input() showFirstLastButtons = false;
  @Output() page = new EventEmitter<PageEvent>();
  @Input('pageIndex') pageIndexChanged(pageIndex: number) {
    this.pageIndex = pageIndex;
  }

  @Input('length') lengthChanged(length: number) {
    this.length = length;
  }
  @Input('pageSize') pageSizeChanged(pageSize: number) {
    this.pageSize = pageSize;
  }

  paginationChange(pageEvt: PageEvent) {
    this.length = pageEvt.length;
    this.pageIndex = pageEvt.pageIndex;
    this.pageSize = pageEvt.pageSize;
    // this.updateGoto();
    this.emitPageEvent(pageEvt);
  }

  emitPageEvent(pageEvent: PageEvent) {
    this.page.next(pageEvent);
  }
}
