import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { UserType } from 'src/app/shared/enums-constant/user-type.enum';

@Component({
  selector: 'app-billing-student-profile-detail',
  templateUrl: './billing-student-profile-detail.component.html',
  styleUrls: ['./billing-student-profile-detail.component.scss'],
})
export class BillingStudentProfileDetailComponent implements OnInit, OnChanges {
  @Input() billings;
  @Input() outstandingAmount;
  @Input() paidAmount;
  @Output() editEvent = new EventEmitter();
  @Output() extendDate = new EventEmitter();
  @Input() loadingExtendDueDate;
  @Input() withdrawDetails;
  @Input() securityDeposit;
  public tabType = 0;
  public counter = { all: 0 };
  public viewType = 1;
  isParentView = false;
  isStuedntView = false;

  ngOnInit() {
    const userType = localStorage.getItem('user_type');
    this.isParentView = userType === UserType.Parent;
    this.isStuedntView = userType === UserType.Student;
    this.tabCounts(this.billings);
  }

  ngOnChanges() {
    this.tabCounts(this.billings);
  }

  public onTabChange($event: any): void {
    this.tabType = $event;
  }

  public tabCounts(data): void {
    this.getCount(data);
  }

  public viewTypeHandler($event: any): void {
    this.viewType = $event;
  }

  public getCount(data): void {
    this.counter['all'] = 0;
    this.counter['paid'] = 0;
    this.counter['unpaid'] = 0;
    this.counter['expired'] = 0;
    this.counter['cancelled'] = 0;
    if (data && data.length) {
      this.counter['all'] = data.length;
      this.counter['paid'] = data.filter((d) => d.status.name == 'Paid').length;
      this.counter['unpaid'] = data.filter(
        (d) => d.status.name == 'Publish',
      ).length;
      this.counter['expired'] = data.filter(
        (d) => d.status.name == 'Expired',
      ).length;
      this.counter['cancelled'] = data.filter(
        (d) => d.status.name == 'Cancel',
      ).length;
    }
  }

  fireEditEvent(item) {
    this.editEvent.emit(true);
  }

  extendDueDate(item) {
    this.extendDate.emit(item);
  }
}
