import { AuthService } from '../../auth/auth.service';
import { BehaviorSubject } from 'rxjs';
import { GenericService } from '../../generic/generic.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StudentParentService {
  controller = 'studentparents';

  userTypeVisibility: BehaviorSubject<string> = new BehaviorSubject('');
  selectedStudentId: any;

  constructor(
    private generic: GenericService,
    private authService: AuthService,
  ) {
    this.authService.getUser().subscribe((user) => {
      if (user !== null) {
        this.userTypeVisibility.next('');
      }
    });
  }

  get() {
    return this.generic.getData(this.controller);
  }

  post(body) {
    return this.generic.create(this.controller, body);
  }

  getByID(id) {
    return this.generic.getDataById(this.controller, id);
  }

  getChildren(id) {
    return this.generic.getDataById(this.controller + '/childrens', id);
  }

  delete(id) {
    //This method is used to delete record from database and it fires a snackbar on successful delete
    return this.generic.delete(this.controller, id);
  }

  update(body) {
    return this.generic.update(this.controller, body);
  }

  getbyParams(params) {
    const order = 'asc';
    const sort = 'fatherInformation.name';
    return this.generic.getDataByParams(this.controller, params, order, sort);
  }

  getStudentByRollNo(roll) {
    const rollNo = 'rollNo';
    const desc = 'desc';

    return this.generic.getDataBySearch(this.controller, roll, rollNo, desc);
  }

  search(search, filter, order) {
    return this.generic.getDataBySearch(this.controller, search, filter, order);
  }
  getAttendance(params) {
    const desc = 'desc';
    return this.generic.getDataByParams(
      `student/attendanceinsight`,
      `studentId=${params}`,
      desc,
    );
  }
  getPrograms() {
    return this.generic.getData(`parent/program`);
  }

  imageUpload(body) {
    return this.generic.imageUpload('studentprofiles/avatar', body);
  }
}
