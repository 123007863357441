import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UserType } from '../../enums-constant/user-type.enum';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-teacher-profile-shared',
  templateUrl: './teacher-profile-shared.component.html',
  styleUrls: ['./teacher-profile-shared.component.scss'],
})
export class TeacherProfileSharedComponent {
  @Input() profileData;
  @Input() showViewTimeTable = false;

  selectedTab = 0;

  constructor(private router: Router) {}

  edit(id) {
    if (UserType.Teacher === localStorage.getItem('user_type')) {
      this.router.navigateByUrl('teacher/profile/edit');
    } else {
      this.router.navigateByUrl('admin/teacher/teacher-main/' + id);
    }
  }

  viewTimeTable(id) {
    // Only visible for staff of admin user type
    this.router.navigateByUrl(
      'admin/teacher/teacher-profile/' + id + '/timetable',
    );
  }

  changeTab(index) {
    this.selectedTab = index;
  }

  downloadDocument(url) {
    saveAs(url);
  }

  getBranches(assignBranch) {
    if (!assignBranch || assignBranch.length === 0) return '';
    return assignBranch.map((ab) => ab.branch.name).join(', ');
  }
}
