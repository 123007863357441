import { GenericService } from '../generic/generic.service';
import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class FeatureService {
  constructor(private generic: GenericService) {}
  controller = 'features';
  get() {
    //getting data from service in this method and showing it on list
    return this.generic.getData(this.controller);
  }
  getModuleFeatures() {
    //getting data from service in this method and showing it on list
    return this.generic.getData('feature/modulesfeature');
  }
  getModuleFeaturesForAssign(params) {
    return this.generic.getData('features/modulesfeatureschool/' + params);
  }
  post(body) {
    return this.generic.create(this.controller, body);
  }

  getByID(id) {
    return this.generic.getDataById(this.controller, id);
  }

  delete(id) {
    return this.generic.delete(this.controller, id);
  }
  update(body) {
    return this.generic.update(this.controller, body);
  }

  getModuleFeaturesPermissionRole(body) {
    //getting data from service in this method and showing it on list
    return this.generic.getDataByPost(
      'feature/modulesfeaturepermissionrole',
      body,
    );
  }

  getModuleFeaturesUserwise() {
    //getting data from service in this method and showing it on list
    return this.generic.getData('feature/modulesfeaturepermissionuser');
  }
}
