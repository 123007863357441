import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyCommaFormat',
})
export class CurrencyCommaFormatPipe implements PipeTransform {
  transform(value: number): string {
    if (value > 10000) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      return value.toString();
    }
  }
}
