import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-flexible-timetable-card',
  templateUrl: './flexible-timetable-card.component.html',
  styleUrls: ['./flexible-timetable-card.component.scss'],
})
export class FlexibleTimetableCardComponent {
  @Input() data;
  @Input() showAction = false;
  @Output() editClicked = new EventEmitter<any>();
  @Output() deleteClicked = new EventEmitter<any>();
}
