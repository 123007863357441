import { GenericService } from '../generic/generic.service';
import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class AcademicYearService {
  constructor(private generic: GenericService) {}
  controller = 'academicyears';

  get() {
    return this.generic.getData(this.controller);
  }

  getByParams(param) {
    const order = 'asc';
    const sort = 'name';
    return this.generic.getDataByParams(this.controller, param, order, sort);
  }

  getAcademicYearBySchoolId(param) {
    return this.generic.getData(`${this.controller}/${param}/list`);
  }

  post(body) {
    return this.generic.create(this.controller, body);
  }

  getByID(id) {
    return this.generic.getDataById(this.controller, id);
  }
  delete(id) {
    return this.generic.delete(this.controller, id);
  }
  update(body) {
    return this.generic.update(this.controller, body);
  }
  search(search, filter, order) {
    return this.generic.getDataBySearch(this.controller, search, filter, order);
  }
}
