import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { CustomSnackBarComponent } from 'src/app/shared';
import { CustomSnackbarDeleteComponent } from 'src/app/shared';
import { DatePipe } from '@angular/common';
import { NewsService } from 'src/app/core/services/news/news.service';
import { Subject } from 'rxjs';
import { helper } from 'src/app/shared';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-news-list-shared',
  templateUrl: './news-list-box.component.html',
  styleUrls: ['./news-list-box.component.scss'],
  providers: [DatePipe],
})
export class NewsListBoxComponent implements OnInit {
  myDate = new Date();
  private destroy$ = new Subject<void>();
  published = true;
  pendingNews = false;
  draftNews = false;
  scheduletNews = false;
  resetButton = false;
  allNewsCat = true;
  dateFilter = false;
  searchDate = null;
  searchKey = false;
  loader = false;
  noData = false;
  fromDateFilter = false;
  toDateFilter = false;
  statusObj = {
    published: true,
    pendingNews: false,
    draftNews: false,
    scheduletNews: false,
  };
  selectedIndex;
  groupArray3 = {};
  hide = {};
  url;
  showdiv;
  draftCount = 0;
  pendingCount = 0;
  publishCount = 0;
  approveCount = 0;
  scheduleCount = 0;
  resDataLength = 0;
  resetbtnCount = 0;
  newPage = 1;
  show = 2;
  scheduleNewsID: any;
  publishedNewsID: any;
  rejectNewsID: any;
  approveNewsID: any;
  draftNewsID: any;
  totalShow: any;
  limitError: boolean;
  radioFilter: any;
  statusId: any;
  pendingNewsID: any;
  branches = [];
  allClasses = [];
  gradesArray = [];
  searchfilter = [];
  filterList = [];
  categories = [];
  datewise = [];
  newArr = [];
  filterDateTo = '';
  filterCategory = '';
  branchID = '';
  gradeID = '';
  selectedCat = '';
  searchText = '';
  branchFilter = '';
  gradeFilter = '';
  filterDateFrom = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
  defaultImg = '../../../../assets/images/default-img1.png';

  constructor(
    public dialog: MatDialog,
    private news: NewsService,
    private datePipe: DatePipe,
    private _snackBar: MatSnackBar,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    // this.getBranches();

    this.authService
      .getSelectedBrnach()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res == 'allBranches') {
          this.branchID = '';
          this.getStatus().then(() => {
            //this.newArr = [];
            //fire other event after getting news status
            this.url =
              'status=' +
              this.approveNewsID.id +
              '&dateTo=' +
              this.filterDateFrom +
              '&dateAt=' +
              this.filterDateTo +
              '&search=' +
              this.searchText +
              '&filter=' +
              this.filterCategory +
              '&branch=' +
              this.branchID +
              '&grade=' +
              this.gradeID +
              '&limit=' +
              this.show +
              '&page=' +
              this.newPage;
            this.statusId = this.approveNewsID.id;
            this.get(this.url);
          });
        } else {
          this.branchID = res;
          this.getStatus().then(() => {
            //this.newArr = [];
            //fire other event after getting news status
            this.url =
              'status=' +
              this.approveNewsID.id +
              '&dateTo=' +
              this.filterDateFrom +
              '&dateAt=' +
              this.filterDateTo +
              '&search=' +
              this.searchText +
              '&filter=' +
              this.filterCategory +
              '&branch=' +
              this.branchID +
              '&grade=' +
              this.gradeID +
              '&limit=' +
              this.show +
              '&page=' +
              this.newPage;
            this.statusId = this.approveNewsID.id;
            this.get(this.url);
          });
        }
      });

    // this.getStatus()
    Object.keys(this.statusObj).forEach((v) => (this.statusObj[v] = false));
  }

  getStatus() {
    //getting news status through this method
    const promise = new Promise<void>((resolve, reject) => {
      this.news
        .getNewsStatus()
        .toPromise()
        .then(
          (res: any) => {
            this.approveNewsID = res.data.find(
              (x) => x.name.toLowerCase() === 'approve',
            );
            this.draftNewsID = res.data.find(
              (x) => x.name.toLowerCase() === 'draft',
            );
            this.pendingNewsID = res.data.find(
              (x) => x.name.toLowerCase() === 'pending',
            );

            this.scheduleNewsID = res.data.find(
              (x) => x.name.toLowerCase() === 'schedule',
            );
            this.publishedNewsID = res.data.find(
              (x) => x.name.toLowerCase() === 'published',
            );
            this.rejectNewsID = res.data.find(
              (x) => x.name.toLowerCase() === 'reject',
            );
            resolve();
          },
          (err) => {
            // Error
            reject(err);
          },
        );
    });
    return promise;
  }

  publish() {
    //method fired on publish tab click
    this.showdiv = '';
    this.resetValues();
    this.statusId = this.approveNewsID.id;
    this.url =
      'status=' +
      this.approveNewsID.id +
      '&dateTo=' +
      this.filterDateFrom +
      '&dateAt=' +
      this.filterDateTo +
      '&search=' +
      this.searchText +
      '&filter=' +
      this.filterCategory +
      '&newsCategory=' +
      this.selectedCat +
      '&branch=' +
      this.branchID +
      '&grade=' +
      this.gradeID +
      '&limit=' +
      this.show +
      '&page=' +
      this.newPage;
    this.get(this.url);
    this.published = true;
    this.pendingNews = false;
    this.draftNews = false;
    this.scheduletNews = false;
  }

  pending() {
    //method fired on pending tab click
    this.showdiv = '';
    this.resetValues();
    this.statusId = this.pendingNewsID.id;
    if (
      this.filterDateFrom === this.datePipe.transform(this.myDate, 'yyyy-MM-dd')
    ) {
      this.url =
        'status=' +
        this.pendingNewsID.id +
        '&dateTo=' +
        '&dateAt=' +
        this.filterDateTo +
        '&search=' +
        this.searchText +
        '&filter=' +
        this.filterCategory +
        '&newsCategory=' +
        this.selectedCat +
        '&branch=' +
        this.branchID +
        '&grade=' +
        this.gradeID +
        '&limit=' +
        this.show +
        '&page=' +
        this.newPage;
    } else {
      this.url =
        'status=' +
        this.statusId +
        '&dateTo=' +
        this.filterDateFrom +
        '&dateAt=' +
        this.filterDateTo +
        '&search=' +
        this.searchText +
        '&filter=' +
        this.filterCategory +
        '&newsCategory=' +
        this.selectedCat +
        '&branch=' +
        this.branchID +
        '&grade=' +
        this.gradeID +
        '&limit=' +
        this.show +
        '&page=' +
        this.newPage;
    }
    // this.url = 'status=' + this.pendingNewsID.id + '&newsCategory=' + this.selectedCat + '&branch=' + this.branchID + '&grade=' + this.gradeID + '&limit=' + this.show;
    this.get(this.url);
    this.published = false;
    this.pendingNews = true;
    this.draftNews = false;
    this.scheduletNews = false;
  }

  draft() {
    //method fired on draft tab click
    this.showdiv = '';
    this.resetValues();
    this.statusId = this.draftNewsID.id;
    if (
      this.filterDateFrom === this.datePipe.transform(this.myDate, 'yyyy-MM-dd')
    ) {
      this.url =
        'status=' +
        this.draftNewsID.id +
        '&dateTo=' +
        '&dateAt=' +
        this.filterDateTo +
        '&search=' +
        this.searchText +
        '&filter=' +
        this.filterCategory +
        '&newsCategory=' +
        this.selectedCat +
        '&branch=' +
        this.branchID +
        '&grade=' +
        this.gradeID +
        '&limit=' +
        this.show +
        '&page=' +
        this.newPage;
    } else {
      this.url =
        'status=' +
        this.statusId +
        '&dateTo=' +
        this.filterDateFrom +
        '&dateAt=' +
        this.filterDateTo +
        '&search=' +
        this.searchText +
        '&filter=' +
        this.filterCategory +
        '&newsCategory=' +
        this.selectedCat +
        '&branch=' +
        this.branchID +
        '&grade=' +
        this.gradeID +
        '&limit=' +
        this.show +
        '&page=' +
        this.newPage;
    }
    // this.url = 'status=' + this.draftNewsID.id + '&newsCategory=' + this.selectedCat + '&branch=' + this.branchID + '&grade=' + this.gradeID + '&limit=' + this.show;
    this.get(this.url);
    this.published = false;
    this.pendingNews = false;
    this.draftNews = true;
    this.scheduletNews = false;
  }

  schedule() {
    //method fired on schedule tab click
    this.showdiv = '';
    this.resetValues();
    this.statusId = this.draftNewsID.id;
    if (
      this.filterDateFrom === this.datePipe.transform(this.myDate, 'yyyy-MM-dd')
    ) {
      this.url =
        'status=' +
        this.scheduleNewsID.id +
        '&dateTo=' +
        '&dateAt=' +
        this.filterDateTo +
        '&search=' +
        this.searchText +
        '&filter=' +
        this.filterCategory +
        '&newsCategory=' +
        this.selectedCat +
        '&branch=' +
        this.branchID +
        '&grade=' +
        this.gradeID +
        '&limit=' +
        this.show +
        '&page=' +
        this.newPage;
    } else {
      this.url =
        'status=' +
        this.statusId +
        '&dateTo=' +
        this.filterDateFrom +
        '&dateAt=' +
        this.filterDateTo +
        '&search=' +
        this.searchText +
        '&filter=' +
        this.filterCategory +
        '&newsCategory=' +
        this.selectedCat +
        '&branch=' +
        this.branchID +
        '&grade=' +
        this.gradeID +
        '&limit=' +
        this.show +
        '&page=' +
        this.newPage;
    }
    // this.url = 'status=' + this.scheduleNewsID.id + '&newsCategory=' + this.selectedCat + '&branch=' + this.branchID + '&grade=' + this.gradeID + '&limit=' + this.show;
    // this.url = 'status=' + this.pendingNewsID.id + '&dateTo=' + this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    this.get(this.url);
    this.published = false;
    this.pendingNews = false;
    this.draftNews = false;
    this.scheduletNews = true;
  }

  setIndex(index: number, item) {
    //method to handle active class on news cat list
    this.selectedIndex = index;
    this.allNewsCat = false;
    this.selectedCat = item;
    this.feedState();
  }

  get(url) {
    this.loader = true;
    this.news.newList(url).subscribe((res: any) => {
      this.newArr = [];
      this.loader = false;
      this.resDataLength = res.data.length + this.resDataLength;
      this.totalShow = res.pagination.totalRecords;

      const group1 = this.groupByKey(res.data, 'date');
      for (const e in group1) {
        this.newArr.push({ news: group1[e], dateAt: e });
      }
      if (this.newArr.length == 0) {
        this.noData = true;
      } else {
        this.noData = false;
      }
    });
  }

  groupByKey = (list, key) =>
    list.reduce(
      (hash, obj) => ({
        ...hash,
        [obj[key]]: (hash[obj[key]] || []).concat(obj),
      }),
      {},
    );

  edit(id) {
    //open modal for edit purpose
  }

  approve(i) {
    const news = {
      id: i,
      status: this.approveNewsID.id,
    };
    this.news.changeNewsStatus(news).subscribe(
      (res: any) => {
        if (res.status == 'success') {
          this.showSnackBar('success', res.message);
          this.resetCount();
          this.resetValues();
          this.url =
            'status=' +
            this.pendingNewsID.id +
            '&newsCategory=' +
            this.selectedCat;
          this.get(this.url);
        }
      },
      (err) => {
        this.showSnackBar('delete', err.error.message);
      },
    );
  }

  reject(i) {
    const news = {
      id: i,
      status: this.rejectNewsID.id,
    };

    this.news.changeNewsStatus(news).subscribe(
      (res: any) => {
        if (res.status == 'success') {
          this.showSnackBar('success', res.message);
          this.resetCount();
          this.resetValues();
          this.url =
            'status=' +
            this.pendingNewsID.id +
            '&dateTo=' +
            '&dateAt=' +
            this.filterDateTo +
            '&search=' +
            this.searchText +
            '&filter=' +
            this.filterCategory +
            '&newsCategory=' +
            this.selectedCat +
            '&branch=' +
            this.branchID +
            '&grade=' +
            this.gradeID +
            '&limit=' +
            this.show +
            '&page=' +
            this.newPage;
          // this.url = 'status=' + this.pendingNewsID.id + '&newsCategory=' + this.selectedCat
          this.get(this.url);
        }
      },
      (err) => {
        this.showSnackBar('delete', err.error.message);
      },
    );
  }

  rejectDraft(i) {
    const news = {
      id: i,
      status: this.rejectNewsID.id,
    };

    this.news.changeNewsStatus(news).subscribe(
      (res: any) => {
        if (res.status == 'success') {
          this.showSnackBar('success', res.message);
          this.resetCount();
          this.resetValues();
          this.url =
            'status=' +
            this.draftNewsID.id +
            '&dateTo=' +
            '&dateAt=' +
            this.filterDateTo +
            '&search=' +
            this.searchText +
            '&filter=' +
            this.filterCategory +
            '&newsCategory=' +
            this.selectedCat +
            '&branch=' +
            this.branchID +
            '&grade=' +
            this.gradeID +
            '&limit=' +
            this.show +
            '&page=' +
            this.newPage;

          // this.url = 'status=' + this.draftNewsID.id + '&newsCategory=' + this.selectedCat
          // this.url = 'status=' + this.draftNewsID.id + '&dateTo=' + this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
          this.get(this.url);
        }
      },
      (err) => {
        this.showSnackBar('delete', err.error.message);
      },
    );
  }

  rejectSchedule(i) {
    const news = {
      id: i,
      status: this.rejectNewsID.id,
    };

    this.news.changeNewsStatus(news).subscribe(
      (res: any) => {
        if (res.status == 'success') {
          this.showSnackBar('success', res.message);
          this.resetCount();
          this.resetValues();
          this.url =
            'status=' +
            this.scheduleNewsID.id +
            '&dateTo=' +
            '&dateAt=' +
            this.filterDateTo +
            '&search=' +
            this.searchText +
            '&filter=' +
            this.filterCategory +
            '&newsCategory=' +
            this.selectedCat +
            '&branch=' +
            this.branchID +
            '&grade=' +
            this.gradeID +
            '&limit=' +
            this.show +
            '&page=' +
            this.newPage;

          // this.url = 'status=' + this.scheduleNewsID.id + '&newsCategory=' + this.selectedCat
          // this.url = 'status=' + this.pendingNewsID.id + '&dateTo=' + this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
          this.get(this.url);
        }
      },
      (err) => {
        this.showSnackBar('delete', err.error.message);
      },
    );
  }

  resetCount() {
    //reset count on status change
    this.pendingCount = 0;
    this.draftCount = 0;
    this.scheduleCount = 0;
  }

  feedState() {
    //check state of news
    if (this.published == true) {
      this.publish();
    } else if (this.pendingNews == true) {
      this.pending();
    } else if (this.draftNews == true) {
      this.draft();
    } else if (this.scheduletNews == true) {
      this.schedule();
    } else {
      this.publish();
    }
  }

  selectAllCategory() {
    //select all news by category
    this.allNewsCat = true;
    this.selectedIndex = '';
    this.selectedCat = '';
    this.feedState();
  }

  changeDate(id) {
    //open modal to change date
  }

  view(id) {
    //open modal to view
  }

  delete(id) {
    //delete news
    this.news.delete(id).subscribe((res: any) => {
      if (res.status == 'success') {
        this.resetCount();
        this.feedState();
        this.showSnackBar('delete', 'News has been deleted!');
      }
    });
  }

  option(i) {
    if (i == this.showdiv) {
      this.showdiv = '';
    } else {
      this.showdiv = i;
    }
  }

  onClickedOutside() {
    this.showdiv = '';
  }

  onChecklistDate(e) {
    this.dateFilter = e;
    if (e == false) {
      this.searchDate = null;
      this.resetButton = false;
    }
  }

  onDateKey($event) {
    this.resetButton = true;
  }

  resetSearch() {
    this.searchKey = false;
    this.searchText = '';
  }

  loadMore() {
    this.show = 3;
    this.newPage += 1;
    this.newArr = [];
    this.url = this.url.slice(0, -1).replace(/.$/, '=' + this.newPage);
    this.get(this.url);
  }

  selectBranch($event) {
    let branchCount = 0;
    branchCount += 1;
    if (branchCount == 1) {
      this.resetbtnCount += 1;
    }
    this.resetButton = true;
    this.gradesArray.length = 0;
    const grades = this.allClasses.filter(
      (element) => element.branch.id == $event.target.value,
    );
    this.branchID = $event.target.value;
    for (let i = 0; i < grades.length; i++) {
      this.gradesArray.push(grades[i].grade);
    }
    const branchArr = this.branches.filter((e) => e.id == this.branchID);

    this.branchFilter = branchArr[0].name;
    this.feedState();
    // this.get(this.url)
  }

  selectGrades($event) {
    let gradeCount = 0;
    gradeCount += 1;
    if (gradeCount == 1) {
      this.resetbtnCount += 1;
    }
    this.resetButton = true;
    this.gradeID = $event.target.value;
    const gradeArr = this.gradesArray.filter((e) => e.id == this.gradeID);
    this.gradeFilter = gradeArr[0].name;
    this.feedState();
  }

  getFromDate(e) {
    let dateCountFrom = 0;
    dateCountFrom += 1;
    if (dateCountFrom == 1) {
      this.resetbtnCount += 1;
    }
    this.resetButton = true;
    this.filterDateFrom = e.target.value;
    this.fromDateFilter = true;

    this.url =
      'status=' +
      this.statusId +
      '&dateTo=' +
      this.filterDateFrom +
      '&dateAt=' +
      this.filterDateTo +
      '&search=' +
      this.searchText +
      '&filter=' +
      this.filterCategory +
      '&search=' +
      this.searchText +
      '&filter=' +
      this.filterCategory +
      '&branch=' +
      this.branchID +
      '&grade=' +
      this.gradeID +
      '&limit=' +
      this.show;
    this.get(this.url);
  }

  getToDate(e) {
    let dateCountTo = 0;
    dateCountTo += 1;
    if (dateCountTo == 1) {
      this.resetbtnCount += 1;
    }
    this.resetButton = true;
    this.filterDateTo = e.target.value;
    this.toDateFilter = true;
    if (
      this.filterDateFrom === this.datePipe.transform(this.myDate, 'yyyy-MM-dd')
    ) {
      this.url =
        'status=' +
        this.statusId +
        '&dateTo=' +
        '&dateAt=' +
        this.filterDateTo +
        '&search=' +
        this.searchText +
        '&filter=' +
        this.filterCategory +
        '&newsCategory=' +
        this.selectedCat +
        '&branch=' +
        this.branchID +
        '&grade=' +
        this.gradeID +
        '&limit=' +
        this.show;
    } else {
      this.url =
        'status=' +
        this.statusId +
        '&dateTo=' +
        this.filterDateFrom +
        '&dateAt=' +
        this.filterDateTo +
        '&search=' +
        this.searchText +
        '&filter=' +
        this.filterCategory +
        '&newsCategory=' +
        this.selectedCat +
        '&branch=' +
        this.branchID +
        '&grade=' +
        this.gradeID +
        '&limit=' +
        this.show;
    }
    this.get(this.url);
  }

  removeFromDate() {
    this.resetbtnCount -= 1;
    this.fromDateFilter = false;
    this.filterDateFrom = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    this.feedState();
  }

  removeToDate() {
    this.resetbtnCount -= 1;
    this.toDateFilter = false;
    this.filterDateTo = '';
    this.feedState();
  }

  removeBranch() {
    this.resetbtnCount -= 1;
    this.branchFilter = '';
    this.branchID = '';
    this.gradeID = '';
    this.feedState();
  }

  removeGrade() {
    this.resetbtnCount -= 1;
    this.gradeFilter = '';
    this.gradeID = '';
    this.feedState();
  }

  hideReset() {
    this.resetButton = false;
  }

  resetValues() {
    this.datewise = [];
    this.newArr = [];
    this.groupArray3 = {};
    this.resDataLength = 0;
    this.newPage = 1;
  }

  showSnackBar(type, message) {
    if (type === 'delete') {
      this._snackBar.openFromComponent(CustomSnackbarDeleteComponent, {
        data: { message: message },
        horizontalPosition: 'center',
        duration: 2000,
        panelClass: ['delete-snackBar'],
        verticalPosition: 'top',
      });
    } else if (type === 'success') {
      this._snackBar.openFromComponent(CustomSnackBarComponent, {
        data: { message: message },
        horizontalPosition: 'center',
        duration: helper.duration,
        panelClass: ['save-snackBar'],
        verticalPosition: 'top',
      });
    }
  }

  getGradeNames(grades: any[]): string {
    if (!grades || grades.length === 0) {
      return '';
    }
    const gradeNames = grades.map((grade) => grade.name);
    return gradeNames.join(', ');
  }

  getBranchNames(branch: any[]): string {
    if (!branch || branch.length === 0) {
      return '';
    }
    const branchNames = branch.map((branch) => branch.name);
    return branchNames.join(', ');
  }
}
