import { GenericService } from '../generic/generic.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StaffUserService {
  controller = 'staffusers';
  constructor(private generic: GenericService) {}

  get() {
    return this.generic.getData(this.controller);
  }

  post(body) {
    return this.generic.create(this.controller, body);
  }

  getByID(id) {
    return this.generic.getDataById(this.controller, id);
  }
  delete(id) {
    return this.generic.delete(this.controller, id);
  }
  update(body) {
    return this.generic.update(this.controller, body);
  }
  search(search, filter, order) {
    return this.generic.getDataBySearch(this.controller, search, filter, order);
  }
  me() {
    return this.generic.getData(this.controller + '/me');
  }
  getMeetingLogs(params = '', order = 'desc') {
    return this.generic.getDataByParams(
      `${this.controller}/meeting-logs`,
      params,
      order,
    );
  }

  getUserTypes() {
    return this.generic.getData('userTypes?showall=true');
  }
  logoUpload(body) {
    return this.generic.imageUpload(this.controller + '/uploadImage', body);
  }
  getByParamss(param) {
    return this.generic.getData(this.controller + '?' + param);
  }
}
