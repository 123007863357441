import { Injectable } from '@angular/core';
import { GenericService } from '../generic/generic.service';

@Injectable({
  providedIn: 'root',
})
export class BulkBillingService {
  controller = 'billing/bulk';
  constructor(private generic: GenericService) {}

  post(body) {
    return this.generic.create(this.controller, body);
  }
  put(body) {
    return this.generic.update(this.controller, body);
  }
  get() {
    return this.generic.getData(this.controller);
  }

  search(search, filter, order) {
    return this.generic.getDataBySearch(this.controller, search, filter, order);
  }

  getBillingList(params, order) {
    return this.generic.getDataByParams(
      this.controller + '/list',
      params,
      order,
    );
  }

  getbyParams(params) {
    var order = 'asc';
    return this.generic.getDataByParams(this.controller, params, order);
  }

  getGradeCountbyParams(params) {
    var order = 'asc';
    return this.generic.getDataByParams(
      this.controller + '/summary',
      params,
      order,
    );
  }

  getBulkSummary(body) {
    return this.generic.create(this.controller + '/bulkSummary', body);
  }

  getSummaryByID(id) {
    return this.generic.getDataById(this.controller + `/bulkSummary`, id);
  }

  publishBills(body) {
    return this.generic.create(this.controller + `/postBilling`, body);
  }
}
