import { GenericService } from '../../generic/generic.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AcademicHoldService {
  controller = 'academicHold';
  constructor(private generic: GenericService) {}

  get() {
    return this.generic.getData(this.controller + '?showall=true');
  }

  getbyParams(params, order) {
    return this.generic.getDataByParams(this.controller, params, order);
  }

  getReport(params: any) {
    const order = 'desc';
    return this.generic.getDataByParams(
      `${this.controller}/academic-hold-report`,
      params,
      order,
    );
  }

  getAcademicHoldStudent(params) {
    const order = 'desc';
    return this.generic.getDataByParams(
      `${this.controller}/academicHoldStudents`,
      params,
      order,
    );
  }

  getAcademicHoldToBillStudents(params) {
    const order = 'desc';
    return this.generic.getDataByParams(
      `${this.controller}/academicHoldToBillStudents`,
      params,
      order,
    );
  }

  getReportByIdForInvoice(params) {
    const order = 'desc';
    return this.generic.getDataByParams(
      `${this.controller}/get-academic-hold-for-invoices`,
      params,
      order,
    );
  }

  post(body) {
    return this.generic.create(this.controller, body);
  }

  getByID(id) {
    return this.generic.getDataById(this.controller, id);
  }
  delete(id) {
    return this.generic.delete(this.controller, id);
  }
  update(body) {
    return this.generic.update(this.controller, body);
  }
  updateByParam(body, param) {
    return this.generic.update(`${this.controller}/${param}`, body);
  }
}
