import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-dashboard-card',
  templateUrl: './list-dashboard-card.component.html',
  styleUrls: ['./list-dashboard-card.component.scss'],
})
export class ListDashboardCardComponent implements OnInit {
  @Input() heading: any;
  @Input() count: any;
  constructor() {}

  ngOnInit() {}
}
