import { AuthService } from '../auth/auth.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { GenericService } from '../generic/generic.service';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/internal/operators/tap';

@Injectable({
  providedIn: 'root',
})
export class CityService {
  private citie$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  controller = 'citys';
  constructor(private generic: GenericService, private auth: AuthService) {
    this.auth.getUser().subscribe((res: any) => {
      if (!res) this.citie$.next(null);
    });
  }

  get() {
    return this.citie$.getValue() ? this.citie$.asObservable() : this.set();
  }

  private set() {
    return this.generic
      .getData(this.controller)
      .pipe(tap((response) => this.citie$.next(response)));
  }

  post(body) {
    return this.generic.create(this.controller, body);
  }
  getByID(id) {
    return this.generic.getDataById(this.controller, id);
  }
  getCityByCountryID(id) {
    return this.generic.getData('citys?country=' + id);
  }
  getCityByStateID(id) {
    return this.generic.getData('citys?state=' + id);
  }
  delete(id) {
    //This method is used to delete record from database and it fires a snackbar on successful delete
    return this.generic.delete(this.controller, id);
  }
  update(body) {
    return this.generic.update(this.controller, body);
  }
}
