import { GenericService } from '../generic/generic.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class InvoiceConcessionService {
  controller = 'concessions';
  constructor(private generic: GenericService) {}

  get() {
    return this.generic.getDataByParams(
      this.controller,
      'showall=true',
      'desc',
      'createdAt',
    );
  }

  post(body) {
    return this.generic.create(this.controller, body);
  }

  getByID(id) {
    return this.generic.getDataById(this.controller, id);
  }
  delete(id) {
    return this.generic.delete(this.controller, id);
  }
  update(body) {
    return this.generic.update(this.controller, body);
  }
  search(search, filter, order) {
    return this.generic.getDataBySearch(this.controller, search, filter, order);
  }

  getbyParams(params, order, sort) {
    return this.generic.getDataByParams(this.controller, params, order, sort);
  }

  getConcessionForFilter() {
    return this.generic.getData(`${this.controller}/concession-filter`);
  }
}
