import { Component, Renderer2 } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { Subscription } from 'rxjs';
export let browserRefresh = false;
import { ConnectionService } from 'ng-connection-service';
import { VersionCheckService } from './core/services/version-check/version-check.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'SimpliEd';
  subscription: Subscription;
  loading;
  isConnected = true;
  noInternetConnection: boolean;
  constructor(
    private router: Router,
    private connectionService: ConnectionService,
    private versionCheckService: VersionCheckService,
    private renderer: Renderer2,
  ) {
    this.loading = true;
    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
      }
    });
    this.connectionService.monitor().subscribe((isConnected) => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.noInternetConnection = false;
      } else {
        this.noInternetConnection = true;
      }
    });
  }
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.versionCheckService.initVersionCheck(environment.versionCheckURL);
  }

  ngAfterViewInit() {
    const loader = this.renderer.selectRootElement('#loader');
    this.renderer.setStyle(loader, 'display', 'none');
  }

  // ngAfterViewInit() {
  //     this.router.events
  //         .subscribe((event) => {
  //             if (event instanceof NavigationStart) {
  //                 this.loading = true;
  //             }
  //             else if (
  //                 event instanceof NavigationEnd ||
  //                 event instanceof NavigationCancel
  //             ) {
  //                 this.loading = false;
  //             }
  //         });
  // }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
