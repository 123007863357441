import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ParentNewsfeedService } from 'src/app/core/services/parent/parent-newsfeed/parent-newsfeed.service';
export interface DialogData {
  id?: string;
  name: string;
}
@Component({
  selector: 'app-news-modal',
  templateUrl: './news-modal.component.html',
  styleUrls: ['./news-modal.component.scss'],
})
export class NewsModalComponent implements OnDestroy {
  newsData: any;

  constructor(
    public dialogRef: MatDialogRef<NewsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public newsFeed: ParentNewsfeedService,
  ) {}

  ngOnDestroy(): void {
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }
}
