import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { CustomSnackbarDeleteComponent } from 'src/app/shared/components/custom-snackbar-delete/custom-snackbar-delete.component';
import { StudentProfileService } from 'src/app/core/services/student/student-profile/student-profile.service';
import { helper } from 'src/app/shared/helper-functions/helper';

@Component({
  selector: 'app-class-list-modal',
  templateUrl: './class-list-modal.component.html',
  styleUrls: ['./class-list-modal.component.scss'],
})
export class ClassListModalComponent implements OnInit {
  classData: any;
  branches = [];
  grades = [];
  sections = [];
  allClasses = [];
  branchId: any;
  gradeId: any;
  sectionId: '';
  branch = new FormControl(undefined);
  grade = new FormControl(undefined);
  section = new FormControl(undefined);
  studentProfileId = '';
  isLoading = false;
  public form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<any>,
    private fb: FormBuilder,
    private studentProfileService: StudentProfileService,
    private _snackBar: MatSnackBar,
  ) {
    this.classData = data;
  }

  ngOnInit(): void {
    this.formPost();
    this.getBranches();
  }

  formPost() {
    this.form = this.fb.group({
      branch: new FormControl(undefined, Validators.required),
      grade: new FormControl(undefined, Validators.required),
      section: new FormControl(undefined, Validators.required),
    });
  }

  get FormControl() {
    return this.form.controls;
  }

  submit() {
    if (this.form.invalid) {
      helper.validateAllFormFields(this.form);
      return;
    }
    this.isLoading = true;
    const formValue = `${this.form.value.branch}&grade=${this.form.value.grade}&section=${this.form.value.section}`;
    this.studentProfileService.classListReport(formValue).subscribe(
      (res: any) => {
        if (res.status == 'success') {
          const pdfSource = res.data;
          const a = document.createElement('a');
          a.href = pdfSource;
          a.setAttribute('target', '_blank');
          a.click();
        }
        this.dialogRef.close();
        this.isLoading = false;
      },
      (err) => {
        this._snackBar.openFromComponent(CustomSnackbarDeleteComponent, {
          data: { message: err.error.message },
          horizontalPosition: 'center',
          duration: helper.duration,
          panelClass: ['delete-snackBar'],
          verticalPosition: 'top',
        });
        this.isLoading = false;
      },
    );
  }

  getBranches() {
    return new Promise((resolve) => {
      this.allClasses = this.data.allClasses;
      const branchArray = [];
      for (let i = 0; i < this.allClasses.length; i++) {
        branchArray.push(this.allClasses[i].branch);
      }
      const seen = new Set();
      const filteredArr = branchArray.filter((el) => {
        const duplicate = seen.has(el.id);
        seen.add(el.id);
        return !duplicate;
      });
      this.branches = filteredArr;
      resolve(true);
    });
  }

  selectBranch(ev: any) {
    this.form.get('grade').reset();
    this.form.get('section').reset();
    this.grades = [];
    this.sections = [];
    this.branchId = ev;
    const gradesArray = this.allClasses.filter(
      (element) => element.branch.id == ev,
    );
    for (let i = 0; i < gradesArray.length; i++) {
      this.grades.push(gradesArray[i].grade);
    }
  }

  selectGrade(ev: any) {
    this.form.get('section').reset();
    this.sections = [];
    this.gradeId = ev;
    const sectionsArray = this.allClasses.filter(
      (element) => element.branch.id == this.branchId && element.grade.id == ev,
    );
    for (let i = 0; i < sectionsArray.length; i++) {
      for (let y = 0; y < sectionsArray[i].sections.length; y++) {
        this.sections.push(sectionsArray[i].sections[y].section);
      }
    }
  }

  selectedSection(ev: any) {
    this.sectionId = ev;
    this.section.setValue(this.sectionId);
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
