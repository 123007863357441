import { Component, Inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { CustomSnackBarComponent } from 'src/app/shared/components/custom-snack-bar/custom-snack-bar.component';
import { CustomSnackbarDeleteComponent } from 'src/app/shared/components/custom-snackbar-delete/custom-snackbar-delete.component';
import { StudentProfileService } from 'src/app/core/services/student/student-profile/student-profile.service';
import { helper } from 'src/app/shared/helper-functions/helper';
import { studentProfile } from 'src/app/shared/models/student-profile';

@Component({
  selector: 'app-change-grade-modal',
  templateUrl: './change-grade-modal.component.html',
  styleUrls: ['./change-grade-modal.component.scss'],
})
export class ChangeGradeModalComponent {
  studentData: any;
  branches = [];
  grades = [];
  sections = [];
  allClasses = [];
  gradeId: any;
  sectionId: '';
  grade = new FormControl(undefined);
  section = new FormControl(undefined);
  editData: studentProfile;
  studentProfileId = '';
  isLoading = false;
  public form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<any>,
    private studentProfileService: StudentProfileService,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
  ) {
    this.studentData = data;
  }

  ngOnInit(): void {
    this.studentProfileId = this.studentData.studentInfo.id;
    this.formPost();
    this.getBranches().then(() => {
      this.bindStudentProfileByID();
    });
  }

  formPost() {
    this.form = this.fb.group({
      grade: new FormControl('', Validators.required),
      section: new FormControl('', Validators.required),
    });
  }

  get FormControl() {
    return this.form.controls;
  }

  submit() {
    if (this.form.invalid) {
      helper.validateAllFormFields(this.form);
      return;
    }
    this.isLoading = true;
    const formValue = { ...this.form.value, id: this.studentProfileId };
    this.studentProfileService.updateGrade(formValue).subscribe(
      (res: any) => {
        if (res.status == 'success') {
          this._snackBar.openFromComponent(CustomSnackBarComponent, {
            data: { message: 'Grade Changed successfully!' },
            horizontalPosition: 'center',
            duration: helper.duration,
            panelClass: ['save-snackBar'],
            verticalPosition: 'top',
          });
          this.dialogRef.close(true);
        }
        this.isLoading = false;
      },
      (err) => {
        this._snackBar.openFromComponent(CustomSnackbarDeleteComponent, {
          data: { message: err.error.message },
          horizontalPosition: 'center',
          duration: helper.duration,
          panelClass: ['delete-snackBar'],
          verticalPosition: 'top',
        });
        this.isLoading = false;
      },
    );
  }

  bindStudentProfileByID() {
    this.editData = this.studentData.studentInfo as studentProfile;

    this.grades.length = 0;
    const gradesArray = this.allClasses.filter(
      (element) => element.branch.id == this.editData.branch.id,
    );

    for (let i = 0; i < gradesArray.length; i++) {
      this.grades.push(gradesArray[i].grade);
    }

    this.sections.length = 0;
    const sectionsArray = this.allClasses.filter(
      (element) =>
        element.branch.id == this.editData.branch.id &&
        element.grade.id == this.editData.grade.id,
    );

    for (let i = 0; i < sectionsArray.length; i++) {
      for (let y = 0; y < sectionsArray[i].sections.length; y++) {
        this.sections.push(sectionsArray[i].sections[y].section);
      }
    }

    this.form.patchValue({
      id: this.editData.id,
      grade: this.editData.grade.id,
      section: this.editData.section.id,
    });
  }

  getBranches() {
    return new Promise((resolve) => {
      this.allClasses = this.data.allClasses;
      const branchArray = [];
      for (let i = 0; i < this.allClasses.length; i++) {
        branchArray.push(this.allClasses[i].branch);
      }
      const seen = new Set();
      const filteredArr = branchArray.filter((el) => {
        const duplicate = seen.has(el.id);
        seen.add(el.id);
        return !duplicate;
      });
      this.branches = filteredArr;
      resolve(true);
    });
  }

  selectGrade(ev: any) {
    this.form.get('section').reset();
    this.sections = [];
    this.gradeId = ev;
    const sectionsArray = this.allClasses.filter(
      (element) => element.grade.id == ev,
    );
    for (let i = 0; i < sectionsArray.length; i++) {
      for (let y = 0; y < sectionsArray[i].sections.length; y++) {
        this.sections.push(sectionsArray[i].sections[y].section);
      }
    }
  }

  selectedSection(ev: any) {
    this.sectionId = ev;
    this.section.setValue(this.sectionId);
  }

  closeDialog() {
    this.dialogRef.close(false);
  }
}
