import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-academic',
  templateUrl: './academic.component.html',
  styleUrls: ['./academic.component.scss'],
})
export class AcademicComponent implements OnInit {
  @Input() items: any;
  children: any;
  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items != undefined && !changes.items.firstChange) {
      this.children = changes.items.currentValue;
    }
  }
}
