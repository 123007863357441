import { Injectable } from '@angular/core';
import { GenericService } from '../generic/generic.service';

@Injectable({
  providedIn: 'root',
})
export class TaxSetupService {
  controller = 'taxes';
  constructor(private generic: GenericService) {}
  get() {
    return this.generic.getDataByParams(
      this.controller,
      'showall=true',
      'desc',
    ); //return this.generic.getData(this.controller);
  }
  post(body) {
    return this.generic.create(this.controller, body);
  }

  getByID(id) {
    return this.generic.getDataById(this.controller, id);
  }

  delete(id) {
    //This method is used to delete record from database and it fires a snackbar on successful delete
    return this.generic.delete(this.controller, id);
  }
  update(body) {
    return this.generic.update(this.controller, body);
  }

  getbyParams(params) {
    var order = 'desc';
    return this.generic.getDataByParams(this.controller, params, order);
  }

  search(search, filter, order) {
    return this.generic.getDataBySearch(this.controller, search, filter, order);
  }
}
