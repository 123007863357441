import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-meeting-log-card',
  templateUrl: './meeting-log-card.component.html',
  styleUrls: ['./meeting-log-card.component.scss'],
})
export class MeetingLogCardComponent {
  showdiv: string;
  @Input() log;
  @Input() actions;
  @Input() permission;
  @Input() showActions;
  @Output() getStudentLogs = new EventEmitter();
  @Output() openPreviewDialog = new EventEmitter();
  @Output() editMeetingLog = new EventEmitter();
  @Output() deleteMeetingLog = new EventEmitter();

  getStudentLogsClick(id) {
    this.getStudentLogs.emit(id);
  }

  openPreviewDialogClick(id) {
    this.openPreviewDialog.emit(id);
  }

  editMeetingLogClick(id) {
    this.editMeetingLog.emit(id);
  }

  deleteMeetingLogClick(id) {
    this.deleteMeetingLog.emit(id);
  }

  onClickedOutside(e: Event) {
    this.showdiv = '';
  }

  option(i) {
    if (i == this.showdiv) {
      this.showdiv = '';
    } else {
      this.showdiv = i;
    }
  }
}
