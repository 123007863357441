import { AuthService } from '../auth/auth.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { GenericService } from '../generic/generic.service';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/internal/operators/tap';
@Injectable({
  providedIn: 'root',
})
export class SchoolService {
  private schoolDetail$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private generic: GenericService, private auth: AuthService) {
    this.auth.getUser().subscribe((res: any) => {
      if (!res) this.schoolDetail$.next(null);
    });
  }
  controller = 'schools';
  get() {
    //getting data from service in this method and showing it on list
    return this.generic.getData(this.controller);
  }

  getByParams(param) {
    return this.generic.getData(this.controller + '?' + param);
  }

  search(search, filter, order) {
    return this.generic.getDataBySearch(this.controller, search, filter, order);
  }

  post(body) {
    return this.generic.create(this.controller, body);
  }

  getByUser() {
    return this.schoolDetail$.getValue()
      ? this.schoolDetail$.asObservable()
      : this.set();
  }

  private set() {
    return this.generic
      .getData('schools/my')
      .pipe(tap((response) => this.schoolDetail$.next(response)));
  }

  getByID(id) {
    return this.generic.getDataById(this.controller, id);
  }

  delete(id) {
    return this.generic.delete(this.controller, id);
  }
  update(body) {
    this.schoolDetail$.next(null);
    return this.generic.update(this.controller, body);
  }
  assignFeature(params, body) {
    return this.generic.update(this.controller + params, body);
  }
  logoUpload(body) {
    return this.generic.imageUpload('schools/uploadLogo', body);
  }

  getQuickStats(id = 'null') {
    return this.generic.getData('schools/quickstats?id=' + id);
  }

  getStats(params) {
    return this.generic.getDataByParams('schools/stats', params, 'desc');
  }

  getBilling(params) {
    return this.generic.getDataByParams('schools/billing', params, 'desc');
  }
}
