import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-name-card',
  templateUrl: './list-name-card.component.html',
  styleUrls: ['./list-name-card.component.scss'],
})
export class ListNameCardComponent implements OnInit {
  @Input() heading1: any;
  @Input() heading2: string;

  constructor() {}

  ngOnInit() {}
}
