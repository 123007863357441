import { Component, Input, OnInit } from '@angular/core';
import { BillingBatchService } from 'src/app/core/services/billing-batch/billing-batch.service';
import { billingBatch } from 'src/app/shared/models/billing-batch';

@Component({
  selector: 'app-dashboard-status-list',
  templateUrl: './dashboard-status-list.component.html',
  styleUrls: ['./dashboard-status-list.component.scss'],
})
export class DashboardStatusListComponent implements OnInit {
  @Input() mainHeading: any;
  @Input() heading1: any;
  @Input() heading2: string;
  @Input() heading3: string;
  @Input() heading4: any;
  @Input() heading5: string;
  @Input() heading6: string;
  apiData: billingBatch[];

  constructor(private batchService: BillingBatchService) {}

  ngOnInit() {
    this.getbillingRequest();
  }

  getbillingRequest() {
    this.batchService
      .search('', '', '&status=6017e54f931ec149a8fb1013', '', '')
      .subscribe((res: any) => {
        this.apiData = res.data as billingBatch[];
      });
  }
}
