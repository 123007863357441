import { GenericService } from '../../generic/generic.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StudentPromotionService {
  controller = 'promotion';

  constructor(private generic: GenericService) {}

  getPromotionStats(academicYearId) {
    return this.generic.getData(`${this.controller}/${academicYearId}/stats`);
  }

  getAllPromotions(params) {
    let url = '';
    for (const key in params) {
      if (params[key] != undefined && params[key] != '') {
        url = url.concat(`&${key}=${params[key]}`);
      }
    }
    return this.generic.getDataByParams(this.controller, url);
  }

  createPromotions(body) {
    return this.generic.create(this.controller, body);
  }

  getPromotions(params, assignTerm) {
    const { academicYear, currentBranch, currentGrade, branchToPromote } =
      params;

    let path = `${academicYear}/${currentBranch}/${currentGrade}/${branchToPromote}`;

    // TODO: AssignTerm field Will be removed after the result refactoring
    if (assignTerm) {
      path += `?assignTerm=${assignTerm}`;
    }
    return this.generic.getDataById(this.controller, path);
  }

  promoteStudentById(id, body) {
    return this.generic.patch(`${this.controller}/${id}/promote-student`, body);
  }

  detainStudentById(id, body) {
    return this.generic.patch(`${this.controller}/${id}/detain-student`, body);
  }

  approvePromotion(params, body) {
    const { academicYear, currentBranch, currentGrade, branchToPromote } =
      params;

    const path = `${academicYear}/${currentBranch}/${currentGrade}/${branchToPromote}`;

    return this.generic.patch(`${this.controller}/${path}`, body);
  }

  postPromotion(academicYear) {
    return this.generic.patch(
      `${this.controller}/${academicYear}/post-promotion`,
      {},
    );
  }

  revertPromotion(schoolId, body) {
    return this.generic.patch(
      `${this.controller}/${schoolId}/revert-promotion`,
      body,
    );
  }

  revertApprovedPromotion(schoolId, body) {
    return this.generic.patch(
      `${this.controller}/${schoolId}/revert-approved-promotion`,
      body,
    );
  }

  changeStudentAcademicStatus(schoolId, body) {
    return this.generic.patch(
      `${this.controller}/${schoolId}/update-academic-status`,
      body,
    );
  }

  deletePromotion(schoolId, academicYear) {
    return this.generic.deleteWithOutId(
      `${this.controller}/${schoolId}/${academicYear}/delete-promotion`,
    );
  }

  regeneratePromotion(params, body) {
    const { academicYear, yearToPromote, currentBranch, currentGrade } = params;

    const path = `${academicYear}/${yearToPromote}/${currentBranch}/${currentGrade}`;

    return this.generic.patch(`${this.controller}/regenerate/${path}`, body);
  }

  changePromotionBranch(schoolId, body) {
    return this.generic.patch(
      `${this.controller}/${schoolId}/change-branch`,
      body,
    );
  }
}
