import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { PreviewDocumentModalComponent } from '../preview-document-modal/preview-document-modal.component';
import { UserType } from '../../enums-constant/user-type.enum';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-letter-card',
  templateUrl: './letter-card.component.html',
  styleUrls: ['./letter-card.component.scss'],
})
export class LetterCardComponent implements OnInit {
  @Input() data;
  userType;
  isAdmin = false;
  constructor(private dialog: MatDialog) {}

  ngOnInit() {
    const userType = localStorage.getItem('user_type');
    if (userType === UserType.Admin || userType === UserType.Staff) {
      this.isAdmin = true;
    }
  }
  async openPreviewDialog(id) {
    this.dialog.open(PreviewDocumentModalComponent, {
      panelClass: '',
      data: {
        id: id,
        pdf: id.pdfSource,
        grade: id.grade.name,
        title: id.title,
        branchName: id.branch,
        visibleTo: id.visible,
        showEditBtn: false,
        isAdmin: this.isAdmin,
      },
    });
  }

  downloadLetterPdf(id) {
    const pdfSource = id.pdfSource;
    const title = id.title;
    saveAs(pdfSource, `${title}.pdf`);
  }
}
