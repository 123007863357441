import { Injectable } from '@angular/core';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import pdfMake from 'pdfmake/build/pdfmake';

@Injectable({
  providedIn: 'root',
})
export class PdfService {
  constructor() {
    (window as any).pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }

  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');

      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        const dataURL = canvas.toDataURL('image/png');

        resolve(dataURL);
      };

      img.onerror = (error) => {
        reject(error);
      };

      img.src = url;
    });
  }

  // margin: [left, top, right, bottom]
  // margin: [horizontal, vertical]
  async generatePdfTable(title, subtiTle, body, options) {
    // console.log(  localStorage.getItem("schoolImg"))
    // console.log(  localStorage.getItem("profImg"))
    const downloadByText =
      'Downloaded By:  ' +
      localStorage.getItem('firstName') +
      ' at' +
      ' ' +
      new Date().toLocaleString();

    const docDefinition = {
      pageSize: options.pageType,
      pageOrientation: options.pageOrientation,
      pageMargins: [5, 50, 5, 50],
      header: {
        columns: [
          {
            width: 40,
            image: await this.getBase64ImageFromURL(
              localStorage.getItem('schoolImg'),
            ),
            alignment: 'left',
          },
          {
            stack: [
              {
                text: localStorage.getItem('schoolName'),
                fontSize: 14,
                bold: true,
                alignment: 'center',
              },
              subtiTle,
            ],
            width: '*',
            alignment: 'center',
            margin: [5, 7, 0, 0],
          },
        ],
        margin: [15, 10],
      },
      content: [
        // {
        //   text: title,
        //   style: 'heading'
        // },
        {
          columns: [
            { width: '*', text: '' },
            {
              width: 'auto',
              style: 'tableStyle',

              table: {
                // heights: [20, 0],
                alignment: 'center',
                headerRows: 1,
                body: body,
                widths: options.colWidths,
                // width: 100,
              },
              layout: {
                paddingTop: function (i, node) {
                  return 10;
                },
                paddingBottom: function (i, node) {
                  return 10;
                },
                hLineColor: function (i, node) {
                  return i === 0 || i === node.table.body.length
                    ? 'black'
                    : options.charcolColor;
                },
                vLineColor: function (i, node) {
                  return i === 0 || i === node.table.widths.length
                    ? 'black'
                    : options.charcolColor;
                },
              },
            },
            { width: '*', text: '' },
          ],
          margin: [5, 25, 5, 10],
        },
      ],
      footer: function (currentPage, pageCount) {
        return {
          margin: 10,
          columns: [
            {
              //text: options.footerText, alignment: 'left', fontSize: 9
              text: downloadByText,
              alignment: 'left',
              fontSize: 9,
            },
            {
              text: 'Page: ' + currentPage.toString() + ' of ' + pageCount,
              alignment: 'right',
              fontSize: 9,
            },
          ],
        };
      },
      styles: {
        tableStyle: {
          alignment: 'center',
          fontSize: options.tableContentFontSize,
          // lineHeight:2,
        },
        heading: {
          alignment: 'center',
          fontSize: options.mainHeadingFontSize,
          bold: true,
          padding: [10, 10, 10, 10],
        },
        footer: {
          alignment: 'center',
          fontSize: 10,
          bold: false,
          margin: [0, 20, 0, 20],
          padding: [10, 10, 10, 10],
        },
        tableHeader: {
          bold: true,
          alignment: 'center',
          // marginTop:10,
          fontSize: options.tableHeadingFontSize,
          color: options.headerTextClr,
          // fillColor: options.charcolColor,
          fillColor: options.headerBackground,
        },
      },
    };

    if (options.download == true) {
      pdfMake.createPdf(docDefinition).download(options.pdfName);
    } else {
      pdfMake.createPdf(docDefinition).print();
    }
  }
}
