import * as moment from 'moment';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatMenuTrigger, MatSnackBar } from '@angular/material';
import { EventService } from 'src/app/core';
import { ParentEventService } from 'src/app/core/services/parent/parent-events/parent-event.service';
import { StudentEventsService } from 'src/app/core/services/student/student-events/student-events.service';
import { Subscription } from 'rxjs';
import { TeacherUserEventService } from 'src/app/core/services/teacher-user/teacher-user-event.service';
const DAY_MS = 60 * 60 * 24 * 1000;

@Component({
  selector: 'app-month-view-shared',
  templateUrl: './month-view.component.html',
  styleUrls: ['./month-view.component.scss'],
})
export class MonthViewComponent implements OnInit, OnDestroy {
  dates: Array<Date>;
  monthCount = 0;
  dateCount = 0;
  showdiv;
  days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
  date = new Date();

  todayDate;
  @Output() selected = new EventEmitter();
  eventArray = [];
  selectedDate: Date = new Date();
  y: number;
  m: number;
  firstDay: Date;
  lastDay: Date;
  @Input() service;
  eventCount = [];
  count = 0;
  userType: string;
  isAdmin = false;
  studentId = localStorage.getItem('studentId');
  @ViewChild(MatMenuTrigger, { static: false })
  languageMenuTrigger: MatMenuTrigger;
  subscription: Subscription;

  constructor(
    private eventTeacherUserService: TeacherUserEventService,
    private eventStudentService: StudentEventsService,
    private eventParentService: ParentEventService,
    private eventService: EventService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {}

  eventDate = new Date();

  ngOnInit(): void {
    this.dates = this.getCalendarDays(this.date);
    const today = moment();
    this.todayDate = new Date(today.startOf('day').toISOString()).getTime();
    this.eventInit();
    this.subscription = this.eventService.notifyObservable$.subscribe((res) => {
      if (res.option == 'refresh-event-monthView') {
        this.getEvents();
      }
    });
  }

  async eventInit(): Promise<void> {
    await this.switchService(this.service);
    this.getEvents();
  }

  private switchService(mock: string) {
    if (mock === 'student') {
      this.service = this.eventStudentService;
    } else if (mock === 'admin') {
      this.isAdmin = true;
      this.service = this.eventService;
    } else if (mock === 'parent') {
      this.service = this.eventParentService;
    } else if (mock === 'teacher') {
      this.service = this.eventTeacherUserService;
    } else {
      this.service = this.eventService;
    }
  }

  getUserType(): void {
    this.eventParentService.userTypeVisibility.subscribe((type) => {
      this.userType = type;
    });
  }

  setMonth(inc: number): void {
    const [year, month] = [this.date.getFullYear(), this.date.getMonth()];
    this.date = new Date(year, month + inc, 1);
    this.getEvents();
    this.dates = this.getCalendarDays(this.date);
  }

  isSameMonth(date: Date): boolean {
    return date.getMonth() === this.date.getMonth();
  }

  private getCalendarDays(date = new Date()) {
    const calendarStartTime = this.getCalendarStartDay(date).getTime();
    return this.range(0, 41).map(
      (num) => new Date(calendarStartTime + DAY_MS * num),
    );
  }

  private getCalendarStartDay(date = new Date()) {
    const [year, month] = [date.getFullYear(), date.getMonth()];
    const firstDayOfMonth = new Date(year, month, 1).getTime();

    return this.range(1, 7)
      .map((num) => new Date(firstDayOfMonth - DAY_MS * num))
      .find((dt) => dt.getDay() === 0);
  }

  private range(start, end, length = end - start + 1) {
    return Array.from({ length }, (_, i) => start + i);
  }

  getEvents(): void {
    this.y = this.date.getFullYear();
    this.m = this.date.getMonth();
    this.firstDay = new Date(this.y, this.m, 1);

    const endOfMonth = moment(new Date(this.y, this.m))
      .endOf('month')
      .toISOString();

    this.lastDay = new Date(endOfMonth);

    const params =
      'fromDate=' +
      this.firstDay.toISOString() +
      '&toDate=' +
      this.lastDay.toISOString();

    this.service.getbyParams(params).subscribe((res) => {
      this.eventArray = res.data;
      for (let i = 0; i < res.data.length; i++) {
        const to_date = moment(res.data[i].toDate);
        const from_date = moment(res.data[i].fromDate);
        const day_diff = to_date.diff(from_date, 'days');
        this.eventArray[i].day_diff = day_diff;

        let attendeesCount = 0;

        for (let j = 0; j < res.data[i].attendees.length; j++) {
          if (res.data[i].attendees[j].rsvp === true) {
            attendeesCount++;
          }
        }

        this.eventArray[i].attendCount = attendeesCount;
      }
    });
  }

  checkdate(
    startDate: string | Date,
    endDate: string | Date,
    currentDate: string | Date,
  ): boolean {
    const fromDateMonthWise = moment(startDate).format('DD/MM/YYYY');
    const toDateMonthWise = moment(endDate).format('DD/MM/YYYY');
    const dateTemp = new Date(currentDate);
    const currentDateMonthWise = moment(dateTemp).format('DD/MM/YYYY');
    if (
      fromDateMonthWise == currentDateMonthWise ||
      (currentDateMonthWise > fromDateMonthWise &&
        currentDateMonthWise < toDateMonthWise) ||
      toDateMonthWise == currentDateMonthWise
    ) {
      return true;
    } else {
      return false;
    }
  }

  goToday(): void {
    const date = moment();
    const startOfMonth = date.startOf('month').format('YYYY-MM-DD hh:mm');
    this.date = new Date(startOfMonth);
    this.dates = this.getCalendarDays(new Date(startOfMonth));
    this.getEvents();
  }

  onClickedOutside(): void {
    this.showdiv = '0';
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
