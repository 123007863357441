import * as moment from 'moment';
import { GenericService } from '../../generic/generic.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommunicationService {
  constructor(private generic: GenericService) {}
  controller = 'communication/events';
  get() {
    return this.generic.getData(this.controller);
  }

  getByID(id) {
    return this.generic.getDataById(this.controller, id);
  }

  getbyParams(params, order = 'desc', sort = 'createdAt') {
    return this.generic.getDataByParams(this.controller, params, order, sort);
  }

  getQuickStats() {
    return this.generic.getData(`communication/quickstats`);
  }

  getUpcomingEvents(branch?: string): Observable<any> {
    const todayDate = moment();
    let url = `fromDate=${todayDate.format('YYYY-MM-DD')}&limit=5`;
    if (branch !== undefined && branch !== '' && branch != 'allBranches') {
      url = url.concat(`&branch=${branch}`);
    }

    return this.getbyParams(url, 'asc', 'fromDate');
  }

  getCalendarEvents(branch?: string, dates?: any): Observable<any> {
    const firstDay =
      dates && dates.startDate
        ? moment(dates.startDate).startOf('month').format('YYYY-MM-DD')
        : moment().startOf('month').format('YYYY-MM-DD');
    const lastDay =
      dates && dates.endDate
        ? moment(dates.endDate).endOf('month').format('YYYY-MM-DD')
        : moment().endOf('month').format('YYYY-MM-DD');
    let url = `fromDate=${firstDay}&toDate=${lastDay}`;
    if (branch !== undefined && branch !== '' && branch != 'allBranches') {
      url = url.concat(`&branch=${branch}`);
    }

    return this.getbyParams(url, 'asc', 'fromDate');
  }
}
