import { Injectable } from '@angular/core';
import { GenericService } from '../generic/generic.service';

@Injectable({
  providedIn: 'root',
})
export class ActiveTaxPayerService {
  controller = 'billings/taxpayer';
  constructor(private generic: GenericService) {}

  get() {
    return this.generic.getData(
      this.controller + '?search=ATP&filter=uploadFile',
    );
  }

  post(body) {
    return this.generic.imageUpload(`${this.controller}/upload`, body);
  }

  getByID(id) {
    return this.generic.getDataById(this.controller, id);
  }
  delete(id) {
    return this.generic.delete(this.controller, id);
  }
  update(body) {
    return this.generic.update(this.controller, body);
  }
  search(search, filter, order) {
    return this.generic.getDataBySearch(this.controller, search, filter, order);
  }
}
