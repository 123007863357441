import { GenericService } from '../generic/generic.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CurriculumAssignmentsService {
  private readonly controller = 'curriculum';
  private readonly resource = 'assignments';
  constructor(private generic: GenericService) {}

  getAllAssignments(curriculumId) {
    return this.generic.getData(
      `${this.controller}/${curriculumId}/${this.resource}`,
    );
  }

  getAssignmentById(curriculumId, assignmentId) {
    return this.generic.getDataById(
      `${this.controller}/${curriculumId}/${this.resource}`,
      assignmentId,
    );
  }

  post(curriculumId, body) {
    return this.generic.create(
      `${this.controller}/${curriculumId}/${this.resource}`,
      body,
    );
  }

  update(body, curriculumId, id) {
    return this.generic.update(
      `${this.controller}/${curriculumId}/${this.resource}/${id}`,
      body,
    );
  }

  delete(curriculumId, id) {
    return this.generic.delete(
      `${this.controller}/${curriculumId}/${this.resource}`,
      id,
    );
  }
}
