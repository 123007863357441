import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { BillingService } from 'src/app/core';
import { CustomSnackBarComponent } from '../custom-snack-bar/custom-snack-bar.component';
import { CustomSnackbarDeleteComponent } from '../custom-snackbar-delete/custom-snackbar-delete.component';
import { MatSnackBar } from '@angular/material';
import { ParentBillingService } from 'src/app/core/services/parent/parent-billing/parent-billing.service';
import { StudentParentService } from 'src/app/core/services/student/student-parent/student-parent.service';
import { SudentParent } from 'src/app/shared/models/student-parent';
import Swal from 'sweetalert2';
import { UserType } from '../../enums-constant/user-type.enum';
import { environment } from 'src/environments/environment';
import { helper } from '../../helper-functions';

@Component({
  selector: 'app-parent-profile',
  templateUrl: './parent-profile.component.html',
  styleUrls: ['./parent-profile.component.scss'],
})
export class ParentProfileComponent implements OnInit {
  @Input() showActions;
  @Input() module;
  baseUrl: any;
  userProfileId = '';
  currentPageUrl = '';
  father = true;
  mother = false;
  tax = false;
  EmergencyContactInfo = false;
  editID: any;
  isAdminView = false;
  isStaffView = false;
  isParentView = false;
  isStudentView = false;
  guardian = false;
  studentParent = true;
  qualification = false;
  workExperience = false;
  parentExist = false;
  studentParentData = new SudentParent();
  siblingInfo: any;
  // childrenCount: number;
  children: any;
  limitShow: number;
  billings: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private studentParentService: StudentParentService,
    public parent: ParentBillingService,
    private billingService: BillingService,
    private _snackBar: MatSnackBar,
  ) {
    this.route.queryParams.subscribe((query) => {
      if (query['feature'] == 'isMotherSelected') {
        this.getMotherInfo();
      }
    });
  }
  breadcrumb = [
    {
      label: 'Home /',
      url: '/admin/dashboard',
    },
    {
      label: 'Parents /',
      url: '/admin/parent/parents',
    },
  ];
  /*
    Confirmation Popups
  */
  cpopUpDeleteTitle = 'Are you sure you want to delete it?';
  cpopUpDeletetext = 'Your data will be deleted permanently!';
  cpopUpCancelTitle = 'Are you sure you want to cancel it?';
  cpopUpCanceltext = 'Your changes will be lost.';
  cpopUpicon: any = 'warning';
  cpopUpshowCancelButton = true;
  cpopUpconfirmButtonText = 'Yes';
  cpopUpcancelButtonText = 'No';
  ngOnInit() {
    this.userProfileId = this.route.snapshot.paramMap.get('id');
    const userType = localStorage.getItem('user_type');
    this.isAdminView = userType === UserType.Admin;
    this.isStaffView = userType === UserType.Staff;
    this.isParentView = userType === UserType.Parent;
    this.isStudentView = userType === UserType.Student;
    if (userType == 'Parent') {
      this.breadcrumb = [
        {
          label: 'Home/',
          url: '',
        },
        {
          label: '',
          url: '',
        },
        {
          label: 'Profile',
          url: '',
        },
      ];
    }
    if (userType == 'Student') {
      this.breadcrumb = [
        {
          label: 'Home/',
          url: '/student/home',
        },
        {
          label: '',
          url: '',
        },
        {
          label: 'Profile',
          url: '',
        },
      ];
    }
    if (this.module == 'billing') {
      this.breadcrumb = [
        {
          label: 'Home',
          url: '/admin/home/dashboard',
        },
        {
          label: '/ Billing',
          url: '/admin/billing/billing-dashboard',
        },
        {
          label: '/ Parents',
          url: '/admin/billing/parent-list',
        },
      ];
    }
    this.limitShow = 3;
    this.getParentProfile(this.userProfileId);
    this.baseUrl = environment.baseUrl;
    this.currentPageUrl = this.router.url;
  }

  getParentProfile(id) {
    this.studentParentService.getChildren(id).subscribe((res: any) => {
      if (res.data != undefined) {
        this.studentParentData = res.data;
        // this.childrenCount = res.data.childrenCount;
        this.children = res.data.children;
        this.getBillings();
      }
    });
  }

  getBillings() {
    let url = 'limit=' + this.limitShow;
    const userType = localStorage.getItem('user_type');
    if (userType == UserType.Admin) {
      url = url.concat(`&studentIds=`);
      this.children.forEach((s) => {
        url = url.concat(`${s.studentProfile._id},`);
      });
    }
    this.parent.getbyParams(url).subscribe(async (res: any) => {
      if (res.data && res.data.length) {
        this.billings = res.data;
      } else {
        if (this.children && this.children.length) {
          const studentIds = this.children.map(
            (child) => child.studentProfile.rollNo,
          );
          this.getStudentsBillingInfo(studentIds);
        }
      }
    });
  }

  viewProfile(profile) {
    if (this.module == 'billing') {
      this.router.navigateByUrl(
        `/admin/billing/student-profile-billing/${profile.rollNo}?feature=parents`,
      );
    } else {
      const redirectUrl = `${this.currentPageUrl}/children/${profile._id}`;
      this.router.navigateByUrl(redirectUrl);
    }
  }

  getFatherInfo() {
    this.father = true;
    this.mother = false;
    this.tax = false;
    this.guardian = false;
    this.EmergencyContactInfo = false;
  }

  getMotherInfo() {
    this.father = false;
    this.mother = true;
    this.tax = false;
    this.guardian = false;
    this.EmergencyContactInfo = false;
  }

  getTaxInfo() {
    this.father = false;
    this.mother = false;
    this.tax = true;
    this.guardian = false;
    this.EmergencyContactInfo = false;
  }

  getGuardianInfo() {
    this.father = false;
    this.mother = false;
    this.tax = false;
    this.guardian = true;
    this.EmergencyContactInfo = false;
  }
  getEmergencyContactInfo() {
    this.father = false;
    this.mother = false;
    this.tax = false;
    this.guardian = false;
    this.EmergencyContactInfo = true;
  }
  edit(id) {
    if (this.isAdminView || this.isStaffView) {
      this.router.navigateByUrl('admin/parent/parent-main/' + id);
      this.editID = id;
    }
    if (this.isParentView) {
      this.router.navigateByUrl(`/parent/profile-edit/${id}`);
      this.editID = id;
    }
  }
  showSnackBar(type, message) {
    if (type === 'delete') {
      this._snackBar.openFromComponent(CustomSnackbarDeleteComponent, {
        data: { message: message },
        horizontalPosition: 'center',
        duration: 2000,
        panelClass: ['delete-snackBar'],
        verticalPosition: 'top',
      });
    } else if (type === 'success') {
      this._snackBar.openFromComponent(CustomSnackBarComponent, {
        data: { message: message },
        horizontalPosition: 'center',
        duration: helper.duration,
        panelClass: ['save-snackBar'],
        verticalPosition: 'top',
      });
    }
  }

  delete(id) {
    Swal.fire({
      title: this.cpopUpDeleteTitle,
      text: this.cpopUpDeletetext,
      icon: this.cpopUpicon,
      showCancelButton: this.cpopUpshowCancelButton,
      confirmButtonText: this.cpopUpconfirmButtonText,
      cancelButtonText: this.cpopUpcancelButtonText,
    }).then((result) => {
      if (result.isConfirmed) {
        this.studentParentService.delete(id).subscribe((res: any) => {
          if (res.status == 'success') {
            this.showSnackBar('success', 'Parent has been deleted!');
          }

          this.router.navigateByUrl('admin/parent/parents');
        });
      }
    });
  }
  /**
   * Get Billing Info for Each student
   * @param studentIds
   */
  getStudentsBillingInfo(studentIds) {
    this.billingService
      .getBillingForProfiles({ studentIds })
      .subscribe((res: any) => {
        if (res.data && res.data.length) {
          this.billings = [];
          res.data.forEach((d) => {
            d.data.forEach((e) => {
              const child = this.children.find(
                (c) => c.studentProfile.rollNo === e.rollNo,
              );
              e.fkStudentId = child.studentProfile;
              this.billings.push(e);
            });
          });
          this.billings = this.billings
            .filter((bill) => bill.status.name === 'Publish')
            .slice(0, 3);
        }
      });
  }
}
