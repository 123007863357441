import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AssignSubjectService } from 'src/app/core/services/assign-subject/assign-subject.service';
import { AssingnClassService } from 'src/app/core/services/assign-classes/assingn-class.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { CustomSnackBarComponent } from '../../custom-snack-bar/custom-snack-bar.component';
import { CustomSnackbarDeleteComponent } from '../../custom-snackbar-delete/custom-snackbar-delete.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material';
import { StructureTimetableService } from 'src/app/core/services/timetable/structured-Timetable/timetable/structure-timetable.service';
import Swal from 'sweetalert2';
import { TeacherProfileService } from 'src/app/core/services/teacher/teacher-profile/teacher-profile.service';
import { TimetableModalComponent } from '../timetable-modal/timetable-modal.component';
import { helper } from 'src/app/shared/helper-functions/helper';
import html2canvas from 'html2canvas';
// eslint-disable-next-line no-var
declare var jspdf: any;

@Component({
  selector: 'app-timetablecom',
  templateUrl: './timetablecom.component.html',
  styleUrls: ['./timetablecom.component.scss'],
})
export class TimetablecomComponent implements OnInit, OnDestroy {
  newValue: number;
  grades = [];
  slectedGrade;
  myForm: FormGroup;
  timeTable: any;
  selectedTimeTable: any;
  selectedBranch: any;
  allClasses: any;
  branches = [];
  branchId: any;
  sections = [];
  gradeId: any;
  selectedPeriod: any;
  teacherList = [];
  slectedsection: any;
  sectionId;
  selectedBranchStructure = [];
  currentIndexNew: number;
  PreviousIndexNew: number;
  teachers: any[];
  routeId: string;
  loaderMessage = 'Loading...';
  loading = false;
  isTimetableshow = false;
  timeTableID: any;
  isShowNoTimetableMessage = false;
  loader = false;
  isModalOpen = false;
  constructor(
    public dialog: MatDialog,
    private teacherProfileService: TeacherProfileService,
    private assignClassService: AssingnClassService,
    private timetable: StructureTimetableService,
    private fb: FormBuilder,
    private assignSubject: AssignSubjectService,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
  ) {
    this.myForm = this.fb.group({
      grade: [undefined, Validators.required],
      branch: [undefined, Validators.required],
      section: [undefined, Validators.required],
    });
  }
  subPeriod = [];
  showPeriods = [];
  showPeriodList = false;
  showSubject = false;
  subjects: any;
  submittedR = false;
  dragAlow = true;
  ngOnInit(): void {
    this.routeId = this.route.snapshot.paramMap.get('id');
    if (this.routeId) {
      this.timeTableID = this.routeId;
      this.getByID();
    } else {
      this.getBranches();
    }
    this.route.queryParams.subscribe((queryParams) => {
      this.sectionId = queryParams.section;
    });
  }

  get f() {
    return this.myForm.controls;
  }

  getByID() {
    this.timetable.getByID(this.routeId).subscribe((res: any) => {
      this.selectedBranch = res.data.branch.id;
      this.slectedGrade = res.data.grade.id;
      this.getBranches();
    });
  }

  print() {
    if (!this.timeTableID) return;
    this.loader = true;
    const branch = this.findBranch(this.myForm.value.branch);
    const grade = this.findGrade(this.myForm.value.grade);
    const section = this.findSection(this.myForm.value.section);
    const data = document.getElementById('contentToConvert');

    html2canvas(data).then(async (canvas) => {
      const imgWidth = 210; // Set the width to fit within the A4 page width
      const pageHeight = 297;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png');
      const pdf = new jspdf('p', 'mm', 'a4');
      const schoolImg = localStorage.getItem('schoolImg');
      const headerImgUrl = await this.getBase64ImageFromURL(schoolImg);
      const headerText = localStorage.getItem('schoolName');
      const text = `${branch.name} - ${grade.name} - ${section.name}`;
      pdf.setFontSize(14);

      const headerCanvas = document.createElement('canvas');
      const headerImg: any = new Image();
      headerImg.onload = function () {
        headerCanvas.width = headerImg.width;
        headerCanvas.height = headerImg.height;
        const ctx = headerCanvas.getContext('2d');
        const borderRadius = 140;
        ctx.beginPath();
        ctx.moveTo(borderRadius, 0);
        ctx.lineTo(headerCanvas.width - borderRadius, 0);
        ctx.quadraticCurveTo(
          headerCanvas.width,
          0,
          headerCanvas.width,
          borderRadius,
        );
        ctx.lineTo(headerCanvas.width, headerCanvas.height - borderRadius);
        ctx.quadraticCurveTo(
          headerCanvas.width,
          headerCanvas.height,
          headerCanvas.width - borderRadius,
          headerCanvas.height,
        );
        ctx.lineTo(borderRadius, headerCanvas.height);
        ctx.quadraticCurveTo(
          0,
          headerCanvas.height,
          0,
          headerCanvas.height - borderRadius,
        );
        ctx.lineTo(0, borderRadius);
        ctx.quadraticCurveTo(0, 0, borderRadius, 0);
        ctx.closePath();
        ctx.clip();
        ctx.drawImage(headerImg, 0, 0);
        const headerImgUrlWithBorderRadius =
          headerCanvas.toDataURL('image/png');
        pdf.addImage(headerImgUrlWithBorderRadius, 'PNG', 3, 5, 20, 20);
        pdf.text(headerText, 27, 13);
        pdf.text(text, 27, 20);

        const position = 30;
        const margin = 3;
        const maxHeight = pageHeight - position - margin * 2;
        let scaledImgHeight = imgHeight;
        let scaledImgWidth = imgWidth;

        // Scale the image if it exceeds the page height
        if (imgHeight > maxHeight) {
          scaledImgHeight = maxHeight;
          scaledImgWidth = (canvas.width * maxHeight) / canvas.height;
        }

        // Center the image horizontally on the page
        const xOffset = (210 - scaledImgWidth) / 2;

        pdf.addImage(
          contentDataURL,
          'PNG',
          xOffset,
          position,
          scaledImgWidth,
          scaledImgHeight,
        );

        pdf.save(
          `${localStorage.getItem('schoolName')} - Timetable - ${
            grade.name
          } .pdf`,
        );
      };
      headerImg.src = headerImgUrl;
      this.loader = false;
    });
  }

  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = (error) => {
        reject(error);
      };
      img.src = url;
    });
  }

  getStructuredTimetable() {
    this.submittedR = true;
    if (this.myForm.invalid) return;
    this.submittedR = false;
    this.loading = true;
    this.showPeriods = [];
    this.showPeriodList = true;
    const url = 'branch/' + this.selectedBranch + '/grade/' + this.slectedGrade;
    if (this.selectedBranch && this.slectedGrade && this.slectedsection) {
      this.timetable.getByParamsData(url).subscribe(
        (res: any) => {
          this.timeTableID = res.data._id;
          this.timeTable = res.data.structure;
          this.isTimetableshow = true;
          this.loading = false;
          this.getFrequency(this.timeTable);
        },
        (err) => {
          this.loading = false;
          this.isShowNoTimetableMessage = true;
          this._snackBar.openFromComponent(CustomSnackbarDeleteComponent, {
            data: { message: err.error.message },
            horizontalPosition: 'center',
            duration: helper.duration,
            panelClass: ['delete-snackBar'],
            verticalPosition: 'top',
          });
        },
      );
    }
  }

  getFrequency(timetable) {
    for (let i = 0; i < timetable.length; i++) {
      for (let j = 0; j < timetable[i].periods.length; j++) {
        this.showPeriods.push(timetable[i].periods[j]);
      }
    }
    this.getAssignSubjects();
  }

  getAssignSubjects() {
    const url = 'grade=' + this.slectedGrade;
    this.assignSubject.getbyParams(url).subscribe((res: any) => {
      if (res) {
        if (res.data.length !== 0) {
          this.showSubject = true;
          this.subjects = res.data;
        }
      }
      for (let i = 0; i < this.showPeriods.length; i++) {
        this.showPeriods[i].assign.forEach((e) => {
          if (this.slectedsection == e.section._id) {
            const objIndex = this.subjects.findIndex(
              (s: any) => s.subject._id == e.subject._id,
            );
            if (this.subjects[objIndex].frequency != 0) {
              this.newValue = this.subjects[objIndex].frequency - 1;
            } else {
              this.newValue = 0;
            }
            this.subjects[objIndex].frequency = this.newValue;
          }
        });
      }
    });
  }

  openDialog(day, period, structureId, name, assignId) {
    if (this.isModalOpen) return;
    let permission = {};
    this.route.data.subscribe((data) => (permission = data));
    const modalData = {
      subject: this.subjects, // assign subjects wrt branch and grade
      teacherList: this.teacherList, // teacher list of assign subjects wrt branch and grade
      branch: this.selectedBranch, // selected branch
      grade: this.slectedGrade, //selected grade
      section: this.slectedsection,
      day: day, //selected day
      periods: period, // selected period
      timetable: this.timeTable, // structure wrt branch
      StID: structureId,
      modalType: name,
      routeID: this.timeTableID,
      routeData: permission,
      assignId,
    };
    this.selectedPeriod = period.id;
    const dialogRef = this.dialog.open(TimetableModalComponent, {
      data: modalData,
      panelClass: 'timetableModal',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'success') this.getStructuredTimetable();
    });
  }

  selectedSection(ev) {
    this.slectedsection = ev;
    this.timeTable = [];
    this.isTimetableshow = false;
  }

  getBranches() {
    this.assignClassService.get().subscribe((res: any) => {
      this.allClasses = res.data.list;
      const branchArray = [];
      for (let i = 0; i < res.data.list.length; i++) {
        branchArray.push(res.data.list[i].branch);
      }
      const seen = new Set();
      const filteredArr = branchArray.filter((el) => {
        const duplicate = seen.has(el.id);
        seen.add(el.id);
        return !duplicate;
      });
      this.branches = filteredArr;
      if (this.routeId) {
        this.selectBranchNew(this.selectedBranch, true);
      }
    });
  }

  drop(event: CdkDragDrop<any>) {
    this.isModalOpen = true;
    this.currentIndexNew = event.currentIndex;
    this.PreviousIndexNew = event.previousIndex;
    this.teachers = [];
    const url =
      'branch=' +
      this.selectedBranch +
      '&grade=' +
      this.slectedGrade +
      '&subject=' +
      this.subjects[this.PreviousIndexNew].subject.id;
    let teacherList: any;
    this.teacherProfileService
      .getTeacherProfilesForSearchFilter(url)
      .subscribe((res: any) => {
        teacherList = res.data;
        for (const i in teacherList) {
          this.teachers.push({
            id: teacherList[i]._id,
            name: teacherList[i].firstName + ' ' + teacherList[i].lastName,
          });
        }
        let sectionMatch = false;
        for (
          let i = 0;
          i < event.container.data.periods[this.currentIndexNew].assign.length;
          i++
        ) {
          if (
            event.container.data.periods[this.currentIndexNew].assign[i].section
              ._id == this.slectedsection
          )
            sectionMatch = true;
        }
        if (
          event.container.data.day &&
          event.container.data.day._id &&
          event.container.data.periods[this.currentIndexNew] &&
          sectionMatch == false &&
          !event.container.data.periods[this.currentIndexNew].has_break
        ) {
          let permission = {};
          this.route.data.subscribe((data) => (permission = data));
          const dialogRef = this.dialog.open(TimetableModalComponent, {
            data: {
              subject: this.subjects,
              teacherList: this.teacherList,
              branch: this.selectedBranch,
              grade: this.slectedGrade,
              section: this.slectedsection,
              day: event.container.data.day._id,
              periods: event.container.data.periods[this.currentIndexNew],
              timetable: this.timeTable,
              StID: event.container.data._id,
              selectedSubject: this.subjects[this.PreviousIndexNew],
              selectedTeacher: this.teachers,
              modalType: 'Add',
              routeData: permission,
              routeID: this.timeTableID,
            },
            panelClass: 'timetableModal',
          });
          this.isModalOpen = false;
          dialogRef.afterClosed().subscribe((result) => {
            if (result == 'success') this.getStructuredTimetable();
          });
        }
      });
  }

  selectBranchNew(ev, IsSelectedAuto) {
    this.timeTable = [];
    this.isTimetableshow = false;
    this.grades = [];
    this.selectedBranch = ev;
    const gradesArray = this.allClasses.filter(
      (element) => element.branch.id == ev,
    );
    for (let i = 0; i < gradesArray.length; i++) {
      this.grades.push(gradesArray[i].grade);
    }
    if (!IsSelectedAuto) {
      this.myForm.patchValue({
        branch: this.selectedBranch,
      });
      this.myForm.get('grade').reset();
      this.myForm.get('section').reset();
    } else {
      this.selectGradeNew(this.slectedGrade, true);
    }
  }

  selectGradeNew(ev, IsSelectedAuto) {
    this.timeTable = [];
    this.isTimetableshow = false;
    this.sections = [];
    this.slectedGrade = ev;
    this.myForm.get('section').reset();
    const sectionsArray = this.allClasses.filter(
      (element) =>
        element.branch.id == this.selectedBranch && element.grade.id == ev,
    );
    for (let i = 0; i < sectionsArray.length; i++) {
      for (let y = 0; y < sectionsArray[i].sections.length; y++) {
        this.sections.push(sectionsArray[i].sections[y].section);
      }
    }
    if (IsSelectedAuto) {
      this.selectedSectionNew(this.sectionId, true);
    }
  }

  selectedSectionNew(ev, IsSelectedAuto) {
    this.timeTable = [];
    this.isTimetableshow = false;
    this.slectedsection = ev;
    if (this.selectedBranch && this.slectedGrade && this.slectedsection) {
      this.patchTimeTableValue();
      if (IsSelectedAuto) {
        this.getStructuredTimetable();
      }
    }
  }

  patchTimeTableValue() {
    this.myForm.patchValue({
      branch: this.selectedBranch,
      grade: this.slectedGrade,
      section: this.slectedsection,
    });
  }

  visibleAddButton(period: any) {
    let visible = true;
    period.assign.forEach((el) => {
      if (el.section.id === this.slectedsection) {
        visible = false;
      }
    });
    if (period.has_break) {
      visible = false;
    }
    return visible;
  }

  publishTimetable() {
    this.timetable.publishStructure(this.timeTableID, '').subscribe(
      (res: any) => {
        this._snackBar.openFromComponent(CustomSnackBarComponent, {
          data: { message: res.message },
          horizontalPosition: 'center',
          duration: helper.duration,
          panelClass: ['save-snackBar'],
          verticalPosition: 'top',
        });
      },
      (err) => {
        this.loading = false;
        this._snackBar.openFromComponent(CustomSnackbarDeleteComponent, {
          data: { message: err.error.message },
          horizontalPosition: 'center',
          duration: helper.duration,
          panelClass: ['delete-snackBar'],
          verticalPosition: 'top',
        });
      },
    );
  }

  isAssignedTimetable() {
    let isAnyAssigned = false;
    this.timeTable &&
      this.timeTable.forEach((s) => {
        s.periods.forEach((p) => {
          if (p.assign.length > 0) {
            isAnyAssigned = true;
          }
        });
      });
    return isAnyAssigned;
  }

  findBranch(id) {
    return this.branches.find((e) => e.id == id);
  }
  findGrade(id) {
    return this.grades.find((e) => e.id == id);
  }
  findSection(id) {
    return this.sections.find((e) => e.id == id);
  }
  ngOnDestroy(): void {
    this.dialog.closeAll();
  }

  deleteAssignedPeriod(assignId) {
    const url = `deleteAssignedPeriod?timetableId=${this.timeTableID}&assignId=${assignId}`;
    Swal.fire({
      title: 'Are you sure you want to delete it?',
      text: 'Your data will be deleted permanently!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        this.timetable.deleteAssignedTimetable(url).subscribe(
          (res: any) => {
            if (res.status == 'success') {
              this._snackBar.openFromComponent(CustomSnackBarComponent, {
                data: { message: res.message },
                horizontalPosition: 'center',
                duration: helper.duration,
                panelClass: ['save-snackBar'],
                verticalPosition: 'top',
              });
              this.getByID();
            }
          },
          (err) => {
            this._snackBar.openFromComponent(CustomSnackbarDeleteComponent, {
              data: { message: err.error.message },
              horizontalPosition: 'center',
              duration: helper.duration,
              panelClass: ['delete-snackBar'],
              verticalPosition: 'top',
            });
          },
        );
      }
    });
  }
}
