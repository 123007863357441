import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-teacher-document',
  templateUrl: './teacher-document.component.html',
  styleUrls: ['./teacher-document.component.scss'],
})
export class TeacherDocumentComponent implements OnInit {
  @Input() teacherProfileData;
  @Output() addTeacherDocument = new EventEmitter();
  @Output() removeTeacherDocument = new EventEmitter();

  form: FormGroup;
  submitted = false;
  files = [];
  fileHasError: boolean;
  fileErrorMessage: string;
  documentTypeOptions = ['CV', 'Certificate', 'Degree', 'Passport', 'CNIC'];

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.formPost();
  }

  formPost() {
    this.form = this.fb.group({
      documentName: new FormControl(undefined, Validators.required),
      documentType: new FormControl(undefined, Validators.required),
    });
  }

  get FormControl() {
    return this.form.controls;
  }

  handleFileSelect(event) {
    if (event.addedFiles.length === 0) {
      this.fileHasError = true;
      this.fileErrorMessage = 'Please select a file';
      return;
    }
    const file = event.addedFiles[0];
    if (file.type !== 'application/pdf') {
      this.fileHasError = true;
      this.fileErrorMessage = 'Only PDF files are allowed';
      return;
    }
    if (file.size > 2000000) {
      this.fileHasError = true;
      this.fileErrorMessage = 'File size should be less than 2MB';
      return;
    }
    this.files.push(file);
    this.fileHasError = false;
    this.fileErrorMessage = '';
  }

  handleFileRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  resetForm() {
    this.form.reset();
    this.files = [];
    this.fileHasError = false;
    this.fileErrorMessage = '';
  }

  deleteDocument(id) {
    Swal.fire({
      title: 'Are you sure you want to delete it?',
      text: 'Your document will be deleted permanently!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        this.removeTeacherDocument.emit({
          documentId: id,
        });
      }
    });
  }

  downloadDocument(url) {
    saveAs(url);
  }

  submit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    const teacherDocument = new FormData();
    teacherDocument.append('documentName', this.form.get('documentName').value);
    teacherDocument.append('documentType', this.form.get('documentType').value);
    teacherDocument.append('file', this.files[0]);

    this.addTeacherDocument.emit(teacherDocument);
  }
}
