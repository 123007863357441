import { GenericService } from '../../generic/generic.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DailyAttendanceService {
  constructor(private generic: GenericService) {}

  controller = 'attendance/daily-attendance';

  get() {
    return this.generic.getData(this.controller);
  }

  post(body) {
    return this.generic.create(this.controller, body);
  }

  getByID(id) {
    return this.generic.getDataById(this.controller, id);
  }
  delete(id) {
    return this.generic.delete(this.controller, id);
  }
  update(body) {
    return this.generic.update(this.controller, body);
  }
  search(search, filter, order) {
    return this.generic.getDataBySearch(this.controller, search, filter, order);
  }
  getAttendanceOfStudents(params) {
    const order = 'desc';
    return this.generic.getDataByParams(this.controller, params, order);
  }
  getAttendanceOfStudentsReport(params) {
    const order = 'desc';
    return this.generic.getDataByParams(
      this.controller + '/report',
      params,
      order,
    );
  }
  getAttendanceInsight(params) {
    return this.generic.getData(
      `${this.controller}/attendanceInsight?${params}`,
    );
  }

  markDayOff(body) {
    return this.generic.create(`${this.controller}/markDayOff`, body);
  }

  getConsecutive(params) {
    return this.generic.getData(`${this.controller}/consecutive?${params}`);
  }

  getLowAttendance(params) {
    return this.generic.getData(`${this.controller}/lowattendance?${params}`);
  }

  getHighAttendance(params) {
    return this.generic.getData(`${this.controller}/highAttendance?${params}`);
  }
}
