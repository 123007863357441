import {
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
} from '@angular/core';

@Directive({
  selector: '[appValidateMarks]',
})
export class ValidateMarksDirective {
  private _obtainMarks: number;
  private _totalMarks: number;
  private _isNew = true;

  @Input('appValidateMarks')
  set marks(marks: { obtainMarks: number; total: number }) {
    this._obtainMarks = marks.obtainMarks;
    this._totalMarks = marks.total;
    this.updateClass();
  }

  get obtainMarks(): number {
    return this._obtainMarks;
  }

  get totalMarks(): number {
    return this._totalMarks;
  }

  constructor(private el: ElementRef<HTMLInputElement>) {}

  @HostBinding('class.bg-secondary-5')
  isUnsaved = false;

  @HostBinding('class.bg-critical-10')
  isInvalid = false;

  @HostListener('input')
  onInput() {
    this.updateClass();
  }

  private updateClass() {
    const currentValue = parseFloat(this.el.nativeElement.value);
    if (isNaN(currentValue) && this.obtainMarks && this._isNew) {
      // no error
      // console.log('1.1')
      this.isInvalid = false;
      this.isUnsaved = false;
      this._isNew = false;
      return;
    }
    if (isNaN(currentValue) && this.obtainMarks && !this._isNew) {
      // no error
      // console.log('1.2')
      this.isInvalid = true;
      this.isUnsaved = false;
      this._isNew = false;
      return;
    }
    if (isNaN(currentValue) && this.obtainMarks) {
      // show error
      // console.log('2')
      this.isInvalid = true;
      this.isUnsaved = false;
      return;
    }
    if (isNaN(currentValue) && !this.obtainMarks) {
      // no error
      // console.log('3')
      this.isInvalid = false;
      this.isUnsaved = false;
      return;
    }
    if (currentValue < 0) {
      // show error
      // console.log('4')
      this.isInvalid = true;
      this.isUnsaved = false;
      return;
    }
    if (currentValue > this.totalMarks) {
      // show error
      // console.log('5')
      this.isInvalid = true;
      this.isUnsaved = false;
      return;
    }
    // console.log('6')
    this.isInvalid = false;
    this.isUnsaved = currentValue !== this.obtainMarks && currentValue >= 0;
  }
}
