import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as fs from 'file-saver';
import { Injectable, NgModule } from '@angular/core';
import { Workbook } from 'exceljs';

const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root',
})
export class ExcelService {
  logoBase64 =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAfQAAAFKCAMAAADcyF29AAABxVBMVEVHcEwJCAggFxEBAQE2KyQAAAA0LScAAAAAAAA1LysXEQ0EBAQFBAMDAwMLCQgGBQUFBAOEQhUHBwZjQSuScFoVFRZvNAx5NghcOyaudU8yMDBrNhOiViMZFhXEdD3Ef0+4ZzFISUdSJwliMA6BPA6lVR8CAgEDAgQPDhANDgsKCQoVFhPObi4SERS2VxcE.......';

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data'],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  saveFileByURL(url, name) {
    FileSaver.saveAs(url, name);
  }

  generateExcel(
    titleFile,
    tableHead,
    body,
    fileName,
    downloadedBy,
    cb = () => ({}),
    subtitle2 = '',
  ) {
    //Excel Title, Header, Data
    const title = localStorage.getItem('schoolName');
    const header = tableHead;
    const data = body;

    const downloadByText =
      'Downloaded By:  ' +
      localStorage.getItem('firstName') +
      ' at' +
      ' ' +
      new Date().toLocaleString();
    //Create workbook and worksheet
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(fileName);
    //Add Row and formatting
    const titleRow = worksheet.addRow([title]);
    titleRow.font = { family: 4, size: 16, underline: 'double', bold: true };
    // titleRow.alignment = { horizontal: 'center' };
    worksheet.addRow([]);
    const subtitleRow = worksheet.addRow([titleFile]);
    subtitleRow.font = { family: 4, size: 10, bold: true };
    const subtitleRow2 = worksheet.addRow([subtitle2]); // Add the new subtitle if provided
    if (subtitle2) {
      subtitleRow2.font = { family: 4, size: 10, bold: true };
      worksheet.addRow([]);
    }
    worksheet.addRow([]);
    worksheet.addRow([]);

    // let subTitleRow = worksheet.addRow(['Date : ' + new Date().getDate() + '-' + new Date().getMonth() + '-' + new Date().getFullYear()])
    //Add Image

    // let logo = workbook.addImage({
    //   base64: this.logoBase64,
    //   extension: 'png',
    // });
    // worksheet.addImage(logo, 'E1:F3');
    async function getBase64ImageFromUrl(imageUrl) {
      const res = await fetch(imageUrl);
      const blob = await res.blob();

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener(
          'load',
          function () {
            resolve(reader.result);
          },
          false,
        );

        reader.onerror = () => {
          return reject(this);
        };
        reader.readAsDataURL(blob);
      });
    }

    let imgBase;
    getBase64ImageFromUrl('https://cdn.logo.com/hotlink-ok/logo-social.png')
      .then((result) => {
        imgBase = result;
        const myBase64Image = imgBase;
        const imageId2 = workbook.addImage({
          base64: myBase64Image,
          extension: 'png',
        });
        worksheet.addImage(imageId2, 'E1:F3');
      })
      .catch((err) => console.error(err));
    // const imageId1 = workbook.addImage({
    //   filename: '../../../../assets/images/FPS.jpg',
    //   extension: 'jpeg',
    // });
    // worksheet.addImage(imageId1, {
    //   tl: { col: 0, row: 0 },
    //   ext: { width: 500, height: 200 }
    // });
    //     const myBase64Image =imgBase;
    //     console.log(myBase64Image)
    // const imageId2 = workbook.addImage({
    //   base64: myBase64Image,
    //   extension: 'png',
    // });
    // worksheet.addImage(imageId2, {
    //   tl: { col: 0, row: 0 },
    //   ext: { width: 500, height: 200 }
    // });
    worksheet.mergeCells('A1:D2');
    //Blank Row
    worksheet.addRow([]);
    //Add Header Row
    const headerRow = worksheet.addRow(header);

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'f7f7f7' },
        bgColor: { argb: 'f7f7f7' },
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.font = { size: 10, bold: true };
    });
    // worksheet.addRows(data);
    // Add Data and Conditional Formatting
    let lengthofCol;
    data.forEach((d) => {
      const row = worksheet.addRow(d);

      d = d.filter((x) => x != undefined);

      lengthofCol = d.length;
      for (let k = 0; k < lengthofCol; k++) {
        const qty = row.getCell(k + 1);
        qty.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      }
    });

    for (let i = 0; i < lengthofCol; i++) {
      worksheet.getColumn(i + 1).width = 25;
    }

    worksheet.addRow([]);
    const dowloadedBy = worksheet.addRow([downloadByText]);
    //Footer Row
    // let footerRow = worksheet.addRow(['This is system generated excel sheet.']);
    // footerRow.getCell(1).fill = {
    //   type: 'pattern',
    //   pattern: 'solid',
    //   fgColor: { argb: 'FFCCFFE5' }
    // };
    // footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    //Merge Cells
    // worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);
    //Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fs.saveAs(blob, fileName);
      cb();
    });
  }

  public saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }
}
