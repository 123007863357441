import { GenericService } from '../generic/generic.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LmsClassStreamService {
  private readonly controller = 'classes';

  constructor(private generic: GenericService) {}

  uploadMedia(classId, body) {
    return this.generic.imageUpload(
      `${this.controller}/${classId}/stream/upload-media`,
      body,
    );
  }

  postStream(classId, body) {
    return this.generic.submitMultipartFormData(
      `${this.controller}/${classId}/stream`,
      body,
    );
  }

  addReaction(classId, streamId, body) {
    return this.generic.create(
      `${this.controller}/${classId}/stream/${streamId}/reaction`,
      body,
    );
  }

  updateStream(classId, streamId, body) {
    return this.generic.patchMultipartFormData(
      `${this.controller}/${classId}/stream/${streamId}`,
      body,
    );
  }

  getStream(classId, params, order = 'desc', sort = 'createdAt') {
    return this.generic.getDataByParams(
      `${this.controller}/${classId}/stream`,
      params,
      order,
      sort,
    );
  }

  getStreamById(classId, streamId) {
    return this.generic.getDataById(
      `${this.controller}/${classId}/stream`,
      streamId,
    );
  }

  deleteMedia(classId, streamId, mediaId, mediaUrl) {
    return this.generic.deleteWithOutId(
      `${this.controller}/${classId}/stream/${streamId}/media/${mediaId}?mediaUrl=${mediaUrl}`,
    );
  }

  deleteStream(classId, streamId) {
    return this.generic.delete(
      `${this.controller}/${classId}/stream`,
      streamId,
    );
  }
}
