import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class EventSubjectService {
  constructor() {}
  private _data: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _dataMain: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  // private _dataByGrade: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public setData(data: any) {
    this._data.next(data);
  }

  public getData(): Observable<any> {
    return this._data.asObservable();
  }

  // private _dataByGrade: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public setDataMain(data: any) {
    this._dataMain.next(data);
  }

  public getDataMain(): Observable<any> {
    return this._dataMain.asObservable();
  }
}
