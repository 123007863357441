import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { CustomSnackBarComponent } from '../../custom-snack-bar/custom-snack-bar.component';
import { CustomSnackbarDeleteComponent } from '../../custom-snackbar-delete/custom-snackbar-delete.component';
import { DatePipe } from '@angular/common';
import { StudentEventsService } from 'src/app/core/services/student/student-events/student-events.service';
import { UserType } from '../../../enums-constant/user-type.enum';
import { helper } from '../../../helper-functions';
@Component({
  selector: 'app-event-detail-modal',
  templateUrl: './event-detail-modal.component.html',
  styleUrls: ['./event-detail-modal.component.scss'],
  providers: [DatePipe],
})
export class EventDetailModalComponent implements OnInit {
  eventInfo;
  alreadyGoing: boolean;
  UserType = UserType;
  isAdmin: boolean;
  userType = localStorage.getItem('user_type');
  studentId = localStorage.getItem('studentId');
  constructor(
    private studentEventService: StudentEventsService,
    public dialogRef: MatDialogRef<EventDetailModalComponent>,
    private _snackBar: MatSnackBar,
    public datePipe: DatePipe,

    @Inject(MAT_DIALOG_DATA)
    public data: {
      eventDetail: any;
    },
  ) {}

  ngOnInit(): void {
    const userType = localStorage.getItem('user_type');
    if (userType === UserType.Admin || userType === UserType.Staff) {
      this.isAdmin = true;
    }
    this.bindEvent(this.data.eventDetail);
  }

  close(): void {
    this.dialogRef.close();
  }

  bindEvent(event): void {
    const attendeesArray = [];

    if (event.attendees && event.attendees.length > 0) {
      for (let i = 0; i < event.attendees.length; i++) {
        if (event.attendees[i].rsvp === true) {
          attendeesArray.push(event.attendees[i]);
        }
        if (
          event.attendees[i].student &&
          this.studentId == event.attendees[i].student.id &&
          event.attendees[i].rsvp === true
        ) {
          this.alreadyGoing = true;
        }
        if (
          event.attendees[i].student &&
          this.studentId == event.attendees[i].student.id &&
          event.attendees[i].rsvp === false
        ) {
          this.alreadyGoing = false;
        }
      }
    }

    this.eventInfo = {
      title: event.title,
      description: event.description,
      grade: event.grade,
      section: event.section,
      capacity: event.capacity,
      location: event.eventLocation ? event.eventLocation : '',
      fromDate: event.fromDate,
      toDate: event.toDate,
      visible: event.visible,
      url: event.eventBanner.url,
      rsvp: event.rsvp,
      branch: event.branch,
      attendees: attendeesArray,
      isAllDayEvent: event.isAllDayEvent,
    };
  }

  getEvent(id) {
    this.studentEventService.getByID(id).subscribe((res: any) => {
      const attendeesArray = [];
      for (let i = 0; i < res.data.attendees.length; i++) {
        if (res.data.attendees[i].rsvp === true) {
          attendeesArray.push(res.data.attendees[i]);
        }

        if (
          this.studentId == res.data.attendees[i].student.id &&
          res.data.attendees[i].rsvp === true
        ) {
          this.alreadyGoing = true;
        }
        if (
          this.studentId == res.data.attendees[i].student.id &&
          res.data.attendees[i].rsvp === false
        ) {
          this.alreadyGoing = false;
        }
      }

      this.eventInfo = {
        title: res.data.title,
        description: res.data.description,
        grade: res.data.grade,
        section: res.data.section,
        capacity: res.data.capacity,
        location: res.data.eventLocation ? res.data.eventLocation : '',
        fromDate: res.data.fromDate,
        toDate: res.data.toDate,
        url: res.data.eventBanner.url,
        visible: res.data.visible,
        rsvp: res.data.rsvp,
        branch: res.data.branch,
        attendees: attendeesArray,
        isAllDayEvent: res.data.isAllDayEvent,
      };
    });
  }

  setGoingYes(): void {
    const eventId = this.data.eventDetail._id;

    const param = eventId;
    const body = {
      rsvp: true,
      studentId: this.studentId,
    };
    this.studentEventService.postRsvp(param, body).subscribe(
      async (res: any) => {
        if (res.status === 'success') {
          this.showSnackBar(
            'success',
            'The Event Response Recorded. successfully.',
          );
          await this.getEvent(this.data.eventDetail.id);
        }
      },
      (err) => {
        this.showSnackBar('delete', err.error.message);
      },
    );
  }

  setGoingNo(): void {
    const eventId = this.data.eventDetail._id;
    const param = eventId;
    const body = {
      rsvp: false,
      studentId: this.studentId,
    };
    this.studentEventService.postRsvp(param, body).subscribe(
      async (res: any) => {
        if (res.status === 'success') {
          this.showSnackBar(
            'success',
            'The Event Response Recorded. successfully.',
          );
          await this.getEvent(this.data.eventDetail.id);
        }
      },
      (err) => {
        this.showSnackBar('delete', err.error.message);
      },
    );
  }

  showSnackBar(type, message) {
    if (type === 'delete') {
      this._snackBar.openFromComponent(CustomSnackbarDeleteComponent, {
        data: { message: message },
        horizontalPosition: 'center',
        duration: 2000,
        panelClass: ['delete-snackBar'],
        verticalPosition: 'top',
      });
    } else if (type === 'success') {
      this._snackBar.openFromComponent(CustomSnackBarComponent, {
        data: { message: message },
        horizontalPosition: 'center',
        duration: helper.duration,
        panelClass: ['save-snackBar'],
        verticalPosition: 'top',
      });
    }
  }

  getBranchGradeNames(branchName: string, grades: any[]): string {
    if (!branchName) return 'All Branches';
    if (!grades || grades.length === 0) {
      return branchName;
    }
    return `${branchName} - ${grades.map((grade) => grade.name).join(', ')}`;
  }
}
