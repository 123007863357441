export const apiURL = 'http://localhost:3000/api'; //node api url

// token
export const token =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MDA3ZTRjN2ZhZjM2NzBmODA4NmJiYjkiLCJpYXQiOjE2MTQ3NTI4NjIsImV4cCI6MTYxNDkyNTY2Mn0.Cc7qrKA5J7BuAP5GxXOeTN3ZLIHSQNwdJ3Ovt0VKt0A';

export const configRoute = {
  billingHeads: '/billingheads', //billing head route
  branches: '/branches', //branch route
  grades: '/grades',
  gradesByBranch: '/assignclasses',
  banks: '/banks', //bank route
  status: '/status', //status for activation and deactivation route
};

export const fileObj = {
  fileFormat: 'image/jpeg,image/jpg,image/png,image/gif',
  fileSize: '3000000',
};
