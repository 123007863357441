import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-checklist-action',
  templateUrl: './checklist-action.component.html',
  styleUrls: ['./checklist-action.component.scss'],
})
export class ChecklistActionComponent implements OnInit {
  @Input() heading: string;
  constructor() {}

  ngOnInit() {}
}
