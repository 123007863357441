import { AuthService } from '../auth/auth.service';
import { BehaviorSubject } from 'rxjs';
import { GenericService } from '../generic/generic.service';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GradeService {
  private grades$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  controller = 'grades';
  constructor(private generic: GenericService, private auth: AuthService) {
    this.auth.getUser().subscribe((res: any) => {
      if (!res) this.grades$.next(null);
    });
  }
  get() {
    return this.grades$.getValue() ? this.grades$.asObservable() : this.set();
  }
  private set() {
    return this.generic
      .getData(this.controller)
      .pipe(tap((response) => this.grades$.next(response)));
  }
  getByParams(param) {
    const order = 'asc';
    const sort = 'name';
    return this.generic.getDataByParams(this.controller, param, order, sort);
  }
  post(body) {
    return this.generic.create(this.controller, body);
  }

  getByID(id) {
    return this.generic.getDataById(this.controller, id);
  }

  delete(id) {
    //This method is used to delete record from database and it fires a snackbar on successful delete
    return this.generic.delete(this.controller, id);
  }
  update(body) {
    return this.generic.update(this.controller, body);
  }
  search(search, filter, order) {
    return this.generic.getDataBySearch(this.controller, search, filter, order);
  }
}
