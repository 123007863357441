import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { AddStudentModalComponent } from 'src/app/admin/extracurricular/add-student-modal/add-student-modal.component';
import { CourseService } from 'src/app/core/services/course/course.service';
import { CustomSnackBarComponent } from '../custom-snack-bar/custom-snack-bar.component';
import { CustomSnackbarDeleteComponent } from '../custom-snackbar-delete/custom-snackbar-delete.component';
import { ExcelService } from 'src/app/core';
import Swal from 'sweetalert2';
import { UserType } from '../../enums-constant/user-type.enum';
import { helper } from '../../helper-functions';

@Component({
  selector: 'app-course-details',
  templateUrl: './course-details.component.html',
  styleUrls: ['./course-details.component.scss'],
})
export class CourseDetailsComponent implements OnInit {
  course: any;
  routeId: string;
  userType;
  studentId;
  showOptdiv: any;
  headText;
  isAdminView = false;
  isStaffView = false;
  isParentView = false;
  isStudentView = false;
  seatsLeft;
  loading = false;
  enrollbtn = false;
  allterms = [];
  grade = '';
  isLoading = false;
  excelData = [];
  tableHeadings = [
    'Sr. no',
    'Roll No',
    'Student Name ',
    'Branch',
    'Grade',
    'Section',
  ];
  constructor(
    public dialog: MatDialog,
    private courseService: CourseService,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private excelService: ExcelService,
  ) {}
  breadcrumb = [
    {
      label: 'Home /',
      url: '/admin/dashboard',
    },
    {
      label: 'Extracurricular /',
      url: '/admin/extracurricular/courses',
    },
    {
      label: 'Course Details',
      url: '',
    },
  ];
  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.routeId = params.id;
      this.studentId = params.studentId;
      const userType = localStorage.getItem('user_type');
      if (userType == 'Parent') {
        this.breadcrumb = [
          {
            label: 'Home /',
            url: '/parent/home',
          },
          {
            label: '',
            url: '',
          },
          {
            label: 'Course Detail',
            url: '',
          },
        ];
      }
      if (userType == 'Student') {
        this.breadcrumb = [
          {
            label: 'Home /',
            url: '/student/home',
          },
          {
            label: '',
            url: '',
          },
          {
            label: 'course ',
            url: '/student/course-details',
          },
        ];
      }
    });
    this.getCourse();
    const userType = localStorage.getItem('user_type');
    this.isAdminView = userType === UserType.Admin;
    this.isStaffView = userType === UserType.Staff;
    this.isParentView = userType === UserType.Parent;
    this.isStudentView = userType === UserType.Student;
  }

  getCourse() {
    this.grade = '';
    this.courseService.getByID(this.routeId).subscribe((res: any) => {
      this.course = res.data;
      this.seatsLeft = this.course.seats - this.course.attendees.length;
      const result = this.course.attendees.filter(
        (e) => e.studentId._id == this.studentId,
      );
      res.data.grade &&
        res.data.grade.forEach((g) => {
          this.grade += this.grade == '' ? g.name : ' , ' + g.name;
        });
      this.enrollbtn = result.length > 0;
      this.allterms = [];
      this.excelData = res.data.attendees.map((item, i) => {
        return {
          'Sr. no': `${i + 1}`,
          'Roll No': item.studentId.rollNo ? item.studentId.rollNo : '-',
          'Student Name':
            item.studentId.firstName != null
              ? item.studentId.firstName + ' ' + item.studentId.lastName
              : '-',
          Branch:
            item.studentId.branch.name != null
              ? item.studentId.branch.name
              : '-',
          Grade:
            item.studentId.grade.name != null ? item.studentId.grade.name : '-',
          Section:
            item.studentId.section.name != null
              ? item.studentId.section.name
              : '-',
        };
      });
    });
  }
  enroll(studentId?) {
    if (studentId) this.studentId = studentId;
    if (this.loading) return;
    this.loading = true;
    if (this.course.termConditions.length == this.allterms.length) {
      this.courseService
        .enroll(this.routeId, this.studentId, this.course.program._id)
        .subscribe(
          (res: any) => {
            if (res.status == 'success') {
              this._snackBar.openFromComponent(CustomSnackBarComponent, {
                data: { message: res.message },
                horizontalPosition: 'center',
                duration: helper.duration,
                panelClass: ['save-snackBar'],
                verticalPosition: 'top',
              });
              this.enrollbtn = true;
              this.isLoading = false;
              this.loading = false;
            }
          },
          (err) => {
            this.allterms = [];
            this._snackBar.openFromComponent(CustomSnackbarDeleteComponent, {
              data: { message: err.error.message },
              horizontalPosition: 'center',
              duration: helper.duration,
              panelClass: ['delete-snackBar'],
              verticalPosition: 'top',
            });
            this.loading = false;
          },
        );
    } else {
      this._snackBar.openFromComponent(CustomSnackbarDeleteComponent, {
        data: { message: 'Please agree with all terms and conditions' },
        horizontalPosition: 'center',
        duration: helper.duration,
        panelClass: ['delete-snackBar'],
        verticalPosition: 'top',
      });
      this.loading = false;
    }
  }
  Delete(studentId) {
    this.studentId = studentId;
    Swal.fire({
      title: 'Are you sure you want to delete it?',
      text: 'Student will be unenrolled from this course!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        this.UnEnroll();
      }
    });
  }
  UnEnroll() {
    if (this.loading) return;
    this.loading = true;
    this.courseService.unEnroll(this.routeId, this.studentId).subscribe(
      (res: any) => {
        if (res.status == 'success') {
          this._snackBar.openFromComponent(CustomSnackBarComponent, {
            data: { message: res.message },
            horizontalPosition: 'center',
            duration: helper.duration,
            panelClass: ['save-snackBar'],
            verticalPosition: 'top',
          });
          this.enrollbtn = false;
          this.allterms = [];
          this.loading = false;
        }
      },
      (err) => {
        this._snackBar.openFromComponent(CustomSnackbarDeleteComponent, {
          data: { message: err.error.message },
          horizontalPosition: 'center',
          duration: helper.duration,
          panelClass: ['delete-snackBar'],
          verticalPosition: 'top',
        });
        this.loading = false;
      },
    );
  }
  onChecklistChange(ev, item) {
    if (ev == true) {
      this.allterms.push(item);
    } else {
      for (let i = 0; i < this.allterms.length; i++) {
        if (this.allterms[i] === item) {
          this.allterms.splice(i, 1);
        }
      }
    }
  }
  showStatus() {
    let statustxt;
    if (
      (this.isAdminView || this.isStaffView) &&
      this.course &&
      this.course.status.name == 'Active'
    ) {
      statustxt = 'Registration';
    } else if (
      !(this.isAdminView || this.isStaffView) &&
      this.course &&
      this.course.status.name == 'Active' &&
      this.enrollbtn
    ) {
      statustxt = 'Registered';
    } else if (
      !(this.isAdminView || this.isStaffView) &&
      this.course &&
      this.course.status.name == 'Active' &&
      !this.enrollbtn
    ) {
      statustxt = 'Registration';
    } else {
      statustxt = this.course && this.course.status.name;
    }
    return statustxt;
  }

  createPdf(courseId) {
    this.isLoading = true;
    const param = `courseId=${courseId}`;
    this.courseService.getAttendeesList(param).subscribe(
      (res: any) => {
        if (res.status == 'success') {
          const pdfSource = res.data;
          const a = document.createElement('a');
          a.href = pdfSource;
          a.setAttribute('target', '_blank');
          a.click();
        }
        this.isLoading = false;
      },
      (err) => {
        this._snackBar.openFromComponent(CustomSnackbarDeleteComponent, {
          data: { message: err.error.message },
          horizontalPosition: 'center',
          duration: helper.duration,
          panelClass: ['delete-snackBar'],
          verticalPosition: 'top',
        });
        this.isLoading = false;
      },
    );
  }

  exportexcel(): void {
    const outerArray = [];
    const sendBody = [];
    const newArray = JSON.parse(JSON.stringify(this.excelData));
    for (let i = 0; i < newArray.length; i++) {
      const innerArray = [];
      for (const k in newArray[i]) {
        delete newArray[i].col1;
        delete newArray[i].colImg;
        innerArray.push(newArray[i][k]);
      }
      outerArray.push(innerArray);
    }
    const filtered = outerArray.filter(function (x) {
      const newArra = x.filter((y) => {
        return y !== undefined;
      });
      sendBody.push(newArra);
    });

    const schoolName =
      localStorage.getItem('schoolName') != 'undefined'
        ? localStorage.getItem('schoolName') + '-'
        : '';
    const subTitleForExcel = `Student Attendees List for ${this.course.branch.name}`;
    const footerText =
      'Downloaded By:  ' +
      localStorage.getItem('firstName') +
      ' at' +
      ' ' +
      new Date().toLocaleString();
    const pdfName = `${schoolName} Attendes list`;
    const subtitle2 = `${this.course.program.name} - ${this.course.title}`;
    this.excelService.generateExcel(
      subTitleForExcel,
      this.tableHeadings,
      sendBody,
      pdfName,
      footerText,
      () => ({}),
      subtitle2,
    );
  }

  addStudent() {
    if (this.course.termConditions.length == this.allterms.length) {
      const dialogRef = this.dialog.open(AddStudentModalComponent, {
        data: { branch: this.course.branch, grades: this.course.grade },
        panelClass: 'modal-small-width',
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result != 'cancel') {
          this.enroll(result['studentId']);
        }
      });
    } else {
      this._snackBar.openFromComponent(CustomSnackbarDeleteComponent, {
        data: { message: 'Please agree with all terms and conditions' },
        horizontalPosition: 'center',
        duration: helper.duration,
        panelClass: ['delete-snackBar'],
        verticalPosition: 'top',
      });
    }
  }
  option(i) {
    if (i == this.showOptdiv) {
      this.showOptdiv = '';
    } else {
      this.showOptdiv = i;
    }
  }
  onClickedOutside(e: Event) {
    this.showOptdiv = '';
  }
}
