import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-error404',
  templateUrl: './error404.component.html',
  styleUrls: ['./error404.component.scss'],
})
export class Error404Component {
  baseUrl: any;
  url;

  constructor(public route: ActivatedRoute) {
    this.route.data.subscribe((routeData) => {
      this.url = routeData.url;
    });
  }
}
