import { CountByValuePipe } from 'src/app/shared/pipes/Filter-Pipe-Count/count-by-value.pipe';
import { CountPipe } from 'src/app/shared/pipes/Filter-Pipe-Count/count.pipe';
import { LeadingWhiteZerpPipe } from 'src/app/shared/pipes/leading-white-zero/leading-white-zero.pipe';
import { NgModule } from '@angular/core';
import { OrderPipe } from 'src/app/shared/pipes/ngx-order.pipe';
import { SumPipe } from 'src/app/shared/pipes/sum/sum.pipe';
import { TimeAgoOrDatePipe } from 'src/app/shared/pipes/timeAgoOrDate/timeAgoOrDate.pipe';
import { TimeAgoPipe } from 'time-ago-pipe';
import { UrlFormatPipe } from 'src/app/shared/pipes/url-format/url-format.pipe';
import { ZeroPaddingPipe } from 'src/app/shared/pipes/Zero-Padding/zero-padding.pipe';

@NgModule({
  declarations: [
    CountPipe,
    CountByValuePipe,
    TimeAgoPipe,
    OrderPipe,
    TimeAgoOrDatePipe,
    LeadingWhiteZerpPipe,
    SumPipe,
    UrlFormatPipe,
    ZeroPaddingPipe,
  ],
  imports: [],
  exports: [
    CountPipe,
    CountByValuePipe,
    TimeAgoPipe,
    OrderPipe,
    TimeAgoOrDatePipe,
    LeadingWhiteZerpPipe,
    SumPipe,
    UrlFormatPipe,
    ZeroPaddingPipe,
  ],
})
export class CustomPipesModule {}
