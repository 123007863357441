import * as moment from 'moment';
import {
  AssingnClassService,
  ColorService,
  EventService,
  UserTypeService,
} from 'src/app/core';
import { Component, Inject, OnInit } from '@angular/core';
import {
  CustomSnackBarComponent,
  CustomSnackbarDeleteComponent,
  helper,
} from 'src/app/shared';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';

export interface DialogData {
  id?: string;
  state: string;
  routeData: any;
}

@Component({
  selector: 'app-event-add-modal',
  templateUrl: './event-add-modal.component.html',
  styleUrls: ['./event-add-modal.component.scss'],
  providers: [DatePipe],
})
export class EventAddModalComponent implements OnInit {
  visibleToError = false;
  isDisabled = false;
  panelOpenState;
  selectedFile: File = null;
  imgError = false;
  imgErrorText = '';
  addText: any;
  isLoading = false;
  showEditThumbnail = false;
  showCreateThumbnail = true;
  files: File[] = [];
  viewDisable: any;
  reqStartTime = false;
  reqEndTime = false;
  imageSrc;
  toTimeGreaterError = false;
  toTimeGreaterErrorText: string;
  colors: any;
  totalCount: 0;
  public form: FormGroup;
  branches = [];
  allClasses = [];
  gradesArray = [];
  submitted;
  visibleTo = [];
  userTypes = [];
  gradeId: any;
  branchId: any;
  heading = 'Add';
  currentDate = new Date();
  selectedDate;
  fromTime;
  toTime;
  disableAnimation = true;
  allDayEvent = false;
  rsvp = false;

  constructor(
    private userType: UserTypeService,
    private assignClass: AssingnClassService,
    private _snackBar: MatSnackBar,
    private fb: FormBuilder,
    public datePipe: DatePipe,
    private event: EventService,
    private colorService: ColorService,
    public dialogRef: MatDialogRef<EventAddModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private router: Router,
  ) {}

  get FormControl() {
    return this.form.controls;
  }

  ngOnInit() {
    this.panelOpenState = false;
    this.formPost();
    this.getBranches();
    this.getUserType();
    this.getColors();
    if (this.data.state == 'Edit') {
      this.bindEvent();
      this.heading = this.data.state;
    }
  }

  ngAfterViewInit(): void {
    // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
    setTimeout(() => (this.disableAnimation = false));
  }

  formPost() {
    this.form = this.fb.group({
      id: '',
      title: new FormControl(
        { value: '', disabled: this.isDisabled },
        Validators.required,
      ),
      branch: new FormControl({
        value: undefined,
        disabled: this.isDisabled,
      }),
      grade: new FormControl({ value: undefined, disabled: this.isDisabled }),
      color: new FormControl({ value: undefined, disabled: this.isDisabled }),
      fromDate: new FormControl(
        { value: '', disabled: this.isDisabled },
        Validators.required,
      ),
      toDate: new FormControl({ value: '', disabled: this.isDisabled }),
      fromTime: new FormControl(
        { value: '', disabled: this.isDisabled },
        Validators.required,
      ),
      toTime: new FormControl(
        { value: '', disabled: this.isDisabled },
        Validators.required,
      ),
      eventLocation: new FormControl({
        value: '',
        disabled: this.isDisabled,
      }),
      visible: new FormControl({ value: '', disabled: this.isDisabled }),
      rsvp: new FormControl({ value: false, disabled: this.isDisabled }),
      capacity: new FormControl({ value: '', disabled: this.isDisabled }),
      isAllDayEvent: new FormControl({
        value: false,
        disabled: this.isDisabled,
      }),
      description: new FormControl({ value: '', disabled: this.isDisabled }),
      eventBanner: {
        imageId: '',
      },
    });
  }

  getBranches() {
    //get branches through assign class
    this.assignClass.get().subscribe((res: any) => {
      this.allClasses = res.data.list;

      const branchArray = [];
      for (let i = 0; i < res.data.list.length; i++) {
        branchArray.push(res.data.list[i].branch);
      }
      const seen = new Set();
      const filteredArr = branchArray.filter((el) => {
        const duplicate = seen.has(el.id);
        seen.add(el.id);
        return !duplicate;
      });
      this.branches = filteredArr;
    });
  }

  selectBranch($event) {
    //on branch change dropdown
    this.form.get('grade').reset();
    this.gradesArray.length = 0;
    const grades = this.allClasses.filter(
      (element) => element.branch.id == $event,
    );
    this.branchId = $event;
    for (let i = 0; i < grades.length; i++) {
      this.gradesArray.push(grades[i].grade);
    }

    this.gradeId = this.gradesArray[0].id;
  }

  getUserType() {
    //get users to select news visibility
    this.userType.getUserTypeVisible().subscribe((res: any) => {
      this.userTypes = res.data;
    });
  }

  onChecklistChange(ev, item) {
    //visible to checkbox
    if (ev == true) {
      this.visibleTo.push(item.id);
    } else {
      for (let i = 0; i < this.visibleTo.length; i++) {
        if (this.visibleTo[i] === item.id) {
          this.visibleTo.splice(i, 1);
        }
      }
    }
    if (this.visibleTo.length == 0) {
      this.visibleToError = true;
    } else {
      this.visibleToError = false;
    }
  }

  onAllDayEventCheck(e) {
    this.allDayEvent = e;
    this.form.value.isAllDayEvent = e;
    this.conditionalValidationALLDayEVENT();
  }

  onRsvpCheck(e) {
    this.rsvp = e;
    this.form.value.rsvp = e;
    this.form.controls['rsvp'].setValue(e);
    this.conditionalValidationRsvp();
  }

  submit() {
    if (this.isLoading) {
      return;
    }
    if (this.visibleTo.length == 0) {
      this.visibleToError = true;
    } else {
      this.visibleToError = false;
    }
    if (this.form.invalid) {
      helper.validateAllFormFields(this.form);
      return;
    }
    if (!this.visibleToError && !this.allDayEvent) {
      const startTime = this.form.controls.fromTime.value;
      const endTime = this.form.controls.toTime.value;
      const diff = endTime.getTime() - startTime.getTime();
      const diffHours = diff / (1000 * 60 * 60);

      if (diffHours < 0.01) {
        this.form.controls.toTime.setErrors({ incorrect: true });
        this.toTimeGreaterError = true;
      } else {
        this.toTimeGreaterError = false;
      }
    }

    if (!this.toTimeGreaterError && !this.visibleToError) {
      this.isLoading = true;
      if (this.form.value.eventBanner.imageId == '') {
        this.form.controls['eventBanner'].setValue('');
      }
      if (!this.rsvp) {
        this.form.value.capacity = 0;
        this.form.controls['capacity'].setValue(0);
      }

      if (this.allDayEvent) {
        //this.form.controls["toDate"].setValue('');
        const toEventDate = this.form.value.toDate;
        const fromEventDate = this.form.value.fromDate;
        this.form.value.toDate = moment(toEventDate)
          .endOf('day')
          .format('yyyy-MM-DD');
        this.form.value.fromDate = moment(fromEventDate)
          .startOf('day')
          .format('yyyy-MM-DD');
      } else {
        const fromTime = new Date(this.form.get('fromTime').value);
        const toTime = new Date(this.form.get('toTime').value);
        const fromDate = new Date(this.form.get('fromDate').value);
        const toDate = new Date(this.form.get('fromDate').value);

        fromDate.setHours(fromTime.getHours());
        fromDate.setMinutes(fromTime.getMinutes());
        fromDate.setSeconds(fromTime.getSeconds());

        toDate.setHours(toTime.getHours());
        toDate.setMinutes(toTime.getMinutes());
        toDate.setSeconds(toTime.getSeconds());

        this.form.get('fromDate').setValue(fromDate);
        this.form.get('toDate').setValue(toDate);
      }

      this.form.value.visible = this.visibleTo;
      const formValue = { ...this.form.value };

      for (const prop in formValue) {
        if (!formValue[prop]) {
          delete formValue[prop];
        }

        if (Array.isArray(formValue[prop])) {
          const resultArray = formValue[prop].filter((item) => item);

          if (resultArray.length > 0) {
            formValue[prop] = resultArray;
          } else {
            delete formValue[prop];
          }
        }
      }

      formValue.isAllDayEvent = this.allDayEvent;
      formValue.rsvp = this.rsvp;
      if (this.form.value.id != '') {
        this.event.update(formValue).subscribe(
          (res: any) => {
            res;
            if (res.status == 'success') {
              this._snackBar.openFromComponent(CustomSnackBarComponent, {
                data: { message: res.message },
                horizontalPosition: 'center',
                duration: helper.duration,
                panelClass: ['save-snackBar'],
                verticalPosition: 'top',
              });
              this.isLoading = false;
              this.dialogRef.close('success');
              this.router.navigateByUrl('admin/communication/event-main');
            }
          },
          (err) => {
            this._snackBar.openFromComponent(CustomSnackbarDeleteComponent, {
              data: { message: err.error.message },
              horizontalPosition: 'center',
              duration: helper.duration,
              panelClass: ['delete-snackBar'],
              verticalPosition: 'top',
            });
            this.isLoading = false;
          },
        );
      } else {
        this.event.post(formValue).subscribe(
          (res: any) => {
            res;
            if (res.status == 'success') {
              this._snackBar.openFromComponent(CustomSnackBarComponent, {
                data: { message: res.message },
                horizontalPosition: 'center',
                duration: helper.duration,
                panelClass: ['save-snackBar'],
                verticalPosition: 'top',
              });
              this.isLoading = false;
              this.dialogRef.close('success');
              this.dialogRef.afterClosed().subscribe(() => {
                console.log('The dialog was closed');
                this.router.navigateByUrl('admin/communication/event-main');
              });
            }
          },
          (err) => {
            this._snackBar.openFromComponent(CustomSnackbarDeleteComponent, {
              data: { message: err.error.message },
              horizontalPosition: 'center',
              duration: helper.duration,
              panelClass: ['delete-snackBar'],
              verticalPosition: 'top',
            });
            this.isLoading = false;
          },
        );
      }
    }
  }

  bindEvent() {
    this.isLoading = false;
    this.event.getByID(this.data.id).subscribe((res: any) => {
      this.allDayEvent = res.data.isAllDayEvent;
      this.rsvp = res.data.rsvp;
      this.imageSrc = res.data.eventBanner.url;
      this.showEditThumbnail = true;
      this.showCreateThumbnail = false;
      this.branchId = res.data.branch && res.data.branch.id;
      this.gradeId = res.data.grade && res.data.grade.map((e) => e.id);
      this.selectBranchNew(this.branchId, true);
      this.conditionalValidationALLDayEVENT();
      if (res.data.eventBanner.imageId != null) {
        this.form.value.eventBanner.imageId = res.data.eventBanner.imageId;
      }

      for (let i = 0; i < res.data.visible.length; i++) {
        this.visibleTo.push(res.data.visible[i].id);
      }

      ////////////////////////////////// visible checkbox filed //////////////////////////////
      for (let k = 0; k < this.userTypes.length; k++) {
        if (res.data.visible.some((e) => e.id == this.userTypes[k].id)) {
          this.userTypes[k].active = true;
        } else {
          this.userTypes[k].active = false;
        }
      }
      /////////////////////////////////////////////////////////// convert Time
      const dTfromTimeConvert = new Date();
      const hourFrom = moment(res.data.fromDate).format('H'); // this.datePipe.transform(res.data.fromDate, 'H', 'UTC');
      const minFrom = moment(res.data.fromDate).format('m'); // this.datePipe.transform(res.data.fromDate, 'mm', 'UTC');
      dTfromTimeConvert.setHours(Number(hourFrom), Number(minFrom));
      this.fromTime = dTfromTimeConvert;

      const dTodateConvert = new Date();
      const hourTo = moment(res.data.toDate).format('H'); // this.datePipe.transform(res.data.toDate, 'H', 'UTC');
      const minTo = moment(res.data.toDate).format('m'); // this.datePipe.transform(res.data.toDate, 'mm', 'UTC');
      dTodateConvert.setHours(Number(hourTo), Number(minTo));
      this.toTime = dTodateConvert;

      if (res.data.capacity != 0) {
        this.form.patchValue({ capacity: res.data.capacity });
      }

      this.form.patchValue({
        id: res.data.id,
        title: res.data.title,
        color: res.data.color,
        description: res.data.description,
        eventLocation: res.data.eventLocation,
        toDate: res.data.toDate,
        fromDate: res.data.fromDate,
        rsvp: res.data.rsvp,
        isAllDayEvent: res.data.isAllDayEvent,
      });
      this.conditionalValidationRsvp();
    });
  }

  onSelect(event) {
    if (this.files.length >= 1) {
      this.files.splice(0, 1);
      this.selectedFile = event.addedFiles[0];
      const uploadData = new FormData();
      uploadData.append('image', this.selectedFile);
      // uploadData.append('uploadCategory', 'news');
      this.event.imageUpload(uploadData).subscribe(
        (res: any) => {
          if (res.status == 'success') {
            this.imgError = false;
            this.form.value.eventBanner.imageId = res.data.id;
          }
        },
        (err) => {
          this.imgError = true;
          this.imgErrorText = err.error.message;
        },
      );
      this.files.push(...event.addedFiles);
    } else {
      this.selectedFile = event.addedFiles[0];
      const uploadData = new FormData();
      uploadData.append('image', this.selectedFile);
      this.event.imageUpload(uploadData).subscribe(
        (res: any) => {
          if (res.status == 'success') {
            this.imgError = false;
            this.form.value.eventBanner.imageId = res.data.id;
          }
        },
        (err) => {
          this.imgError = false;
          this.imgErrorText = err.error.message;
        },
      );
      this.files.push(...event.addedFiles);
    }
  }

  onRemove(event) {
    this.imgError = false;
    this.files.splice(this.files.indexOf(event), 1);
  }

  editImg() {
    this.showEditThumbnail = false;
    this.showCreateThumbnail = true;
  }

  conditionalValidationALLDayEVENT() {
    ///SETTING VALIDATION TO FORM CONTROLS BY CONDITION
    if (this.allDayEvent) {
      this.form.controls['toDate'].setValidators([Validators.required]);
      this.form.controls['toDate'].updateValueAndValidity();

      this.form.controls['fromTime'] &&
        this.form.controls['fromTime'].clearValidators();

      this.form.controls['fromTime'] &&
        this.form.controls['fromTime'].updateValueAndValidity();

      this.form.controls['toTime'] &&
        this.form.controls['toTime'].clearValidators();
      this.form.controls['toTime'] &&
        this.form.controls['toTime'].updateValueAndValidity();
    } else {
      this.form.controls['toDate'].clearValidators();
      this.form.controls['toDate'].updateValueAndValidity();

      this.form.controls['fromTime'].setValidators([Validators.required]);
      this.form.controls['toTime'].setValidators([Validators.required]);
    }
  }

  conditionalValidationRsvp() {
    ///SETTING VALIDATION TO FORM CONTROLS BY CONDITION
    if (!this.rsvp) {
      this.form.controls['capacity'].clearValidators();
      this.form.controls['capacity'].updateValueAndValidity();
    } else {
      this.form.controls['capacity'].setValidators([Validators.required]);
      this.form.controls['capacity'].updateValueAndValidity();
    }
  }

  getColors() {
    this.colorService.get().subscribe((res: any) => {
      this.colors = res.data;
    });
  }

  cancel(isfromView: boolean) {
    if (this.form.dirty == true || !isfromView) {
      Swal.fire({
        title: 'Are you sure you want to cancel it?',
        text: 'Your changes will be lost!',
        icon: 'warning',
        showCancelButton: true,
        // confirmButtonColor: '#3085d6',
        // cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          this.dialogRef.close();
          this.router.navigateByUrl('admin/communication/event-main');
        }
      });
    } else {
      this.dialogRef.close();
      this.router.navigateByUrl('admin/communication/event-main');
    }
  }

  selectBranchNew(ev, IsSelectedAuto) {
    this.gradesArray = [];
    this.branchId = ev;
    const gradesArray = this.allClasses.filter(
      (element) => element.branch.id == ev,
    );
    for (let i = 0; i < gradesArray.length; i++) {
      this.gradesArray.push(gradesArray[i].grade);
    }
    if (!IsSelectedAuto) {
      this.form.get('grade').reset();
    } else {
      this.form.patchValue({
        branch: this.branchId,
      });
      this.gradeId.length > 0 && this.selectGradeNew(this.gradeId, true);
    }
  }

  selectGradeNew(ev, IsSelectedAuto) {
    this.gradeId = ev;
    if (IsSelectedAuto) {
      this.form.patchValue({
        grade: this.gradeId.map((e) => e),
      });
    }
  }
}
