import { GenericService } from '../generic/generic.service';
import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class LetterService {
  constructor(private generic: GenericService) {}
  controller = 'letters';
  get() {
    return this.generic.getData(this.controller);
  }
  post(body) {
    return this.generic.create(this.controller, body);
  }
  getPdfPreviewUrl(body) {
    return this.generic.create(`${this.controller}/preview`, body);
  }

  getByID(id) {
    return this.generic.getDataById(this.controller, id);
  }

  delete(id) {
    return this.generic.delete(this.controller, id);
  }
  update(body) {
    return this.generic.update(this.controller, body);
  }
  publishLetter(id) {
    return this.generic.update(`${this.controller}/${id}/publish`, {});
  }
  imageUpload(body) {
    return this.generic.imageUpload('letters/banner', body);
  }
  getbyParams(params) {
    const order = 'desc';
    return this.generic.getDataByParams(this.controller, params, order);
  }
  search(search, filter, order, type, params: string, branch?: string) {
    if (typeof branch === 'undefined' || branch == '') {
      return this.generic.getCircularBySearch(
        this.controller,
        search + '&showall=true',
        filter,
        order,
        type,
        params,
      );
    } else {
      return this.generic.getCircularBySearch(
        this.controller,
        search + '&showall=true',
        filter,
        order,
        type,
        params,
        branch,
      );
    }
  }
  getStudentLetters(params = '', order = 'desc', sort = 'createdAt') {
    return this.generic.getDataByParams(
      `student/${this.controller}`,
      params,
      order,
      sort,
    );
  }
}
