import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FlexibleTimetableService } from 'src/app/core/services/timetable/flexible-timetable/flexible-timetable.service';
import { Router } from '@angular/router';
import { StructureTimetableService } from 'src/app/core/services/timetable/structured-Timetable/timetable/structure-timetable.service';
import { StudentProfileService } from 'src/app/core';
import { UserType } from '../../enums-constant/user-type.enum';

@Component({
  selector: 'app-time-table-card',
  templateUrl: './time-table-card.component.html',
  styleUrls: ['./time-table-card.component.scss'],
})
export class TimeTableCardComponent implements OnInit, AfterViewInit {
  @Input() items: any;
  date = new Date();
  selectedDay;
  selectedDayFlexible;
  timeTable = [];
  children: any;
  days = [];
  timeTableData;
  loading = false;
  showTimeTable = false;
  isStudent = false;
  isParent = false;
  selectedChild;
  selectedChildSection;
  selectedDayTimetable = [];
  selectedDayTimetableFlexible = [];
  tempDays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  flexibleTimetable = [];
  sectionId: string; // TODO: Need to improve this

  constructor(
    private router: Router,
    private StructureTimetable: StructureTimetableService,
    private studentProfileService: StudentProfileService,
    private flexibleTimetableService: FlexibleTimetableService,
  ) {}

  ngOnInit() {
    const userType = localStorage.getItem('user_type');
    this.isStudent = userType === UserType.Student;
    this.isParent = userType === UserType.Parent;
    this.selectedDay = this.tempDays[this.date.getDay()];
    this.selectedDayFlexible = this.tempDays[this.date.getDay()];
  }
  ngAfterViewInit() {
    if (this.isStudent) {
      const stdID = localStorage.getItem('studentId');
      this.getSelectedProfileData(stdID);
    }
  }

  viewDetails() {
    if (this.isStudent) {
      this.router.navigateByUrl('/student/timetable');
    }
    if (this.isParent) {
      this.router.navigateByUrl('/parent/timetable');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items != undefined && !changes.items.firstChange) {
      this.children = changes.items.currentValue;
    }
  }

  getTimeTable(child) {
    if (child) {
      this.selectedChild = child.studentProfile._id;
      this.selectedChildSection = child.studentProfile.section.id;
    } else {
      this.selectedChild = '';
      this.selectedChildSection = '';
      this.timeTableData = [];
      this.selectedDayTimetable = [];
      this.timeTable = [];
      this.days = [];
      return;
    }
    this.getStudentFlexibleTimetable();
    this.timeTable = [];
    this.days = [];
    this.timeTableData = [];
    this.selectedDayTimetable = [];
    const url = `branch/${child.studentProfile.branch.id}/grade/${child.studentProfile.grade.id}/section/${child.studentProfile.section.id}`;
    this.StructureTimetable.getByParamsData(url).subscribe((res: any) => {
      this.loading = false;
      if (res.data && res.data !== null) {
        this.timeTableData = res.data;
        this.showTimeTable = true;
        res.data.structure.forEach((s) => {
          this.days.push(s.day);
          if (this.selectedDay == s.day.name) {
            this.selectedDayTimeTable(s.day);
          }
        });
      }
    });
  }

  getTimeTableforStudent(studentData) {
    const branchId = studentData.branch.id;
    const gradeId = studentData.grade.id;
    this.sectionId = studentData.section.id;
    this.getFlexibleTimetableForStudent(studentData._id);
    this.timeTable = [];
    this.days = [];
    this.timeTableData = [];
    this.selectedDayTimetable = [];
    const url = `branch/${branchId}/grade/${gradeId}/section/${this.sectionId}`;
    this.StructureTimetable.getByParamsData(url).subscribe((res: any) => {
      this.loading = false;
      if (res.data && res.data !== null) {
        this.timeTableData = res.data;
        this.showTimeTable = true;
        res.data.structure.forEach((s) => {
          this.days.push(s.day);
          if (this.selectedDay == s.day.name) {
            this.selectedDayTimeTable(s.day);
          }
        });
      }
    });
  }

  selectedDayTimeTable(day) {
    this.selectedDay = day.name;
    this.selectedDayTimetable = [];
    this.timeTableData.structure.forEach((s) => {
      if (s.day._id == day._id) {
        this.selectedDayTimetable = s.periods;
      }
    });
  }

  getSelectedProfileData(id) {
    this.studentProfileService.getByID(id).subscribe((res: any) => {
      this.getTimeTableforStudent(res.data);
    });
  }

  getStudentFlexibleTimetable() {
    if (!this.selectedChild) return;
    this.flexibleTimetableService
      .getStudentTimetable(this.selectedChild)
      .subscribe((res: any) => {
        if (res.data.length == 0) return;
        this.flexibleTimetable = res.data && res.data.days;
        res.data.days.forEach((d) => {
          if (d.day === this.selectedDayFlexible) {
            this.selectedDayTimeTableFlexible(d);
          }
        });
      });
  }

  getFlexibleTimetableForStudent(studentId) {
    if (!studentId) return;
    this.flexibleTimetableService
      .getStudentTimetable(studentId)
      .subscribe((res: any) => {
        if (res.data.length == 0) return;
        this.flexibleTimetable = res.data && res.data.days;
        res.data.days.forEach((d) => {
          if (d.day === this.selectedDayFlexible) {
            this.selectedDayTimeTableFlexible(d);
          }
        });
      });
  }

  selectedDayTimeTableFlexible(day) {
    this.selectedDayFlexible = day.day;
    this.selectedDayTimetableFlexible = [];
    this.selectedDayTimetableFlexible = day.periods;
  }
}
