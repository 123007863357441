import { AuthService } from '../auth/auth.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { GenericService } from '../generic/generic.service';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class StatusService {
  private statu$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private generic: GenericService, private auth: AuthService) {
    this.auth.getUser().subscribe((res: any) => {
      if (!res) this.statu$.next(null);
    });
  }

  controller = 'status';

  get() {
    return this.statu$.getValue() ? this.statu$.asObservable() : this.set();
  }

  private set() {
    return this.generic
      .getData(this.controller)
      .pipe(tap((response) => this.statu$.next(response)));
  }

  getForAdjustment() {
    return this.generic.getData('status/adjustment');
  }

  getForExtracurricular() {
    const order = 'desc';
    return this.generic.getDataByParams(
      `${this.controller}`,
      `statusCategories=extracurricular`,
      order,
    );
  }

  getStatusByRoute(route) {
    return this.generic.getData(this.controller + '/' + route);
  }

  getStatusByCategory(category) {
    return this.generic.getData(`${this.controller}/category/${category}`);
  }
}
