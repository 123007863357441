import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-teacher-branch',
  templateUrl: './teacher-branch.component.html',
  styleUrls: ['./teacher-branch.component.scss'],
})
export class TeacherBranchComponent implements OnInit, OnChanges {
  @Input() allClasses;
  @Input() branches;
  @Input() subjects;
  @Input() teacherProfileData;
  @Output() submitTeacherBranch = new EventEmitter();
  @Input() disabledFields = false;

  form: FormGroup;
  submitted = false;
  grades = [];
  departments = [
    'Curriculum',
    'Sports',
    'Finance',
    'IT Department',
    'Teachers',
  ];
  designations = [
    'General Manager',
    'Accountant',
    'Executive Director',
    'Teacher',
    'Administrator',
    'Secondary Teacher',
  ];
  teachingStandard = ['Junior', 'Senior'];
  shift = ['Full Time', 'Part Time'];

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.formPost();
    if (this.teacherProfileData && this.teacherProfileData != undefined) {
      if (this.disabledFields) this.form.disable();
      this.bindData();
    }
  }

  ngOnChanges() {
    if (this.teacherProfileData && this.teacherProfileData != undefined) {
      this.bindData();
      if (this.disabledFields) this.form.disable();
    }
  }

  formPost() {
    this.form = this.fb.group({
      assignBranch: this.fb.array([this.createTeacherBranchFormGroup()]),
    });
  }

  get assignBranch(): FormArray {
    return this.form.get('assignBranch') as FormArray;
  }

  createTeacherBranchFormGroup(): FormGroup {
    return this.fb.group({
      id: [undefined],
      branch: [undefined, Validators.required],
      department: [undefined],
      designation: [undefined],
      teachingStandard: [[]],
      grade: [undefined, Validators.required],
      subject: [undefined, Validators.required],
      shift: [undefined],
    });
  }

  addTeacherBranch() {
    this.assignBranch.push(this.createTeacherBranchFormGroup());
  }

  removeTeacherBranch(i: number) {
    this.assignBranch.removeAt(i);
  }

  bindData() {
    const assignBranch = this.teacherProfileData.assignBranch;
    if (assignBranch && assignBranch.length === 0) return;
    this.assignBranch.controls.length = 0;
    assignBranch.forEach((branch, index) => {
      this.selectBranch(branch.branch.id, index);
      const assignBranchGroup = this.fb.group({
        id: [branch._id],
        branch: [branch.branch.id, Validators.required],
        department: [branch.department ? branch.department : undefined],
        designation: [branch.designation ? branch.designation : undefined],
        teachingStandard: [branch.teachingStandard.map((x) => x)],
        grade: [branch.grade.map((x) => x.id), Validators.required],
        subject: [branch.subject.map((x) => x.id), Validators.required],
        shift: [branch.shift ? branch.shift : undefined],
      });
      this.assignBranch.push(assignBranchGroup);
    });
  }

  selectBranch($event, index) {
    const gradesArray = this.allClasses.filter(
      (element) => element.branch.id == $event,
    );
    const branchWiseGrade = [];
    for (let i = 0; i < gradesArray.length; i++) {
      branchWiseGrade.push(gradesArray[i].grade);
    }
    this.grades[index] = branchWiseGrade;
    // TODO: reset selected grade
  }

  submit(nextTab) {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    const { assignBranch } = this.form.getRawValue();

    this.submitTeacherBranch.emit({ assignBranch, nextTab });
  }
}
