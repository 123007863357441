import { GenericService } from '../generic/generic.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PostService {
  constructor(private generic: GenericService) {}
  controller = 'posts';
  get() {
    //getting data from service in this method and showing it on list
    return this.generic.getData(this.controller);
  }
  post(body) {
    return this.generic.create(this.controller, body);
  }
  getPdfPreviewUrl(body) {
    return this.generic.create(`${this.controller}/preview`, body);
  }

  getByID(id) {
    return this.generic.getDataById(this.controller, id);
  }

  delete(id) {
    return this.generic.delete(this.controller, id);
  }
  update(body) {
    return this.generic.update(this.controller, body);
  }
  publishCircular(id) {
    return this.generic.update(`${this.controller}/${id}/publish`, {});
  }
  mediaUpload(body) {
    return this.generic.imageUpload(`${this.controller}/media/upload`, body);
  }
  getbyParams(params) {
    const order = 'desc';
    return this.generic.getDataByParams(this.controller, params, order);
  }
  search(params = '', order = 'desc', sort = 'createdAt') {
    return this.generic.getDataByParams(this.controller, params, order, sort);
  }
  getStudentPosts(limit, page, order = 'desc', sort = 'createdAt') {
    const params = `limit=${limit}&page=${page}`;
    return this.generic.getDataByParams(
      `student/${this.controller}`,
      params,
      order,
      sort,
    );
  }

  getPosts(params = '', order = 'desc', sort = 'createdAt') {
    return this.generic.getDataByParams(
      `${this.controller}/listofpost`,
      params,
      order,
      sort,
    );
  }
  postFeed(limit, page, branch?: string) {
    let url = `communication/postFeed?limit=${limit}&page=${page}`;
    if (branch != undefined && branch != '' && branch != 'allBranches') {
      url = url.concat(`&branch=${branch}`);
    }
    return this.generic.getData(`${url}`);
  }

  getNewsStatus() {
    return this.generic.getData('status/news');
  }

  changeStatus(body) {
    return this.generic.update(`${this.controller}/changestatus`, body);
  }

  addReaction(postId, body) {
    return this.generic.create(`${this.controller}/${postId}/reaction`, body);
  }
}
