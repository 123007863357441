import { GenericService } from '../generic/generic.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BillingWithdrawService {
  controller = 'billing/withdraw';
  academicYear = '';

  constructor(private generic: GenericService) {}

  get() {
    return this.generic.getData(this.controller);
  }

  post(body) {
    return this.generic.create(this.controller, body);
  }

  getByID(id) {
    return this.generic.getDataById(this.controller, id);
  }
  delete(id) {
    return this.generic.delete(this.controller, id);
  }
  update(body) {
    return this.generic.update(this.controller, body);
  }
  search(search, filter, order) {
    return this.generic.getDataBySearch(this.controller, search, filter, order);
  }
  getUnpaidBills(id) {
    return this.generic.getDataById(this.controller + '/unpaidInvoices', id);
  }

  cancelInvoice(body) {
    return this.generic.update(this.controller + '/cancelInvoice', body);
  }

  createInvoice(body) {
    return this.generic.create(this.controller + '/createInvoice', body);
  }

  getWithdrawStudentList(params) {
    const order = 'desc';
    return this.generic.getDataByParams(
      `${this.controller}/withdrawStudentList`,
      params,
      order,
    );
  }
}
