import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { CustomSnackBarComponent } from '../../custom-snack-bar/custom-snack-bar.component';
import { CustomSnackbarDeleteComponent } from '../../custom-snackbar-delete/custom-snackbar-delete.component';
import { MatSnackBar } from '@angular/material';
import { StructureTimetableService } from 'src/app/core/services/timetable/structured-Timetable/timetable/structure-timetable.service';
import { TeacherProfileService } from 'src/app/core/services/teacher/teacher-profile/teacher-profile.service';
import { helper } from 'src/app/shared/helper-functions/helper';
export interface DialogData {
  assignId: any;
  id?: string;
  subject: string;
  teacherList: string;
  newID: string;
  day: string;
  routeData: any;
  branch: string;
  grade: string;
  section: string;
  periods: string;
  timetable: any;
  StID: string;
  selectedSubject?: any;
  selectedTeacher?: any;
  modalType: string;
  subjectFrequeny: any;
  routeID: any;
}
@Component({
  selector: 'app-timetable-modal',
  templateUrl: './timetable-modal.component.html',
  styleUrls: ['./timetable-modal.component.scss'],
})
export class TimetableModalComponent implements OnInit {
  subjectSelected: any;
  periodSelected: any;
  teacherSelected: any;
  selectedSecondarTeacher: any;
  routeData: any;
  submittedR = false;
  modalName = 'Add';
  timeTableInfo: FormGroup;
  selectedStructure: any;
  filterList = [];
  panelOpenState = false;
  show = 1;
  showSub = 1;
  subjects = [];
  teachers = [];
  branch: any;
  grade: any;
  section: any;
  assignId: any;
  timeTable = [];
  selectedday: any;
  isValueNull = false;
  day: any;
  structureId;
  finalData;

  constructor(
    public dialogRef: MatDialogRef<TimetableModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private teacherProfileService: TeacherProfileService,
    private timetableService: StructureTimetableService,
  ) {
    this.timeTableInfo = this.fb.group({
      id: null,
      subject: [undefined, Validators.required],
      teacher: [undefined],
      period: ['', Validators.required],
      day: ['', Validators.required],
      room: [''],
      secondaryTeacher: [undefined],
    });
  }

  ngOnInit() {
    this.branch = this.data.branch;
    this.day = this.data.day;
    this.grade = this.data.grade;
    this.section = this.data.section;
    this.periodSelected = this.data.periods;
    this.selectedStructure = this.data.StID;
    this.teachers = this.data.selectedTeacher;
    this.assignId = this.data.assignId;
    this.bindData();
  }

  get f() {
    return this.timeTableInfo.controls;
  }
  onSubmit() {
    this.submittedR = true;
    if (this.timeTableInfo.invalid) {
      return;
    }
    if (
      this.timeTableInfo.get('teacher').value != null &&
      this.timeTableInfo.get('teacher').value ==
        this.timeTableInfo.get('secondaryTeacher').value
    ) {
      return;
    }

    this.timeTable.forEach((e) => {
      if (e.day._id == this.day) {
        this.structureId = e._id;
      }
    });

    this.finalData = {
      startTime: this.periodSelected.startTime,
      endTime: this.periodSelected.endTime,
      dayId: this.day,
      subject: this.timeTableInfo.value.subject,
      branch: this.branch,
      grade: this.grade,
      section: this.section,
      room: this.timeTableInfo.value.room,
    };

    if (
      this.data.periods != this.periodSelected &&
      this.data.modalType == 'Edit'
    ) {
      let old_assign_id;
      this.data.periods['assign'].forEach((e) => {
        if (e.section._id == this.section) {
          {
            old_assign_id = e._id;
          }
        }
      });
      this.finalData = {
        startTime: this.periodSelected.startTime,
        endTime: this.periodSelected.endTime,
        dayId: this.day,
        subject: this.timeTableInfo.value.subject,
        branch: this.branch,
        grade: this.grade,
        section: this.section,
        room: this.timeTableInfo.value.room,
        old_structure_id: this.data.StID,
        old_period_id: this.data.periods['_id'],
        old_assign_id: old_assign_id,
      };
    }

    if (this.timeTableInfo.value.teacher != null) {
      this.finalData['teacher'] = this.timeTableInfo.value.teacher;
    }

    if (this.timeTableInfo.value.teacher != null) {
      this.finalData['secondaryTeacher'] =
        this.timeTableInfo.value.secondaryTeacher;
    }

    let url =
      '/timetable/' +
      this.data.routeID +
      '/structure/' +
      this.structureId +
      '/period/' +
      this.periodSelected._id +
      '/assign';
    if (this.modalName === 'Edit') {
      url = url + `/${this.assignId}`;
    }
    if (
      this.section &&
      this.branch &&
      this.grade &&
      Object.keys(this.timeTableInfo.value.subject).length > 0 &&
      // Object.keys(this.timeTableInfo.value.teacher).length > 0 &&
      Object.keys(this.periodSelected).length > 0
    ) {
      this.timetableService.update(url, this.finalData).subscribe(
        (res: any) => {
          if (res.status == 'success') {
            this._snackBar.openFromComponent(CustomSnackBarComponent, {
              data: { message: res.message },
              horizontalPosition: 'center',
              duration: helper.duration,
              panelClass: ['save-snackBar'],
              verticalPosition: 'top',
            });
            this.dialogRef.close('success');
          } else {
            this._snackBar.openFromComponent(CustomSnackbarDeleteComponent, {
              data: { message: res.message },
              horizontalPosition: 'center',
              duration: helper.duration,
              panelClass: ['delete-snackBar'],
              verticalPosition: 'top',
            });
          }
        },
        (err) => {
          this._snackBar.openFromComponent(CustomSnackbarDeleteComponent, {
            data: { message: err.error.message },
            horizontalPosition: 'center',
            duration: helper.duration,
            panelClass: ['delete-snackBar'],
            verticalPosition: 'top',
          });
        },
      );
    } else {
      return this._snackBar.openFromComponent(CustomSnackbarDeleteComponent, {
        data: { message: 'Please select the period' },
        horizontalPosition: 'center',
        duration: helper.duration,
        panelClass: ['delete-snackBar'],
        verticalPosition: 'top',
      });
    }
  }
  increaseShow() {
    this.show += this.teachers.length;
  }
  decreaseShow() {
    this.show = 1;
  }
  increaseSub() {
    this.showSub += 10;
  }
  decreaseSub() {
    this.showSub = 1;
  }
  onNoClick(result) {
    this.dialogRef.close(result);
  }

  //change subject by teacher
  changeSubject(event: any) {
    this.subjectSelected = event;
    this.teacherSelected = null;
    this.getTeachersBySelectedSubject(this.subjectSelected);
  }

  changeTeacher(ev: any) {
    this.teacherSelected = ev;
  }

  selectedDay(ev: any) {
    this.day = ev.target.id;
    this.periodSelected = {};
  }
  selectedPeriod(ev: any, period) {
    this.periodSelected = period;
  }

  filterSubjects(subjectsData: any[], currentSubject: any) {
    const subjects = [];
    for (const i in subjectsData) {
      if (
        subjectsData[i].frequency > 0 ||
        (currentSubject && subjectsData[i].subject._id === currentSubject._id)
      ) {
        subjects.push(subjectsData[i].subject);
      }
    }
    return subjects;
  }

  bindData() {
    this.subjects = [];
    this.modalName = this.data.modalType;
    const subject: any = this.data.subject;
    if (this.data.timetable) {
      this.timeTable = this.data.timetable;
    }
    if (this.data.modalType == 'Edit') {
      const selectedAssign = this.data.periods['assign'].find(
        (e) => e.section._id == this.section,
      );
      this.subjects = this.filterSubjects(subject, selectedAssign.subject);
      this.subjectSelected = selectedAssign.subject;
      this.teacherSelected = selectedAssign
        ? selectedAssign['teacher']
        : undefined;
      this.selectedSecondarTeacher = selectedAssign
        ? selectedAssign['secondaryTeacher']
        : undefined;
    } else {
      this.subjects = this.filterSubjects(subject, null);
    }
    if (this.data.selectedSubject) {
      this.subjectSelected = this.data.selectedSubject.subject;
    }
    // if (
    //   this.data.modalType == 'Add' &&
    //   this.teachers &&
    //   this.teachers.length > 0
    // ) {
    //   this.teacherSelected = {
    //     user: {
    //       teacherProfile: {
    //         teacherProfile: this.teachers[0].teacherId,
    //       },
    //     },
    //   };
    // }
    this.subjectSelected &&
      this.getTeachersBySelectedSubject(this.subjectSelected._id);
    this.timeTableInfo.patchValue({
      period: this.data.periods,
      day: this.data.day,
      subject: this.subjectSelected ? this.subjectSelected._id : undefined,
      teacher:
        this.teacherSelected != undefined
          ? this.teacherSelected._id
          : undefined,
      secondaryTeacher:
        this.selectedSecondarTeacher != undefined
          ? this.selectedSecondarTeacher._id
          : undefined,
    });
  }

  getTeachersBySelectedSubject(id: any) {
    this.teachers = [];
    this.timeTableInfo.get('teacher').reset();
    const url =
      'branch=' + this.branch + '&grade=' + this.grade + '&subject=' + id;
    this.teacherProfileService
      .getTeacherProfilesForSearchFilter(url)
      .subscribe((res: any) => {
        if (res.data) {
          this.teachers = [];
          const teacherList: any = res.data;
          for (const i in teacherList) {
            this.teachers.push({
              id: teacherList[i]._id,
              name: teacherList[i].firstName + ' ' + teacherList[i].lastName,
            });
          }
        }
      });
  }
}
