import * as moment from 'moment';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-attendance-month-view',
  templateUrl: './attendance-month-view.component.html',
  styleUrls: ['./attendance-month-view.component.scss'],
})
export class AttendanceMonthViewComponent implements OnInit {
  @Output() childEvent = new EventEmitter<{
    startOfMonth: string;
    endOfMonth: string;
  }>();
  @Input() attendanceMap = {};
  @Input() loading;
  dates: Array<Date>;
  days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
  date = new Date();
  y: number;
  m: number;

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {
    this.y = this.date.getFullYear();
    this.m = this.date.getMonth();
    this.dates = this.getCalendarDates(this.y, this.m);
    this.getAttendance();
  }

  setMonth(inc: number): void {
    const [year, month] = [this.date.getFullYear(), this.date.getMonth()];
    this.date = new Date(year, month + inc, 1);
    this.dates = this.getCalendarDates(
      this.date.getFullYear(),
      this.date.getMonth(),
    );
    this.getAttendance();
  }

  isSameMonth(date: Date): boolean {
    return date.getMonth() === this.date.getMonth();
  }

  goToday(): void {
    const date = moment();
    const startOfMonth = date.startOf('month').format('YYYY-MM-DD hh:mm');
    this.date = new Date(startOfMonth);
    this.dates = this.getCalendarDates(
      this.date.getFullYear(),
      this.date.getMonth(),
    );
  }

  getCalendarDates(year, month) {
    const startDate = new Date(year, month, 1);
    const endDate = new Date(year, month + 1, 0);
    while (startDate.getDay() !== 0) {
      startDate.setDate(startDate.getDate() - 1);
    }
    while (endDate.getDay() !== 6) {
      endDate.setDate(endDate.getDate() + 1);
    }
    const dateIterator = new Date(startDate);
    const calendarDates = [];
    while (dateIterator <= endDate) {
      calendarDates.push(new Date(dateIterator));
      dateIterator.setDate(dateIterator.getDate() + 1);
    }
    return calendarDates;
  }

  getAttendance() {
    const date = moment(this.date);
    const startOfMonth = date.startOf('month').format('YYYY-MM-DD');
    const endOfMonth = date.endOf('month').format('YYYY-MM-DD');
    const dateRange = { startOfMonth, endOfMonth };
    this.childEvent.emit(dateRange);
  }
}
