import {
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { UserType } from '../../enums-constant/user-type.enum';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-preview-document-modal',
  templateUrl: './preview-document-modal.component.html',
  styleUrls: ['./preview-document-modal.component.scss'],
})
export class PreviewDocumentModalComponent implements OnInit, OnDestroy {
  pdfSource: any;
  title: string;
  visibles = [];
  branch = {
    name: '',
  };
  grade = [];
  truncateSize = 32;
  userType;
  isAdmin = false;
  showEditBtn = false;
  cancelbtnText = 'Close';
  hideDownloadButton = false;
  hideBranchAndVisibleTo = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<PreviewDocumentModalComponent>,
  ) {}

  ngOnInit() {
    const userType = localStorage.getItem('user_type');
    if (userType === UserType.Admin || userType === UserType.Staff) {
      this.isAdmin = true;
    }
    this.showEditBtn = this.data.showEditBtn;
    this.pdfSource = this.data.pdf;
    this.title = this.data.title;
    this.branch = this.data.branchName;
    this.grade = this.data.grade;
    this.visibles = this.data.visibleTo;
    this.hideDownloadButton = this.data.hideDownloadButton;
    this.hideBranchAndVisibleTo = this.data.hideBranchAndVisibleTo;
    this.checkScreenSize();
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkScreenSize();
  }

  checkScreenSize() {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 300) {
      this.truncateSize = 10;
    }
    if (screenWidth > 300 && screenWidth <= 540) {
      this.truncateSize = 20;
    }
    if (screenWidth > 540 && screenWidth <= 720) {
      this.truncateSize = 28;
    }
    if (screenWidth > 720 && screenWidth <= 992) {
      this.truncateSize = 30;
    }
    if (screenWidth > 992) {
      this.truncateSize = 250;
    }
  }

  downloadPdf() {
    saveAs(this.pdfSource, `${this.title}.pdf`);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.dialog.closeAll();
  }

  getBranchGradeNames(branchName: string, grades: any[]): string {
    if (!branchName) return 'All Branches';
    if (!grades || grades.length === 0) {
      return branchName;
    }
    return `${branchName} - ${grades}`;
  }
}
