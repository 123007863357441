import { academicYearList } from './academic-year';
import { branch } from './branch';
import { city } from './city';
import { country } from './country';
import { gender } from './gender';
import { generalSetup } from './general-setup';
import { grades } from './grades';
import { language } from './language';
import { section } from './section';
import { state } from './state';
import { status } from './status';
import { SudentParent } from './student-parent';
import { studentStatus } from './student-status';

export class studentProfile {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  admissionDate?: string;
  pob?: string;
  nationality?: country;
  religion?: string;
  status?: status;
  studentStatus?: studentStatus;
  countryCode?: country;
  cell?: string;
  email?: string;
  branch?: branch;
  grade?: grades;
  section?: section;
  house?: generalSetup;
  rollNo?: string;
  gender?: gender;
  academicYear?: academicYearList;
  primaryLanguage?: language;
  secondaryLanguage?: language;
  dob?: string;
  id?: string;
  _id?: string;
  address?: {
    address?: string;
    postalCode: number;
    district?: string;
    state: state;
    city?: city;
    country?: country;
  };
  studentParent?: {
    id?: string;
    studentParent: SudentParent;
  };
  profileImage?: {
    haveDefault: false;
    url: string;
  };
}
