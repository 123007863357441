import { Injectable } from '@angular/core';
import { getFromLocalStorage } from 'src/app/shared';
import { GenericService } from '../generic/generic.service';
@Injectable({
  providedIn: 'root',
})
export class FeeStructureService {
  controller = 'feestructures';
  academicYear = '';

  constructor(private generic: GenericService) {
    this.academicYear = getFromLocalStorage.currentAcademicYearID();
  }

  get() {
    //getting data from service in this method and showing it on list
    return this.generic.getData(this.controller);
  }
  post(body) {
    return this.generic.create(this.controller, body);
  }

  getByID(id) {
    return this.generic.getDataById(this.controller, id);
  }

  delete(id) {
    return this.generic.delete(this.controller, id);
  }
  update(body) {
    return this.generic.update(this.controller, body);
  }
  getbyParams(params, order) {
    return this.generic.getDataByParams(this.controller, params, order);
  }

  getCurrentYearByParams(params = '', order = 'desc') {
    return this.generic.getDataByParams(
      `${this.controller}/activeYear`,
      params,
      order,
    );
  }
}
