import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UserType } from 'src/app/shared/enums-constant/user-type.enum';
import { getFromLocalStorage } from '../../helper-functions';

@Component({
  selector: 'app-meeting-logs',
  templateUrl: './meeting-logs.component.html',
  styleUrls: ['./meeting-logs.component.scss'],
})
export class MeetingLogsComponent {
  @Input() meetingLogs: any;
  @Output() viewDetails: EventEmitter<any> = new EventEmitter();

  isAdminView;
  constructor(private router: Router) {
    const userType = localStorage.getItem('user_type');
    this.isAdminView = userType === UserType.Admin;
  }

  viewDetailsOfMeetingLog() {
    this.viewDetails.emit();
  }
}
