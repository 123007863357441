import { AuthService } from '../auth/auth.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { GenericService } from '../generic/generic.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';

@Injectable({
  providedIn: 'root',
})
export class AssingnClassService {
  private initialState = {
    data: { list: [] },
  };
  private classe$: BehaviorSubject<any> = new BehaviorSubject<any>(
    this.initialState,
  );
  private dataLoading = false;
  controller = 'assignclasses';

  constructor(private generic: GenericService, private auth: AuthService) {
    this.auth.getUser().subscribe((res: any) => {
      if (!res) this.classe$.next(this.initialState);
    });
  }

  get(): Observable<any> {
    if (
      this.classe$.getValue() &&
      this.classe$.getValue() !== this.initialState
    ) {
      return this.classe$.asObservable();
    } else if (!this.dataLoading) {
      return this.set();
    } else {
      return this.classe$.asObservable();
    }
  }

  private set(): Observable<any> {
    this.dataLoading = true;
    return this.generic.getData(this.controller).pipe(
      tap((response) => {
        this.classe$.next(response);
        this.dataLoading = false;
      }),
    );
  }

  getByParams(param) {
    //getting data from service in this method and showing it on list
    return this.generic.getData(this.controller + '?' + param);
  }

  post(body) {
    return this.generic.create(this.controller, body);
  }

  getByID(id) {
    return this.generic.getDataById(this.controller, id);
  }

  delete(id) {
    return this.generic.delete(this.controller, id);
  }

  update(body) {
    return this.generic.update(this.controller, body);
  }

  getbyParams(params) {
    const order = 'desc';
    return this.generic.getDataByParams(this.controller, params, order);
  }

  search(search, filter, order) {
    return this.generic.getDataBySearch(this.controller, search, filter, order);
  }

  getByStats(params) {
    const order = 'desc';
    return this.generic.getDataByParams('assignclasses/stats', params, order);
  }

  getBySchool(param) {
    return this.generic.getData(this.controller + '/school/' + param);
  }

  getAllAssignClasses() {
    return this.generic.getData(`${this.controller}?showAllBranches=true`);
  }
}
