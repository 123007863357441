import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Billing } from '../../models/billing';
import { CustomSnackbarDeleteComponent } from '../custom-snackbar-delete/custom-snackbar-delete.component';
import { DatePipe } from '@angular/common';
import { InvoiceService } from 'src/app/core/services/billing/invoice/invoice.service';
import { MatSnackBar } from '@angular/material';
import { Subject } from 'rxjs';
import { UserType } from '../../enums-constant/user-type.enum';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./billing.component.scss'],
  providers: [DatePipe],
})
export class BillingComponent implements OnInit {
  loading$ = new Subject<boolean>();
  @Input() billingUi = '';
  @Input() items: Billing[] = [];
  url: any;
  month: string;
  billingHeads: any;
  loading = false;
  userType;
  isAdminView = false;
  isStaffView = false;
  isParentView = false;
  showDueDate = true;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private invoiceService: InvoiceService,
    private _snackBar: MatSnackBar,
  ) {}

  ngOnInit() {
    this.userType = localStorage.getItem('user_type');
    if (this.userType == UserType.Admin) this.isAdminView = true;
    if (this.userType == UserType.Staff) this.isStaffView = true;
    // this.url = this.activatedRoute.snapshot.pathFromRoot[1].routeConfig.path === 'parent' ? this.activatedRoute.snapshot.pathFromRoot[1].routeConfig.path : this.router.url;
    const currentUrl = this.router.url;
    if (currentUrl.includes('profile')) {
      this.url = this.router.url;
    } else if (currentUrl.includes('student')) {
      this.url = 'student';
    } else if (currentUrl.includes('parent')) {
      this.url = 'parent';
    }
  }

  checkDate(date) {
    return moment(date).fromNow();
  }

  checkDueDate(date) {
    const today = moment().startOf('day');
    const dueDate = moment(date).startOf('day');
    if (dueDate.isBefore(today)) {
      this.showDueDate = false;
    } else {
      this.showDueDate = true;
    }
    return this.showDueDate;
  }

  showDownloadButton(hValue) {
    if (
      hValue.paid ||
      moment(hValue.dueDate).isBefore(moment()) ||
      hValue.isZeroBilling
    ) {
      return false;
    }
    return true;
  }

  downloadInvoice(invoice) {
    this.loading$.next(true);
    this.invoiceService
      .viewInvoice(`invoiceId=${invoice ? invoice.invoiceId : ''}`)
      .subscribe(
        (res: any) => {
          this.loading$.next(false);
          if (Object.keys(res.data).length !== 0) {
            const pdfSource = res.data;
            this.loading = false;
            const newWindow = window.open(pdfSource, '_blank');
            if (!newWindow) {
              alert(
                'A pop-up blocker prevented opening the document. Please allow pop-ups and try again.',
              );
            }
          } else {
            this.loading = false;
            this._snackBar.openFromComponent(CustomSnackbarDeleteComponent, {
              data: { message: res.message },
              horizontalPosition: 'center',
              duration: 2000,
              panelClass: ['delete-snackBar'],
              verticalPosition: 'top',
            });
          }
        },
        (error) => {
          this.loading$.next(false);
        },
      );
  }
  clickViewDetials() {
    if (this.userType == UserType.Parent) {
      this.router.navigateByUrl('/parent/billing');
    }
    if (this.userType == UserType.Student) {
      this.router.navigateByUrl('/student/billing');
    }
  }
}
