import { GenericService } from '../../../generic/generic.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StructureTimetableService {
  controller = 'structured-timetable';
  constructor(private generic: GenericService) {}
  get() {
    return this.generic.getData(this.controller);
  }

  getByParamss(param) {
    return this.generic.getData(this.controller + '?' + param);
  }

  getByParamsData(param) {
    return this.generic.getData(this.controller + '/' + param);
  }

  getAllByParms(params) {
    const order = 'asc';
    return this.generic.getDataByParams(
      this.controller + '/all',
      params,
      order,
    );
  }

  post(url, body) {
    return this.generic.create(this.controller + url, body);
  }

  getByID(id) {
    return this.generic.getDataById(this.controller, id);
  }
  delete(id) {
    return this.generic.delete(this.controller, id);
  }
  update(url, body) {
    return this.generic.update(this.controller + url, body);
  }
  getbyParams(params) {
    const order = 'desc';
    return this.generic.getDataByParams(this.controller, params, order);
  }
  search(search, filter, order) {
    return this.generic.getDataBySearch(this.controller, search, filter, order);
  }
  //get list by params and route .
  getByParams(params) {
    const order = 'asc';
    return this.generic.getDataByParams(
      this.controller + '/byBranchGrade',
      params,
      order,
    );
  }
  publishStructure(structureId, body) {
    return this.generic.update(`${this.controller}/${structureId}`, body);
  }

  deleteAssignedTimetable(url) {
    return this.generic.deleteWithOutId(`${this.controller}/${url}`);
  }
}
