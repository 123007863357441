import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { GenericService } from '../../generic/generic.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ParentEventService {
  userTypeVisibility: BehaviorSubject<string> = new BehaviorSubject('');
  selectedStudentId: any;
  controller = 'parent';

  constructor(
    private generic: GenericService,
    private authService: AuthService,
  ) {
    this.authService.getUser().subscribe((user) => {
      if (user !== null) {
        this.userTypeVisibility.next('');
      }
    });
  }

  get() {
    return this.generic.getData(this.controller + '/events');
  }

  getByID(id) {
    return this.generic.getDataById(this.controller + '/events', id);
  }

  postRsvp(params, body) {
    return this.generic.create(
      this.controller + '/events/' + params + '/rsvp',
      body,
    );
  }

  imageUpload(body) {
    return this.generic.imageUpload('events/banner', body);
  }

  getbyParams(params) {
    const order = 'asc';
    return this.generic.getDataByParams(
      this.controller + '/events',
      params,
      order,
      'fromDate',
    );
  }

  getAttendanceByStudent(param) {
    const order = 'asc';
    return this.generic.getDataByParams(
      `${this.controller}/student/attendance/`,
      param,
      order,
    );
  }

  getUpcomingEvents(studentId?: string): Observable<any> {
    const todayDate = moment();
    let url = `fromDate=${todayDate.format('YYYY-MM-DD')}&limit=3`;
    if (studentId) {
      url = url.concat(`&studentId=${studentId}`);
    }

    return this.getbyParams(url);
  }

  getCalendarEvents(studentId?: string, dates?: any): Observable<any> {
    const firstDay =
      dates && dates.startDate
        ? moment(dates.startDate).startOf('month').format('YYYY-MM-DD')
        : moment().startOf('month').format('YYYY-MM-DD');
    const lastDay =
      dates && dates.endDate
        ? moment(dates.endDate).endOf('month').format('YYYY-MM-DD')
        : moment().endOf('month').format('YYYY-MM-DD');

    let url = `fromDate=${firstDay}&toDate=${lastDay}`;

    if (studentId) {
      url = url.concat(`&studentId=${studentId}`);
    }

    return this.getbyParams(url);
  }
}
