import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-activity',
  templateUrl: './view-activity.component.html',
  styleUrls: ['./view-activity.component.scss'],
})
export class ViewActivityComponent implements OnInit {
  @Input() heading: string;
  constructor() {}

  ngOnInit() {}
}
