import { Component, OnInit } from '@angular/core';
import { helper } from '../../helper-functions/helper';

@Component({
  selector: 'app-progress-circle-icon',
  templateUrl: './progress-circle-icon.component.html',
  styleUrls: ['./progress-circle-icon.component.scss'],
})
export class ProgressCircleIconComponent implements OnInit {
  color = helper.primaryColor;
  constructor() {}

  ngOnInit() {}
}
