import { Component, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { EventDetailModalComponent } from '../shared-events/event-detail-modal/event-detail-modal.component';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { UserType } from 'src/app/shared/enums-constant/user-type.enum';

@Component({
  selector: 'app-upcoming-event-list',
  templateUrl: './upcoming-event-list.component.html',
  styleUrls: ['./upcoming-event-list.component.scss'],
  providers: [DatePipe],
})
export class UpcomingEventListComponent {
  @Input() events: any;
  show = 5;
  totalShow: any;
  myDate = new Date();
  eventService: any;
  userType: any;
  showdiv;
  constructor(public dialog: MatDialog, private router: Router) {}

  ngOnInit() {
    this.userType = localStorage.getItem('user_type');
  }

  viewEvents() {
    switch (this.userType) {
      case UserType.Admin:
      case UserType.Staff:
        this.router.navigate(['/admin/communication/event-main']);
        break;
      case UserType.Parent:
        this.router.navigate(['/parent/events']);
        break;
      case UserType.Student:
        this.router.navigate(['/student/events']);
        break;
      case UserType.Teacher:
        this.router.navigate(['/teacher/events']);
        break;
    }
  }

  view(event) {
    this.dialog.open(EventDetailModalComponent, {
      data: { eventDetail: event },
      panelClass: 'dialog-with-scroll',
      width: event.rsvp ? '50vw' : '40vw',
    });
  }
}
