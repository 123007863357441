import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DialogData } from '../course-detail-modal/course-detail-modal.component';
import { StudentProfileService } from 'src/app/core/services/student/student-profile/student-profile.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-add-student-modal',
  templateUrl: './add-student-modal.component.html',
  styleUrls: ['./add-student-modal.component.scss'],
})
export class AddStudentModalComponent implements OnInit {
  public form: FormGroup;
  public input$ = new Subject<string | null>();
  branch = new FormControl(undefined);
  grade = new FormControl(undefined);
  studentProfile = new FormControl(undefined, Validators.required);
  headText;
  submitted = false;
  isDisabled;
  studentProfID;
  isLoading = false;
  students = [];
  grades = [];
  editdetail: any;
  stundentId;
  studentHash = {};

  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private studentService: StudentProfileService,
    public dialogRef: MatDialogRef<AddStudentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    this.input$
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe(async (item) => {
        await this.searchByValue(item);
      });
  }

  ngOnInit() {
    this.grades = this.data['grades'];
    this.branch.setValue(this.data['branch'].id);
    this.grade.setValue(this.data['grades'].map((x) => x.id));
    this.branch.disable();
    this.grade.disable();
  }

  formPost() {
    this.form = this.fb.group({
      id: 0,

      studentProfile: new FormControl(null, Validators.required),
    });
  }

  getStudents() {
    this.studentService.get().subscribe((res: any) => {
      this.students = res.data.list;
    });
  }

  onNoClick(): void {
    this.dialogRef.close('cancel');
  }

  resetSearch() {
    this.studentProfID = '';
    this.stundentId = null;
    this.form.controls['studentProfile'].setValue('');
  }

  searchByValue($event) {
    const params = `&branch=${this.branch.value}&grade=${
      this.grade.value
    }&searchText=${encodeURIComponent($event)}`;
    this.studentService
      .getStudentProfilesForSearchFilter(params)
      .subscribe((res: any) => {
        this.students = res.data;
        res.data.forEach((s) => {
          this.studentHash[s.id] = s;
        });
      });
  }

  submit() {
    this.submitted = true;
    if (this.studentProfile.value == undefined) return;
    const data = {
      studentId: this.studentProfile.value,
    };
    this.dialogRef.close(data);
    this.submitted = false;
  }
}
