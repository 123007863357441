import { Injectable } from '@angular/core';
import { GenericService } from '../generic/generic.service';

@Injectable({
  providedIn: 'root',
})
export class MartialStatusService {
  constructor(private generic: GenericService) {}

  controller = 'maritalstatus';
  get() {
    //getting data from service in this method and showing it on list
    return this.generic.getData(this.controller);
  }
  post(body) {
    return this.generic.create(this.controller, body);
  }

  getByID(id) {
    return this.generic.getDataById(this.controller, id);
  }

  delete(id) {
    return this.generic.delete(this.controller, id);
  }
  update(body) {
    return this.generic.update(this.controller, body);
  }
}
