import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserType } from '../../enums-constant/user-type.enum';

@Component({
  selector: 'app-course-card',
  templateUrl: './course-card.component.html',
  styleUrls: ['./course-card.component.scss'],
})
export class CourseCardComponent implements OnInit {
  @Input() title;
  @Input() img;
  @Input() instructor;
  @Input() courseId;
  @Input() studentId;
  @Input() amountPerMonth;
  @Input() programName;
  @Input() noOfMonths;
  @Input() months;
  @Input()
  studentExtracurricular;
  isAdminView = false;
  isParentView = false;
  isStudentView = false;
  isStaffView = false;
  constructor(private router: Router) {}

  ngOnInit() {
    const userType = localStorage.getItem('user_type');
    this.isAdminView = userType === UserType.Admin;
    this.isParentView = userType === UserType.Parent;
    this.isStudentView = userType === UserType.Student;
    this.isStaffView = userType === UserType.Staff;
    if (this.isStudentView) {
      this.studentId = localStorage.getItem('studentId');
    }
  }

  goToViewDetails() {
    if (this.isAdminView || this.isStaffView) {
      this.router.navigateByUrl(
        '/admin/extracurricular/course-details/' + this.courseId,
      );
    } else if (this.isParentView) {
      this.router.navigateByUrl(
        '/parent/course-details/' +
          this.courseId +
          '/student/' +
          this.studentId,
      );
    } else if (this.isStudentView) {
      this.router.navigateByUrl(
        '/student/course-details/' +
          this.courseId +
          '/student/' +
          this.studentId,
      );
    }
  }
}
