import { Component, Input, OnDestroy } from '@angular/core';
import { EventDetailModalComponent } from '../shared-events/event-detail-modal/event-detail-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-events-list-modal',
  templateUrl: './events-list-modal.component.html',
  styleUrls: ['./events-list-modal.component.scss'],
})
export class EventsListModalComponent implements OnDestroy {
  @Input() events;
  constructor(public dialog: MatDialog) {}

  openDetaiPopup(event: any): void {
    this.dialog.open(EventDetailModalComponent, {
      data: { eventDetail: event },
      panelClass: 'dialog-with-scroll',
    });
  }

  ngOnDestroy(): void {
    this.dialog.closeAll();
  }
}
