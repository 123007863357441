import { GenericService } from '../generic/generic.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BillingBatchService {
  controller = 'billing/billingBatch';
  constructor(private generic: GenericService) {}

  get() {
    return this.generic.getData(this.controller);
  }

  post(body) {
    return this.generic.create(this.controller, body);
  }

  getByID(id) {
    return this.generic.getDataById(this.controller, id);
  }

  getBatchForReview(id) {
    return this.generic.getDataById(`${this.controller}/review`, id);
  }

  delete(id) {
    return this.generic.delete(this.controller, id);
  }
  update(body) {
    return this.generic.update(this.controller, body);
  }

  cancelBilling(body) {
    return this.generic.update(this.controller + '/cancel', body);
  }

  approveBilling(body) {
    return this.generic.update(this.controller + '/approve', body);
  }
  search(params, search, filter, order, type) {
    return this.generic.getDataByParams(
      this.controller,
      `${params}&search=${search}&batchType=${type}`,
      order,
      '',
    );
  }
  getByParams(param) {
    return this.generic.getData(this.controller + '?' + param);
  }
}
