import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[alphaNumericOnly]',
})
export class AlphaNumericOnlyDirective {
  key: any;
  @HostListener('keypress', ['$event']) keypress(event: KeyboardEvent) {
    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, space, underscore
    if (/[a-zA-Z0-9-_ ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
}
