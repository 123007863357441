import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-news-tabular-list',
  templateUrl: './news-tabular-list.component.html',
  styleUrls: ['./news-tabular-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsTabularListComponent implements OnInit {
  @Input() news: any;
  constructor() {}

  ngOnInit() {}
  public ngAfterViewInit() {}
  public ngOnChanges(changes: SimpleChanges): void {
    const object: SimpleChange = changes.news;
    this.news = object.currentValue;
  }
}
