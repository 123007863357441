import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SwitcherInteractorService } from './../../../core/services/switcher/switcher-interactor.service';
import { browserRefresh } from 'src/app/app.component';
@Component({
  selector: 'app-button-new-module',
  templateUrl: './button-new-module.component.html',
  styleUrls: ['./button-new-module.component.scss'],
})
export class ButtonNewModuleComponent implements OnInit {
  constructor(
    private switcher: SwitcherInteractorService,
    private route: ActivatedRoute,
  ) {}
  message: string;
  navigation = [];
  public browserRefresh: boolean;

  ngOnInit() {
    this.navigation = [
      // {
      //   id: "3",
      //   name: "Add School",
      //   link: "admin/school",
      //   icon: "fa fa-plus-circle"
      // },
      // {
      //   id: "4",
      //   name: "Add Branch",
      //   link: "admin/branch",
      //   icon: "fa fa-plus-circle"
      // },
      // {
      //   id: "5",
      //   name: "Add Building",
      //   link: "admin/school/buildings",
      //   icon: "fa fa-plus-circle"
      // },
      // {
      //   id: "6",
      //   name: "Add Room",
      //   link: "admin/room",
      //   icon: "fa fa-plus-circle"
      // },
      // {
      //   id: "7",
      //   name: "Add Grade",
      //   link: "admin/grades",
      //   icon: "fa fa-plus-circle"
      // },
      // {
      //   id: "8",
      //   name: "Add Section",
      //   link: "admin/sections",
      //   icon: "fa fa-plus-circle"
      // },
    ];
    this.getSwitherData();
  }

  /* get switcher value from service */
  getSwitherData() {
    this.switcher.getMessage().subscribe((myMessage) => {
      this.message = myMessage;
      this.checkNav(myMessage);
    });

    this.browserRefresh = browserRefresh;
    if (this.browserRefresh == true) {
      this.routeSet();
      this.checkNav(this.message);
    }
  }

  routeSet() {
    const stringroute = this.route.snapshot.firstChild.url[0].path;
    const check = stringroute.charAt(0).toUpperCase() + stringroute.slice(1);

    if (check == 'Teacher') {
      this.message = 'Employees';
      this.checkNav(this.message);
    } else if (check == 'User') {
      this.message = 'Settings';
      this.checkNav(this.message);
    } else if (check == 'Communication') {
      this.message = 'Communication';
      this.checkNav(this.message);
    } else if (check == 'School') {
      this.message = 'Schools';
      this.checkNav(this.message);
    } else {
      this.message = check;
      this.checkNav(this.message);
    }
    // this.message = check
  }
  /* get switcher name and change module menu according to it */
  checkNav(message) {
    if (this.message == 'Student') {
      this.navigation = [
        {
          id: '3',
          name: 'Add Student',
          link: 'admin/student/student-main',
          icon: 'fa fa-plus-circle',
        },
      ];
    } else if (this.message == 'Academics') {
      this.navigation = [
        {
          id: '3',
          name: 'Add Academic Year',
          link: 'admin/academics/assign-term',
          icon: 'fa fa-plus-circle',
        },
        {
          id: '4',
          name: 'Add Subject',
          link: 'admin/academics/subject',
          icon: 'fa fa-plus-circle',
        },
        {
          id: '5',
          name: 'Add Timetable',
          link: 'admin/academics/structured-timetable',
          icon: 'fa fa-plus-circle',
        },
      ];
    } else if (this.message == 'Billing') {
      this.navigation = [
        {
          id: '3',
          name: 'Add Fee Structure',
          link: 'admin/billing/invoice-setup',
          icon: 'fa fa-plus-circle',
        },
        {
          id: '3',
          name: 'Add Adjustment',
          link: 'admin/billing/adjustment',
          icon: 'fa fa-plus-circle',
        },
        {
          id: '3',
          name: 'Add Concession',
          link: 'admin/billing/invoicing-concession/add',
          icon: 'fa fa-plus-circle',
        },
      ];
    } else if (this.message == 'Employees' || this.message == 'Employee') {
      this.navigation = [
        {
          id: '3',
          name: 'Add Teacher',
          link: 'admin/teacher/teacher-main',
          icon: 'fa fa-plus-circle',
        },
        {
          id: '3',
          name: 'Add Department',
          link: 'admin/setup/departments',
          icon: 'fa fa-plus-circle',
        },
        {
          id: '3',
          name: 'Add Designation',
          link: 'admin/setup/designations',
          icon: 'fa fa-plus-circle',
        },
      ];
    } else if (
      this.message == 'Communications' ||
      this.message == 'Communication'
    ) {
      this.navigation = [
        {
          id: '3',
          name: 'Add News',
          link: 'admin/communication/news/addnmodal',
          icon: 'fa fa-plus-circle',
        },
        {
          id: '3',
          name: 'Add Event',
          link: 'admin/communication/event-main/addmodal',
          icon: 'fa fa-plus-circle',
        },
        {
          id: '4',
          name: 'Add Circular',
          link: 'admin/communication/add-circular',
          feature: 'Circulars',
          action: 'Add',
          icon: 'fa fa-plus-circle',
        },
        {
          id: '4',
          name: 'Add Post',
          link: 'admin/communication/posts/add',
          feature: 'Posts',
          action: 'Add',
          icon: 'fa fa-plus-circle',
        },
        {
          id: '4',
          name: 'Add Meeting Log',
          link: 'admin/communication/meeting-logs/add',
          feature: 'Meeting Logs',
          action: 'Add',
          icon: 'fa fa-plus-circle',
        },
      ];
    } else if (this.message == 'Schools' || this.message == 'School') {
      this.navigation = [
        // {
        //   id: "3",
        //   name: "Add School",
        //   link: "admin/school/school",
        //   icon: "fa fa-plus-circle"
        // },
        {
          id: '4',
          name: 'Add Branch',
          link: 'admin/school/branch',
          icon: 'fa fa-plus-circle',
        },
        {
          id: '4',
          name: 'Add Buildings',
          link: 'admin/school/building',
          icon: 'fa fa-plus-circle',
        },
        {
          id: '4',
          name: 'Add Room',
          link: 'admin/school/room',
          icon: 'fa fa-plus-circle',
        },
        {
          id: '4',
          name: 'Add Grade',
          link: 'admin/school/grades',
          icon: 'fa fa-plus-circle',
        },
        {
          id: '4',
          name: 'Add Section',
          link: 'admin/school/sections',
          icon: 'fa fa-plus-circle',
        },
      ];
    } else if (this.message == 'Extracurricular') {
      this.navigation = [
        {
          id: '3',
          name: 'Add Program',
          link: 'admin/extracurricular/programs',
          icon: 'fa fa-plus-circle',
        },
        {
          id: '4',
          name: 'Add Extracurricular Course',
          link: 'admin/extracurricular/add-extracurricular',
          icon: 'fa fa-plus-circle',
        },
      ];
    } else if (this.message == 'Settings') {
      this.navigation = [
        {
          id: '3',
          name: 'Add User',
          link: 'admin/user/user',
          icon: 'fa fa-plus-circle',
        },
        {
          id: '4',
          name: 'Add User Role',
          link: 'admin/user/userroles',
          icon: 'fa fa-plus-circle',
        },
      ];
    } else {
      this.navigation = [];
    }
  }
}
