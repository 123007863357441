import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-social-cards',
  templateUrl: './social-cards.component.html',
  styleUrls: ['./social-cards.component.scss'],
})
export class SocialCardsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
