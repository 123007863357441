import { GenericService } from '../../generic/generic.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TeacherProfileService {
  controller = 'teacherprofiles';

  constructor(private generic: GenericService) {}

  getAllTeacherProfiles(params) {
    return this.generic.getDataByParams(this.controller, params);
  }

  createTeacherProfile(body) {
    return this.generic.create(this.controller, body);
  }

  getTeacherProfile(id) {
    return this.generic.getDataById(this.controller, id);
  }

  updateTeacherProfile(id, body) {
    return this.generic.update(`${this.controller}/${id}`, body);
  }

  deleteTeacherProfile(id) {
    return this.generic.delete(this.controller, id);
  }

  updateTeacherBranch(id, body) {
    return this.generic.update(`${this.controller}/${id}/branch`, body);
  }

  updateTeacherQualification(id, body) {
    return this.generic.update(`${this.controller}/${id}/qualification`, body);
  }

  updateTeacherExperience(id, body) {
    return this.generic.update(`${this.controller}/${id}/experience`, body);
  }

  addTeacherDocument(id, body) {
    return this.generic.upload(`${this.controller}/${id}/document`, body);
  }

  removeTeacherDocument(id, documentId) {
    return this.generic.delete(`${this.controller}/${id}/document`, documentId);
  }

  getTeacherTimetable(id) {
    return this.generic.getData(`${this.controller}/${id}/timetable`);
  }

  updateTeacherProfileImage(id, body) {
    return this.generic.imageUpload(
      `${this.controller}/${id}/profile-image`,
      body,
    );
  }

  getTeacherProfilesForSearchFilter(params) {
    return this.generic.getDataByParams(
      `${this.controller}/teacher-filter`,
      params,
    );
  }

  createTeacherProfileForDevTooltDirect(body) {
    return this.generic.create(
      `${this.controller}/devtool/create-teacher-profile`,
      body,
    );
  }
}
