import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-academic-list-dashobard-component',
  templateUrl: './academic-list-dashobard-component.component.html',
  styleUrls: ['./academic-list-dashobard-component.component.scss'],
})
export class AcademicListDashobardComponentComponent {
  @Input() heading1: any;
  @Input() heading2: string;
  @Input() heading3: string;
  @Input() shortTextBit: boolean;

  @Input() shortText: string;
  @Input() bottomTextBit: boolean;

  @Input() bottomText: string;
  @Input() data: any;
  @Input() type: number;

  @Input() url: any;
  @Input() isBilling: boolean;
  selectedURL = '';

  constructor(private router: Router) {}

  onClick(student) {
    if (this.isBilling) {
      this.selectedURL = `/admin/billing/student-profile-billing/${student.rollNo}`;
    } else {
      this.selectedURL = `/admin/student/student-profile/${student.studentId}`;
    }
    this.router.navigateByUrl(this.selectedURL);
  }
}
