import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inputFilter',
})
export class InputFilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) return [];
    if (!searchText) return items;

    if (searchText.length >= 2) {
      searchText = searchText.toLowerCase();

      return items.filter((it) => {
        return (
          it.name.toLowerCase().includes(searchText) ||
          it.description.toLowerCase().includes(searchText)
        );
      });
    } else {
      return items;
    }
  }
}
