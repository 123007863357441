import { Component, OnInit } from '@angular/core';
import {
  CustomSnackBarComponent,
  CustomSnackbarDeleteComponent,
  helper,
} from '../shared';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { InvitationService } from '../core';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-accept-invitation',
  templateUrl: './accept-invitation.component.html',
  styleUrls: ['./accept-invitation.component.scss'],
})
export class AcceptInvitationComponent implements OnInit {
  form: FormGroup;
  routeId;
  hidePassword = true;
  isLoading = false;
  constructor(
    private fb: FormBuilder,
    private invitation: InvitationService,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    const emailRegexp =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    this.form = this.fb.group({
      code: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(emailRegexp)]],
      schoolName: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.routeId = this.route.snapshot.paramMap.get('id');
    if (this.routeId) {
      this.form.get('code').setValue(this.routeId);
    }
  }

  submit() {
    if (this.isLoading) return;
    if (this.form.invalid) return;
    this.isLoading = true;
    const { code, ...body } = this.form.value;
    const url = `${code}/accept`;
    this.invitation.post(url, body).subscribe(
      (res: any) => {
        if (res.status == 'success') {
          this._snackBar.openFromComponent(CustomSnackBarComponent, {
            data: { message: res.message },
            horizontalPosition: 'center',
            duration: helper.duration,
            panelClass: ['save-snackBar'],
            verticalPosition: 'top',
          });
          this.router.navigate(['/login']);
        }
        this.isLoading = false;
      },
      (err) => {
        this._snackBar.openFromComponent(CustomSnackbarDeleteComponent, {
          data: { message: err.error.message },
          horizontalPosition: 'center',
          duration: helper.duration,
          panelClass: ['delete-snackBar'],
          verticalPosition: 'top',
        });
        this.isLoading = false;
      },
    );
  }
}
