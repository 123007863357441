import * as moment from 'moment';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Utility } from 'src/app/shared';

@Component({
  selector: 'app-billing-student-profile',
  templateUrl: './billing-student-profile.component.html',
  styleUrls: ['./billing-student-profile.component.scss'],
  providers: [Utility],
})
export class BillingStudentProfileComponent implements OnInit {
  @Input() breadcrumb;
  @Input() student;
  @Input() securityDeposit;
  @Input() withdrawDetails;
  @Output() removeFromAcademicHold = new EventEmitter();
  parentExist = true;
  isAdminView = true;
  color;

  constructor(private router: Router, private utility: Utility) {}

  ngOnInit(): void {
    this.color =
      this.student &&
      this.student.house &&
      this.utility.getContrastColorByHexCode(this.student.house.color);
  }

  viewParentProfile(parentId, isMotherSelected?, isFatherSelected?) {
    let redirectUrl = '';
    if (this.isAdminView) {
      redirectUrl = `admin/billing/parent/${parentId}`;
    }
    if (isMotherSelected) {
      redirectUrl = redirectUrl.concat(`?feature=isMotherSelected`);
    }
    if (isFatherSelected) {
      redirectUrl = redirectUrl.concat(`?feature=isFatherSelected`);
    }
    this.router.navigateByUrl(redirectUrl);
  }

  changeIcon() {
    const element = document.getElementById('btn');
    element.classList.toggle('arrow-up');
  }

  handleRemoveFromAcademicHold() {
    this.removeFromAcademicHold.emit(this.student._id);
  }
}
