import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-behaviour',
  templateUrl: './behaviour.component.html',
  styleUrls: ['./behaviour.component.scss'],
})
export class BehaviourComponent implements OnInit {
  @Input() items: any;
  children: any;
  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items != undefined && !changes.items.firstChange) {
      this.children = changes.items.currentValue;
    }
  }
}
