import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appRestrictScreen]',
})
export class RestrictScreenDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {
    // Disable text selection
    this.renderer.setStyle(this.el.nativeElement, 'user-select', 'none');
    this.renderer.setStyle(
      this.el.nativeElement,
      '-webkit-user-select',
      'none',
    );
    this.renderer.setStyle(this.el.nativeElement, '-moz-user-select', 'none');
    this.renderer.setStyle(this.el.nativeElement, '-ms-user-select', 'none');

    // Disable cut, copy, paste
    this.el.nativeElement.addEventListener('cut', this.blockAction);
    this.el.nativeElement.addEventListener('copy', this.blockAction);
    this.el.nativeElement.addEventListener('paste', this.blockAction);
  }

  // Prevent right-click context menu
  @HostListener('contextmenu', ['$event'])
  onRightClick(event: MouseEvent) {
    event.preventDefault();
  }

  // Prevent text selection
  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    if (event.detail > 1) {
      event.preventDefault();
    }
  }

  // Block clipboard actions
  private blockAction(event: ClipboardEvent) {
    event.preventDefault();
  }

  // Block specific keyboard shortcuts
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (
      event.ctrlKey ||
      event.metaKey ||
      event.altKey ||
      event.shiftKey ||
      (event.ctrlKey &&
        (event.key === 'c' ||
          event.key === 'x' ||
          event.key === 'v' ||
          event.key === 'a' ||
          event.key === 'p')) ||
      (event.altKey &&
        (event.key === 'c' ||
          event.key === 'x' ||
          event.key === 'v' ||
          event.key === 'a' ||
          event.key === 'p')) ||
      (event.shiftKey &&
        (event.key === 'c' ||
          event.key === 'x' ||
          event.key === 'v' ||
          event.key === 'a' ||
          event.key === 'p')) ||
      event.key === 'PrintScreen' // Disable PrtSc key
    ) {
      event.preventDefault();
    }
  }
}
