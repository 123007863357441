import {
  AssingnClassService,
  MeetingLogsService,
  StudentProfileService,
  UserTypeService,
} from 'src/app/core';
import { Component, Inject, OnInit } from '@angular/core';
import {
  CustomSnackBarComponent,
  CustomSnackbarDeleteComponent,
  helper,
} from 'src/app/shared';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2/dist/sweetalert2.js';

export interface DialogData {
  id?: string;
  state: string;
}

@Component({
  selector: 'app-add-meeting-log-modal',
  templateUrl: './add-meeting-log-modal.component.html',
  styleUrls: ['./add-meeting-log-modal.component.scss'],
  providers: [DatePipe],
})
export class AddMeetingLogModalComponent implements OnInit {
  public form: FormGroup;
  public input$ = new Subject<string | null>();
  isDisabled: false;
  branches = [];
  allClasses = [];
  gradesArray = [];
  sectionArray = [];
  submitted;
  isLoading = false;
  gradeId: any;
  branchId: any;
  sectionId: any;
  studentId: any;
  params: string;
  active: any = '';
  selectedItems = [];
  visibleTo = [];
  userTypes = [];
  selectedTags = [];
  meetingLogTags = [];
  heading = 'Add';
  currentDate = new Date();
  viewDisable = false;
  cancelbtnText = 'Cancel';
  totalCount: 0;
  studentsHash: any = [];
  isRequestPending = false;
  isStudentSearched = false;

  constructor(
    public dialogRef: MatDialogRef<AddMeetingLogModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      id: any;
      state: any;
      service: any;
    },
    private fb: FormBuilder,
    private assignClass: AssingnClassService,
    private _snackBar: MatSnackBar,
    private userType: UserTypeService,
    public meetingLogService: MeetingLogsService,
    private studentProfileService: StudentProfileService,
    public datePipe: DatePipe,
    public router: Router,
  ) {
    this.input$
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe(async (item) => {
        await this.searchStudent(item);
      });
  }

  get FormControl() {
    return this.form.controls;
  }

  ngOnInit() {
    this.formPost();
    this.getBranches();
    this.meetingLogTags = this.data.service.meetingTags;

    if (this.data != null) {
      //check if id is null or not to view edit modal

      if (this.data.state == 'view') {
        this.heading = 'View';
        this.viewDisable = true;
        this.cancelbtnText = 'Close';
      } else if (this.data.state == 'edit') {
        this.heading = 'Edit';
      }
    }
  }

  formPost() {
    this.form = this.fb.group({
      id: '',
      branch: new FormControl(undefined, Validators.required),
      grade: new FormControl(undefined, Validators.required),
      section: new FormControl(undefined, Validators.required),
      student: new FormControl(undefined, Validators.required),
      date: new FormControl('', Validators.required),
      time: new FormControl('', Validators.required),
      attendees: new FormControl(''),
      location: new FormControl(''),
      description: new FormControl('', Validators.required),
    });
  }

  bindData(id) {
    //bind data on edit and view mode

    this.data.service.getMeetingLogById(id).subscribe(
      (res: any) => {
        this.branchId = res.data.branch.id;
        this.gradeId = res.data.grade[0].id;
        this.sectionId = res.data.section[0].id;
        this.studentId = res.data.student.id;

        for (let k = 0; k < this.userTypes.length; k++) {
          if (res.data.visible.some((e) => e.id == this.userTypes[k].id)) {
            this.userTypes[k].active = true;
          } else {
            this.userTypes[k].active = false;
          }
        }

        for (let a = 0; a < res.data.visible.length; a++) {
          this.visibleTo.push(res.data.visible[a].id);
        }

        this.selectedTags = res.data.tags.map((tag) => tag.label);

        this.form.patchValue({
          id: res.data.id,
          branch: res.data.branch ? res.data.branch.id : '',
          date: res.data.date ? new Date(res.data.date) : '',
          time: res.data.date ? new Date(res.data.date) : '',
          attendees: res.data.attendees ? res.data.attendees : '',
          location: res.data.location ? res.data.location : '',
          description: res.data.description ? res.data.description : '',
        });

        this.selectBranchNew(this.branchId, true);
      },
      (err) => {
        this._snackBar.openFromComponent(CustomSnackbarDeleteComponent, {
          data: {
            message:
              err.error.message ||
              'Something went wrong while getting meeting log by ID',
          },
          horizontalPosition: 'center',
          duration: helper.duration,
          panelClass: ['delete-snackBar'],
          verticalPosition: 'top',
        });
      },
    );
  }

  onNoClick(): void {
    //close modal method
    if (this.form.dirty == true) {
      Swal.fire({
        title: 'Are you sure you want to cancel it?',
        text: 'Your changes will be lost.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          this.dialogRef.close('cancel');
        }
      });
    } else {
      this.dialogRef.close();
    }
  }

  submit() {
    if (this.isLoading) {
      return;
    }
    this.submitted = true;
    if (this.form.invalid || this.selectedTags.length == 0) {
      helper.validateAllFormFields(this.form); //if form fields are not valid fire thos method;
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
      return;
    }
    this.isLoading = true;
    const time = new Date(this.form.get('time').value);
    const date = new Date(this.form.get('date').value);

    date.setHours(time.getHours());
    date.setMinutes(time.getMinutes());
    date.setSeconds(time.getSeconds());

    this.form.get('date').setValue(date);
    this.form.value.visible = this.visibleTo;

    const formValue = { ...this.form.value };

    for (const prop in formValue) {
      if (!formValue[prop]) {
        delete formValue[prop];
      }

      if (Array.isArray(formValue[prop])) {
        const resultArray = formValue[prop].filter((item) => item);

        if (resultArray.length > 0) {
          formValue[prop] = resultArray;
        } else {
          delete formValue[prop];
        }
      }
    }

    formValue.tags = [];

    this.selectedTags.forEach((tag) => {
      this.meetingLogTags.forEach((mtag) => {
        if (mtag.label === tag) {
          formValue.tags.push(mtag);
        }
      });
    });

    const studentList = this.studentsHash;
    const student = studentList.find((std) => std._id === formValue.student);
    if (student) {
      formValue.studentName = `${student.firstName} ${student.lastName}`;
    }

    this.isRequestPending = true;
    this.meetingLogService.isDataLoading = true;

    if (formValue.id != undefined) {
      this.data.service.updateMeetingLog(formValue).subscribe(
        (res: any) => {
          res;
          if (res.status == 'success') {
            this._snackBar.openFromComponent(CustomSnackBarComponent, {
              data: { message: res.message },
              horizontalPosition: 'center',
              duration: helper.duration,
              panelClass: ['save-snackBar'],
              verticalPosition: 'top',
            });
            this.isLoading = false;
            this.dialogRef.close('success');
            this.isRequestPending = false;
            this.meetingLogService.isDataLoading = false;
          }
        },
        (err) => {
          this._snackBar.openFromComponent(CustomSnackbarDeleteComponent, {
            data: { message: err.error.message },
            horizontalPosition: 'center',
            duration: helper.duration,
            panelClass: ['delete-snackBar'],
            verticalPosition: 'top',
          });
          this.isLoading = false;
          this.isRequestPending = false;
          this.meetingLogService.isDataLoading = false;
        },
      );
    } else {
      this.data.service.postMeetingLog(formValue).subscribe(
        (res: any) => {
          res;
          if (res.status == 'success') {
            this._snackBar.openFromComponent(CustomSnackBarComponent, {
              data: { message: res.message },
              horizontalPosition: 'center',
              duration: helper.duration,
              panelClass: ['save-snackBar'],
              verticalPosition: 'top',
            });
            this.isLoading = false;
            this.dialogRef.close('success');
          }
        },
        (err) => {
          this._snackBar.openFromComponent(CustomSnackbarDeleteComponent, {
            data: { message: err.error.message },
            horizontalPosition: 'center',
            duration: helper.duration,
            panelClass: ['delete-snackBar'],
            verticalPosition: 'top',
          });
          this.isLoading = false;
        },
      );
    }
  }

  getUserType() {
    this.userType.getUserTypeVisible().subscribe((res: any) => {
      this.userTypes = res.data;
      this.userTypes = this.userTypes.filter((f) => f.name !== 'Staff');
      if (this.data.id) {
        this.bindData(this.data.id);
      }
    });
  }

  getBranches() {
    //get branches through assign class
    this.assignClass.get().subscribe(
      (res: any) => {
        this.allClasses = res.data.list;

        const branchArray = [];
        for (let i = 0; i < res.data.list.length; i++) {
          branchArray.push(res.data.list[i].branch);
        }
        const seen = new Set();
        const filteredArr = branchArray.filter((el) => {
          const duplicate = seen.has(el.id);
          seen.add(el.id);
          return !duplicate;
        });
        this.branches = filteredArr;
        // this.selectBranchNew(this.branches[0].id, true);
        this.getUserType();
      },
      (err) => {
        this._snackBar.openFromComponent(CustomSnackbarDeleteComponent, {
          data: {
            message:
              err.error.message ||
              'Something went wrong while getting branches',
          },
          horizontalPosition: 'center',
          duration: helper.duration,
          panelClass: ['delete-snackBar'],
          verticalPosition: 'top',
        });
      },
    );
  }

  getStudents(branch, grade, section) {
    //for assign branches get branch
    if (
      !this.studentsHash[`${branch}|${grade}|${section}`] ||
      (this.studentsHash[`${branch}|${grade}|${section}`] &&
        !this.studentsHash[`${branch}|${grade}|${section}`].length)
    ) {
      this.studentProfileService
        .getActiveAcademicYearStudents(
          'branch=' + branch + '&grade=' + grade + '&section=' + section,
        )
        .subscribe(
          (res: any) => {
            this.studentsHash = res.data;
            if (this.data.id) {
              this.form.patchValue({
                student: this.studentId,
              });
            }
          },
          (err) => {
            this._snackBar.openFromComponent(CustomSnackbarDeleteComponent, {
              data: {
                message:
                  err.error.message ||
                  'Something went wrong while getting students',
              },
              horizontalPosition: 'center',
              duration: helper.duration,
              panelClass: ['delete-snackBar'],
              verticalPosition: 'top',
            });
          },
        );
    }
  }

  searchStudent(item) {
    let param = `searchText=${item}`;
    if (this.branchId && this.branchId !== '') {
      param = param.concat(`&branch=${this.branchId}`);
    }
    if (this.gradeId && this.gradeId !== '') {
      param = param.concat(`&grade=${this.gradeId}`);
    }
    if (this.sectionId && this.sectionId !== '') {
      param = param.concat(`&section=${this.sectionId}`);
    }

    this.studentProfileService
      .getStudentProfilesForSearchFilter(param)
      .subscribe((res: any) => {
        this.studentsHash = res.data;
        this.isStudentSearched = true;
      });
  }

  selectStudent(student) {
    this.studentId = student;
    const studentObj = this.studentsHash.filter((item) => student == item.id);
    if (this.isStudentSearched) {
      this.branchId = studentObj[0].branch;
      this.gradeId = studentObj[0].grade;
      this.sectionId = studentObj[0].section;
      this.selectBranchNew(this.branchId, true);
    }
  }

  onChecklistChange(ev, item) {
    //visible to checkbox
    if (ev == true) {
      this.visibleTo.push(item.id);
    } else {
      for (let i = 0; i < this.visibleTo.length; i++) {
        if (this.visibleTo[i] === item.id) {
          this.visibleTo.splice(i, 1);
        }
      }
    }
  }
  selectBranchNew(ev, IsSelectedAuto) {
    this.gradesArray = [];
    this.sectionArray = [];
    this.studentsHash = [];
    this.branchId = ev;
    const gradesArray = this.allClasses.filter(
      (element) => element.branch.id == ev,
    );
    for (let i = 0; i < gradesArray.length; i++) {
      this.gradesArray.push(gradesArray[i].grade);
    }
    if (!IsSelectedAuto) {
      this.isStudentSearched = false;
      this.form.get('grade').reset();
      this.form.get('section').reset();
      this.form.get('student').reset();
    } else {
      this.form.patchValue({
        branch: this.branchId,
      });
      this.selectGradeNew(this.gradeId, true);
    }
  }

  selectGradeNew(ev, IsSelectedAuto) {
    this.sectionArray = [];
    this.studentsHash = [];
    this.gradeId = ev;
    const sectionsArray = this.allClasses.filter(
      (element) => element.branch.id == this.branchId && element.grade.id == ev,
    );
    for (let i = 0; i < sectionsArray.length; i++) {
      for (let y = 0; y < sectionsArray[i].sections.length; y++) {
        this.sectionArray.push(sectionsArray[i].sections[y].section);
      }
    }
    if (!IsSelectedAuto) {
      this.form.get('section').reset();
      this.form.get('student').reset();
    } else {
      this.form.patchValue({
        grade: this.gradeId,
      });
      this.selectedSectionNew(this.sectionId, true);
    }
  }

  selectedSectionNew(ev, IsSelectedAuto) {
    if (ev == undefined) return;
    this.studentsHash = [];
    this.sectionId = ev;
    if (!IsSelectedAuto) {
      this.form.get('student').reset();
    } else {
      this.form.patchValue({
        section: this.sectionId,
      });
    }
    this.getStudents(this.branchId, this.gradeId, this.sectionId);
  }
}
