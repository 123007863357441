import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'countByValue',
  pure: false,
})
export class CountByValuePipe implements PipeTransform {
  transform(items: any[], key: string, value: any): any {
    let count = 0;
    if (!items || !key || value === undefined) return count;

    items.forEach((item) => {
      if (item[key] === value) count++;
    });
    return count;
  }
}
