import { Component, Input } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { PreviewDocumentModalComponent } from '../preview-document-modal/preview-document-modal.component';

@Component({
  selector: 'app-policy-card',
  templateUrl: './policy-card.component.html',
  styleUrls: ['./policy-card.component.scss'],
})
export class PolicyCardComponent {
  @Input() policyCategory;

  constructor(public _snackBar: MatSnackBar, public dialog: MatDialog) {}

  async openPreviewDialog(item) {
    this.dialog.open(PreviewDocumentModalComponent, {
      panelClass: '',
      data: {
        pdf: item.pdfSource,
        title: item.name,
        id: item,
        showEditBtn: false,
        hideDownloadButton: true,
      },
    });
  }
}
