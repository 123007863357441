import * as moment from 'moment';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-timetableview',
  templateUrl: './timetableview.component.html',
  styleUrls: ['./timetableview.component.scss'],
})
export class TimetableviewComponent {
  @Input() section;
  @Input() teacherProfileId;
  @Input() studentId: string;
  @Input() structureTimetableData: any;
  @Input() flexibleTimetableData: any;
  StartTime = 8;
  EndTime = 20;
  startnewTime = new Date();
  endnewTime = new Date();
  timetable = [];
  daysLength = 0;
  children;
  @Input() roomId = '';
  gridBoxes: number[] = Array.from({
    length: this.EndTime - this.StartTime,
  });
  parentId;
  timeTable = [];
  teacherGrades = [];
  loaderMessage = 'Loading...';
  loading = false;
  loadingFlexibleTimetable = false;
  showTimeTable = false;

  data = [];
  timeChunks = [];
  timeSlots = [];
  filterdTimetable: any;

  ngOnInit() {
    const result = this.calculateMinMaxTime(this.flexibleTimetableData);
    const sStartTime = new Date(result.minStartTime).getHours() || 8;
    const eEndTime = new Date(result.maxEndTime).getHours() || 20;
    this.StartTime = sStartTime;
    this.EndTime = eEndTime;

    this.initTimeSlots(sStartTime, eEndTime);

    if (this.flexibleTimetableData) {
      const filteredPeriods = this.filterResponse(this.flexibleTimetableData);
      this.filterdTimetable = filteredPeriods;
      this.daysLength = filteredPeriods.length;
      this.gridBoxes = Array.from({
        length: this.EndTime - this.StartTime,
      });
    }
  }
  private filterResponse(response): any[] {
    const filteredDays = response.map((day) => {
      const periodsWithTopKey = day.periods.map((period) => {
        const hoursPixels = this.getHours(period);
        const height =
          this.calculateMinutesFromStart(period.endTime) -
          this.calculateMinutesFromStart(period.startTime);
        const updatedPeriod = {
          ...period,
          height: height * 3.2,
          top: `${hoursPixels}px`,
        };
        return updatedPeriod;
      });
      return { ...day, periods: periodsWithTopKey };
    });
    return filteredDays.filter((day) => day.periods.length > 0);
  }
  calculateMinMaxTime(data: any[]): {
    minStartTime: string;
    maxEndTime: string;
  } {
    let minStartTime = Infinity;
    let maxEndTime = -Infinity;

    data &&
      data.forEach((entry) => {
        const periods = entry.periods;

        periods.forEach((period) => {
          const startTime = new Date(period.startTime).getTime();
          const endTime = new Date(period.endTime).getTime();

          if (startTime < minStartTime) {
            minStartTime = startTime;
          }

          if (endTime > maxEndTime) {
            maxEndTime = endTime;
          }
        });
      });

    const result: any = {
      minStartTime: this.isValidDate(minStartTime)
        ? new Date(minStartTime).toISOString()
        : new Date().setHours(8, 0, 0, 0).toString(),
      maxEndTime: this.isValidDate(maxEndTime)
        ? new Date(maxEndTime).toISOString()
        : new Date().setHours(20, 0, 0, 0).toString(),
    };

    return result;
  }

  isValidDate(date: any): boolean {
    return !isNaN(new Date(date).getTime());
  }
  getHours(p) {
    const startHour = new Date(p.startTime).getHours();
    const startMinutes = new Date(p.startTime).getMinutes();
    const hoursPixels = (startHour - this.StartTime) * 192;
    const minutesPixels = startMinutes * 3.2;
    return hoursPixels + minutesPixels;
  }

  initTimeSlots(startHour, endHour): void {
    const startTime = startHour;
    const endTime = endHour;
    const interval = 30;
    this.timeSlots = this.generateTimeSlots(startTime, endTime, interval);
  }

  private calculateMinutesFromStart(timeString) {
    const time = moment(timeString);
    const hours = time.hours();
    const minutes = time.minutes();
    const periodMinutes = hours * 60 + minutes;
    const startMinutes = 8 * 60;
    return periodMinutes - startMinutes;
  }
  private generateTimeSlots(startTime, endTime, interval): string[] {
    const timeSlots = [];
    const currentTime = new Date();
    currentTime.setHours(startTime, 0, 0, 0);

    while (currentTime.getHours() < endTime) {
      timeSlots.push(
        currentTime.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        }),
      );
      currentTime.setMinutes(currentTime.getMinutes() + interval);
    }

    timeSlots.push(
      currentTime.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      }),
    );

    return timeSlots;
  }

  selectGrade(event) {
    const selectedGrade = event.target.value;
    this.structureTimetableData.forEach((e) => {
      if (e.grade._id == selectedGrade) {
        this.timeTable = e.structure;
      }
    });
  }
}
