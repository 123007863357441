import { GenericService } from '../generic/generic.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ResultAnnouncementService {
  private readonly controller = 'result-announcement-setup';
  constructor(private generic: GenericService) {}
  get() {
    return this.generic.getData(this.controller);
  }
  getByID(id) {
    return this.generic.getDataById(this.controller, id);
  }
  getByParams(param) {
    return this.generic.getData(this.controller + '?' + param);
  }
  getByGrade(gradeId) {
    return this.generic.getData(`${this.controller}/grade/${gradeId}`);
  }
  post(body) {
    return this.generic.create(this.controller, body);
  }
  update(body, id) {
    return this.generic.update(`${this.controller}/${id}`, body);
  }
  delete(id) {
    return this.generic.delete(this.controller, id);
  }
  search(search, filter, order) {
    return this.generic.getDataBySearch(this.controller, search, filter, order);
  }
}
