import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { DayViewComponent } from '../day-view/day-view.component';
import { EventAddModalComponent } from '../event-add-modal/event-add-modal.component';
import { EventService } from 'src/app/core';
import { EventSubjectService } from '../event-subject.service';
import { MonthViewComponent } from 'src/app/shared/components/shared-events/month-view/month-view.component';
import { Subscription } from 'rxjs';
import { WeekViewComponent } from '../week-view/week-view.component';

@Component({
  selector: 'app-event-main-dashboard',
  templateUrl: './event-main-dashboard.component.html',
  styleUrls: ['./event-main-dashboard.component.scss'],
})
export class EventMainDashboardComponent
  implements OnInit, OnChanges, OnDestroy
{
  @ViewChild(MonthViewComponent, { static: false })
  eventChildMonth: MonthViewComponent;
  @ViewChild(WeekViewComponent, { static: false })
  eventChildWeek: WeekViewComponent;
  @ViewChild(DayViewComponent, { static: false })
  eventChildDay: DayViewComponent;

  showdiv;
  show = 4;
  events = [];
  date = new Date();
  @Input() serviceName;
  currentService;
  currentTab = 'monthView';
  subscription: Subscription;
  breadcrumb = [
    {
      label: 'Home /',
      url: '/admin/dashboard',
    },
    {
      label: 'Communication /',
      url: '/admin/communication/communication-dashboard',
    },
    {
      label: ' Events',
      url: '',
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private event: EventService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private eventSubject: EventSubjectService,
  ) {}

  ngOnInit(): void {
    const userType = localStorage.getItem('user_type');
    if (userType == 'Parent') {
      this.breadcrumb = [
        {
          label: 'Home /',
          url: '/parent/home',
        },
        {
          label: '',
          url: '',
        },
        {
          label: 'Events',
          url: 'parent/events',
        },
      ];
    }
    if (userType == 'Student') {
      this.breadcrumb = [
        {
          label: 'Home /',
          url: '/student/home',
        },
        {
          label: '',
          url: '',
        },
        {
          label: 'Events',
          url: 'student/events',
        },
      ];
    }
    this.subscription = this.event.notifyObservable$.subscribe((res) => {
      if (res.option == 'refresh-event') {
        this.event.notifyOther({
          option: res.option + '-' + this.currentTab,
        });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.serviceName != undefined) {
      this.currentService = changes.serviceName.currentValue;
    }
  }

  openDialog() {
    let permission = {};
    this.route.data.subscribe((data) => (permission = data));
    const dialogRef = this.dialog.open(EventAddModalComponent, {
      data: { id: null, state: 'Add', routeData: permission },
      panelClass: 'event-modal',
    });
    dialogRef.afterClosed().subscribe((e) => {
      if (e == 'success') {
        this.onButtonClick();
        this.event.notifyOther({
          option: 'refresh-event',
        });
      }
    });
  }

  onButtonClick(): void {
    if (this.currentTab == 'weekView') {
      this.eventChildWeek.emptyEventArray();
      this.eventChildWeek.getEvents();
    } else if (this.currentTab == 'dayView') {
      this.eventChildDay.emptyEventArray();
      this.eventChildDay.getEvents();
    }
  }

  day(): void {
    this.currentTab = 'dayView';
  }

  month(): void {
    this.currentTab = 'monthView';
  }

  week(): void {
    this.currentTab = 'weekView';
  }
  year(): void {
    this.currentTab = 'yearView';
  }

  option(i: unknown): void {
    if (i == this.showdiv) {
      this.showdiv = '';
    } else {
      this.showdiv = i;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.dialog.closeAll();
  }
}
