import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberParse',
})
export class NumberPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    return parseInt(value, 10);
  }
}
