import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-student-average-box-list',
  templateUrl: './student-average-box-list.component.html',
  styleUrls: ['./student-average-box-list.component.scss'],
})
export class StudentAverageBoxListComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
