import * as moment from 'moment';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeAgoOrDate',
  pure: false,
})
export class TimeAgoOrDatePipe implements PipeTransform {
  transform(date: string, format = 'MM/DD/YYYY'): string {
    if (!date) return '';
    if (moment(date).isSame(new Date(), 'day')) {
      return moment(date).fromNow();
    } else {
      return moment(date).format(format);
    }
  }
}
