import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-preview-media-modal',
  templateUrl: './preview-media-modal.component.html',
  styleUrls: ['./preview-media-modal.component.scss'],
})
export class PreviewMediaModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}
