import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { CustomSnackBarComponent } from '../../custom-snack-bar/custom-snack-bar.component';
import { CustomSnackbarDeleteComponent } from '../../custom-snackbar-delete/custom-snackbar-delete.component';
import { EventDetailModalComponent } from '../event-detail-modal/event-detail-modal.component';
import { EventService } from 'src/app/core';
import { ParentEventService } from 'src/app/core/services/parent/parent-events/parent-event.service';
import { StudentEventsService } from 'src/app/core/services/student/student-events/student-events.service';
import { UserType } from 'src/app/shared/enums-constant/user-type.enum';
import { helper } from '../../../helper-functions';

@Component({
  selector: 'app-event-detail-popup',
  templateUrl: './event-detail-popup.component.html',
  styleUrls: ['./event-detail-popup.component.scss'],
})
export class EventDetailPopupComponent implements OnInit {
  @Input() data;
  @Input() service;
  isAdmin = false;
  alreadyGoing: boolean;
  studentId = localStorage.getItem('studentId');
  event;
  userType;

  constructor(
    private eventStudentService: StudentEventsService,
    private eventParentService: ParentEventService,
    private eventService: EventService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    const userType = localStorage.getItem('user_type');
    if (userType === UserType.Admin || userType === UserType.Staff) {
      this.isAdmin = true;
    }
    this.eventInit();
    this.getUserType();
  }

  getUserType(): void {
    this.eventParentService.userTypeVisibility.subscribe((type) => {
      this.userType = type;
    });
  }

  async eventInit(): Promise<void> {
    await this.switchService(this.service.controller);
    await this.getEvent();
  }

  private switchService(mock: string) {
    if (mock === 'student') {
      this.service = this.eventStudentService;
    } else if (mock === 'events') {
      this.isAdmin = true;
      this.service = this.eventService;
    } else if (mock === 'parent') {
      this.service = this.eventParentService;
    } else {
      this.service = this.eventService;
    }
  }

  getEvent(): void {
    this.event = {
      id: this.data.id,
      title: this.data.title,
      fromDate: this.data.fromDate,
      toDate: this.data.toDate,
      fromTime: this.data.fromTime,
      toTime: this.data.toDate,
      image: this.data.image ? this.data.image : this.data.eventBanner,
      location: this.data.location
        ? this.data.location
        : this.data.eventLocation,
      description: this.data.description,
      capacity: this.data.capacity,
      eventBanner: this.data.url ? this.data.url : this.data.eventBanner.url,
      url: this.data.url ? this.data.url : this.data.eventBanner.url,
      isAllDayEvent: this.data.isAllDayEvent,
      color: this.data.color,
      rsvp: this.data.rsvp,
      attendees: this.data.attendees,
      attendCount: this.data.attendCount,
      branch: this.data.branch,
    };
  }

  setGoingYes(id: string): void {
    const eventId = id;

    const param = eventId;
    const body = {
      rsvp: true,
      studentId: this.eventParentService.selectedStudentId
        ? this.eventParentService.selectedStudentId
        : this.studentId,
    };
    this.alreadyGoing = true;
    this.service.postRsvp(param, body).subscribe(
      async (res) => {
        if (res.status === 'success') {
          this.snackBar.openFromComponent(CustomSnackBarComponent, {
            data: { message: 'The Event Response Recorded. successfully.' },
            horizontalPosition: 'center',
            duration: helper.duration,
            panelClass: ['save-snackBar'],
            verticalPosition: 'top',
          });
          await this.getEvent();
        }
      },
      (err) => {
        this.snackBar.openFromComponent(CustomSnackbarDeleteComponent, {
          data: { message: err.error.message },
          horizontalPosition: 'center',
          duration: helper.duration,
          panelClass: ['delete-snackBar'],
          verticalPosition: 'top',
        });
      },
    );
  }

  setGoingNo(id: string): void {
    const eventId = id;
    const param = eventId;
    const body = {
      rsvp: false,
      studentId: this.eventParentService.selectedStudentId
        ? this.eventParentService.selectedStudentId
        : this.studentId,
    };
    this.alreadyGoing = false;
    this.service.postRsvp(param, body).subscribe(
      async (res) => {
        if (res.status === 'success') {
          this.snackBar.openFromComponent(CustomSnackBarComponent, {
            data: { message: 'The Event Response Recorded. successfully.' },
            horizontalPosition: 'center',
            duration: helper.duration,
            panelClass: ['save-snackBar'],
            verticalPosition: 'top',
          });
          await this.getEvent();
        }
      },
      (err) => {
        this.snackBar.openFromComponent(CustomSnackbarDeleteComponent, {
          data: { message: err.error.message },
          horizontalPosition: 'center',
          duration: helper.duration,
          panelClass: ['delete-snackBar'],
          verticalPosition: 'top',
        });
      },
    );
  }

  openDetaiPopup(event): void {
    this.dialog.open(EventDetailModalComponent, {
      data: { eventDetail: this.data, service: this.service },
      panelClass: 'dialog-with-scroll',
    });
  }
}
