import { Moment } from 'moment';
import { country } from './country';
import { designation } from './designation';
import { generalSetup } from './general-setup';
import { status } from './status';
import { studentProfile } from './student-profile';

export class SudentParent {
  id?: string;
  lastLoginAt?: Moment;
  profileImage?: { url: string };
  fatherInformation?: {
    name?: string;
    countryCode: country;
    cell: string;
    email: string;
    cnic: string;
    businessType: generalSetup;
    company: string;
    officeCountryCode: country;
    officeCell: string;
    occupation: generalSetup;
    designation?: designation;
    highestQualification: string;
    university: string;
    otherInfo: string;
    address?: string;
    officeCompanyName?: string;
  };
  motherInformation?: {
    name?: string;
    countryCode: country;
    cell: string;
    email: string;
    cnic: string;
    businessType: generalSetup;
    company: string;
    officeCountryCode: country;
    officeCell: string;
    occupation: generalSetup;
    designation?: designation;
    highestQualification: string;
    university: string;
    otherInfo: string;
    address?: string;
    officeCompanyName?: string;
  };
  taxationInformation?: {
    relation: string;
    name?: string;
    email: string;
    cell: number;
    cnic: string;
    ntnNumber: number;
    countryCode: country;
    isFiler: boolean;
  };
  guardianInformation?: {
    relation: string;
    name?: string;
    cell: number;
    email: string;
    cnic: string;
    countryCode: country;
  };
  emergencyContact?: {
    relation: string;
    name?: string;
    cell: number;
    countryCode: country;
  };
  status?: status;
  StudentProfile?: {
    studentProfile: studentProfile;
  };
  employmentInformation?: {
    employeeId: string;
  };
  studentParent?: {
    studentParent: {
      id: string;
    };
  };
}
