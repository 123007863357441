import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BillingSetupServiceDataService {
  private notify = new Subject<any>();

  notifyObservable$ = this.notify.asObservable();

  constructor() {}

  public notifyOther(data: any) {
    if (data) {
      this.notify.next(data);
    }
  }
}
