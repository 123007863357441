import { Component, Input, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material';

@Component({
  selector: 'app-flexible-timetable-responsive',
  templateUrl: './flexible-timetable-responsive.component.html',
  styleUrls: ['./flexible-timetable-responsive.component.scss'],
})
export class FlexibleTimetableResponsiveComponent implements OnInit {
  @Input() data;
  selectedIndex;
  selectedDayFlexibleTimeTable;

  ngOnInit() {
    this.selectedDayFlexibleTimeTable = this.data[0];
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.selectedIndex = tabChangeEvent.index;
    this.selectedDayFlexibleTimeTable = this.data[this.selectedIndex];
  }
}
