import { Component, Input, OnInit } from '@angular/core';
import { InvoiceService } from 'src/app/core/services/billing/invoice/invoice.service';
import * as FileSaver from 'file-saver';
@Component({
  selector: 'app-billing-list-dashboard',
  templateUrl: './billing-list-dashboard.component.html',
  styleUrls: ['./billing-list-dashboard.component.scss'],
})
export class BillingListDashboardComponent implements OnInit {
  @Input() billing = [];
  loading = false;
  constructor(public invoiceService: InvoiceService) {}

  ngOnInit() {}

  ngOnChanges(changes) {}
  downloadInvoice(invoice) {
    this.loading = true;
    this.invoiceService
      .downloadInvoice(`invoiceId=${invoice.id ? invoice.id : invoice._id}`)
      .subscribe(
        (res: any) => {
          this.downloadPdf(res.data.pdfUrl);
          this.loading = false;
        },
        (error) => {
          this.loading = false;
        },
      );
  }

  downloadPdf(pdfUrl) {
    const pdfName = `invoice-${new Date().toISOString()}.pdf`;
    FileSaver.saveAs(pdfUrl, pdfName);
  }
}
