import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-academic-hold-to-bill-dashboard',
  templateUrl: './academic-hold-to-bill-dashboard.component.html',
  styleUrls: ['./academic-hold-to-bill-dashboard.component.scss'],
})
export class AcademicHoldToBillDashboardComponent {
  @Input() heading1: any;
  @Input() heading2: string;
  @Input() heading3: string;
  @Input() shortTextBit: boolean;

  @Input() shortText: string;
  @Input() bottomTextBit: boolean;

  @Input() bottomText: string;
  @Input() data: any;
  @Input() type: number;

  @Input() url: any;
}
