import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AssingnClassService,
  ExcelService,
  MeetingLogsService,
  PdfService,
} from 'src/app/core';
import { Component, Input, OnInit } from '@angular/core';
import { CustomSnackbarDeleteComponent, helper } from 'src/app/shared';
import { MatDialog, MatPaginatorIntl, MatSnackBar } from '@angular/material';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { UserType } from 'src/app/shared/enums-constant/user-type.enum';
import { ViewMeetingLogModalComponent } from 'src/app/shared/components/view-meeting-log-modal/view-meeting-log-modal.component';

@Component({
  selector: 'app-student-meeting-logs',
  templateUrl: './student-meeting-logs.component.html',
  styleUrls: ['./student-meeting-logs.component.scss'],
})
export class StudentMeetingLogsComponent implements OnInit {
  @Input() showTitle;
  title = 'Meeting Logs';
  btnText = 'Add Meeting Log';
  branches;
  actionText = 'Action';
  searchText = new FormControl('');
  showList = 1;
  apiData: any;
  totalRecord: any;
  tableValues = [];
  cardValues = [];
  slicedData = [];
  excelData = [];
  permission: any;
  pageSize = 12;
  page = 1;
  allClasses = [];
  breadcrumbs = [];
  show = 8;
  tagFilter = '';
  reportedById = '';
  selectedStudent;
  userType;
  currentYear = new Date().getFullYear();
  routeId;
  // Defined Header Names on this object
  headerItems = [
    {
      label: 'Image',
      rowWidth: ' d-none',
    },
    {
      label: 'Reported By',
      rowWidth: ' pl-3 col-md-3',
    },
    {
      label: 'Grade',
      rowWidth: 'pl-2 col-md-1',
    },
    {
      label: 'Section',
      rowWidth: ' col-md-1',
    },
    {
      label: 'Date',
      rowWidth: ' col-md-1',
    },
    {
      label: 'Time',
      rowWidth: ' col-md-1',
    },
    {
      label: 'Description',
      rowWidth: ' col-md-3',
    },
    {
      label: 'Actions',
      rowWidth: ' col-md-2',
    },
  ];

  // Through this array we are providing filters to filter popup
  actionItems = [
    {
      label: 'View',
      iconClass: 'visibility',
      action: (id) => this.openPreviewDialog(id),
    },
  ];

  //PDF Table
  tableHeadings = [
    'Reported By',
    'Grade',
    'Section',
    'Date',
    'Time',
    'Description',
  ];
  tableOptions = {
    pdfTitle: 'Meeting Logs',
    tableHeadingFontSize: 10,
    charcolColor: '#454b60',
    headerTextClr: '#fff',
    headerBackground: '#3c26bf',
    mainHeadingFontSize: 15,
    tableContentFontSize: 8,
    pageOrientation: 'portrait',
    pageType: 'A4',
    pageMargins: [40, 155, 40, 55],
    footerText:
      'Downloaded By:  ' +
      localStorage.getItem('firstName') +
      ' at' +
      ' ' +
      new Date().toLocaleString(),
    subTitle: `${this.title} list for the year of ${this.currentYear}-${
      this.currentYear + 1
    }`,
    pdfName: `meeting-logs-${new Date().getTime()}.pdf`,
    download: true,
  };

  constructor(
    public meetingLogService: MeetingLogsService,
    public router: Router,
    public assignClass: AssingnClassService,
    public _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private paginator: MatPaginatorIntl,
    private route: ActivatedRoute,
    private excelService: ExcelService,
    public pdfService: PdfService,
  ) {
    this.permission = helper.permissions(this.route.data);
    this.route.data.subscribe((routeData) => {
      this.breadcrumbs = routeData.breadcrumb;
    });
    this.paginator.itemsPerPageLabel = 'Rows per page:';
    this.permission = helper.permissions(route.data);
  }

  ngOnInit() {
    this.userType = localStorage.getItem('user_type');
    if (this.userType === UserType.Admin || this.userType === UserType.Staff) {
      this.breadcrumbs = [];
    }
    this.getBranches();
    this.route.params.subscribe((params) => {
      if (params['id']) this.routeId = params['id'];
      if (params['id'] && this.router.url.includes('details')) {
        this.reportedById = params['id'];
      }
    });
    this.searchText.valueChanges
      .pipe(debounceTime(100), distinctUntilChanged())
      .subscribe(() => {
        this.getMeetingLogs();
      });

    setTimeout(() => {
      this.getMeetingLogs();
    }, 300);
  }

  /**
   * Function to get meetingLog list with search filters
   * @param { string } type
   */
  getMeetingLogs(loadMore = false) {
    if (!this.searchText.value) {
      this.meetingLogService.isDataLoading = true;
    }
    let url = `page=${this.page}&limit=${this.pageSize}`;
    if (this.tagFilter) {
      url += `&tag=${this.tagFilter}`;
    }
    if (this.reportedById) {
      url += `&createdAt=${this.reportedById}`;
    }
    if (this.searchText.value) {
      url += `&search=${this.searchText.value}`;
    }
    if (this.routeId) {
      url += `&studentId=${this.routeId}`;
    }
    this.meetingLogService.getStudentMeetingLogs(url, 'desc').subscribe(
      (res: any) => {
        this.cardValues = loadMore
          ? [...this.cardValues, ...res.data]
          : res.data;
        const selectedLog = this.cardValues.find(
          (log) =>
            log.createdBy &&
            log.createdBy.id &&
            log.createdBy.id === this.reportedById,
        );
        if (selectedLog) {
          this.selectedStudent = selectedLog.createdBy;
        }
        this.totalRecord = res.pagination.totalRecords;
        this.slicedData = this.cardValues.map((item) => {
          return [
            //to map api data on generaic list columns
            {
              Image:
                item.student &&
                item.student.profileImage &&
                item.student.profileImage.url
                  ? item.student.profileImage.url
                  : '-',
              rowWidth: '',
              rowId: item.student.id,
            },
            {
              'Reported By':
                item.createdBy && item.createdBy.firstName != null
                  ? item.createdBy.firstName + ' ' + item.createdBy.lastName
                  : '-',
              rowWidth: ' col-md-3 max-width-280px',
              rowId: item.createdBy.id,
            },
            {
              Grade: item.grade && item.grade.length ? item.grade[0].name : '-',
              rowWidth: ' col-md-1',
              rowId: item.student.id,
            },
            {
              Section:
                item.section && item.section.length != null
                  ? item.section[0].name
                  : '-',
              rowWidth: ' pl-2 col-md-1',
              rowId: item.student.id,
            },
            {
              Date: item.date != null ? item.date : '-',
              rowWidth: 'pl-2 col-md-1',
              rowId: item.student.id,
            },
            {
              Time: item.date != null ? item.date : '-',
              rowWidth: 'pl-4 col-md-1',
              rowId: item.student.id,
            },
            {
              Description: item.description != null ? item.description : '-',
              rowWidth: 'pl-4 col-md-3',
              rowId: item.student.id,
            },
            {
              Actions: this.actionItems,
              rowWidth: ' pl-4 col-md-2',
              rowId: item._id,
            },
          ];
        });
        this.excelData = res.data.map((item) => {
          return {
            'Reported By':
              item.createdBy && item.createdBy.firstName != null
                ? item.createdBy.firstName + ' ' + item.createdBy.lastName
                : '-',
            Grade: item.grade && item.grade.length ? item.grade[0].name : '-',
            Section:
              item.section && item.section.length != null
                ? item.section[0].name
                : '-',
            Date: item.date != null ? moment(item.date).format('ll') : '-',
            Time: item.date != null ? moment(item.date).format('h:mm a') : '-',
            Description: item.description != null ? item.description : '-',
          };
        });
        this.tableValues = this.slicedData.slice(0, this.pageSize);
        this.cardValues.forEach((item) => {
          let reportedPerson = {};
          if (
            this.userType === UserType.Admin ||
            this.userType === UserType.Staff
          ) {
            reportedPerson = {
              id: item.student.id,
              name: `${item.student.firstName} ${item.student.lastName}`,
              profileImageUrl: `${item.student.profileImage.url}`,
            };
          } else if (
            this.userType === UserType.Student ||
            this.userType === UserType.Parent
          ) {
            reportedPerson = {
              id: item.createdBy.id,
              name: `${item.createdBy.firstName} ${item.createdBy.lastName}`,
              profileImageUrl: `${
                item.createdBy.profileThumbnail &&
                item.createdBy.profileThumbnail.url
              }`,
            };
          }
          item.reportedPerson = reportedPerson;
        });
        this.meetingLogService.isDataLoading = false;
      },
      (err) => {
        this._snackBar.openFromComponent(CustomSnackbarDeleteComponent, {
          data: {
            message: err.message || 'Error in getting meetiong logs',
          },
          horizontalPosition: 'center',
          duration: helper.duration,
          panelClass: ['delete-snackBar'],
          verticalPosition: 'top',
        });
        this.meetingLogService.isDataLoading = false;
      },
    );
  }

  selectTag(selectedTag) {
    this.tagFilter = selectedTag;
    this.getMeetingLogs();
  }

  /**
   * clear Search Filter input
   * @param event
   */
  public clearSearchFilter(event: any): void {
    event.stopPropagation();
    this.searchText.setValue('');
  }

  /**
   * Function to get meetingLog list with search filters
   * @param { string } id
   */
  public getStudentLogs(id): void {
    let redirectURL = '';
    if (this.userType === UserType.Admin || this.userType === UserType.Staff) {
      redirectURL = `/admin/communication/meeting-logs/details/${id}`;
    } else if (this.userType === UserType.Parent) {
      redirectURL = `/parent/meeting-logs/details/${id}`;
    } else if (this.userType === UserType.Student) {
      redirectURL = `/student/meeting-logs/details/${id}`;
    }
    this.router.navigateByUrl(redirectURL);
  }

  /**
   * Function to get branches for search filter
   */
  public getBranches(): void {
    //get branches through assign class
    this.assignClass.get().subscribe((res: any) => {
      this.allClasses = res.data.list;

      const branchArray = [];
      for (let i = 0; i < res.data.list.length; i++) {
        branchArray.push(res.data.list[i].branch);
      }
      const seen = new Set();
      branchArray.filter((el) => {
        const duplicate = seen.has(el.id);
        seen.add(el.id);
        return !duplicate;
      });
    });
  }

  openPreviewDialog(id) {
    const log = this.cardValues.find((val) => val.id === id);
    this.dialog.open(ViewMeetingLogModalComponent, {
      panelClass: '',
      width: '800px',
      data: {
        log,
      },
    });
  }

  // Changing modes from list to card Or from Card to List
  changeDisplay(mode: number): void {
    this.showList = mode;
  }

  // Handeling Pagination Arrow Buttons
  onPageChange($event) {
    this.page = $event.pageIndex + 1;
    this.pageSize = $event.pageSize;
    this.getMeetingLogs();
  }

  loadMoreMeetingLogs() {
    if (this.cardValues.length < this.totalRecord) {
      this.page = this.page + 1;
      this.getMeetingLogs(true);
    }
  }

  //Invoking excel service, and giving parameters to excel method
  exportexcel(): void {
    const outerArray = [];
    const sendBody = [];
    const newArray = JSON.parse(JSON.stringify(this.excelData));
    for (let i = 0; i < newArray.length; i++) {
      const innerArray = [];
      for (const k in newArray[i]) {
        delete newArray[i].col1;
        delete newArray[i].colImg;
        innerArray.push(newArray[i][k]);
      }
      outerArray.push(innerArray);
    }
    outerArray.filter(function (x) {
      const newArra = x.filter((y) => {
        return y !== undefined;
      });
      sendBody.push(newArra);
    });

    const schoolName =
      localStorage.getItem('schoolName') != 'undefined'
        ? localStorage.getItem('schoolName') + '-'
        : '';
    const subTitleForExcel = `${this.title} list for the year of ${
      this.currentYear
    }-${this.currentYear + 1}`;
    const footerText =
      'Downloaded By:  ' +
      localStorage.getItem('firstName') +
      ' at' +
      ' ' +
      new Date().toLocaleString();
    const pdfName = schoolName + this.title;
    this.excelService.generateExcel(
      subTitleForExcel,
      this.tableHeadings,
      sendBody,
      pdfName,
      footerText,
    );
  }

  /**
   * Function to create pdf table to download circular records
   */
  exportPdf() {
    const heading = [];
    const values = [];
    const body = [];
    this.tableHeadings.forEach((item) => {
      heading.push({
        text: item,
        style: 'tableHeader',
      });
    });

    let newArray = JSON.parse(JSON.stringify(this.excelData));

    newArray = newArray.filter(function (obj) {
      delete obj.Image;
      return newArray;
    });
    for (const item of newArray) {
      for (const k in item) if (!item[k]) delete item[k];
    }

    newArray.forEach((item) => {
      values.push(Object.values(item));
    });
    body.push(heading);
    for (let i = 0; i < values.length; i++) {
      const element = values[i];
      body.push(element);
    }
    this.tableOptions.download = true;

    this.pdfService.generatePdfTable(
      this.tableOptions.pdfTitle,
      this.tableOptions.subTitle,
      body,
      this.tableOptions,
    );
  }

  elementById(index, element) {
    return element.col1;
  }
}
