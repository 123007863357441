import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-board',
  templateUrl: './progress-board.component.html',
  styleUrls: ['./progress-board.component.scss'],
})
export class ProgressBoardComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
