import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { apiURL, configRoute, token } from 'src/app/config/config-route';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AccountSuspensionService {
  apiUrl: any;
  tokenId: any;
  constructor(public http: HttpClient) {
    this.apiUrl = environment.apiBaseUrl; // config apiUrl
    this.tokenId = localStorage.getItem('token'); // config token
  }

  //get all grades ..
  getGrades() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-access-token': this.tokenId,
    });
    const httpOptions = {
      headers: headers,
    };
    const url = this.apiUrl + configRoute.grades;
    console.log('---api url', url);

    return this.http.get<any>(url, httpOptions).pipe(
      map((response) => {
        console.log('res--', response);
        return response;
      }),
      catchError((err, caught) => {
        console.error('---err', err);
        throw err;
      }),
    );
  }

  //get grades by branch Id
  getGradeByBranchId(id: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-access-token': this.tokenId,
    });
    const httpOptions = {
      headers: headers,
      params: new HttpParams({
        fromString: `branch=${id}`,
      }),
    };
    console.log(httpOptions);
    const url = this.apiUrl + configRoute.gradesByBranch;
    console.log('---api url', url);

    return this.http.get<any>(url, httpOptions).pipe(
      map((response) => {
        console.log('res--', response);
        return response;
      }),
      catchError((err, caught) => {
        console.error('---err', err);
        throw err;
      }),
    );
  }
}
