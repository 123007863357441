import { GenericService } from '../generic/generic.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class InvitationService {
  controller = 'invite';
  constructor(private generic: GenericService) {}

  get() {
    return this.generic.getData(this.controller);
  }

  getByParams(param) {
    return this.generic.getData(this.controller + '?' + param);
  }

  post(url, body) {
    return this.generic.create(`${this.controller}/${url}`, body);
  }

  delete(id) {
    return this.generic.delete(this.controller, id);
  }

  update(id, body) {
    return this.generic.update(`${this.controller}/${id}`, body);
  }

  search(search, filter, order) {
    return this.generic.getDataBySearch(this.controller, search, filter, order);
  }
}
