import * as moment from 'moment';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-teacher-timetable-view',
  templateUrl: './teacher-timetable-view.component.html',
  styleUrls: ['./teacher-timetable-view.component.scss'],
})
export class TeacherTimetableViewComponent implements OnInit, OnChanges {
  @Input() branch;
  @Input() grade;
  @Input() section;
  @Input() teacher;
  @Input() teacherProfileId;
  @Input() isFrom: string;
  @Input() changeTeacher: boolean;
  timetable = [];

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('teacherTimeTable') set setTimeTable(value: any) {
    this.timetableData = value;
  }
  @Input() roomId = '';
  daysLength = 0;

  timetableData;
  loader = false;
  loaderMessage = 'Loading...';
  loading = false;
  selectedPeriods = [];
  timeChunks = [];
  timeSlots = [];
  startnewTime = new Date();
  endnewTime = new Date();
  StartTime = 8;
  EndTime = 20;
  filterdTimetable: any;
  gridBoxes: number[] = Array.from({
    length: this.EndTime - this.StartTime,
  });

  ngOnInit() {
    if (this.timetableData.type == 'flexible') {
      const result = this.calculateMinMaxTime(this.timetableData.days);
      const sStartTime = new Date(result.minStartTime).getHours() || 8;
      const eEndTime = new Date(result.maxEndTime).getHours() || 20;
      this.StartTime = sStartTime;
      this.EndTime = eEndTime;

      this.initTimeSlots(sStartTime, eEndTime);
      const filteredPeriods = this.filterResponse(this.timetableData);
      this.timetable = filteredPeriods;
      this.daysLength = filteredPeriods.length;
      this.gridBoxes = Array.from({
        length: this.EndTime - this.StartTime,
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.timetableData.type == 'flexible') {
      const result = this.calculateMinMaxTime(this.timetableData.days);
      const sStartTime = new Date(result.minStartTime).getHours() || 8;
      const eEndTime = new Date(result.maxEndTime).getHours() || 20;
      this.StartTime = sStartTime;
      this.EndTime = eEndTime;

      this.initTimeSlots(sStartTime, eEndTime);

      this.gridBoxes = Array.from({
        length: this.EndTime - this.StartTime,
      });
      const filteredPeriods = this.filterResponse(this.timetableData);
      this.timetable = filteredPeriods;
      this.daysLength = filteredPeriods.length;
    }
  }
  calculateMinMaxTime(data: any[]): {
    minStartTime: string;
    maxEndTime: string;
  } {
    let minStartTime = Infinity;
    let maxEndTime = -Infinity;

    data &&
      data.forEach((entry) => {
        const periods = entry.periods;

        periods.forEach((period) => {
          const startTime = new Date(period.startTime).getTime();
          const endTime = new Date(period.endTime).getTime();

          if (startTime < minStartTime) {
            minStartTime = startTime;
          }

          if (endTime > maxEndTime) {
            maxEndTime = endTime;
          }
        });
      });

    const result: any = {
      minStartTime: this.isValidDate(minStartTime)
        ? new Date(minStartTime).toISOString()
        : new Date().setHours(8, 0, 0, 0).toString(),
      maxEndTime: this.isValidDate(maxEndTime)
        ? new Date(maxEndTime).toISOString()
        : new Date().setHours(20, 0, 0, 0).toString(),
    };

    return result;
  }

  isValidDate(date: any): boolean {
    return !isNaN(new Date(date).getTime());
  }
  getHours(p) {
    const startHour = new Date(p.startTime).getHours();
    const startMinutes = new Date(p.startTime).getMinutes();
    const hoursPixels = (startHour - this.StartTime) * 192;
    const minutesPixels = startMinutes * 3.2;
    return hoursPixels + minutesPixels;
  }

  initTimeSlots(startHour, endHour): void {
    const startTime = startHour;
    const endTime = endHour;
    const interval = 30;
    this.timeSlots = this.generateTimeSlots(startTime, endTime, interval);
  }

  private calculateMinutesFromStart(timeString) {
    const time = moment(timeString);
    const hours = time.hours();
    const minutes = time.minutes();
    const periodMinutes = hours * 60 + minutes;
    const startMinutes = 8 * 60;
    return periodMinutes - startMinutes;
  }
  private generateTimeSlots(startTime, endTime, interval): string[] {
    const timeSlots = [];
    const currentTime = new Date();
    currentTime.setHours(startTime, 0, 0, 0);

    while (currentTime.getHours() < endTime) {
      timeSlots.push(
        currentTime.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        }),
      );
      currentTime.setMinutes(currentTime.getMinutes() + interval);
    }

    timeSlots.push(
      currentTime.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      }),
    );

    return timeSlots;
  }

  private filterResponse(response): any[] {
    const filteredDays = response.days.map((day) => {
      const periodsWithTopKey = day.periods.map((period) => {
        const hoursPixels = this.getHours(period);
        const height =
          this.calculateMinutesFromStart(period.endTime) -
          this.calculateMinutesFromStart(period.startTime);
        const updatedPeriod = {
          ...period,
          height: height * 3.2,
          top: `${hoursPixels}px`,
        };
        return updatedPeriod;
      });
      return { ...day, periods: periodsWithTopKey };
    });
    return filteredDays.filter((day) => day.periods.length > 0);
  }

  selectPeriod(e, p, selectedTeacher, secondaryTeacher) {
    e.stopPropagation();
    if (!this.changeTeacher) return;
    if (p.assign.length == 0) return;
    if (!selectedTeacher == undefined || !secondaryTeacher == undefined) return;
    p['isSelected'] = !p['isSelected'];
    this.selectedPeriods.push(p._id);
  }

  removePeriod(p) {
    if (!p['isSelected']) return;
    p['isSelected'] = false;
    const index = this.selectedPeriods.indexOf(p._id);
    this.selectedPeriods.splice(index, 1);
  }
}
