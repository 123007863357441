import { GenericService } from '../generic/generic.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CurriculumSettingsService {
  private readonly controller = 'curriculum';
  private curriculumId: string;
  constructor(private generic: GenericService) {}

  getGradebookSetting(curriculumId) {
    return this.generic.getData(`${this.controller}/${curriculumId}/setting`);
  }

  updateGradebookSetting(body, curriculumId) {
    return this.generic.update(
      `${this.controller}/${curriculumId}/grade-book-setting`,
      body,
    );
  }

  // Assignment Groups
  getAssignmentGroups(curriculumId) {
    return this.generic.getData(
      `${this.controller}/${curriculumId}/assignment-group`,
    );
  }

  createAssignmentGroup(body, curriculumId) {
    return this.generic.create(
      `${this.controller}/${curriculumId}/assignment-group`,
      body,
    );
  }

  updateAssignmentGroup(body, curriculumId, id) {
    return this.generic.update(
      `${this.controller}/${curriculumId}/assignment-group/${id}`,
      body,
    );
  }

  deleteAssignmentGroup(curriculumId, id) {
    return this.generic.deleteWithOutId(
      `${this.controller}/${curriculumId}/assignment-group/${id}`,
    );
  }

  // update standard setting
  updateStandardSetting(body, curriculumId) {
    return this.generic.update(
      `${this.controller}/${curriculumId}/standard-setting`,
      body,
    );
  }
}
