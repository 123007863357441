import { GenericService } from '../generic/generic.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SectionService {
  controller = 'sections';
  constructor(private generic: GenericService) {}
  get() {
    //getting data from service in this method and showing it on list
    return this.generic.getData(this.controller);
  }
  getByParams(param) {
    const order = 'asc';
    const sort = 'name';
    return this.generic.getDataByParams(this.controller, param, order, sort);
  }
  post(body) {
    return this.generic.create(this.controller, body);
  }

  getByID(id) {
    return this.generic.getDataById(this.controller, id);
  }

  delete(id) {
    //This method is used to delete record from database and it fires a snackbar on successful delete
    return this.generic.delete(this.controller, id);
  }
  update(body) {
    return this.generic.update(this.controller, body);
  }
  search(search, filter, order) {
    return this.generic.getDataBySearch(this.controller, search, filter, order);
  }
}
