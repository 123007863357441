import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lms-cards',
  templateUrl: './lms-cards.component.html',
  styleUrls: ['./lms-cards.component.scss'],
})
export class LmsCardsComponent implements OnInit {
  subjects = [
    'Maths',
    'English',
    'Urdu',
    'Pak Studies',
    'Computer',
    'Arts',
    'Drawing',
  ];
  constructor() {}

  ngOnInit() {}
}
