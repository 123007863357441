import { Injectable } from '@angular/core';
import { getFromLocalStorage } from 'src/app/shared';
import { GenericService } from '../../generic/generic.service';

@Injectable({
  providedIn: 'root',
})
export class IndividualBillingService {
  controller = 'billing/individuals';
  academicYear = '';

  constructor(private generic: GenericService) {
    this.academicYear = getFromLocalStorage.currentAcademicYearID();
  }

  post(body) {
    return this.generic.create(this.controller, body);
  }
  put(body) {
    return this.generic.update(this.controller, body);
  }
  get() {
    return this.generic.getData(this.controller);
  }

  search(search, filter, order) {
    return this.generic.getDataBySearch(this.controller, search, filter, order);
  }

  getBillingList(params, order) {
    return this.generic.getDataByParams(
      this.controller + '/list',
      params,
      order,
    );
  }

  getbyParams(params) {
    var order = 'asc';
    return this.generic.getDataByParams(this.controller, params, order);
  }

  getGradeCountbyParams(params) {
    var order = 'asc';
    return this.generic.getDataByParams(
      this.controller + '/summary',
      params,
      order,
    );
  }

  getIndividualSummary(body) {
    return this.generic.create(`${this.controller}/feesummary`, body);
  }

  getSummaryByID(id) {
    return this.generic.getDataById(`billing/bulk/bulkSummary`, id);
  }

  publishBills(body) {
    return this.generic.create(this.controller + `/postBilling`, body);
  }
}
