import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HasPermissionDirective } from './permission.directive';
import { NumbersOnlyDirective } from './numbers-only.directive';
import { RestrictScreenDirective } from './restrict-screen.directive';
import { ValidateMarksDirective } from './validate-marks.directive';

@NgModule({
  declarations: [
    HasPermissionDirective,
    NumbersOnlyDirective,
    ValidateMarksDirective,
    RestrictScreenDirective,
  ],
  imports: [],
  exports: [
    HasPermissionDirective,
    NumbersOnlyDirective,
    ValidateMarksDirective,
    RestrictScreenDirective,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CustomDirectiveModule {}
