import { GenericService } from '../generic/generic.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AssignTermService {
  constructor(private generic: GenericService) {}

  controller = 'assignterms';

  get() {
    return this.generic.getData(this.controller);
  }

  getByParams(param) {
    return this.generic.getData(this.controller + '?' + param);
  }

  post(body) {
    return this.generic.create(this.controller, body);
  }

  getByID(id) {
    return this.generic.getDataById(this.controller, id);
  }
  delete(id) {
    return this.generic.delete(this.controller, id);
  }
  update(body) {
    return this.generic.update(this.controller, body);
  }
  search(search, filter, order) {
    return this.generic.getDataBySearch(this.controller, search, filter, order);
  }

  getByAcademicYear(academicYearId) {
    return this.generic.getData(
      `${this.controller}/academicYearId/${academicYearId}`,
    );
  }
}
