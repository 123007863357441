import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { CustomSnackbarDeleteComponent } from 'src/app/shared/components/custom-snackbar-delete/custom-snackbar-delete.component';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material';
import { UserType } from 'src/app/shared/enums-constant/user-type.enum';
import { helper } from 'src/app/shared/helper-functions/helper';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  constructor(
    private http: HttpClient,
    public auth: AuthService,
    public router: Router,
    private _snackBar: MatSnackBar,
    private _location: Location,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    if (!this.auth.isAuthenticated()) {
      localStorage.clear();
      localStorage.setItem('redirectUrl', state.url);
      this.router.navigate(['login']);
      return false;
    }
    this.permissions(route.data);

    return true;
  }

  /* permission w.r.t role*/
  permissions(routeDat) {
    const userType = localStorage.getItem('user_type');
    if (userType !== UserType.Admin && userType !== UserType.SU) {
      const routeData = routeDat;
      if (
        routeData.module != 'home' &&
        routeData.module != 'profile' &&
        routeData.module != undefined
      ) {
        this.checkPermissions(routeData);
      }
    }
  }
  /* permission w.r.t features/module */
  checkPermissions(routeData) {
    const isAllowed = this.auth.checkPermissions(routeData);
    if (!isAllowed) {
      this._snackBar.openFromComponent(CustomSnackbarDeleteComponent, {
        data: { message: "You don't have permission to access this page" },
        horizontalPosition: 'center',
        duration: helper.duration,
        panelClass: ['delete-snackBar'],
        verticalPosition: 'top',
      });
      this.router.navigate(['/']);
    }
  }
}
