import * as moment from 'moment';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DatePipe } from '@angular/common';
import { EventAddModalComponent } from '../event-add-modal/event-add-modal.component';
import { EventService } from 'src/app/core';
import { EventSubjectService } from '../event-subject.service';
import { MatDialog } from '@angular/material';
import { ParentEventService } from 'src/app/core/services/parent/parent-events/parent-event.service';
import { StudentEventsService } from 'src/app/core/services/student/student-events/student-events.service';
import { TeacherUserEventService } from 'src/app/core/services/teacher-user/teacher-user-event.service';

const DAY_MS = 60 * 60 * 24 * 1000;

@Component({
  selector: 'app-month-year-view-shared',
  templateUrl: './month-year-view.component.html',
  styleUrls: ['./month-year-view.component.scss'],
  providers: [DatePipe],
})
export class MonthYearViewComponent implements OnInit {
  @Input() monthGet: any;
  subscription$;
  dates: Array<Date>;
  showdiv;
  year;
  month;
  days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
  date = new Date();
  currentYear;
  highlightEvent = [];
  @Output() selected = new EventEmitter();
  eventArray = [];
  selectedDate = new Date();
  numberIndexes = [];
  y: any;
  m: any;
  firstDay: any;
  lastDay: any;
  studentId = '';
  @Input() service: any;
  toDayEvents = [];

  constructor(
    public datePipe: DatePipe,
    public dialog: MatDialog,
    private eventService: EventService,
    private eventSubject: EventSubjectService,
    private eventStudentService: StudentEventsService,
    private eventParentService: ParentEventService,
    private eventTeacherUserService: TeacherUserEventService,
  ) {}

  eventDate = new Date();

  ngOnInit() {
    this.y = this.date.getFullYear();
    this.m = this.date.getMonth();
    this.firstDay = new Date(this.y, this.m, 1);
    this.lastDay = new Date(this.y, this.m + 1, 0);

    this.currentYear = this.datePipe.transform(this.date, 'y');
    this.subscription$ = this.eventSubject.getData().subscribe((res: any) => {
      if (res) {
        this.year = res;
        this.date = new Date(this.date.setFullYear(this.year, this.monthGet));
        this.dates = this.getCalendarDays(this.date);
        this.getEvents();
      }
    });
    if (this.year !== undefined) {
      this.date = new Date(this.date.setFullYear(this.year, this.monthGet));

      this.dates = this.getCalendarDays(this.date);
    } else {
      this.date = new Date(
        this.date.setFullYear(this.currentYear, this.monthGet),
      );

      this.dates = this.getCalendarDays(this.date);
    }

    this.eventInit();
  }

  async eventInit() {
    this.switchService(this.service, () => {
      this.getEvents();
    });
  }

  private switchService(mock: string, cb: () => void) {
    if (mock === 'student') {
      this.service = this.eventStudentService;
    } else if (mock === 'admin') {
      this.service = this.eventService;
    } else if (mock === 'parent') {
      this.service = this.eventParentService;
    } else if (mock === 'teacher') {
      this.service = this.eventTeacherUserService;
    } else {
      this.service = this.eventService;
    }
    cb();
  }

  ngOnDestroy(): void {
    this.eventSubject.setData(this.currentYear);
    this.subscription$.complete();
  }

  setMonth(inc) {
    this.date = new Date(this.year, this.month + inc, 1);
    this.dates = this.getCalendarDays(this.date);
  }

  isSameMonth(date) {
    return date.getMonth() === this.date.getMonth();
  }

  private getCalendarDays(date = new Date()) {
    const calendarStartTime = this.getCalendarStartDay(date).getTime();

    return this.range(0, 41).map(
      (num) => new Date(calendarStartTime + DAY_MS * num),
    );
  }

  private getCalendarStartDay(date = new Date()) {
    const [year, month] = [date.getFullYear(), date.getMonth()];
    const firstDayOfMonth = new Date(year, month, 1).getTime();

    return this.range(1, 7)
      .map((num) => new Date(firstDayOfMonth - DAY_MS * num))
      .find((dt) => dt.getDay() === 0);
  }

  private range(start, end, length = end - start + 1) {
    return Array.from({ length }, (_, i) => start + i);
  }

  getEvents() {
    this.y = this.date.getFullYear();
    this.m = this.date.getMonth();
    this.firstDay = new Date(this.y, this.m, 1);
    //this.lastDay = new Date(this.y, this.m + 1, 0);

    const endOfMonth = moment(new Date(this.y, this.m))
      .endOf('month')
      .toISOString();

    this.lastDay = new Date(endOfMonth);

    this.studentId = this.studentId ? this.studentId : '';

    let url = '';
    // if (this.service.controller === 'events') {
    url =
      'fromDate=' +
      this.firstDay.toISOString() +
      '&toDate=' +
      this.lastDay.toISOString();
    // } else {
    //   url = 'studentId=' + this.studentId;
    // }

    if (this.service.getbyParams) {
      this.service.getbyParams(url).subscribe((res: any) => {
        this.eventArray = res.data;
      });
    }
  }

  openPopup(i, d) {
    // news action options
    if (i === this.showdiv) {
      this.showdiv = '';
      this.selectedDate = new Date();
    } else {
      this.showdiv = i;
      this.selectedDate = d;
    }
  }

  closePopup() {
    // console.log('cehck')
    // this.showdiv = '0'
  }

  openDetaiPopup(id) {
    this.dialog.open(EventAddModalComponent, {
      data: { id: id, state: 'View' },
      panelClass: 'event-modal',
    });
  }

  selectedMonthDate(d) {
    console.log(d);
  }

  isClassMonth(date) {
    for (let j = 0; j < this.eventArray.length; j++) {
      const fromDateMonthWise = moment(this.eventArray[j].fromDate).format(
        'DD/MM/YYYY',
      );
      const toDateMonthWise = moment(this.eventArray[j].toDate).format(
        'DD/MM/YYYY',
      );
      const dateTemp = new Date(date);
      const currentDateMonthWise = moment(dateTemp).format('DD/MM/YYYY');
      if (
        fromDateMonthWise == currentDateMonthWise ||
        (currentDateMonthWise > fromDateMonthWise &&
          currentDateMonthWise < toDateMonthWise) ||
        toDateMonthWise == currentDateMonthWise
      ) {
        return true;
      }
    }
    return false;
  }

  getTodayEvents(date) {
    this.clearEvents();
    for (let i = 0; i < this.eventArray.length; i++) {
      const fromDateMonthWise = moment(this.eventArray[i].fromDate).format(
        'DD/MM/YYYY',
      );
      const toDateMonthWise = moment(this.eventArray[i].toDate).format(
        'DD/MM/YYYY',
      );
      const currentDateMonthWise = moment(date).format('DD/MM/YYYY');

      if (
        fromDateMonthWise === currentDateMonthWise ||
        (currentDateMonthWise > fromDateMonthWise &&
          currentDateMonthWise < toDateMonthWise) ||
        toDateMonthWise === currentDateMonthWise
      ) {
        this.toDayEvents.push(this.eventArray[i]);
      }
    }
  }

  clearEvents() {
    this.toDayEvents = [];
  }
}
