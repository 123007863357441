import { GenericService } from '../generic/generic.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MeetingLogsService {
  controller = 'meeting-logs';
  isDataLoading = true;
  meetingTags = [
    {
      label: 'Academic Counseling',
      color: '#118000',
    },
    {
      label: 'Behaviour',
      color: '#FD0000',
    },
    {
      label: 'Parent Teacher Meeting',
      color: '#0081CC',
    },
    {
      label: 'Career Counseling',
      color: '#F708EF',
    },
    {
      label: 'Psychologist',
      color: '#FFC533',
    },
    {
      label: 'Other',
      color: '#8F7FE6',
    },
  ];

  constructor(private generic: GenericService) {}
  get() {
    //getting data from service in this method and showing it on list
    return this.generic.getData(this.controller);
  }
  post(body) {
    return this.generic.create(this.controller, body);
  }

  getByID(id) {
    return this.generic.getDataById(this.controller, id);
  }

  delete(id) {
    return this.generic.delete(this.controller, id);
  }
  update(body) {
    return this.generic.update(this.controller, body);
  }
  getbyParams(params) {
    const order = 'desc';
    return this.generic.getDataByParams(this.controller, params, order);
  }
  getStudentLogs(id) {
    return this.generic.getDataById(`${this.controller}/student`, id);
  }
  search(params = '', order = 'desc', sort = 'createdAt') {
    return this.generic.getDataByParams(this.controller, params, order, sort);
  }
  getStudentMeetingLogs(params = '', order = 'desc', sort = 'createdAt') {
    return this.generic.getDataByParams(
      `student/${this.controller}`,
      params,
      order,
      sort,
    );
  }
  getAllChildrenMeetingLogs(params = '', order = 'desc', sort = 'createdAt') {
    return this.generic.getDataByParams(
      `parent/${this.controller}`,
      params,
      order,
      sort,
    );
  }
  getMeetingLogByStudent(params = '', id, order = 'desc', sort = 'createdAt') {
    return this.generic.getDataByParams(
      `parent/${this.controller}/student/${id}`,
      params,
      order,
      sort,
    );
  }

  postMeetingLog(body) {
    return this.generic.create(this.controller, body);
  }

  updateMeetingLog(body) {
    return this.generic.update(this.controller, body);
  }

  getMeetingLogById(id) {
    return this.generic.getDataById(this.controller, id);
  }
}
