import { GenericService } from '../../generic/generic.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  controller = 'invoice';

  constructor(private generic: GenericService) {}

  get() {
    return this.generic.getData(this.controller);
  }

  delete(id) {
    return this.generic.delete(this.controller, id);
  }

  getInvoiceDetails(params) {
    const order = 'desc';
    return this.generic.getPdfByParams(
      `${this.controller}/preview`,
      params,
      order,
    );
  }

  downloadInvoice(params) {
    const order = 'desc';
    return this.generic.getDataByParams(
      `${this.controller}/download`,
      params,
      order,
    );
  }

  viewInvoice(params) {
    return this.generic.getDataByParams(
      `${this.controller}/generate-invoice`,
      params,
      '',
    );
  }

  updateInvoiceDates(id, body) {
    return this.generic.update(this.controller + '/' + id, body);
  }

  search(params) {
    return this.generic.getDataByParams(
      `${this.controller}/search`,
      params,
      '',
    );
  }

  getBatchList(params) {
    return this.generic.getDataByParams(
      `${this.controller}/batchList`,
      params,
      'asc',
    );
  }
}
