import { Component, Input, OnInit } from '@angular/core';
import { CustomSnackbarDeleteComponent } from '../custom-snackbar-delete/custom-snackbar-delete.component';
import { FormControl } from '@angular/forms';
import { LetterService } from 'src/app/core/services/letter/letter.service';
import { MatSnackBar } from '@angular/material';
import { StatusService } from 'src/app/core';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';
import { helper } from '../../helper-functions';

@Component({
  selector: 'app-student-profile-letter',
  templateUrl: './student-profile-letter.component.html',
  styleUrls: ['./student-profile-letter.component.scss'],
})
export class StudentProfileLetterComponent implements OnInit {
  @Input() userProfileId;
  lettersList = [];
  apiData = [];
  slicedData = [];
  skip = 0;
  allStatus = [];
  currentStatus = {};
  page = 1;
  pageSize = 12;
  searchText = new FormControl('');
  filterCategory = '';
  radioFilter = '';
  type = 'letter';
  totalShow = 0;
  showList = 0;
  constructor(
    private letterService: LetterService,
    private statusService: StatusService,
    private _snackBar: MatSnackBar,
  ) {}
  ngOnInit(): void {
    this.getStatus();
    this.getAllLetters();
    this.searchText.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(() => {
        this.searchLetters();
        this.page = 1;
      });
  }

  getAllLetters(loadMore = false) {
    const params = `studentId=${this.userProfileId}&page=${this.page}&limit=${this.pageSize}`;

    this.letterService.getStudentLetters(params).subscribe((res: any) => {
      this.lettersList = loadMore
        ? [...this.lettersList, ...res.data]
        : res.data;

      this.totalShow = res.pagination.totalRecords;
    });
  }
  searchLetters() {
    let url = `&status=${this.currentStatus['_id']}&studentId=${this.userProfileId}`;
    if (this.searchText.value.length >= 2) {
      url = url.concat(`&search=${this.searchText.value}`);
    }
    this.letterService.getStudentLetters(url).subscribe(
      (res: any) => {
        this.apiData = res.data;
        this.lettersList.forEach((letter) => {
          letter.status =
            letter.status && letter.status.name
              ? letter.status.name
              : 'Pending';
        });
        this.lettersList = this.apiData.slice(0, this.pageSize);
        this.totalShow = res.pagination.totalRecords;
      },
      (err: any) => {
        this._snackBar.openFromComponent(CustomSnackbarDeleteComponent, {
          data: {
            message: `${
              err && err.message ? err.message : 'Error in getting letters'
            }`,
          },
          horizontalPosition: 'center',
          duration: helper.duration,
          panelClass: ['delete-snackBar'],
          verticalPosition: 'top',
        });
      },
    );
  }
  getStatus() {
    const url = `?statusCategory=letter`;
    this.statusService.getStatusByRoute(url).subscribe((res: any) => {
      this.allStatus = res.data;
      this.currentStatus = this.allStatus.find((s) => s.name == 'Published');
    });
  }
  onPageChange($event) {
    this.page = $event.pageIndex + 1;
    this.pageSize = $event.pageSize;
    this.getAllLetters();
  }

  loadMoreLetters() {
    if (this.lettersList.length < this.totalShow) {
      this.page = this.page + 1;
      this.getAllLetters(true);
    }
  }

  clearSearchFilter(event) {
    event.stopPropagation();
    this.searchText.setValue('');
  }
}
