import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { CommonModule } from '@angular/common';
import { CoreComponent } from './core.component';
import { Error500Component } from './error-pages/error500/error500.component';
import { MatSnackBarModule } from '@angular/material';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [BreadcrumbComponent, CoreComponent, Error500Component],
})
export class CoreModule {}
