import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StudentEventsService } from 'src/app/core/services/student/student-events/student-events.service';
import { StudentParentService } from 'src/app/core/services/student/student-parent/student-parent.service';
import { UserType } from '../../enums-constant/user-type.enum';

@Component({
  selector: 'app-attendence-card',
  templateUrl: './attendence-card.component.html',
  styleUrls: ['./attendence-card.component.scss'],
})
export class AttendenceCardComponent implements OnInit {
  @Input() items: any;
  @Input() studentPrent: boolean;
  @Input() selectedStudent;
  selectedStudentId = '';
  children: any;
  totalNoOfDays = 0;
  totalPresent = 0;
  totalAbsent = 0;
  totalLeave = 0;
  totalLate = 0;
  totalPresentRatio = 0;
  totalAbsentRatio = 0;
  percentage = '';
  isStudentView = false;
  isParentView = false;
  isAdminView = false;
  isStaffView = false;
  studentId: any;
  constructor(
    private studentService: StudentEventsService,
    private studentParentService: StudentParentService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    const userType = localStorage.getItem('user_type');
    this.isStudentView = userType === UserType.Student;
    this.isParentView = userType === UserType.Parent;
    this.isAdminView = userType === UserType.Admin;
    this.isStaffView = userType === UserType.Staff;
    if (this.isStudentView) this.getAttendance();
    if (this.isAdminView || this.isStaffView) {
      const routeId = this.route.snapshot.paramMap.get('id');
      this.getAttendanceByStudentId(routeId);
    }
    if (this.isParentView) {
      const routeId = this.route.snapshot.paramMap.get('id');
      this.getAttendanceByStudentId(routeId);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items != undefined && !changes.items.firstChange) {
      this.children = changes.items.currentValue;
    }
  }
  getAttendance() {
    this.studentService.getAttendance().subscribe((res: any) => {
      this.totalPresent = res.data.Present;
      this.totalAbsent = res.data.Absent;
      this.totalLeave = res.data.Leave;
      this.totalLate = res.data.Late;
      this.totalNoOfDays = res.data.noOfDays;
      this.totalPresentRatio = this.totalPresent + this.totalLate;
      this.totalAbsentRatio = this.totalAbsent + this.totalLeave;
      let per = 0;
      if (this.totalPresentRatio != 0 && this.totalNoOfDays != 0) {
        per = (this.totalPresentRatio / this.totalNoOfDays) * 100;
        this.percentage = per.toFixed(0);
      } else {
        this.percentage = '0';
      }
    });
  }

  getAttendanceByStudentId(id) {
    this.selectedStudentId = id;
    if (this.selectedStudentId && this.selectedStudentId != null) {
      this.studentParentService
        .getAttendance(this.selectedStudentId)
        .subscribe((res: any) => {
          this.totalPresent = res.data.Present;
          this.totalAbsent = res.data.Absent;
          this.totalLeave = res.data.Leave;
          this.totalLate = res.data.Late;
          this.totalNoOfDays = res.data.noOfDays;
          this.totalPresentRatio = this.totalPresent + this.totalLate;
          this.totalAbsentRatio = this.totalAbsent + this.totalLeave;
          let per = 0;
          if (this.totalPresentRatio != 0 && this.totalNoOfDays != 0) {
            per = (this.totalPresentRatio / this.totalNoOfDays) * 100;
            this.percentage = per.toFixed(0);
          } else {
            this.percentage = '0';
          }
        });
    } else {
      this.totalPresent = 0;
      this.totalAbsent = 0;
      this.totalLeave = 0;
      this.totalLate = 0;
      this.totalNoOfDays = 0;
      this.totalPresentRatio = 0;
      this.totalAbsentRatio = 0;
      this.percentage = '0';
    }
  }
}
