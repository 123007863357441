import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AcceptInvitationComponent } from './accept-invitation/accept-invitation.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { ConnectionServiceModule } from 'ng-connection-service';
import { CoreModule } from './core/core.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ExcelService } from './core/services/excel/excel.service';
import { HelperServiceService } from './shared/helper-functions/helper-service.service';
import { NgModule } from '@angular/core';
import { NgSelectConfig } from '@ng-select/ng-select';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { SharedModule } from './shared/shared.module';
import { TokenExpiryInterceptor } from './core/interceptor/token-expiry.interceptor';
import { ɵs } from '@ng-select/ng-select';

@NgModule({
  declarations: [AppComponent, AcceptInvitationComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    CarouselModule,
    CdkStepperModule,
    CdkTableModule,
    CoreModule,
    ConnectionServiceModule,
    DragDropModule,
    SharedModule,
  ],
  providers: [
    ExcelService,
    NgSelectConfig,
    ɵs,
    HelperServiceService,
    // commented due to not auth routes are not working and it's redirecting to login page, example version.json, token expirey and reset password
    // { provide: HTTP_INTERCEPTORS, useClass: HttpAuthInterceptor, multi: true }
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenExpiryInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
