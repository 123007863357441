import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mimeTypePipe',
})
export class MimeTypePipe implements PipeTransform {
  transform(fileName: string): string {
    const ext = fileName.split('.').pop().toLowerCase();
    switch (ext) {
      case 'txt':
        return 'text/plain';
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      case 'pdf':
        return 'application/pdf';
      case 'mp4':
        return 'video/mp4';
      case 'gif':
        return 'image/gif';
      default:
        return 'application/octet-stream';
    }
  }
}
