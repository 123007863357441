import { BehaviorSubject } from 'rxjs';
import { GenericService } from '../generic/generic.service';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LmsClassesService {
  private classDetail$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private generic: GenericService) {}
  private readonly controller = 'classes';

  getByID(id) {
    return this.generic.getDataById(this.controller, id);
  }

  getClassByGrade(gradeId) {
    return this.generic.getData(`${this.controller}/${gradeId}`);
  }

  getClassesCombinations() {
    return this.generic.getData(`${this.controller}/combination`);
  }

  getClassListAssignSetting(gradeId, subjectId) {
    return this.generic.getData(
      `${this.controller}/list?grade=${gradeId}&subject=${subjectId}`,
    );
  }

  getClassListAssignAssignment(curriculumId, curriculumAssignment?) {
    return this.generic.getData(
      `${this.controller}/list?curriculum=${curriculumId}&curriculumAssignment=${curriculumAssignment}`,
    );
  }

  getClassListAssignAssignmentOld(gradeId, subjectId, curriculumAssignment?) {
    return this.generic.getData(
      `${this.controller}/list?$grade=${gradeId}&subject=${subjectId}&curriculumAssignment=${curriculumAssignment}`,
    );
  }

  getClassSetting(id, force = false) {
    if (
      (this.classDetail$.getValue() != null &&
        this.classDetail$.getValue().data._id != id) ||
      force
    ) {
      this.classDetail$.next(null);
    }
    return this.classDetail$.getValue()
      ? this.classDetail$.asObservable()
      : this.set(id);
  }

  private set(id) {
    return this.generic
      .getData(`${this.controller}/${id}/class-setting`)
      .pipe(tap((response) => this.classDetail$.next(response)));
  }

  getByParams(param) {
    const order = 'desc';
    const sort = 'createdAt';
    return this.generic.getDataByParams(this.controller, param, order, sort);
  }

  createIndividualClass(body) {
    return this.generic.create(`${this.controller}/individual`, body);
  }

  generateBulkClasses(body) {
    return this.generic.create(`${this.controller}/bulk`, body);
  }

  update(body, id) {
    return this.generic.update(`${this.controller}/${id}`, body);
  }

  updateStatus(body, id) {
    return this.generic.patch(`${this.controller}/${id}`, body);
  }

  delete(id) {
    return this.generic.delete(this.controller, id);
  }

  search(search, filter, order) {
    return this.generic.getDataBySearch(this.controller, search, filter, order);
  }

  getAssignments(id) {
    return this.generic.getData(`${this.controller}/${id}/class-assignments`);
  }

  getAllTopics(classId) {
    return this.generic.getData(`${this.controller}/${classId}/topic`);
  }

  createTopic(classId, body) {
    return this.generic.create(`${this.controller}/${classId}/topic`, body);
  }

  updateTopic(classId, topicId, body) {
    return this.generic.patch(
      `${this.controller}/${classId}/topic/${topicId}`,
      body,
    );
  }

  deleteTopic(classId, topicId) {
    return this.generic.deleteWithOutId(
      `${this.controller}/${classId}/topic/${topicId}`,
    );
  }

  createMaterial(classId, body) {
    return this.generic.create(`${this.controller}/${classId}/material`, body);
  }

  getByIdMaterial(classId, materialId) {
    return this.generic.getDataById(
      `${this.controller}/${classId}/material`,
      materialId,
    );
  }

  updateMaterial(classId, materialId, body) {
    return this.generic.update(
      `${this.controller}/${classId}/material/${materialId}`,
      body,
    );
  }

  deleteMaterial(classId, materialId) {
    return this.generic.deleteWithOutId(
      `${this.controller}/${classId}/material/${materialId}`,
    );
  }

  uploadMaterialAttachment(classId, body) {
    return this.generic.imageUpload(
      `${this.controller}/${classId}/material/upload-attachment`,
      body,
    );
  }

  deleteMaterialAttachment(classId, url) {
    return this.generic.deleteWithOutId(
      `${this.controller}/${classId}/material/delete-attachment?fileUrl=${url}`,
    );
  }

  getClassWork(classId) {
    return this.generic.getData(`${this.controller}/${classId}/classwork`);
  }

  getClassWorkAssignments(classId) {
    return this.generic.getData(`${this.controller}/${classId}/assignment`);
  }

  createClassAssignment(classId, body) {
    return this.generic.create(
      `${this.controller}/${classId}/assignment`,
      body,
    );
  }

  getByIdClassAssignment(classId, assignmentId) {
    return this.generic.getDataById(
      `${this.controller}/${classId}/assignment`,
      assignmentId,
    );
  }

  updateClassAssignment(classId, assignmentId, body) {
    return this.generic.update(
      `${this.controller}/${classId}/assignment/${assignmentId}`,
      body,
    );
  }

  deleteClassAssignment(classId, assignmentId) {
    return this.generic.deleteWithOutId(
      `${this.controller}/${classId}/assignment/${assignmentId}`,
    );
  }

  uploadClassAssignmentAttachment(classId, body) {
    return this.generic.imageUpload(
      `${this.controller}/${classId}/assignment/upload-attachment`,
      body,
    );
  }

  deleteClassAssignmentAttachment(classId, url) {
    return this.generic.deleteWithOutId(
      `${this.controller}/${classId}/assignment/delete-attachment?fileUrl=${url}`,
    );
  }

  assignOnlineAssignment(classId, assignmentId, body) {
    return this.generic.patch(
      `${this.controller}/${classId}/assignment/${assignmentId}/assign/online-assignment`,
      body,
    );
  }

  assignOfflineAssignment(classId, assignmentId, body) {
    return this.generic.patch(
      `${this.controller}/${classId}/assignment/${assignmentId}/assign/offline-assignment`,
      body,
    );
  }

  unAssignOnlineAssignment(classId, assignmentId, body) {
    return this.generic.patch(
      `${this.controller}/${classId}/assignment/${assignmentId}/unassign/online-assignment`,
      body,
    );
  }

  unAssignOfflineAssignment(classId, assignmentId, body) {
    return this.generic.patch(
      `${this.controller}/${classId}/assignment/${assignmentId}/unassign/offline-assignment`,
      body,
    );
  }
}
