import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-children-selector',
  templateUrl: './children-selector.component.html',
  styleUrls: ['./children-selector.component.scss'],
})
export class ChildrenSelectorComponent {
  @Input() children;
  @Output() getAllChildern: EventEmitter<any> = new EventEmitter();
  @Output() getSelectedProfile: EventEmitter<any> = new EventEmitter();
  @Output() activeProfile: EventEmitter<any> = new EventEmitter();
  @Input() activeProfileIndex;
  showSelectedProfileContent = false;

  getAllChildernData() {
    this.activeProfileIndex = -1;
    this.getAllChildern.emit();
    this.activeProfile.emit(this.activeProfileIndex);
    this.showSelectedProfileContent = false;
  }

  getSelectedProfileData(std, index) {
    this.activeProfileIndex = index;
    this.getSelectedProfile.emit(std);
    this.activeProfile.emit(this.activeProfileIndex);
    this.showSelectedProfileContent = true;
  }
}
