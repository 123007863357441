import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'leadingWhiteZero',
  pure: false,
})
export class LeadingWhiteZerpPipe implements PipeTransform {
  transform(number: any, key: string): any {
    const amount = number
      .toString()
      .split('')
      .map((number) => ({
        isZero: false,
        number,
      }));
    if (amount.length < 6) {
      const remaining = 6 - amount.length;
      for (let i = 0; i < remaining; i++) {
        amount.unshift({ isZero: true, number: 0 });
      }
    }
    return amount;
  }
}
