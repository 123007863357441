import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { CustomSnackBarComponent } from 'src/app/shared';
import { CustomSnackbarDeleteComponent } from 'src/app/shared/components/custom-snackbar-delete/custom-snackbar-delete.component';
import { MatSnackBar } from '@angular/material';
import { UserType } from 'src/app/shared/enums-constant/user-type.enum';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  public form: FormGroup;
  showMsg: any;
  oldpasswordhide = true;
  newpasswordhide = true;
  confrimpasswordhide = true;
  hideText = false;
  loader = false;
  token: any;
  submittedR = false;
  isAdminView;
  isSuperUser;
  routeData: any = [];

  constructor(
    private authService: AuthService,
    public router: Router,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
  ) {}
  breadcrumb = [];
  ngOnInit() {
    this.routeData = this.route.snapshot.data;
    const userType = localStorage.getItem('user_type');
    this.isAdminView = userType === UserType.Admin;
    this.isSuperUser = userType === UserType.SU;
    this.showMsg = '';
    this.formPost();
  }
  formPost() {
    this.form = this.formBuilder.group(
      {
        oldPassword: ['', [Validators.required]],
        newPassword: ['', [Validators.required]],
        confirmPassword: ['', [Validators.required]],
      },
      {
        validator: this.ConfirmedValidator('newPassword', 'confirmPassword'),
      },
    );
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.form.controls;
  }
  async changePassword() {
    this.submittedR = true;
    if (this.loader) return;
    if (this.form.invalid) return;

    this.loader = true;
    await this.authService.changePassword(this.form.value).subscribe(
      (res: any) => {
        this.loader = false;
        if (res.status == 200) {
          this._snackBar.openFromComponent(CustomSnackBarComponent, {
            data: { message: res.message },
            horizontalPosition: 'center',
            duration: 3000,
            panelClass: ['save-snackBar'],
            verticalPosition: 'top',
          });
          this.router.navigateByUrl('/login');
        } else {
          this._snackBar.openFromComponent(CustomSnackbarDeleteComponent, {
            data: { message: res.message },
            horizontalPosition: 'center',
            duration: 2000,
            panelClass: ['delete-snackBar'],
            verticalPosition: 'top',
          });
        }
      },
      (err) => {
        this.loader = false;
        this._snackBar.openFromComponent(CustomSnackbarDeleteComponent, {
          data: { message: err.error.message },
          horizontalPosition: 'center',
          duration: 3000,
          panelClass: ['delete-snackBar'],
          verticalPosition: 'top',
        });
      },
    );
  }

  //confirm password validator
  ConfirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (
        matchingControl.errors &&
        !matchingControl.errors.confirmedValidator
      ) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
}
