import { GenericService } from '../generic/generic.service';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  private notify = new Subject<any>();
  notifyObservable$ = this.notify.asObservable();

  constructor(private generic: GenericService) {}
  controller = 'events';
  get() {
    //getting data from service in this method and showing it on list
    return this.generic.getData(this.controller);
  }
  post(body) {
    return this.generic.create(this.controller, body);
  }

  getByID(id) {
    return this.generic.getDataById(this.controller, id);
  }

  delete(id) {
    return this.generic.delete(this.controller, id);
  }
  update(body) {
    return this.generic.update(this.controller, body);
  }
  imageUpload(body) {
    return this.generic.imageUpload('events/banner', body);
  }
  getbyParams(params) {
    const order = 'asc';
    return this.generic.getDataByParams(
      this.controller,
      params + '&showall=true',
      order,
      'fromDate',
    );
  }
  public notifyOther(data: any) {
    if (data) {
      this.notify.next(data);
    }
  }
}
