import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-detail-card',
  templateUrl: './detail-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./detail-card.component.scss'],
  providers: [DatePipe],
})
export class DetailCardComponent implements OnInit {
  @Output() valueChange = new EventEmitter();
  Counter = 0;
  @Input() item: any;
  newsData = [];
  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.item != undefined && !changes.item.firstChange) {
      this.newsData = changes.item.currentValue;
      console.log(this.newsData);
    }
  }

  sendID(item) {
    this.valueChange.emit(item);
  }
}
