import { GenericService } from '../../generic/generic.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StudentShufflingService {
  controller = 'shuffling';

  constructor(private generic: GenericService) {}

  getAllShufflings(params) {
    let url = '';
    for (const key in params) {
      if (params[key] != undefined && params[key] != '') {
        url = url.concat(`&${key}=${params[key]}`);
      }
    }
    return this.generic.getDataByParams(this.controller, url);
  }

  getShuffling(params) {
    const { academicYear, branch, grade } = params;
    const path = `${academicYear}/${branch}/${grade}`;
    return this.generic.getDataById(this.controller, path);
  }

  postShuffling(params, body) {
    const { academicYear, branch, grade } = params;
    const path = `${academicYear}/${branch}/${grade}`;
    return this.generic.patch(`${this.controller}/${path}`, body);
  }
}
