import { Component, Input, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { EventSubjectService } from '../event-subject.service';

@Component({
  selector: 'app-year-view-shared',
  templateUrl: './year-view.component.html',
  styleUrls: ['./year-view.component.scss'],
  providers: [DatePipe],
})
export class YearViewComponent implements OnInit {
  januaryDate = 0;
  febDate = 1;
  marchDate = 2;
  aprilDate = 3;
  mayDate = 4;
  juneDate = 5;
  julyDate = 6;
  augustDate = 7;
  septemberDate = 8;
  octoberDate = 9;
  novemberDate = 10;
  decemberDate = 11;
  date = new Date();
  todayDate = new Date();
  yearSet: string;
  @Input() service;
  currentService;
  constructor(
    public datePipe: DatePipe,
    private eventSubject: EventSubjectService,
  ) {}

  ngOnInit(): void {
    this.currentService = this.service;
  }
  setMonth(inc: number): void {
    const [year] = [this.date.getFullYear()];
    this.date = new Date(year + inc, 1);
    this.yearSet = this.datePipe.transform(this.date, 'y', 'UTC');
    this.eventSubject.setData(this.yearSet);
  }
  goToday(): void {
    const [year] = [this.todayDate.getFullYear()];
    this.date = new Date(year, 1);
    this.yearSet = this.datePipe.transform(this.date, 'y', 'UTC');
    this.eventSubject.setData(this.yearSet);
  }
}
