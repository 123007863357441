import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-request-form-card',
  templateUrl: './request-form-card.component.html',
  styleUrls: ['./request-form-card.component.scss'],
})
export class RequestFormCardComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
