import { GenericService } from '../../generic/generic.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { getFromLocalStorage } from 'src/app/shared';

@Injectable({
  providedIn: 'root',
})
export class StudentProfileService {
  private readonly controller = 'studentprofiles';
  academicYear = '';

  constructor(private generic: GenericService) {
    this.academicYear = getFromLocalStorage.currentAcademicYearID();
  }
  get() {
    return this.generic.getData(this.controller);
  }
  post(body) {
    return this.generic.create(this.controller, body);
  }

  getByID(id, params = '') {
    const order = 'desc';
    params = params
      ? (params += `&academicYear=${this.academicYear}`)
      : `academicYear=${this.academicYear}`;
    return this.generic.getDataByParams(
      `${this.controller}/${id}`,
      params,
      order,
    );
  }

  delete(id) {
    //This method is used to delete record from database and it fires a snackbar on successful delete
    return this.generic.delete(this.controller, id);
  }
  update(body) {
    return this.generic.update(this.controller, body);
  }
  getbyParams(params) {
    const order = 'asc';
    const sort = 'firstName';
    return this.generic.getDataByParams(this.controller, params, order, sort);
  }

  getActiveStudents(params = '') {
    const order = 'desc';
    params = params
      ? (params += `&academicYear=${this.academicYear}`)
      : `academicYear=${this.academicYear}`;

    params = params.concat('&skipAcademicYear=true');

    return this.generic.getDataByParams(
      `${this.controller}/active`,
      params,
      order,
    );
  }

  getActiveAcademicYearStudents(params = '') {
    const order = 'desc';
    params = params
      ? (params += `&academicYear=${this.academicYear}`)
      : `academicYear=${this.academicYear}`;
    return this.generic.getDataByParams(
      `${this.controller}/active`,
      params,
      order,
    );
  }

  searchActiveStudents(search, filter, order) {
    return this.generic.getDataBySearch(
      `${this.controller}/active`,
      search,
      filter,
      order,
    );
  }

  getStudentByRollNo(roll) {
    const rollNo = 'rollNo';
    const desc = 'desc';
    const param = roll + '&showall=true';

    return this.generic.getDataBySearch(this.controller, param, rollNo, desc);
  }
  search(search, filter, order) {
    return this.generic.getDataBySearch(this.controller, search, filter, order);
  }

  getSiblingByStudentId(id) {
    const queryString = '/siblings/' + id;
    return this.generic.getDataById(this.controller, queryString);
  }

  imageUpload(body) {
    return this.generic.imageUpload('studentprofiles/avatar', body);
  }

  uploadDocument(body) {
    return this.generic.imageUpload('studentprofiles/document', body);
  }
  getDocumentsById(id) {
    return this.generic.getDataById('studentprofiles/document', id);
  }

  deleteDocumentsById(id) {
    return this.generic.delete('studentprofiles/document', id);
  }

  getStudentProfilesForSearchFilter(params = '') {
    params = params
      ? (params += `&academicYear=${this.academicYear}`)
      : `academicYear=${this.academicYear}`;
    params = params.concat(`&skipAcademicYear=true`);
    return this.generic.getDataByParams(
      `${this.controller}/student-filter`,
      params,
      'desc',
    );
  }

  getStudentProfilesForBilling(params = '') {
    return this.generic.getDataByParams(
      `${this.controller}/student-filter`,
      params,
      'desc',
    );
  }

  postDirect(data: unknown): Observable<unknown> {
    return this.generic.create(this.controller + '/direct', data);
  }

  updateBranch(body) {
    return this.generic.update(`${this.controller}/updateBranch`, body);
  }

  updateGrade(body) {
    return this.generic.update(`${this.controller}/updateGrade`, body);
  }

  updateSection(body) {
    return this.generic.update(`${this.controller}/updateSection`, body);
  }

  updateStudent(studentId, body) {
    return this.generic.update(`${this.controller}/${studentId}`, body);
  }

  updateBillingMode(body, id) {
    return this.generic.update(`${this.controller}/${id}/billingMode`, body);
  }
  classListReport(id) {
    return this.generic.getData(`student/classlist?branch=` + id);
  }
  stdSiblingReport(id) {
    return this.generic.getData(`student/studentsiblinginfo?branch=` + id);
  }
  classStrengthReport(url) {
    return this.generic.getData(`student/studentstrength?${url}`);
  }
  StudentHouseInformationReport(url) {
    return this.generic.getExcelByParams(`student/studentshouse`, url, 'desc');
  }
  studentIdCardReport(url) {
    return this.generic.getData(`student/studentidcard?${url}`);
  }
  studentContactInfoReport(url) {
    return this.generic.getData(`student/studentcontactinfo?${url}`);
  }
  studentInfoReport(url) {
    return this.generic.getData(`student/studentDetailedinfo?${url}`);
  }
  studentExtracurricularBillingInfoReport(url) {
    return this.generic.getExcelByParams(
      `courses/extracurricularstudentsinfo`,
      url,
      'desc',
    );
  }

  removeStudentFromAcademicHoldFromBillingStudents(id: string) {
    return this.generic.deleteWithOutId(
      `${this.controller}/${id}/academic-hold`,
    );
  }

  updateStudentAcademicHoldStatus(body) {
    return this.generic.update(`${this.controller}/academic-hold`, body);
  }

  checkResultEligibility(id) {
    return this.generic.getData(
      `${this.controller}/${id}/check-result-eligibility`,
    );
  }

  getResultReport(id) {
    return this.generic.getData(`${this.controller}/${id}/get-result`);
  }
}
