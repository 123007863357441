import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomSnackBarComponent, helper } from 'src/app/shared';
import { MatDialog, MatSnackBar } from '@angular/material';
import { DatePipe } from '@angular/common';
import { EventAddModalComponent } from '../event-add-modal/event-add-modal.component';
import { EventDetailModalComponent } from '../event-detail-modal/event-detail-modal.component';
import { EventService } from 'src/app/core';
import { EventSubjectService } from '../event-subject.service';
import { ParentEventService } from 'src/app/core/services/parent/parent-events/parent-event.service';
import { StudentEventsService } from 'src/app/core/services/student/student-events/student-events.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { TeacherUserEventService } from 'src/app/core/services/teacher-user/teacher-user-event.service';

@Component({
  selector: 'app-communication-events-shared',
  templateUrl: './communication-events.component.html',
  styleUrls: ['./communication-events.component.scss'],
  providers: [DatePipe],
})
export class CommunicationEventsComponent implements OnInit {
  @Output() buttonClick = new EventEmitter();
  events = [];
  totalShow;
  firstDay;
  lastDay;
  y;
  m;
  show = 4;
  today = moment();
  showdiv;
  @Input() showActions;
  @Input() service;
  date = new Date();
  scroll = false;
  subscription: Subscription;

  constructor(
    private eventService: EventService,
    private eventStudentService: StudentEventsService,
    private eventParentService: ParentEventService,
    private eventTeacherUserService: TeacherUserEventService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private datePipe: DatePipe,
    private eventSubject: EventSubjectService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.y = this.date.getFullYear();
    this.m = this.date.getMonth();
    this.firstDay = new Date(this.y, this.m, 1);
    this.lastDay = new Date(this.y, this.m + 1, 0);

    this.route.params.subscribe((params) => {
      if (params['id']) {
        if (params['id'] && params['id'] === 'addmodal') {
          this.openDialog();
        }
      }
    });
    this.eventInit();
    this.subscription = this.service.notifyObservable$.subscribe((res) => {
      this.getEvents();
    });
    // this.showActions
  }

  async eventInit(): Promise<any> {
    await this.switchService(this.service);
    this.getEvents();
  }

  private switchService(mock: string): void {
    if (mock === 'student') {
      this.service = this.eventStudentService;
    } else if (mock === 'admin') {
      this.service = this.eventService;
    } else if (mock === 'parent') {
      this.service = this.eventParentService;
    } else if (mock === 'teacher') {
      this.service = this.eventTeacherUserService;
    } else {
      this.service = this.eventService;
    }
  }

  test(): void {
    this.buttonClick.emit('fire event');
  }

  getEvents(): void {
    const url =
      'limit=' + this.show + '&fromDate=' + this.today.format('YYYY-MM-DD');
    this.service.getbyParams(url).subscribe((res) => {
      this.events = res.data;
      this.totalShow = res.pagination.totalRecords;
      this.getEventsServ();
    });
  }

  getEventsServ(): void {
    this.service.get().subscribe((res) => {
      this.eventSubject.setDataMain(res.data);
    });
  }

  showMore(): void {
    this.show += 5;
    this.getEvents();
    this.scroll = true;
  }

  onClickedOutside(e: Event): void {
    this.showdiv = '';
  }

  option(i: unknown): void {
    //news action options
    if (i == this.showdiv) {
      this.showdiv = '';
    } else {
      this.showdiv = i;
      this.showdiv = i;
    }
  }

  edit(i: unknown): void {
    let permission = {};
    this.route.data.subscribe((data) => (permission = data));
    const dialogRef = this.dialog.open(EventAddModalComponent, {
      data: { id: i, state: 'Edit', routeData: permission },
      panelClass: 'event-modal',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'success') {
        this.getEvents();
        this.buttonClick.emit('fire event');
        this.eventService.notifyOther({
          option: 'refresh-event',
        });
      }
    });
    this.showdiv = '';
  }

  delete(i: unknown): void {
    Swal.fire({
      title: 'Are you sure you want to delete it?',
      text: 'Your data will be deleted permanently!',
      icon: 'warning',
      showCancelButton: true,
      // confirmButtonColor: '#3085d6',
      // cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        this.eventService.delete(i).subscribe((res: any) => {
          this.buttonClick.emit('fire event');
          if (res.code == 200) {
            this.getEvents();
            this._snackBar.openFromComponent(CustomSnackBarComponent, {
              data: { message: res.message },
              horizontalPosition: 'center',
              duration: helper.duration,
              panelClass: ['save-snackBar'],
              verticalPosition: 'top',
            });
          }
        });
      }
    });
  }

  view(event: any): void {
    this.dialog.open(EventDetailModalComponent, {
      data: { eventDetail: event, service: this.service },
      panelClass: 'dialog-with-scroll',
      width: event.rsvp ? '50vw' : '40vw',
    });
    this.showdiv = '';
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(EventAddModalComponent, {
      data: { id: null, state: 'Add' },
      panelClass: 'event-modal',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'success') {
        this.getEvents();
        this.buttonClick.emit('fire event');
        this.eventService.notifyOther({
          option: 'refresh-event',
        });
        this.router.navigateByUrl('admin/communication/event-main');
      }
    });
  }
}
