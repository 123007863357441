import { Component, Inject, OnInit } from '@angular/core';
import {
  CustomSnackBarComponent,
  CustomSnackbarDeleteComponent,
  helper,
} from '../../../../shared';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { InvoiceService } from '../../../../core';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-invoice-modification-modal',
  templateUrl: './invoice-modification-modal.component.html',
  styleUrls: ['./invoice-modification-modal.component.scss'],
})
export class InvoiceModificationModalComponent implements OnInit {
  public form: FormGroup;
  invoiceData;
  isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<InvoiceModificationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private fb: FormBuilder,
    private invoiceService: InvoiceService,
    public _snackBar: MatSnackBar,
  ) {
    if (data) {
      this.invoiceData = data.billingObj[0];
    }
  }

  ngOnInit() {
    this.formPost();
    this.bindData();
  }

  close() {
    this.dialogRef.close('cancel');
  }

  public submit(): void {
    if (this.isLoading || this.form.invalid) {
      return;
    } else {
      this.isLoading = true;
      const body = {
        dueDate: this.form.value.dueDate,
        finalDate: this.form.value.expiryDate,
        voucherExpiryDate: this.form.value.expiryDate,
        reason: this.form.value.reason,
      };

      this.invoiceService
        .updateInvoiceDates(this.invoiceData.id, body)
        .subscribe(
          (res: any) => {
            if (res) {
              if (res['status'] == 'success') {
                this._snackBar.openFromComponent(CustomSnackBarComponent, {
                  data: { message: res['message'] },
                  horizontalPosition: 'center',
                  duration: helper.duration,
                  panelClass: ['save-snackBar'],
                  verticalPosition: 'top',
                });
                this.isLoading = false;
                this.close();
              }
            }
          },
          (error) => {
            this._snackBar.openFromComponent(CustomSnackbarDeleteComponent, {
              data: { message: error.error.message },
              horizontalPosition: 'center',
              duration: helper.duration,
              panelClass: ['delete-snackBar'],
              verticalPosition: 'top',
            });
            this.isLoading = false;
          },
        );
    }
  }

  public formPost(): void {
    this.form = this.fb.group({
      dueDate: new FormControl('', Validators.required),
      expiryDate: new FormControl('', Validators.required),
      reason: new FormControl('', Validators.required),
    });
  }

  bindData() {
    this.form.patchValue({
      dueDate: this.invoiceData.dueDate,
      expiryDate: this.invoiceData.voucherExpiryDate,
      reason: this.form.value.reason,
    });
  }

  get FormControl() {
    return this.form.controls;
  }
}
