import { ActivatedRoute, Data } from '@angular/router';
import {
  Directive,
  Input,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

@Directive({
  selector: '[appHasPermissions]',
})
export class HasPermissionDirective {
  permission = {
    add: false,
    delete: false,
    edit: false,
    export: false,
    print: false,
    view: false,
  };
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private renderer: Renderer2,
    private activatedRoute: ActivatedRoute,
  ) {}

  @Input() appHasPermissionsOptions: { module: string; feature: string };

  @Input()
  set appHasPermissions(userPermission: string) {
    this.decideView(userPermission);
  }

  decideView(value) {
    const data: Data = this.activatedRoute.snapshot.data;
    const permission = this.validatePermissions(
      this.appHasPermissionsOptions || data,
    );
    let isShowComponent = false;
    const permissionsList = value.split(',');
    if (permissionsList.length > 1) {
      permissionsList.forEach((p) => {
        if (permission[p.toLowerCase()]) isShowComponent = true;
      });
      if (isShowComponent) {
        this.showComponent();
      }
    }
    if (permissionsList.length == 1) {
      if (permission[permissionsList[0].toLowerCase()]) this.showComponent();
    }
  }

  validatePermissions(routeData: any) {
    const user_type_short_name = localStorage.getItem('user_type_short_name');
    if (user_type_short_name == 'SF') {
      if (
        routeData.module != 'home' &&
        routeData.module != 'profile' &&
        routeData.module != undefined
      ) {
        return this.checkPermissions(routeData);
      }
    } else {
      return {
        add: true,
        delete: true,
        edit: true,
        export: true,
        print: true,
        view: true,
      };
    }
  }

  checkPermissions(routeData: any) {
    const menuItems = JSON.parse(localStorage.getItem('menuitems') as string);
    if (routeData.module === undefined) return this.permission;
    if (
      routeData.feature == undefined &&
      !menuItems.find(
        (x) => x.name.toUpperCase() == routeData.module.toUpperCase(),
      )
    ) {
      return this.permission;
    }
    if (
      routeData.feature != undefined &&
      routeData.feature != 'dashboard' &&
      !menuItems.find((x) =>
        x.features.some(
          (item) =>
            x.name.toUpperCase() === routeData.module.toUpperCase() &&
            item.name.toUpperCase() === routeData.feature.toUpperCase(),
        ),
      )
    ) {
      return this.permission;
    } else {
      return menuItems
        .find((x) => x.name.toUpperCase() == routeData.module.toUpperCase())
        .features.find(
          (item) => item.name.toUpperCase() === routeData.feature.toUpperCase(),
        ).permissions.permissions;
    }
  }

  disableComponent(): void {
    this.viewContainerRef.clear();
    const viewRootElement: HTMLElement =
      this.viewContainerRef.createEmbeddedView(this.templateRef).rootNodes[0];
    viewRootElement.setAttribute('style', 'background-color: grey');
    this.renderer.setProperty(viewRootElement, 'disabled', true);
  }

  enableComponent(): void {
    this.viewContainerRef.clear();
    const viewRootElement: HTMLElement =
      this.viewContainerRef.createEmbeddedView(this.templateRef).rootNodes[0];
    this.renderer.setProperty(viewRootElement, 'disabled', false);
  }

  removeComponent(): void {
    this.viewContainerRef.clear();
  }

  showComponent(): void {
    this.viewContainerRef.clear();
    this.viewContainerRef.createEmbeddedView(this.templateRef).rootNodes[0];
  }
}
