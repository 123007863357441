import * as moment from 'moment';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { EventSubjectService } from '../shared-events/event-subject.service';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { UserType } from '../../enums-constant/user-type.enum';
import { takeUntil } from 'rxjs/operators';

const DAY_MS = 60 * 60 * 24 * 1000;
@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [DatePipe],
})
export class CalendarComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  @Input() events;
  @Output() dateRange: any = new EventEmitter();
  subscription$;
  dates: Array<Date>;
  showdiv;
  year;
  days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
  date = new Date();
  currentYear;
  y;
  m;
  firstDay;
  lastDay;
  toDayEvents = [];
  userType: any;

  constructor(
    public datePipe: DatePipe,
    public dialog: MatDialog,
    private eventSubject: EventSubjectService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.userType = localStorage.getItem('user_type');
    this.getMonthRange(this.date);
    this.currentYear = this.datePipe.transform(this.date, 'y');
    this.subscription$ = this.eventSubject
      .getData()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.year = res;
        this.date = new Date(
          this.date.setFullYear(this.year, this.date.getMonth()),
        );
        this.dates = this.getCalendarDays(this.date);
      });
    if (this.year !== undefined && this.year !== null) {
      this.date = new Date(
        this.date.setFullYear(this.year, this.date.getMonth()),
      );
      this.dates = this.getCalendarDays(this.date);
    } else {
      this.date = new Date(this.currentYear, this.date.getMonth());
      this.dates = this.getCalendarDays(this.date);
    }
  }

  ngOnDestroy(): void {
    this.eventSubject.setData(this.currentYear);
    this.subscription$.complete();
    this.destroy$.next();
    this.destroy$.complete();
  }

  getMonthRange(date) {
    this.y = date.getFullYear();
    this.m = date.getMonth();
    this.firstDay = new Date(this.y, this.m, 1);
    this.lastDay = new Date(this.y, this.m + 1, 0);
    return {
      startDate: this.firstDay,
      endDate: this.lastDay,
    };
  }

  setMonth(inc: number): void {
    const [year, month] = [this.date.getFullYear(), this.date.getMonth()];
    this.date = new Date(year, month + inc, 1);
    const monthDates = this.getMonthRange(this.date);
    this.dateRange.emit(monthDates);
    //this.getEvents();
    this.dates = this.getCalendarDays(this.date);
  }

  isSameMonth(date: Date): boolean {
    return date.getMonth() === this.date.getMonth();
  }

  private getCalendarDays(date) {
    const calendarStartTime = this.getCalendarStartDay(date).getTime();

    return this.range(0, 41).map(
      (num) => new Date(calendarStartTime + DAY_MS * num),
    );
  }

  private getCalendarStartDay(date) {
    const [year, month] = [date.getFullYear(), date.getMonth()];
    const firstDayOfMonth = new Date(year, month, 1).getTime();

    return this.range(1, 7)
      .map((num) => new Date(firstDayOfMonth - DAY_MS * num))
      .find((dt) => dt.getDay() === 0);
  }

  private range(start, end, length = end - start + 1) {
    return Array.from({ length }, (_, i) => start + i);
  }

  isClassMonth(date): boolean {
    const currentDate = moment(date);
    for (let j = 0; j < this.events.length; j++) {
      const event = this.events[j];
      const fromDate = moment(event.fromDate);
      const toDate = moment(event.toDate);

      if (
        currentDate.isSameOrAfter(fromDate, 'day') &&
        currentDate.isSameOrBefore(toDate, 'day') &&
        currentDate.isSame(fromDate, 'month') &&
        currentDate.isSame(toDate, 'month')
      ) {
        return true;
      }
    }
    return false;
  }

  getTodayEvents(date) {
    this.clearEvents();
    for (let i = 0; i < this.events.length; i++) {
      const fromDateMonthWise = moment(this.events[i].fromDate).format(
        'DD/MM/YYYY',
      );
      const toDateMonthWise = moment(this.events[i].toDate).format(
        'DD/MM/YYYY',
      );
      const currentDateMonthWise = moment(date).format('DD/MM/YYYY');

      if (
        fromDateMonthWise === currentDateMonthWise ||
        (currentDateMonthWise > fromDateMonthWise &&
          currentDateMonthWise < toDateMonthWise) ||
        toDateMonthWise === currentDateMonthWise
      ) {
        this.toDayEvents.push(this.events[i]);
      }
    }
  }
  clearEvents() {
    this.toDayEvents = [];
  }

  viewEvents() {
    switch (this.userType) {
      case UserType.Admin:
      case UserType.Staff:
        this.router.navigate(['/admin/communication/event-main']);
        break;
      case UserType.Parent:
        this.router.navigate(['/parent/events']);
        break;
      case UserType.Student:
        this.router.navigate(['/student/events']);
        break;
      case UserType.Teacher:
        this.router.navigate(['/teacher/events']);
        break;
    }
  }
}
