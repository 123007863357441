import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GenericService {
  constructor(private http: HttpClient) {}

  public getData(route: string) {
    return this.http.get(
      this.createCompleteRoute(environment.apiBaseUrl, route),
      this.generateHeaders(),
    );
  }
  public getDataById(route: string, ID) {
    return this.http.get(
      this.createCompleteRouteId(environment.apiBaseUrl, route, ID),
      this.generateHeaders(),
    );
  }

  public getDataByIdCSV(route: string, ID, csvFor: string) {
    return this.http.get(
      this.createCompleteRouteIdWithParams(
        environment.apiBaseUrl,
        route,
        ID,
        csvFor,
      ),
      this.generateHeaders(),
    );
  }

  public getDataByPost(route: string, body) {
    return this.http.post(
      this.createCompleteRoute(environment.apiBaseUrl, route),
      body,
      this.generateHeaders(),
    );
  }
  public create(route: string, body) {
    return this.http.post(
      this.createCompleteRoute(environment.apiBaseUrl, route),
      body,
      this.generateHeaders(),
    );
  }

  public submitMultipartFormData(route: string, formData: FormData) {
    return this.http.post(
      this.createCompleteRoute(environment.apiBaseUrl, route),
      formData,
      this.generateFormDataHeaders(),
    );
  }

  public patchMultipartFormData(route: string, formData: FormData) {
    return this.http.patch(
      this.createCompleteRoute(environment.apiBaseUrl, route),
      formData,
      this.generateFormDataHeaders(),
    );
  }

  public update(route: string, body) {
    return this.http.put(
      this.createCompleteRoute(environment.apiBaseUrl, route),
      body,
      this.generateHeaders(),
    );
  }
  public patch(route: string, body) {
    return this.http.patch(
      this.createCompleteRoute(environment.apiBaseUrl, route),
      body,
      this.generateHeaders(),
    );
  }
  public imageUpload(route: string, body) {
    return this.http.post(
      this.createCompleteRoute(environment.apiBaseUrl, route),
      body,
      this.generateImageHeaders(),
    );
  }
  public upload(route: string, body) {
    return this.http.post(
      this.createCompleteRoute(environment.apiBaseUrl, route),
      body,
      this.generateUploadHeaders(),
    );
  }
  public csvUpload(route: string, body) {
    return this.http.post(
      this.createCompleteRoute(environment.apiBaseUrl, route),
      body,
      this.generateImageHeaders(),
    );
  }

  public csvProcess(route: string, ID, body) {
    return this.http.post(
      this.createCompleteRouteId(environment.apiBaseUrl, route, ID),
      body,
      this.generateHeaders(),
    );
  }

  // Saad's Work
  // public delete(route: string){
  //   return this.http.delete(this.createCompleteRoute(route, environment.apiBaseUrl));
  // }

  // Danial's Work
  public delete(route: string, ID) {
    return this.http.delete(
      this.createCompleteRouteId(environment.apiBaseUrl, route, ID),
      this.generateHeaders(),
    );
  }
  public deleteWithOutId(route: string) {
    return this.http.delete(
      this.createCompleteRoute(environment.apiBaseUrl, route),
      this.generateHeaders(),
    );
  }
  public getDataBySearch(
    route: string,
    search: string,
    filter: string,
    order: string,
  ) {
    return this.http.get(
      this.searchMethod(
        environment.apiBaseUrl,
        route + '?search=' + search + '&filter=' + filter + '&order=' + order,
      ),
      this.generateHeaders(),
    );
  }

  //saba's work

  public getDataByPostAndParam(route, body, params) {
    return this.http.post(
      this.createCompleteRouteWithParam(environment.apiBaseUrl, route, params),
      body,
      this.generateHeaders(),
    );
  }

  //////////////////////Ghayooorrr Work ///////////////////////////////////////////

  public getCircularBySearch(
    route: string,
    search: string,
    filter: string,
    order: string,
    type: string,
    params: string,
    branch?: string,
  ) {
    return this.http.get(
      this.searchMethod(
        environment.apiBaseUrl,
        route +
          '?search=' +
          search +
          '&filter=' +
          filter +
          '&order=' +
          order +
          `&type=${type}` +
          params +
          `${!branch || branch === '' ? '' : `&branch=${branch}`}`,
      ),
      this.generateHeaders(),
    );
  }

  //////////////////////////

  public getDataByParams(
    route: string,
    params: string,
    order?: string,
    sort?: string,
  ) {
    let url = route + '?' + params;
    if (order) url = url + '&order=' + order;
    if (sort) url = url + '&sort=' + sort;
    return this.http.get(
      this.searchMethod(environment.apiBaseUrl, url),
      this.generateHeaders(),
    );
  }

  public getExcelByParams(
    route: string,
    params: string,
    order: string,
    sort?: string,
  ) {
    return this.http.get(
      this.searchMethod(
        environment.apiBaseUrl,
        route + '?' + params + '&order=' + order + '&sort=' + sort,
      ),
      { headers: this.generateExcelHeaders(), responseType: 'blob' as 'json' },
    );
  }

  public getPdfByParams(route: string, params: string, order: string) {
    return this.http.get(
      this.searchMethod(
        environment.apiBaseUrl,
        route + '?' + params + '&order=' + order,
      ),
      { headers: this.generateHtmlHeaders(), responseType: 'blob' as 'json' },
    );
  }
  /////////////saba's work
  private createCompleteRouteWithParam(
    envAddress: string,
    route: string,
    params: string,
  ) {
    return `${envAddress}/${route}?category= ${params}`;
  }

  private createCompleteRoute(envAddress: string, route: string) {
    return `${envAddress}/${route}`;
  }
  private createCompleteRouteId(envAddress: string, route: string, Id: string) {
    return `${envAddress}/${route}/${Id}`;
  }

  private createCompleteRouteIdWithParams(
    envAddress: string,
    route: string,
    Id: string,
    csvFor,
  ) {
    return `${envAddress}/${route}/${Id}?csvFor=${csvFor}`;
  }

  private createCompleteRouteIdQuery(
    envAddress: string,
    route: string,
    Id: string,
  ) {
    return `${envAddress}/${route}/${Id}`;
  }

  private searchMethod(envAddress: string, route: string) {
    return `${envAddress}/${route}`;
  }

  private generateHeaders() {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    if (localStorage.getItem('token') != undefined) {
      headers = headers.set('x-access-token', localStorage.getItem('token'));
    }
    //headers = headers.set('x-access-token', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InNta2hvd2FqYTFAaG90bWFpbC5jb20iLCJfaWQiOiI1ZWJjZWNmY2UwYzVhOTI4OTQ5ZTJjOGUiLCJpYXQiOjE2MDYyODM1NjgsImV4cCI6MTYwNjQ1NjM2OH0.l-ShAeuPtjjo8-M3uGqTZEJ2oXuZU2N1r2XboMZ9KFY');
    return {
      headers,
    };
  }

  private generatePdfHeaders(): HttpHeaders {
    let headers = new HttpHeaders().set('Content-Type', 'application/pdf');

    if (localStorage.getItem('token') != undefined) {
      headers = headers.set('x-access-token', localStorage.getItem('token'));
    }
    headers = headers.set('responseType', 'text');
    return headers;
  }

  private generateExcelHeaders() {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    if (localStorage.getItem('token') !== undefined) {
      headers = headers.set('x-access-token', localStorage.getItem('token'));
    }
    headers = headers.set('Accept', 'application/vnd.ms-excel');
    return headers;
  }

  private generateHtmlHeaders(): HttpHeaders {
    let headers = new HttpHeaders().set('Content-Type', 'text/html');

    if (localStorage.getItem('token') != undefined) {
      headers = headers.set('x-access-token', localStorage.getItem('token'));
    }
    headers = headers.set('responseType', 'text');
    return headers;
  }
  private generateImageHeaders() {
    // let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    let headers = new HttpHeaders();
    if (localStorage.getItem('token') != undefined) {
      headers = headers.set('x-access-token', localStorage.getItem('token'));
    }
    //headers = headers.set('x-access-token', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InNta2hvd2FqYTFAaG90bWFpbC5jb20iLCJfaWQiOiI1ZWJjZWNmY2UwYzVhOTI4OTQ5ZTJjOGUiLCJpYXQiOjE2MDYyODM1NjgsImV4cCI6MTYwNjQ1NjM2OH0.l-ShAeuPtjjo8-M3uGqTZEJ2oXuZU2N1r2XboMZ9KFY');
    return {
      headers,
    };
  }
  private generateUploadHeaders() {
    // let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    let headers = new HttpHeaders();
    if (localStorage.getItem('token') != undefined) {
      headers = headers.set('x-access-token', localStorage.getItem('token'));
    }
    return {
      headers,
    };
  }

  private generateFormDataHeaders() {
    let headers = new HttpHeaders();

    if (localStorage.getItem('token') != undefined) {
      headers = headers.set('x-access-token', localStorage.getItem('token'));
    }

    return {
      headers,
    };
  }
}
