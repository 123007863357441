import { GenericService } from '../generic/generic.service';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WithdrawRequestService {
  controller = 'withdrawRequest';
  private notify = new Subject<any>();
  notifyObservable$ = this.notify.asObservable();

  constructor(private generic: GenericService) {}

  get() {
    return this.generic.getData(this.controller);
  }

  post(body) {
    return this.generic.create(this.controller, body);
  }

  getByID(id) {
    return this.generic.getDataById(this.controller, id);
  }

  delete(id) {
    return this.generic.delete(this.controller, id);
  }

  update(body) {
    return this.generic.update(this.controller, body);
  }

  search(search, filter, order) {
    return this.generic.getDataBySearch(this.controller, search, filter, order);
  }

  getbyParams(params, order, sort) {
    return this.generic.getDataByParams(this.controller, params, order, sort);
  }

  getstudentWithdrawRequest(id) {
    return this.generic.getDataById(this.controller + '/student', id);
  }

  uploadAttachment(body) {
    return this.generic.imageUpload(
      `${this.controller}/upload-attachment`,
      body,
    );
  }

  deleteAttachment(url) {
    return this.generic.deleteWithOutId(
      `${this.controller}/delete-attachment?fileUrl=${url}`,
    );
  }

  confirmRequest(id) {
    const body = '';
    return this.generic.update(
      this.controller + `/${id}/confirm-request`,
      body,
    );
  }

  cancelRequest(id) {
    const body = '';
    return this.generic.update(this.controller + `/${id}/cancel-request`, body);
  }

  createCheque(id, body) {
    return this.generic.create(this.controller + `/cheque/${id}`, body);
  }

  confirmCheque(id) {
    const body = '';
    return this.generic.update(this.controller + `/cheque/${id}/confirm`, body);
  }

  issueCheque(id, body) {
    return this.generic.update(this.controller + `/cheque/${id}`, body);
  }

  public notifyOther(data: any) {
    if (data) {
      this.notify.next(data);
    }
  }
}
