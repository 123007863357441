import { GenericService } from '../generic/generic.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StudentUserService {
  private readonly controller = 'student';

  constructor(private generic: GenericService) {}

  getAttendance(params) {
    const order = 'asc';
    return this.generic.getDataByParams(
      `${this.controller}/attendance`,
      params,
      order,
    );
  }

  getTimetable() {
    return this.generic.getData(`${this.controller}/timetable`);
  }

  getClasses() {
    return this.generic.getData(`${this.controller}/classes`);
  }

  getClassById(classId) {
    return this.generic.getDataById(`${this.controller}/classes`, classId);
  }

  getStream(classId, params, order = 'desc', sort = 'createdAt') {
    return this.generic.getDataByParams(
      `${this.controller}/classes/${classId}/stream`,
      params,
      order,
      sort,
    );
  }

  addReaction(classId, streamId, body) {
    return this.generic.create(
      `${this.controller}/classes/${classId}/stream/${streamId}/reaction`,
      body,
    );
  }

  getClassWork(classId) {
    return this.generic.getData(
      `${this.controller}/classes/${classId}/classwork`,
    );
  }

  getByIdClassAssignment(classId, assignmentId) {
    return this.generic.getDataById(
      `${this.controller}/classes/${classId}/assignment`,
      assignmentId,
    );
  }

  getByIdMaterial(classId, materialId) {
    return this.generic.getDataById(
      `${this.controller}/classes/${classId}/material`,
      materialId,
    );
  }

  uploadAnswerDocument(classId, assignmentId, questionId, body) {
    return this.generic.imageUpload(
      `${this.controller}/classes/${classId}/assignment/${assignmentId}/question/${questionId}/answer/upload-attachment`,
      body,
    );
  }

  deleteAnswerDocument(classId, assignmentId, questionId, url) {
    return this.generic.deleteWithOutId(
      `${this.controller}/classes/${classId}/assignment/${assignmentId}/question/${questionId}/answer/delete-attachment?fileUrl=${url}`,
    );
  }

  submitAssignment(classId, assignmentId, body) {
    return this.generic.create(
      `${this.controller}/classes/${classId}/assignment/${assignmentId}/submit`,
      body,
    );
  }
}
