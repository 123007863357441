import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-nam-card-custom-columns',
  templateUrl: './list-nam-card-custom-columns.component.html',
  styleUrls: ['./list-nam-card-custom-columns.component.scss'],
})
export class ListNamCardCustomColumnsComponent implements OnInit {
  @Input() heading1: any;
  @Input() heading2: string;
  @Input() heading3: string;
  @Input() shortTextBit: boolean;

  @Input() shortText: string;
  @Input() bottomTextBit: boolean;

  @Input() bottomText: string;

  constructor() {}

  ngOnInit() {}
}
