import { Injectable } from '@angular/core';
import { GenericService } from '../generic/generic.service';

@Injectable({
  providedIn: 'root',
})
export class ResourcesService {
  constructor(private generic: GenericService) {}
  controller = 'resources';
  get() {
    //getting data from service in this method and showing it on list
    return this.generic.getData(this.controller);
  }
  post(body) {
    return this.generic.create(this.controller, body);
  }

  getByID(id) {
    return this.generic.getDataById(this.controller, id);
  }

  delete(id) {
    return this.generic.delete(this.controller, id);
  }
  update(body) {
    return this.generic.update(this.controller, body);
  }
  getbyParams(params) {
    var order = 'desc';
    return this.generic.getDataByParams('resources/newsbydate', params, order);
  }
  getByParamsOld(params) {
    var order = 'desc';
    return this.generic.getDataByParams(this.controller, params, order);
  }
  getbyParamsDashboard(params) {
    var order = 'desc';
    return this.generic.getDataByParams(this.controller, params, order);
  }
  search(search, filter, order) {
    return this.generic.getDataBySearch(this.controller, search, filter, order);
  }
  imageUpload(body) {
    return this.generic.imageUpload('resources/attachment', body);
  }
}
