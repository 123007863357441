/* eslint-disable no-useless-escape */
import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private trackEvent(event: string, properties?: any) {
    if (environment.production && window['mixpanel']) {
      window['mixpanel'].track(event, {
        user_id: localStorage.getItem('id'),
        user_type: localStorage.getItem('user_type_short_name'),
        timestamp: new Date().toISOString(),
        ...properties,
      });
    }
  }

  identifyUser(userId: string, properties?: any) {
    if (environment.production && window['mixpanel']) {
      window['mixpanel'].identify(userId);
      window['mixpanel'].people.set(properties);
    }
  }

  trackViewResult(properties: any) {
    this.trackEvent('View Result', properties);
  }
}
