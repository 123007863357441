import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-attachment-card-shared',
  templateUrl: './attachment-card-shared.component.html',
  styleUrls: ['./attachment-card-shared.component.scss'],
})
export class AttachmentCardSharedComponent {
  @Input() attachmentGroup: any;
  @Input() showDeleteAction: boolean;
  @Output() deleteAttachment: EventEmitter<any> = new EventEmitter();
  fileTypesMap: { [key: string]: { icon: string; displayName: string } } = {
    pdf: { icon: 'picture_as_pdf', displayName: 'PDF' },
    doc: { icon: 'file_copy', displayName: 'Word' },
    docx: { icon: 'file_copy', displayName: 'Word' },
    xlsx: { icon: 'file_copy', displayName: 'Excel' },
    png: { icon: 'photo_size_select_actual', displayName: 'Image' },
    jpg: { icon: 'photo_size_select_actual', displayName: 'Image' },
    jpeg: { icon: 'photo_size_select_actual', displayName: 'Image' },
    default: { icon: 'insert_drive_file', displayName: 'Unknown File' },
  };

  handleDelete() {
    this.deleteAttachment.emit();
  }

  getFileTypeInfo(extension: string): { icon: string; displayName: string } {
    return this.fileTypesMap[extension] || this.fileTypesMap['default'];
  }
}
