import { AuthService } from '../auth/auth.service';
import { BehaviorSubject } from 'rxjs';
import { GenericService } from '../generic/generic.service';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SubjectService {
  private subjects$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  controller = 'subjects';
  constructor(private generic: GenericService, private auth: AuthService) {
    this.auth.getUser().subscribe((res: any) => {
      if (!res) this.subjects$.next(null);
    });
  }

  get() {
    return this.subjects$.getValue()
      ? this.subjects$.asObservable()
      : this.set();
  }

  private set() {
    return this.generic
      .getData(this.controller)
      .pipe(tap((response) => this.subjects$.next(response)));
  }

  getByParams(param) {
    const order = 'asc';
    const sort = 'name';
    return this.generic.getDataByParams(this.controller, param, order, sort);
  }

  post(body) {
    this.subjects$.next(null);
    return this.generic.create(this.controller, body);
  }

  getByID(id) {
    return this.generic.getDataById(this.controller, id);
  }

  delete(id) {
    this.subjects$.next(null);
    return this.generic.delete(this.controller, id);
  }

  update(body) {
    this.subjects$.next(null);
    return this.generic.update(this.controller, body);
  }

  search(search, filter, order) {
    return this.generic.getDataBySearch(this.controller, search, filter, order);
  }
}
