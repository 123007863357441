import * as moment from 'moment';
import { BillingService, ExcelService, PdfService } from 'src/app/core';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { InvoiceModificationModalComponent } from '../invoice-modification-modal/invoice-modification-modal.component';
import { MatDialog } from '@angular/material';
import { NumberPipe } from '../../../pipes/number.pipe';
import { Router } from '@angular/router';

@Component({
  selector: 'app-billing-student-profile-billing-list',
  templateUrl: './billing-student-profile-billing-list.component.html',
  styleUrls: ['./billing-student-profile-billing-list.component.scss'],
  providers: [DatePipe, NumberPipe],
})
export class BillingStudentProfileBillingListComponent
  implements OnInit, OnDestroy {
  @Input() billing;
  @Input() showView;
  @Output() viewType = new EventEmitter();
  @Output() editEvent = new EventEmitter();
  @Output() extendDate = new EventEmitter();
  @Input() loadingExtendDueDate;
  @Input() withdrawDetails;
  currentTab: number;
  excelPDFActions = true;
  switchListActions = true;
  checkboxVisible = false;
  allClasses = [];
  branches = [];
  colSpanButton10 = false;
  colSpanButton8Text = 'Pass';
  colSpanButton9 = false;
  colSpanButton9Text = 'Paid';
  colSpanButton10Text;
  colSpanButton8 = true;
  btn1 = false;
  btn2 = false;
  btn3 = false;
  btn4 = false;
  // btn1Img = this.sanitizer.bypassSecurityTrustHtml("<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M20 3H4C2.897 3 2 3.897 2 5V19C2 20.103 2.897 21 4 21H20C21.103 21 22 20.103 22 19V5C22 3.897 21.103 3 20 3ZM4 19V5H20L20.002 19H4Z' fill='#454B60' /> <path d='M6 7H18V9H6V7ZM6 11H18V13H6V11ZM6 15H12V17H6V15Z' fill='#454B60' /></svg>");
  btn1Img = this.sanitizer.bypassSecurityTrustHtml(
    "<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'> <path d='M12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2ZM12 20C7.589 20 4 16.411 4 12C4 7.589 7.589 4 12 4C16.411 4 20 7.589 20 12C20 16.411 16.411 20 12 20Z' fill='#454B60'/><path d='M9.99909 13.587L7.70009 11.292L6.28809 12.708L10.0011 16.413L16.7071 9.70697L15.2931 8.29297L9.99909 13.587Z' fill='#454B60'/></svg>",
  );
  btn2Img = this.sanitizer.bypassSecurityTrustHtml(
    "<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM13.59 5L10 8.59L6.41 5L5 6.41L8.59 10L5 13.59L6.41 15L10 11.41L13.59 15L15 13.59L11.41 10L15 6.41L13.59 5Z' fill='#454B60'/></svg>",
  );
  btn3Img;
  title = 'Billing Details';
  actionCol = null;
  actionText = 'Action';
  searchText = '';
  addRecord = false;
  branchselect = false;
  showImage = true;
  resetButton = false;
  showCardList = 1;
  showList: any;
  apiData: any;
  totalRecord: any;
  rowCount: any;
  filterList = [];
  colArray = [];
  slicedData = [];
  excelData = [];
  hide = {};
  searchfilter = [];
  filterCategory = '';
  currentPageUrl = '';
  radioFilter: any;
  editID: any;
  limitError: boolean;
  searchKey = false;
  mediumWidth = 115;
  smallWidth = 90;
  largeWidth = 150;
  extraSmallWidth = 50;
  extraLargeWidth = 180;
  colHeightDynamic = 50;
  errorDialog = false;
  loading = false;
  allIncoices = [];
  withdrawInvoices = [];
  // Defined Header Names on this object
  headerName = {
    col1: null,
    col2: null,
    col3: 'Invoice No.',
    col5: 'Amount Payable',
    col6: 'Amount Paid',
    col7: 'Difference Amount',
    col8: 'Due Date',
    col9: 'Received Date',
    col10: 'Status',
    col11: 'Concession',
    col12: 'Adjustment',
    col13: 'Arrears',
    col14: 'Issue Date',
    col15: 'Expiry Date',
    col16: 'Payment Method',
    col17: null,
    col18: null,
    col19: null,
    col20: null,
    col21: null,
    col22: null,
  };
  colWidth = {
    //set width to columns
    colWidth1: this.extraSmallWidth,
    colWidth2: this.smallWidth,
    colWidth3: this.smallWidth,
    colWidth4: this.extraLargeWidth,
    colWidth5: this.largeWidth,
    colWidth6: this.largeWidth,
    colWidth7: this.largeWidth,
    colWidth8: this.largeWidth,
    colWidth9: this.largeWidth,
    colWidth10: this.mediumWidth,
    colWidth11: this.largeWidth,
    colWidth12: this.mediumWidth,
    colWidth13: this.mediumWidth,
    colWidth14: this.largeWidth,
    colWidth15: this.largeWidth,
    colWidth16: this.largeWidth,
    colWidth17: this.smallWidth,
    colWidth18: this.smallWidth,
    colWidth19: this.smallWidth,
    colWidth20: this.smallWidth,
    colWidth21: this.smallWidth,
    colWidth22: this.smallWidth,
    colWidth23: this.smallWidth,
    action: this.mediumWidth,
  };
  // Through this array we are providing filters to filter popup
  paramData = null;
  showOptdiv: any = false;
  cardData: any;
  tooltipTexts: string[] = [];
  studentRollNo;
  billingheads = [];
  showActionHead = true;
  // PDF Table
  tableHeadings = [];
  tableOptions = {
    pdfTitle: 'Billing',
    tableHeadingFontSize: 10,
    charcolColor: '#454b60',
    headerTextClr: '#fff',
    headerBackground: '#3c26bf',
    mainHeadingFontSize: 15,
    tableContentFontSize: 8,
    pageOrientation: 'landscape',
    pageType: 'A4',
    pageMargins: [40, 155, 40, 55],
    footerText:
      'Downloaded By:  ' +
      localStorage.getItem('firstName') +
      ' at' +
      ' ' +
      new Date().toLocaleString(),
    subTitle: `${this.title}`,
    pdfName: 'Billing.pdf',
    download: true,
  };
  userType: any;

  constructor(
    private router: Router,
    private excelService: ExcelService,
    private sanitizer: DomSanitizer,
    public datePipe: DatePipe,
    public dialog: MatDialog,
    public billingService: BillingService,
    public pdfService: PdfService,
  ) {}

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('tabType') set tabType(value: number) {
    this.currentTab = value;
    if (!this.billing || (this.billing && !this.billing.length)) {
      this.getBilling();
    } else {
      this.cardData = this.filterBills(this.billing);
    }
  }

  ngOnInit(): void {
    this.userType = localStorage.getItem('user_type');
    this.currentPageUrl = this.router.url;
    this.getBilling();
  }

  ngOnChanges() {
    this.showCardList = this.showView;

    this.getBilling();
  }
  setTooltipText(index: number, text: string): string {
    this.tooltipTexts[index] = text;
    return text;
  }
  getTooltipText(index: number, head: any): string {
    return head.totalBillingAmount ? head.totalBillingAmount : '-';
  }
  getBilling() {
    this.loading = true;
    if (this.billing) {
      this.apiData = this.filterBills(this.billing);
      this.processBilling(this.apiData);
      this.cardData = this.apiData;
    }

    this.slicedData = this.apiData.map((item) => {
      return {
        col1: item._id,
        col2: null,
        col3: item.invoiceId ? item.invoiceId : '-',
        col4:
          item.transactionId && item.transactionId.depositSlipNo
            ? item.transactionId.depositSlipNo
            : '-',
        col5: item.totalAmount ? item.totalAmount : '-',
        col6:
          item.status.name == 'Paid' && item.receivedAmount
            ? item.receivedAmount -
              (item.serviceCharges ? item.serviceCharges : 0)
            : '-',
        col7:
          item.status.name == 'Paid' && item.receivedAmount
            ? item.totalAmount -
              (item.receivedAmount -
                (item.serviceCharges ? item.serviceCharges : 0))
            : '-',
        col8: item.dueDate ? this.mapDate(item.dueDate) : '-',
        col9:
          item.status.name == 'Paid' && item.receivedDate
            ? this.mapDate(item.receivedDate)
            : '-',
        col10: item.status.name == 'Publish' ? 'Unpaid' : item.status.name,
        col11: item.concessionTotalAmount ? item.concessionTotalAmount : '-',
        col12: item.adjustmentAmount ? item.adjustmentAmount : '-',
        col13: item.arrears ? this.calculateArrears(item.arrears) : '-',
        col14: item.voucherIssueDate
          ? this.mapDate(item.voucherIssueDate)
          : '-',
        col15: item.voucherExpiryDate
          ? this.mapDate(item.voucherExpiryDate)
          : '-',
        col16: item.paymentMethod ? item.paymentMethod : '-',
        col17: null,
        col18: null,
        col19: null,
        col20: null,
        col21: null,
        col22: item.rollNo,
        currentItem: item,
      };
    });
    this.colArray = this.slicedData;
    this.loading = false;

    this.excelData = this.apiData.map((item) => {
      const mainObj = {
        InvoiceNo: item.invoiceId,
        PaymentMethod: item.paymentMethod ? item.paymentMethod : 0,
      };
      this.billingheads.forEach((i) => {
        if (i && i.name) {
          item.billingHeads.forEach((j) => {
            if (i.name == j.fkBillingHeadId.name) {
              mainObj[i.name] = i.totalBillingAmount ? i.totalBillingAmount : 0;
            } else {
              mainObj[i.name] = '-';
            }
          });
        }
      });
      mainObj['Concession'] = item.concessionTotalAmount
        ? item.concessionTotalAmount
        : 0;
      mainObj['Adjustment'] = item.adjustmentAmount ? item.adjustmentAmount : 0;
      mainObj['Status'] =
        item.status.name == 'Publish' ? 'Unpaid' : item.status.name;
      mainObj['Arrears'] = item.arrears
        ? this.calculateArrears(item.arrears)
        : 0;
      mainObj['Due Date'] = item.dueDate ? this.mapDate(item.dueDate) : 0;
      mainObj['Issue Date'] = item.voucherIssueDate
        ? this.mapDate(item.voucherIssueDate)
        : '-';
      mainObj['Expiry Date'] = item.voucherExpiryDate
        ? this.mapDate(item.voucherExpiryDate)
        : 0;
      mainObj['Amount Payable'] = item.totalAmount ? item.totalAmount : 0;
      mainObj['Amount Paid'] =
        item.status.name == 'Paid' && item.receivedAmount
          ? item.receivedAmount
          : 0;
      mainObj['Received Date'] =
        item.status.name == 'Paid' && item.receivedDate
          ? this.mapDate(item.receivedDate)
          : 0;
      mainObj['Difference Amount'] =
        item.status.name == 'Paid' && item.receivedAmount
          ? item.totalAmount - item.receivedAmount
          : 0;

      return mainObj;
    });
    this.withdrawInvoices = [];
    this.allIncoices = [];
    this.apiData.forEach((item) => {
      if (item.batchType == 'withdraw') {
        this.withdrawInvoices.push(item);
      } else {
        this.allIncoices.push(item);
      }
    });
  }

  filterBills(data) {
    let filteredBills = [];
    switch (this.currentTab) {
      case 0: {
        filteredBills = data;
        break;
      }
      case 1: {
        filteredBills = data.filter((x) => x.status.name == 'Paid');
        break;
      }
      case 2: {
        filteredBills = data.filter((x) => x.status.name == 'Publish');
        break;
      }
      case 3: {
        filteredBills = data.filter((x) => x.status.name == 'Expired');
        break;
      }
      case 4: {
        filteredBills = data.filter((x) => x.status.name == 'Cancel');
        break;
      }
    }
    return filteredBills;
  }

  processBilling(data) {
    const mergedArray = [];
    for (const item of data) {
      const billingHeadArray = item.billingHeads.map((x) => ({
        ...x,
        name: x.fkBillingHeadId.name,
      }));
      mergedArray.push(...billingHeadArray);
    }
    const set = new Set();
    const unionArray = mergedArray.filter((item) => {
      if (!set.has(item.name)) {
        set.add(item.name);
        return true;
      }
      return false;
    }, set);
    this.billingheads = unionArray;
  }

  calculateArrears(arrears) {
    let amount = 0;
    arrears.forEach((arrear) => {
      amount += arrear.totalAmount;
    });
    return amount;
  }

  mapDate(date) {
    return moment(date).format('DD-MM-YYYY');
  }

  // Handeling checkboxes  of Filter Popup by getting checkbox indexe

  // Changing modes from list to card Or from Card to List

  //Invoking excel service, and giving parameters to excel method
  exportexcel(): void {
    this.excelService.exportAsExcelFile(this.excelData, this.title);
  }

  edit(id) {
    const dataFiltered = this.billing.filter((item) => item.id == id);
    const dialogRef = this.dialog.open(InvoiceModificationModalComponent, {
      panelClass: 'modal-small-xs',
      data: {
        billingObj: dataFiltered,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.editEvent.emit(true);
    });
  }

  // Changing modes from list to card Or from Card to List
  changeDisplay(mode: number): void {
    this.showCardList = mode;
    this.viewType.emit(mode);
  }

  resetSearch() {
    this.searchKey = false;
    this.searchText = '';
    this.getBilling();
  }

  generateHeads() {
    this.tableHeadings = ['Invoice No', 'Payment Method'];
    this.billingheads.forEach((item) => {
      if (item && item.name) {
        this.apiData.forEach((elem) => {
          elem.billingHeads.forEach((j) => {
            if (item.name == j.fkBillingHeadId.name) {
              this.tableHeadings.push(j.fkBillingHeadId.name);
            }
          });
        });
      }
    });
    this.tableHeadings.push('Concession');
    this.tableHeadings.push('Adjustment');
    this.tableHeadings.push('Status');
    this.tableHeadings.push('Arrears');
    this.tableHeadings.push('Issue Date');
    this.tableHeadings.push('Due Date');
    this.tableHeadings.push('Expiry Date');
    this.tableHeadings.push('Amount Payable');
    this.tableHeadings.push('Amount Paid');
    this.tableHeadings.push('Received Date');
    this.tableHeadings.push('Difference Amount');

    const uniqueArr = this.tableHeadings.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });

    this.tableHeadings = uniqueArr;
  }

  createPdf() {
    const heading = [];
    const values = [];
    const body = [];
    this.generateHeads();
    Object.values(this.tableHeadings).forEach((item) => {
      if (item) {
        heading.push({
          text: item,
          style: 'tableHeader',
        });
      }
    });

    const pdfArray = JSON.parse(JSON.stringify(this.excelData));
    const pdfObj = Object.values(pdfArray);
    pdfObj.forEach((item) => {
      values.push(Object.values(item));
    });
    body.push(heading);
    for (let i = 0; i < values.length; i++) {
      const element = values[i];
      body.push(element);
    }
    this.tableOptions.download = true;
    this.pdfService.generatePdfTable(
      this.tableOptions.pdfTitle,
      this.tableOptions.subTitle,
      body,
      this.tableOptions,
    );
  }

  extendDueDateEmit(item) {
    this.extendDate.emit(item);
  }

  reloadData() {
    this.editEvent.emit();
  }
  ngOnDestroy(): void {
    this.dialog.closeAll();
  }
}
