import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { UserType } from '../../enums-constant/user-type.enum';

@Component({
  selector: 'app-view-meeting-log-modal',
  templateUrl: './view-meeting-log-modal.component.html',
  styleUrls: ['./view-meeting-log-modal.component.scss'],
})
export class ViewMeetingLogModalComponent implements OnInit {
  public log;
  isAdmin;
  grades = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<ViewMeetingLogModalComponent>,
  ) {}

  ngOnInit() {
    const userType = localStorage.getItem('user_type');
    if (userType === UserType.Admin || userType === UserType.Staff) {
      this.isAdmin = true;
    }
    this.log = this.data.log;
    this.grades = this.log.branch;
  }

  onClose(): void {
    this.dialogRef.close();
  }

  getBranchGradeNames(branchName: string, grades: any[]): string {
    if (!branchName) return 'All Branches';
    if (!grades || grades.length === 0) {
      return branchName;
    }
    return `${branchName} - ${grades.map((grade) => grade.name).join(', ')}`;
  }
}
