import { GenericService } from '../../generic/generic.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FlexibleTimetableService {
  private readonly controller = 'flexibleTimetable';
  constructor(private generic: GenericService) {}
  get() {
    return this.generic.getData(this.controller);
  }
  getByID(id) {
    return this.generic.getDataById(this.controller, id);
  }
  getByParams(param) {
    return this.generic.getData(this.controller + '?' + param);
  }
  post(body) {
    return this.generic.create(this.controller, body);
  }
  update(body, id) {
    return this.generic.update(`${this.controller}/${id}`, body);
  }
  delete(id) {
    return this.generic.delete(this.controller, id);
  }
  search(search, filter, order) {
    return this.generic.getDataBySearch(this.controller, search, filter, order);
  }
  assignTimtable(timetableId, body) {
    return this.generic.create(
      `${this.controller}/${timetableId}/assign`,
      body,
    );
  }
  updateAssignTimetable(timetableId, assignId, body) {
    return this.generic.update(
      `${this.controller}/${timetableId}/assign/${assignId}`,
      body,
    );
  }
  deleteAssignTimetable(timetableId, assignId) {
    return this.generic.delete(
      `${this.controller}/${timetableId}/assign/${assignId}`,
      '',
    );
  }
  getStudentTimetable(studentId) {
    return this.generic.getData(`${this.controller}/student/${studentId}`);
  }
  changeTeacherFlexible(body) {
    return this.generic.update(
      `${this.controller}/changeTeacherFlexible`,
      body,
    );
  }
  viewTimetableByBranchAndGrade(branchId, gradeId) {
    return this.generic.getData(
      `${this.controller}/branch/${branchId}/grade/${gradeId}`,
    );
  }
}
