import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HelperServiceService {
  constructor() {}

  duration = 1000;
  horizontalPosition = 'center';
  verticalPosition = 'top';
}
