/* eslint-disable */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'urlFormat',
})
export class UrlFormatPipe implements PipeTransform {
  transform(value: string): boolean {
    const urlPattern =
      /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})(\/([^\s\/?#]+))?(\?([^?#]*))?(#(.*))?$/i;

    return urlPattern.test(value);
  }
}
