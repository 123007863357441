import * as moment from 'moment';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { emailRegex } from 'src/app/shared';

@Component({
  selector: 'app-teacher-add-profile',
  templateUrl: './teacher-add-profile.component.html',
  styleUrls: ['./teacher-add-profile.component.scss'],
})
export class TeacherAddProfileComponent implements OnInit, OnChanges {
  @Input() countries = [];
  @Input() statuses;
  @Input() teacherProfileData;
  @Output() submiTeacherProfile = new EventEmitter();
  @Input() disabledFields = false;

  form: FormGroup;
  submitted = false;
  userStatus;
  passwordHide = false;
  languages = ['Arabic', 'Chinese', 'Sindhi', 'Urdu', 'English'];
  softSkills = [
    'Loom',
    'Slack',
    'Google Classroom',
    'Skype',
    'Zoom',
    'PowerPoint',
    'Word',
    'Excel',
  ];
  hobbies = [
    'Painting',
    'Planting',
    'Cricket',
    'Table Tennis',
    'Basket Ball',
    'Travelling',
    'Writing',
    'Reading',
  ];

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.formPost();
    if (this.teacherProfileData && this.teacherProfileData != undefined) {
      if (this.disabledFields) this.disableFields();
      this.bindData();
    } else {
      this.toggleUserStatus({ checked: true });
    }
  }

  ngOnChanges() {
    if (this.teacherProfileData && this.teacherProfileData != undefined) {
      if (this.disabledFields) this.disableFields();
      this.bindData();
    } else {
      this.toggleUserStatus({ checked: true });
    }
  }

  formPost() {
    this.form = this.fb.group({
      firstName: new FormControl(undefined, Validators.required),
      lastName: new FormControl(undefined, Validators.required),
      employeeCode: new FormControl(undefined, Validators.required),
      email: new FormControl(undefined, [
        Validators.required,
        Validators.pattern(emailRegex),
      ]),
      cnic: new FormControl(undefined, [Validators.pattern('[0-9]{13}')]),
      phoneCountryCode: new FormControl(undefined, Validators.required),
      phone: new FormControl(undefined, [
        Validators.required,
        Validators.pattern('[0-9]{10}'),
      ]),
      address: new FormControl(undefined),
      nationality: new FormControl(undefined),
      martialStatus: new FormControl(undefined),
      gender: new FormControl(undefined, Validators.required),
      dob: new FormControl(undefined, Validators.required),
      doj: new FormControl(undefined, Validators.required),
      emergencyContactName: new FormControl(undefined),
      emergencyContactNo: new FormControl(undefined, [
        Validators.pattern('[0-9]{11}'),
      ]),
      language: new FormControl(undefined),
      teachRemotely: new FormControl(false),
      softSkill: new FormControl(undefined),
      activityHobby: new FormControl(undefined),
      about: new FormControl(undefined),
      password: new FormControl(undefined, Validators.required),
    });
  }

  disableFields() {
    this.form.get('firstName').disable();
    this.form.get('lastName').disable();
    this.form.get('email').disable();
    // this.form.get('cnic').disable();
    this.form.get('employeeCode').disable();
    this.form.get('dob').disable();
    this.form.get('doj').disable();
  }

  addLanguage = (language) => language; // TODO: Remove this function

  addSoftSkill = (skill) => skill; // TODO: Remove this function

  addHobbies = (hobby) => hobby; // TODO: Remove this function

  get FormControl() {
    return this.form.controls;
  }

  bindData() {
    this.userStatus = this.teacherProfileData.status;
    this.form.get('password').clearValidators();
    this.form.get('password').updateValueAndValidity();
    this.form.patchValue({
      firstName: this.teacherProfileData.firstName,
      lastName: this.teacherProfileData.lastName,
      employeeCode: this.teacherProfileData.employeeCode,
      email: this.teacherProfileData.email,
      cnic: this.teacherProfileData.cnic
        ? this.teacherProfileData.cnic
        : undefined,
      phoneCountryCode: this.teacherProfileData.phoneCountryCode.id,
      phone: this.teacherProfileData.phone,
      address: this.teacherProfileData.address
        ? this.teacherProfileData.address
        : undefined,
      nationality: this.teacherProfileData.nationality
        ? this.teacherProfileData.nationality
        : undefined,
      martialStatus: this.teacherProfileData.martialStatus
        ? this.teacherProfileData.martialStatus
        : undefined,
      gender: this.teacherProfileData.gender
        ? this.teacherProfileData.gender
        : undefined,
      dob: this.teacherProfileData.dob,
      doj: this.teacherProfileData.doj,
      emergencyContactName: this.teacherProfileData.emergencyContactName
        ? this.teacherProfileData.emergencyContactName
        : undefined,
      emergencyContactNo: this.teacherProfileData.emergencyContactNo
        ? this.teacherProfileData.emergencyContactNo
        : undefined,
      language: this.teacherProfileData.language
        ? this.teacherProfileData.language.map((x) => x)
        : undefined,
      teachRemotely: this.teacherProfileData.teachRemotely,
      softSkill: this.teacherProfileData.softSkill
        ? this.teacherProfileData.softSkill.map((x) => x)
        : undefined,
      activityHobby: this.teacherProfileData.activityHobby
        ? this.teacherProfileData.activityHobby.map((x) => x)
        : undefined,
      about: this.teacherProfileData.about
        ? this.teacherProfileData.about
        : undefined,
    });
  }

  toggleUserStatus($event) {
    if ($event.checked == true) {
      this.userStatus = this.statuses.find((x) => x.name === 'ACTIVATED');
    } else {
      this.userStatus = this.statuses.find((x) => x.name === 'DEACTIVATED');
    }
  }

  togglePassoordVisiblity() {
    this.passwordHide = !this.passwordHide;
  }

  submit(nextTab) {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    const teacherProfile = this.form.getRawValue();
    teacherProfile.status = this.userStatus._id;
    teacherProfile.dob = moment(teacherProfile.dob).format('YYYY-MM-DD');
    teacherProfile.doj = moment(teacherProfile.doj).format('YYYY-MM-DD');

    this.submiTeacherProfile.emit({ teacherProfile, nextTab });
  }
}
