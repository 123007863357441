import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { CustomSnackbarDeleteComponent } from 'src/app/shared/components/custom-snackbar-delete/custom-snackbar-delete.component';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { helper } from 'src/app/shared/helper-functions/helper';

@Injectable({
  providedIn: 'root',
})
export class TokenExpiryInterceptor implements HttpInterceptor {
  constructor(public router: Router, private _snackBar: MatSnackBar) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error) {
          if (error.error.message == 'token expired') {
            localStorage.clear();
            this.router.navigate(['login']);
            this._snackBar.openFromComponent(CustomSnackbarDeleteComponent, {
              data: { message: 'Session Expired. Please login again' },
              horizontalPosition: 'center',
              duration: helper.duration,
              panelClass: ['delete-snackBar'],
              verticalPosition: 'top',
            });
            return throwError('Session Expired. Please login again');
          }
        }
        return throwError(error);
      }),
    );
  }
}
