import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UserType } from '../../enums-constant/user-type.enum';

@Component({
  selector: 'app-program-card',
  templateUrl: './program-card.component.html',
  styleUrls: ['./program-card.component.scss'],
})
export class ProgramCardComponent {
  @Input() program;
  constructor(private router: Router) {}

  goToEnroll() {
    const userType = localStorage.getItem('user_type');
    if (userType == UserType.Student) {
      this.router.navigateByUrl('student/extracurricular');
    } else if (userType == UserType.Parent) {
      this.router.navigateByUrl('parent/extracurricular');
    }
  }
}
