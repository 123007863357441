import * as moment from 'moment';
import { GenericService } from '../../generic/generic.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StudentEventsService {
  controller = 'student'; /// upcommingevent
  constructor(private generic: GenericService) {}
  get() {
    return this.generic.getData(this.controller + '/events');
  }

  getByID(id) {
    return this.generic.getDataById(this.controller + '/events', id);
  }

  postRsvp(params, body) {
    return this.generic.create(
      this.controller + '/events/' + params + '/rsvp',
      body,
    );
  }
  imageUpload(body) {
    return this.generic.imageUpload('events/banner', body);
  }

  getbyParams(params) {
    const order = 'asc';
    return this.generic.getDataByParams(
      this.controller + '/events',
      params,
      order,
      'fromDate',
    );
  }
  getAttendance() {
    return this.generic.getData(`${this.controller}/attendanceinsight`);
  }

  getUpcomingEvents(): Observable<any> {
    const todayDate = moment();
    const url = `fromDate=${todayDate.format('YYYY-MM-DD')}&limit=3`;
    return this.getbyParams(url);
  }

  getCalendarEvents(dates?: any): Observable<any> {
    const firstDay =
      dates && dates.startDate
        ? moment(dates.startDate).startOf('month').format('YYYY-MM-DD')
        : moment().startOf('month').format('YYYY-MM-DD');
    const lastDay =
      dates && dates.endDate
        ? moment(dates.endDate).endOf('month').format('YYYY-MM-DD')
        : moment().endOf('month').format('YYYY-MM-DD');
    const url = `fromDate=${firstDay}&toDate=${lastDay}`;

    return this.getbyParams(url);
  }
}
