import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-stats-box',
  templateUrl: './stats-box.component.html',
  styleUrls: ['./stats-box.component.scss'],
})
export class StatsBoxComponent implements OnInit {
  @Input() loader: any;
  @Input() headingText: any;
  @Input() count: string;
  @Input() smallCount: string;
  @Input() bottomText: string;
  @Input() positiveSmallCount: boolean;
  @Input() positiveBottomText: boolean;

  constructor() {}

  ngOnInit() {
    // this.headingText = 'dsdsd'
  }
}
