import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-timetable-cards',
  templateUrl: './timetable-cards.component.html',
  styleUrls: ['./timetable-cards.component.scss'],
})
export class TimetableCardsComponent {
  @Input() data;
  @Input() timetable;
  @Input() isFrom;
  @Input() section;
  @Input() teacherProfileId;

  showCard(a): boolean {
    if (this.teacherProfileId) {
      return (
        (a.teacher && a.teacher._id == this.teacherProfileId) ||
        (a.secondaryTeacher && a.secondaryTeacher._id == this.teacherProfileId)
      );
    } else {
      return a.section._id == this.section.id;
    }
  }
}
