import { GenericService } from '../generic/generic.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CourseService {
  constructor(private generic: GenericService) {}
  controller = 'courses';
  get() {
    //getting data from service in this method and showing it on list
    return this.generic.getData(this.controller);
  }

  getByParams(param) {
    return this.generic.getData(this.controller + '?' + param);
  }

  getForParents(params) {
    //getting data from service in this method and showing it on list
    return this.generic.getData(
      'student/' + this.controller + `?studentId=${params}`,
    );
  }

  getForStuents() {
    //getting data from service in this method and showing it on list
    return this.generic.getData('student/' + this.controller);
  }

  post(body) {
    return this.generic.create(this.controller, body);
  }

  enroll(id, studentId, programId) {
    return this.generic.create(
      `${this.controller}/${id}/enrolment?studentId=${studentId}&programId=${programId}`,
      {},
    );
  }
  unEnroll(id, studentId) {
    return this.generic.delete(
      `${this.controller}/${id}`,
      `unenrolment?studentId=${studentId} `,
    );
  }

  getByID(id) {
    return this.generic.getDataById(this.controller, id);
  }

  delete(id) {
    return this.generic.delete(this.controller, id);
  }
  update(body) {
    return this.generic.update(this.controller, body);
  }

  bannerUpload(body) {
    return this.generic.imageUpload('courses/banner', body);
  }

  iconUpload(body) {
    return this.generic.imageUpload('courses/icon', body);
  }
  // getbyParams(params) {
  //   var order = 'desc'
  //   return this.generic.getDataByParams('news/newsbydate', params, order)
  // }
  search(search, filter, order) {
    return this.generic.getDataBySearch(this.controller, search, filter, order);
  }
  getAttendeesList(param) {
    return this.generic.getData(
      `${this.controller}/attendeesList` + '?' + param,
    );
  }
}
