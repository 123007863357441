import { AuthService } from '../auth/auth.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { GenericService } from '../generic/generic.service';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/internal/operators/tap';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  private stat$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  controller = 'states';
  constructor(private generic: GenericService, private auth: AuthService) {
    this.auth.getUser().subscribe((res: any) => {
      if (!res) this.stat$.next(null);
    });
  }

  getStatesByCountryID(id) {
    return this.generic.getData('states?country=' + id);
  }

  get() {
    return this.stat$.getValue() ? this.stat$.asObservable() : this.set();
  }

  private set() {
    return this.generic
      .getData(this.controller)
      .pipe(tap((response) => this.stat$.next(response)));
  }

  post(body) {
    return this.generic.create(this.controller, body);
  }
  getByID(id) {
    //return this.generic.getData('states?CoId='+id);
    return this.generic.getDataById(this.controller, id);
  }
  delete(id) {
    return this.generic.delete(this.controller, id);
  }
  update(body) {
    return this.generic.update(this.controller, body);
  }
}
