import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { GenericService } from '../generic/generic.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { configRoute } from 'src/app/config/config-route';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BillingHeadService {
  apiUrl: any;
  tokenId: any;
  controller = 'billingheads';

  constructor(public http: HttpClient, private generic: GenericService) {
    this.apiUrl = environment.apiBaseUrl; // config apiUrl
    this.tokenId = this.getToken(); // config token
  }

  //get Billing head data service ...
  // x-access-token is key to pass token in header
  getBillingHeads(params): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-access-token': this.tokenId,
    });
    const httpOptions = {
      headers: headers,
    };
    const url = this.apiUrl + configRoute.billingHeads + '?' + params;

    return this.http.get<any>(url, httpOptions).pipe(
      map((response) => {
        return response;
      }),
      catchError((err, caught) => {
        throw err;
      }),
    );
  }

  // pagination by page limit and size
  getBillingHeadsByPerPage(lm: number, pg: number): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-access-token': this.tokenId,
    });
    const httpOptions = {
      headers: headers,
      params: new HttpParams({
        fromString: `showall=true&limit=${lm}&pg=${pg}`,
      }),
    };
    const url = this.apiUrl + configRoute.billingHeads;

    return this.http.get<any>(url, httpOptions).pipe(
      map((response) => {
        return response;
      }),
      catchError((err, caught) => {
        throw err;
      }),
    );
  }

  // edit billing head by item id

  getBillingHeadById(id: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-access-token': this.tokenId,
    });
    const httpOptions = {
      headers: headers,
    };
    const url = this.apiUrl + configRoute.billingHeads + '/' + id;

    return this.http.get<any>(url, httpOptions).pipe(
      map((response) => {
        return response;
      }),
      catchError((err, caught) => {
        throw err;
      }),
    );
  }
  getAllStatus() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-access-token': this.tokenId,
    });
    const httpOptions = {
      headers: headers,
    };
    const url = this.apiUrl + configRoute.status;

    return this.http.get<any>(url, httpOptions).pipe(
      map((response) => {
        return response;
      }),
      catchError((err, caught) => {
        throw err;
      }),
    );
  }

  getStatusById(id: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-access-token': this.tokenId,
    });
    const httpOptions = {
      headers: headers,
    };
    const url = this.apiUrl + configRoute.status + '/' + id;

    return this.http.get<any>(url, httpOptions).pipe(
      map((response) => {
        return response;
      }),
      catchError((err, caught) => {
        throw err;
      }),
    );
  }

  //post request service ..

  submitForm(data: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-access-token': this.tokenId,
    });
    const httpOptions = {
      headers: headers,
    };
    const url = this.apiUrl + configRoute.billingHeads;
    return this.http.post<any>(url, data, httpOptions).pipe(
      map((response) => {
        return response;
      }),
      catchError((err, caught) => {
        throw err;
      }),
    );
  }

  editForm(data: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-access-token': this.tokenId,
    });
    const httpOptions = {
      headers: headers,
    };
    const url = this.apiUrl + configRoute.billingHeads;
    return this.http.put<any>(url, data, httpOptions).pipe(
      map((response) => {
        return response;
      }),
      catchError((err, caught) => {
        throw err;
      }),
    );
  }

  setToken(token: string): void {
    localStorage.setItem('token', token);
  }

  getToken() {
    if (localStorage.getItem('token') != undefined) {
      return localStorage.getItem('token');
    }
  }

  //// DAnial Work

  get(param?) {
    return this.generic.getData(this.controller + `?${param}`);
  }

  post(body) {
    return this.generic.create(this.controller, body);
  }

  getByID(id) {
    return this.generic.getDataById(this.controller, id);
  }
  delete(id) {
    return this.generic.delete(this.controller, id);
  }
  update(body) {
    return this.generic.update(this.controller, body);
  }
  search(search, filter, order) {
    return this.generic.getDataBySearch(this.controller, search, filter, order);
  }
}
