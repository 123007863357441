import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-timeline-dashboard',
  templateUrl: './timeline-dashboard.component.html',
  styleUrls: ['./timeline-dashboard.component.scss'],
})
export class TimelineDashboardComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
