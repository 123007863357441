import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BillingHeadService } from 'src/app/core/services/billing-head/billing-head.service';

@Component({
  selector: 'app-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss'],
})
export class ListViewComponent implements OnChanges {
  @Input() tableConfig: any[] = [];
  @Input() bodyList: any;
  @Input() pageCount;
  @Input() pageSize;

  @Output() EditItem = new EventEmitter<string>();
  @Output() DeleteItem = new EventEmitter<string>();
  @Output() ViewItem = new EventEmitter<string>();
  @Output() Pagination = new EventEmitter<string>();

  // @ViewChild(MatPaginator)
  // paginator!: MatPaginator;
  // set paginator(value: MatPaginator) {
  //   this.dataSource.paginator = value;
  // }
  keys: string[] = [];
  upDateList: any;
  showList = false;
  term!: string;
  loading;
  // pageEvent: PageEvent | undefined;
  constructor(
    private cdr: ChangeDetectorRef,
    private billingHeadService: BillingHeadService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    this.loading = true;
    if (changes.bodyList && changes.bodyList.currentValue) {
      this.updateList(changes.bodyList.currentValue);
    }
  }

  private updateList(input: any) {
    this.upDateList = input;
    this.loading = false;
  }
  async actionController(action: any, value: any) {
    if (action == 'edit') {
      this.EditItem.emit(value);
    } else if (action == 'delete') {
      this.DeleteItem.emit(value);
    } else {
      this.ViewItem.emit(value);
    }
  }

  //pagination..
  async paginationChange(paginationDetails: any) {
    //
    this.Pagination.emit(paginationDetails);
  }
  changeMode(value) {
    this.showList = value;
  }
}
