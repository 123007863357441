import * as moment from 'moment';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-teacher-qualification',
  templateUrl: './teacher-qualification.component.html',
  styleUrls: ['./teacher-qualification.component.scss'],
})
export class TeacherQualificationComponent implements OnInit, OnChanges {
  @Input() cities;
  @Input() countries;
  @Input() states;
  @Input() statuses;
  @Input() teacherProfileData;
  @Output() submitTeacherQualification = new EventEmitter();
  @Output() selectStatesByCountry = new EventEmitter();
  @Output() selectCitesByState = new EventEmitter();

  form: FormGroup;
  submitted = false;
  qualificationList = ['Bachelor', 'Master']; // TODO: rename to qualificationOptions

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.formPost();
    if (this.teacherProfileData && this.teacherProfileData != undefined) {
      this.bindData();
    }
  }

  ngOnChanges() {
    if (this.teacherProfileData && this.teacherProfileData != undefined) {
      this.bindData();
    }
  }

  formPost() {
    this.form = this.fb.group({
      qualifications: this.fb.array([
        this.createTeacherQualificationFormGroup(),
      ]),
    });
  }

  get qualifications(): FormArray {
    return this.form.get('qualifications') as FormArray;
  }

  createTeacherQualificationFormGroup(): FormGroup {
    return this.fb.group({
      id: [undefined],
      qualification: [undefined, Validators.required],
      educationalInstitution: [undefined, Validators.required],
      gradeResult: [undefined],
      country: [undefined, Validators.required],
      state: [undefined, Validators.required],
      city: [undefined, Validators.required],
      specilization: [undefined],
      start: [undefined, Validators.required],
      end: [undefined, Validators.required],
    });
  }

  addTeacherQualification() {
    this.qualifications.push(this.createTeacherQualificationFormGroup());
  }

  removeTeacherQualification(i: number) {
    this.qualifications.removeAt(i);
  }

  bindData() {
    const qualifications = this.teacherProfileData.qualifications;
    if (qualifications && qualifications.length === 0) return;
    this.qualifications.controls.length = 0;
    qualifications.forEach((qualification, index) => {
      this.handleSelectCountry(qualification.country.id, index);
      this.handleSelectState(qualification.state.id, index);
      const qualificationGroup = this.fb.group({
        id: [qualification._id],
        qualification: [qualification.qualification, Validators.required],
        educationalInstitution: [
          qualification.educationalInstitution
            ? qualification.educationalInstitution
            : undefined,
          Validators.required,
        ],
        gradeResult: [
          qualification.gradeResult ? qualification.gradeResult : undefined,
        ],
        country: [qualification.country.id, Validators.required],
        state: [qualification.state.id, Validators.required],
        city: [qualification.city.id, Validators.required],
        specilization: [
          qualification.specilization ? qualification.specilization : undefined,
        ],
        start: [qualification.start, Validators.required],
        end: [qualification.end, Validators.required],
      });
      this.qualifications.push(qualificationGroup);
    });
  }

  handleSelectCountry($ev, index) {
    this.selectStatesByCountry.emit({ countryId: $ev, index });
  }

  handleSelectState($ev, index) {
    this.selectCitesByState.emit({ stateId: $ev, index });
  }

  submit(nextTab) {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    let { qualifications } = this.form.getRawValue();

    qualifications = qualifications.map((qualification) => {
      return {
        ...qualification,
        start: moment(qualification.start).format('YYYY-MM-DD'),
        end: moment(qualification.end).format('YYYY-MM-DD'),
      };
    });

    this.submitTeacherQualification.emit({ qualifications, nextTab });
  }
}
