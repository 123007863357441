import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-table-records',
  templateUrl: './list-table-records.component.html',
  styleUrls: ['./list-table-records.component.scss'],
})
export class ListTableRecordsComponent implements OnInit {
  @Input() heading1: any;
  @Input() heading2: string;
  @Input() heading3: string;
  @Input() shortTextBit: boolean;

  @Input() shortText: string;
  @Input() bottomTextBit: boolean;

  @Input() bottomText: string;
  constructor() {}

  ngOnInit() {}
}
