import { Component, Input, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material';

@Component({
  selector: 'app-timetable-screen',
  templateUrl: './timetable-screen.component.html',
  styleUrls: ['./timetable-screen.component.scss'],
})
export class TimetableScreenComponent implements OnInit {
  @Input() timetable;
  @Input() isFrom;
  @Input() section;
  @Input() data;
  @Input() teacherProfileId;
  selectedIndex;
  selectedDayTimeTable;
  // currentDay;
  ngOnInit() {
    this.selectedDayTimeTable = this.timetable.structure[0];
  }
  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.selectedIndex = tabChangeEvent.index;
    this.selectedDayTimeTable = this.timetable.structure[this.selectedIndex];
  }
}
