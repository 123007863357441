import * as moment from 'moment';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ChequeDetailModalComponent } from '../billing-student-profile/cheque-detail-modal/cheque-detail-modal.component';
import { CustomSnackbarDeleteComponent } from '../custom-snackbar-delete/custom-snackbar-delete.component';
import { InvoiceModificationModalComponent } from '../billing-student-profile/invoice-modification-modal/invoice-modification-modal.component';
import { InvoiceService } from '../../../core';
import { Subject } from 'rxjs';
import { UserType } from '../../enums-constant/user-type.enum';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-billing-card',
  templateUrl: './billing-card.component.html',
  styleUrls: ['./billing-card.component.scss'],
})
export class BillingCardComponent {
  loading$ = new Subject<boolean>();
  @Input() item;
  @Input() billing;
  @Input() isCancelBill = false;
  @Input() cancelPublishBill = false;
  @Output() billCancelled: EventEmitter<any> = new EventEmitter();
  @Output() extendDate: EventEmitter<any> = new EventEmitter();
  @Input() loadingExtendDueDate = '';
  @Input() withdrawDetails;
  @Input() showCollectCashButton = false;
  @Input() routeData: any;
  @Output() collectCashEvent = new EventEmitter();
  @Output() editEvent = new EventEmitter();
  loading = false;
  userType = localStorage.getItem('user_type');

  constructor(
    public invoiceService: InvoiceService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
  ) {}

  downloadInvoice(invoice) {
    this.loading$.next(true);
    this.invoiceService.viewInvoice(`invoiceId=${invoice.invoiceId}`).subscribe(
      (res: any) => {
        this.loading$.next(false);
        if (Object.keys(res.data).length !== 0) {
          const pdfSource = res.data;
          this.loading = false;
          const newWindow = window.open(pdfSource, '_blank');
          if (!newWindow) {
            alert(
              'A pop-up blocker prevented opening the document. Please allow pop-ups and try again.',
            );
          }
        } else {
          this.loading = false;
          this._snackBar.openFromComponent(CustomSnackbarDeleteComponent, {
            data: { message: res.message },
            horizontalPosition: 'center',
            duration: 2000,
            panelClass: ['delete-snackBar'],
            verticalPosition: 'top',
          });
        }
      },
      () => {
        this.loading$.next(false);
      },
    );
  }

  cancelBill(item) {
    this.billCancelled.emit(item);
  }

  cancelPublishedBill(item) {
    this.billCancelled.emit(item.id);
  }

  showDownloadButton(hValue) {
    if (this.userType == UserType.Parent || this.userType == UserType.Student) {
      if (
        hValue.status.name != 'Paid' &&
        moment(hValue.voucherExpiryDate).isSameOrAfter(moment())
      ) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }

  showExtendButton(hValue) {
    if (this.userType == UserType.Parent && hValue.status.name == 'Publish') {
      return true;
    }
    return false;
  }

  extendDueDate(item) {
    this.extendDate.emit(item);
  }

  chequeDetail(item) {
    const dialogRef = this.dialog.open(ChequeDetailModalComponent, {
      panelClass: 'modal-small-xs',
      data: item,
    });
  }
  extendDates(data) {
    const dataFiltered = this.billing.filter((item) => item.id == data._id);
    const dialogRef = this.dialog.open(InvoiceModificationModalComponent, {
      panelClass: 'modal-small-xs',
      data: {
        billingObj: dataFiltered,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.editEvent.emit(true);
    });
  }

  checkDueDate(date) {
    const today = moment().startOf('day');
    const dueDate = moment(date).startOf('day');
    return !dueDate.isBefore(today);
  }

  collectCash(item) {
    this.collectCashEvent.emit(item);
  }
}
