import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-document-attachment',
  templateUrl: './document-attachment.component.html',
  styleUrls: ['./document-attachment.component.scss'],
})
export class DocumentAttachmentComponent implements OnInit {
  @Input() heading: string;
  constructor() {}

  ngOnInit() {}
}
