import { GenericService } from '../generic/generic.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MonthService {
  controller = 'months';
  constructor(private generic: GenericService) {}
  get() {
    //getting data from service in this method and showing it on list
    return this.generic.getDataByParams(
      this.controller,
      'showall=true',
      'asc',
      'order',
    ); // this.generic.getData(this.controller);
  }

  getByID(id) {
    return this.generic.getDataById(this.controller, id);
  }
}
