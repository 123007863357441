import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-cheque-detail-modal',
  templateUrl: './cheque-detail-modal.component.html',
  styleUrls: ['./cheque-detail-modal.component.scss'],
})
export class ChequeDetailModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<any>,
  ) {}

  closeDialog() {
    this.dialogRef.close();
  }
}
