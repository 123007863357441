import { Component, Input, OnInit } from '@angular/core';
import { helper } from '../../helper-functions/helper';

@Component({
  selector: 'app-progress-circle',
  templateUrl: './progress-circle.component.html',
  styleUrls: ['./progress-circle.component.scss'],
})
export class ProgressCircleComponent implements OnInit {
  @Input() percent;
  color = helper.primaryColor;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
  ngOnInit() {}

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
  ngOnChanges(value) {
    // console.log('valuevaluevaluevaluevalue', value);
    // console.log(this.percent);
  }
}
