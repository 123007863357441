import { GenericService } from '../generic/generic.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LmsClassLessonPlanService {
  private readonly controller = 'classes';
  private readonly resource = 'lesson-plan';
  private readonly lessonPlanBank = 'class-lesson-plan-bank';
  private readonly uploadPdfEndpoint = 'upload-attachment'; // Assuming lessons also have attachments
  private readonly deletePdfEndpoint = 'delete-attachment'; // Assuming lessons also have attachments

  constructor(private generic: GenericService) {}

  getAllLessons(classId, param) {
    return this.generic.getData(
      `${this.controller}/${classId}/${this.resource}` + '?' + param,
    );
  }

  getLessonById(classId, lessonId) {
    return this.generic.getDataById(
      `${this.controller}/${classId}/${this.resource}`,
      lessonId,
    );
  }

  post(classId, body) {
    return this.generic.create(
      `${this.controller}/${classId}/${this.resource}`,
      body,
    );
  }

  update(body, classId, lessonId) {
    return this.generic.update(
      `${this.controller}/${classId}/${this.resource}/${lessonId}`,
      body,
    );
  }

  delete(classId, lessonId) {
    return this.generic.delete(
      `${this.controller}/${classId}/${this.resource}`,
      lessonId,
    );
  }

  uploadDocument(classId, body) {
    return this.generic.imageUpload(
      `${this.controller}/${classId}/${this.resource}/${this.uploadPdfEndpoint}`,
      body,
    );
  }

  deleteDocument(classId, url) {
    return this.generic.deleteWithOutId(
      `${this.controller}/${classId}/${this.resource}/${this.deletePdfEndpoint}?fileUrl=${url}`,
    );
  }

  addToLessonBank(body: any) {
    return this.generic.create(`${this.lessonPlanBank}`, body);
  }

  getLessonsFromBank(subject, grade) {
    return this.generic.getData(
      `${this.lessonPlanBank}?subject=${subject}&grade=${grade}`,
    );
  }
}
