import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { GenericService } from '../generic/generic.service';
import { Injectable } from '@angular/core';
import { configRoute } from 'src/app/config/config-route';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BankSetupService {
  apiUrl: any;
  tokenId: any;
  controller = 'banklist';
  controllerV2 = 'banks';
  constructor(public http: HttpClient, private generic: GenericService) {
    this.apiUrl = environment.apiBaseUrl; // config apiUrl
    this.tokenId = localStorage.getItem('token'); // config token
  }
  // get all branches from server
  getBranches() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-access-token': this.tokenId,
    });
    const httpOptions = {
      headers: headers,
    };
    const url = this.apiUrl + configRoute.branches;
    return this.http.get<any>(url, httpOptions).pipe(
      map((response) => {
        return response;
      }),
      catchError((err, caught) => {
        throw err;
      }),
    );
  }

  //get all list of bank
  getBanks(lm: number, pg: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-access-token': this.tokenId,
    });
    const httpOptions = {
      headers: headers,
      params: new HttpParams({
        fromString: `showall=true&limit=${lm}&pg=${pg}`,
      }),
    };
    const url = this.apiUrl + configRoute.banks;
    return this.http.get<any>(url, httpOptions).pipe(
      map((response) => {
        return response;
      }),
      catchError((err, caught) => {
        throw err;
      }),
    );
  }
  getBankById(id: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-access-token': this.tokenId,
    });
    const httpOptions = {
      headers: headers,
    };
    const url = this.apiUrl + configRoute.banks + '/' + id;

    return this.http.get<any>(url, httpOptions).pipe(
      map((response) => {
        return response;
      }),
      catchError((err, caught) => {
        throw err;
      }),
    );
  }

  submitForm(data: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-access-token': this.tokenId,
    });
    const httpOptions = {
      headers: headers,
    };
    const url = this.apiUrl + configRoute.banks;
    return this.http.post<any>(url, data, httpOptions).pipe(
      map((response) => {
        return response;
      }),
      catchError((err, caught) => {
        throw err;
      }),
    );
  }
  editForm(data: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-access-token': this.tokenId,
    });
    const httpOptions = {
      headers: headers,
    };
    const url = this.apiUrl + configRoute.banks;
    return this.http.put<any>(url, data, httpOptions).pipe(
      map((response) => {
        return response;
      }),
      catchError((err, caught) => {
        throw err;
      }),
    );
  }
  imageUpload(body) {
    return this.generic.imageUpload('banks/logo/', body);
  }
  delete(id: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-access-token': this.tokenId,
    });
    const httpOptions = {
      headers: headers,
    };
    const url = this.apiUrl + configRoute.banks + '/' + id;

    return this.http.delete<any>(url, httpOptions).pipe(
      map((response) => {
        return response;
      }),
      catchError((err, caught) => {
        throw err;
      }),
    );
  }

  getBySchoolId(schoolId) {
    return this.generic.getData(`${this.controllerV2}/bySchool/${schoolId}`);
  }
}
