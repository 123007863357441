import { Injectable } from '@angular/core';
import { GenericService } from '../../generic/generic.service';

@Injectable({
  providedIn: 'root',
})
export class ClassAttendanceService {
  constructor(private generic: GenericService) {}

  controller = 'attendance/class-attendance/report';

  getAttendanceOfClassReport(params) {
    var order = 'desc';
    return this.generic.getDataByParams(this.controller, params, order);
  }
}
