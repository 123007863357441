import * as moment from 'moment';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog, MatMenuTrigger, MatSnackBar } from '@angular/material';
import { DatePipe } from '@angular/common';
import { EventDetailModalComponent } from '../event-detail-modal/event-detail-modal.component';
import { EventService } from 'src/app/core';
import { OrderPipe } from '../../../pipes/ngx-order.pipe';
import { ParentEventService } from 'src/app/core/services/parent/parent-events/parent-event.service';
import { StudentEventsService } from 'src/app/core/services/student/student-events/student-events.service';
import { Subscription } from 'rxjs';
import { TeacherUserEventService } from 'src/app/core/services/teacher-user/teacher-user-event.service';

@Component({
  selector: 'app-week-view-shared',
  templateUrl: './week-view.component.html',
  styleUrls: ['./week-view.component.scss'],
  providers: [DatePipe, OrderPipe],
})
export class WeekViewComponent implements OnInit, OnDestroy {
  dates;
  days = [];
  startDate;
  endDate;
  timeChunks = [];
  weekNumber = moment().week();
  @Output() selected = new EventEmitter();
  startnewTime = new Date();
  endnewTime = new Date();
  eventArray = [];
  height: number;
  showdiv;
  indexDiv;
  date = new Date();
  selectedDate = new Date();
  @Input() service;
  loading = false;
  weekEventData = [];
  isAdmin = false;
  userType: string;
  studentId = localStorage.getItem('studentId');
  languageMenuTrigger: MatMenuTrigger;
  duplicateObj = [];
  subscription: Subscription;

  constructor(
    public datePipe: DatePipe,
    private eventService: EventService,
    public dialog: MatDialog,
    private eventTeacherUserService: TeacherUserEventService,
    private eventParentService: ParentEventService,
    private eventStudentService: StudentEventsService,
    private snackBar: MatSnackBar,
  ) {}
  ngOnInit(): void {
    this.eventInit();
    this.days = moment.weekdaysShort();
    this.weekDays(this.weekNumber);
    this.timeFormul();
    this.subscription = this.eventService.notifyObservable$.subscribe((res) => {
      if (res.option == 'refresh-event-weekView') {
        this.getEvents();
      }
    });
  }

  async eventInit(): Promise<void> {
    await this.switchService(this.service);
    this.getEvents();
  }

  private switchService(mock: string): void {
    if (mock === 'student') {
      this.service = this.eventStudentService;
    } else if (mock === 'admin') {
      this.isAdmin = true;
      this.service = this.eventService;
    } else if (mock === 'parent') {
      this.service = this.eventParentService;
    } else if (mock === 'teacher') {
      this.service = this.eventTeacherUserService;
    } else {
      this.service = this.eventService;
    }
  }

  getUserType(): void {
    this.eventParentService.userTypeVisibility.subscribe((type) => {
      this.userType = type;
    });
  }

  setWeek(i: number): void {
    this.weekNumber = this.weekNumber + i;
    this.weekDays(this.weekNumber);
    this.eventArray = [];
    this.showdiv = '';
    this.getEvents();
  }
  weekDays(weekNumber: number): void {
    const from_date = moment().day('Sunday').week(weekNumber);
    const to_date = moment().day('Saturday').week(weekNumber);
    this.startDate = from_date;
    this.endDate = to_date;
    const now = from_date.clone();
    this.dates = [];
    while (now.isSameOrBefore(to_date)) {
      this.dates.push(now.format());
      now.add(1, 'days');
    }
  }

  goToday(): void {
    this.weekNumber = moment().week();
    this.weekDays(this.weekNumber);
    this.getEvents();
  }

  checkToday(dateObj: string | Date): boolean {
    const today = moment();
    const todayDateCircle = new Date(
      today.startOf('day').toISOString(),
    ).getTime();
    const objDate = moment(dateObj).startOf('day').toISOString();
    const dateInMilli = new Date(objDate).getTime();
    return dateInMilli === todayDateCircle;
  }

  timeFormul(): void {
    const time = {
      start: 'Tue Feb 23 2021 01:00:00 GMT+0500',
      end: 'Wed Feb 24 2021 00:00:00 GMT+0500',
    };

    const startTime = new Date(time.start).getTime();
    const endTime = new Date(time.end).getTime();

    const chunks = [];
    let hold = startTime;
    const threshold = 60 * 60 * 1000; // duration
    for (let j = startTime + threshold; j <= endTime; j += threshold) {
      const newEndTime = new Date(j);
      chunks.push({
        start: this.datePipe.transform(new Date(hold), 'shortTime'),
        end: this.datePipe.transform(newEndTime, 'shortTime'),
      });
      hold = newEndTime.getTime();
    }
    this.timeChunks = chunks; //time chunks on the left are printed by this object
    this.height = this.timeChunks.length * 60;
  }

  emptyEventArray(): void {
    this.eventArray = [];
  }
  getEvents(): void {
    const from_date = moment()
      .day('Sunday')
      .week(this.weekNumber)
      .startOf('day');

    const to_date = moment().day('Saturday').week(this.weekNumber).endOf('day');

    this.date = new Date();

    const params =
      'fromDate=' +
      from_date.toISOString() +
      '&toDate=' +
      to_date.toISOString();
    this.service.getbyParams(params).subscribe((res) => {
      this.weekEventData = res.data;

      //this.loading = true;
      for (let i = 0; i < res.data.length; i++) {
        /// to detect minutes in an hour
        const hoursFromDate = moment(res.data[i].fromDate).minutes(); // checking if fromDate tine is less then an hour so add/subtract height and top from below if/else
        const hoursToDate = moment(res.data[i].toDate).minutes(); // checking if toDate tine is less then an hour so add/subtract height and top from below if/else
        let newTop;
        let newHeight;
        if (hoursFromDate > 0 && hoursToDate == 0) {
          newTop = 30;
          newHeight = 30;
        } else if (hoursFromDate > 0 && hoursFromDate > 30 && hoursToDate > 0) {
          newTop = 30;
          newHeight = 0;
        } else if (
          hoursFromDate > 0 &&
          hoursFromDate <= 30 &&
          hoursToDate > 0
        ) {
          newTop = 0;
          newHeight = 0;
        } else if (hoursFromDate == 0 && hoursToDate > 0) {
          newTop = 0;
          newHeight = -30;
        } else {
          newTop = 0;
          newHeight = 0;
        }

        const startTimeHeight = new Date(res.data[i].fromDate).getTime();
        const endTimeHeight = new Date(res.data[i].toDate).getTime();

        const chunksHeight = [];
        let holdHeight = startTimeHeight;
        const thresholdHeight = 60 * 60 * 1000; // duration
        for (
          let j = startTimeHeight + thresholdHeight;
          j <= endTimeHeight;
          j += thresholdHeight
        ) {
          const newEndTimeHeight = new Date(j);
          chunksHeight.push({
            start: this.datePipe.transform(new Date(holdHeight), 'shortTime'),
            end: this.datePipe.transform(newEndTimeHeight, 'shortTime'),
          });
          holdHeight = newEndTimeHeight.getTime();
        }
        let newHeightadd = 0;
        if (
          this.datePipe.transform(res.data[i].fromDate, 'hh:mm:ss a', 'UTC') ==
          '12:00:00 AM'
        ) {
          newHeightadd = 0;
        } else {
          //newHeightadd = 60;
          newHeightadd = 0;
        }

        let givenHeight = chunksHeight.length * 60;
        givenHeight = givenHeight + newHeight + newHeightadd;

        if (givenHeight > 1380) {
          givenHeight = 1380;
        }

        const timeStatic = new Date('2021-02-25T20:00:00.000Z').getTime();

        ////////////////////////////// formula for top

        const timeUpdate =
          this.datePipe.transform(res.data[i].fromDate, 'yyy-MM-dd') +
          ' ' +
          this.datePipe.transform(timeStatic, 'hh:mm:ss a');

        const startTimeTop = new Date(timeUpdate).getTime();
        const endTimeTop = new Date(res.data[i].fromDate).getTime();

        const chunksTop = [];
        let holdTop = startTimeTop;
        const thresholdTop = 60 * 60 * 1000; // duration
        for (
          let j = startTimeTop + thresholdTop;
          j <= endTimeTop;
          j += thresholdTop
        ) {
          const newEndTimeTop = new Date(j);
          chunksTop.push({
            start: this.datePipe.transform(new Date(holdTop), 'shortTime'),
            end: this.datePipe.transform(newEndTimeTop, 'shortTime'),
          });
          holdTop = newEndTimeTop.getTime();
        }
        let givenTop = (chunksTop.length + 1) * 60;
        givenTop = givenTop + newTop;
        // givenTop = givenTop + newTop old

        const to_date = moment(res.data[i].toDate);
        const from_date = moment(res.data[i].fromDate);
        const day_diff = to_date.diff(from_date, 'days');

        let attendeesCount = 0;

        for (let j = 0; j < res.data[i].attendees.length; j++) {
          if (res.data[i].attendees[j].rsvp === true) {
            attendeesCount++;
          }
        }

        const eventObj = {
          id: res.data[i].id,
          title: res.data[i].title,
          fromDate: moment(res.data[i].fromDate)
            .utcOffset(5)
            .format('YYYY-MM-DD HH:mm'),
          toDate: this.datePipe.transform(res.data[i].toDate, 'M/d/yy', 'UTC'),
          fromTime: res.data[i].fromDate,
          toTime: res.data[i].toDate,
          height: givenHeight,
          top: givenTop,
          image: res.data[i].eventBanner,
          location: res.data[i].eventLocation,
          description: res.data[i].description,
          capacity: res.data[i].capacity,
          eventBanner: {
            url: res.data[i].eventBanner.url,
          },
          rsvp: res.data[i].rsvp,
          attendCount: attendeesCount,
          visible: res.data[i].visible,
          dayDiff: day_diff,
          isAllDayEvent: res.data[i].isAllDayEvent,
          color: res.data[i].color,
          attendees: res.data[i].attendees,
        };
        this.eventArray.push(eventObj);
        //this.loading = false;
      }
    });
  }

  checkdate(
    startDate: string | Date,
    endDate: string | Date,
    currentDate: string | Date,
  ): boolean {
    const fromDateMonthWise = moment(startDate).format('DD/MM/YYYY');
    const toDateMonthWise = moment(endDate).format('DD/MM/YYYY');
    const dateTemp = new Date(currentDate);
    const currentDateMonthWise = moment(dateTemp).format('DD/MM/YYYY');

    if (
      fromDateMonthWise == currentDateMonthWise ||
      (currentDateMonthWise > fromDateMonthWise &&
        currentDateMonthWise < toDateMonthWise) ||
      toDateMonthWise == currentDateMonthWise
    ) {
      return true;
    } else {
      return false;
    }
  }

  isSameMonth(date: Date): boolean {
    return date.getMonth() === this.date.getMonth();
  }

  openDetaiPopup(id: any): void {
    this.dialog.open(EventDetailModalComponent, {
      data: { id: id },
      panelClass: 'modal-small-width',
    });
    this.showdiv = '';
  }

  onClickedOutside(): void {
    this.showdiv = '0';
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
