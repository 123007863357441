import { Injectable } from '@angular/core';
import { GenericService } from '../generic/generic.service';

@Injectable({
  providedIn: 'root',
})
export class DaysService {
  constructor(private generic: GenericService) {}
  controller = 'days';
  get() {
    //getting data from service in this method and showing it on list
    return this.generic.getData(this.controller);
  }
}
