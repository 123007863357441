import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
// export interface DialogData {
//   message: string
// }

@Component({
  selector: 'app-custom-snackbar-delete',
  templateUrl: './custom-snackbar-delete.component.html',
  styleUrls: ['./custom-snackbar-delete.component.scss'],
})
export class CustomSnackbarDeleteComponent implements OnInit {
  now: number;
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    public snackBar: MatSnackBar,
  ) {}

  ngOnInit() {
    setInterval(() => {
      this.now = Date.now();
    }, 1);
  }
}
